/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
/**
 *
 * `PageGroupsOrganizationDetail` shows summary details of a group, and it's members.
 *
 *
 * ```
 * <Page::Groups::Organization::Detail
 *   @group={{foo}}
 *   @rawGroupPolicy={{bar}}
 *   @members={{array bar baz}}
 *   @groupLevelPermissions={{array bar baz}}
 *   @eligibleMembers={{array bar baz}}
 *   @organization={{organization}}
 *   @scimEnabled={{false}}
 *   @truncatedResourceName='my-awesome-...'
 *   @isProjectContext={{true}}
 * />
 * ```
 *
 * @class PageGroupsOrganizationDetail
 *
 */

export default class PageGroupsOrganizationDetailComponent extends Component {
  /**
   * @argument group
   * @type {Object}
   */
  /**
   * @argument rawGroupPolicy
   * @type {<Object>}
   */
  /**
   * @argument members
   * @type {Array<Object>}
   */
  /**
   * @argument groupLevelPermissions
   * @type {Array<String>}
   */
  /**
   * @argument eligibleMembers
   * @type {Array<Object>}
   */
  /**
   * @argument organization
   * @type {Object}
   */
  /**
   * @argument scimEnabled
   * @type {Boolean}
   */
  /**
   * @argument truncatedResourceName
   * @type {String}
   */
  /**
   * @argument isProjectContext
   * @type {Boolean}
   */

  @tracked isActive = false;
  @action
  openDeleteModal() {
    this.isActive = true;
  }

  @action
  closeDeleteModal() {
    this.isActive = false;
  }
}
