import Service from '@ember/service';
import { assert } from '@ember/debug';
import * as Sentry from '@sentry/ember';
import { variation } from 'ember-launch-darkly';

import { MarketingEmailStatuses } from 'core/utils/marketing-email';
import { DEVELOPMENT as defaultConfig } from 'core/utils/cloud-ui-index';

export default class MarketingEmailService extends Service {
  config = defaultConfig; // NOTE: overriden by the marketing-email instance initializer

  async status() {
    assert(
      `URL must be configured to pull the "X-Mktg-Email" header from, but found "${this.config?.url}" ...`,
      !!this.config?.url
    );

    try {
      if (!variation('hcp-id-marketing-email-geolocation')) {
        return MarketingEmailStatuses.UNKNOWN;
      }

      const portalHeadResponse = await fetch(this.config.url, {
        method: 'OPTIONS',
      });
      const status = portalHeadResponse.headers.get('x-hcp-mktg-email');

      const allowedMarketingEmailStatuses = Object.values(
        MarketingEmailStatuses
      );
      if (!allowedMarketingEmailStatuses.includes(status)) {
        const invalidMarketingEmailHeaderException = new Error(
          `Value "${status}" is not allowed for header "X-Hcp-Mktg-Email"`
        );
        Sentry.captureException(invalidMarketingEmailHeaderException);

        return MarketingEmailStatuses.UNKNOWN;
      }

      return status;
    } catch (e) {
      Sentry.captureException(e);

      return MarketingEmailStatuses.UNKNOWN;
    }
  }
}
