import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import IamPolicy from 'core/utils/iam-policy';

export default class CloudAccessControlUsersDetailEditAssignmentRoute extends Route {
  @service abilities;

  previousRoute;

  async beforeModel(transition) {
    // We need to track where this user came from in order to redirect them
    // after they update the assignment.
    let previousRoute = transition.from?.name ?? '';

    // Strip the index so we can rely on the non-indexed route. This helps with
    // model refreshing if we're going from this route to the sibling index
    // route.
    if (previousRoute.endsWith('.index')) {
      previousRoute = previousRoute.replace('.index', '');
    }

    // Ensure that we're coming from a cloud route and not any other routes that
    // wouldn't make sense for a redirect. For example, we do not want to
    // redirect from sign-in or loading pages.
    if (!previousRoute.includes('cloud')) {
      previousRoute = 'cloud.access-control.users.list';
    }

    this.previousRoute = previousRoute;
  }

  async model() {
    return {
      ...this.modelFor('cloud.access-control.users.detail'),
      previousRoute: this.previousRoute,
    };
  }

  async afterModel({
    isProjectContext,
    userPrincipal,
    orgPolicy,
    projectPolicy,
  }) {
    if (isProjectContext) {
      if (
        this.abilities.cannot('update project-user', userPrincipal, {
          orgPolicy: orgPolicy ? new IamPolicy(orgPolicy) : undefined,
          projectPolicy: projectPolicy
            ? new IamPolicy(projectPolicy)
            : undefined,
        })
      ) {
        const error = new Error('ForbiddenError');
        error.name = 'ForbiddenError';
        error.status = 403;
        throw error;
      }
    } else {
      if (this.abilities.cannot('set-iam-policy in organization')) {
        const error = new Error('ForbiddenError');
        error.name = 'ForbiddenError';
        error.status = 403;
        throw error;
      }
    }
  }

  deactivate() {
    this.previousRoute = undefined;
  }
}
