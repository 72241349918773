/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { getOwner } from '@ember/owner';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';

/**
 *
 * `IamGroupsModalsRemoveGroup` renders the modal for removing a member from an HCP Group
 *
 *
 * ```
 * <Iam::Groups::Modals::RemoveMember
 *  @group={{@group}}
 *  @memberToRemove={{@memberToRemove}}
 *  @isActive={{this.isActive}}
 *  @closeModal={{this.closeRemoveModal}}
 * />
 * ```
 *
 * @class IamGroupsModalsRemoveGroup
 *
 */

/**
 * `group` is an object that contains the HCP Group properties
 * @argument group
 * @type {Object}
 */

/**
 * `memberToRemove is an object that contains properties of the member to remove from the HCP Group
 * @argument memberToRemove
 * @type {Object}
 */

/**
 * This shows or hides the modal based on truthiness.
 * @argument isActive
 * @type {Boolean}
 */

/**
 * A callback function to be called on a successful removal of a group member or
 * owhen a user cancels the modal.
 * @argument closeModal
 * @type {Function}
 */

export default class IamGroupsModalsRemoveGroupComponent extends Component {
  @service api;
  @service intl;
  @service flashMessages;
  @service router;
  @service userContext;

  @task
  *updateGroupMembers() {
    let resourceName = this.args.group.resourceName;

    let payload = {
      resourceName,
      memberPrincipalIdsToAdd: [],
      memberPrincipalIdsToRemove: [this.args.memberToRemove.id],
    };

    let flashMessageDetails = {
      groupName: this.args.group.displayName,
      memberNameOrEmail:
        this.args.memberToRemove.name || this.args.memberToRemove.email,
      htmlSafe: true,
    };

    try {
      yield this.api.groups.groupsServiceUpdateGroupMembers(
        resourceName,
        payload
      );

      const successTitle = this.intl.t(
        'components.iam.groups.form.remove-member.success.title'
      );

      this.flashMessages.success(successTitle, {
        content: this.intl.t(
          'components.iam.groups.form.remove-member.success.message',
          flashMessageDetails
        ),
      });

      //refresh the Group detail and list routes to update the model
      const detailRoute = getOwner(this).lookup(
        'route:cloud.access-control.groups.detail'
      );
      const listRoute = getOwner(this).lookup(
        'route:cloud.access-control.groups.list'
      );

      detailRoute.refresh();
      listRoute.refresh();

      yield this.args.closeModal();

      // transition to the Group detail route
      return this.router.transitionTo('cloud.access-control.groups.detail');
    } catch (err) {
      const errorTitle = this.intl.t(
        'components.iam.groups.form.remove-member.error.title'
      );
      this.flashMessages.error(errorTitle, {
        content: this.intl.t(
          'components.iam.groups.form.remove-member.error.message',
          flashMessageDetails
        ),
      });
      return this.router.transitionTo('cloud.access-control.groups.detail');
    }
  }
}
