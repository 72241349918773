/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { TYPE_NETWORK } from 'common/utils/cloud-resource-types';
import { action } from '@ember/object';
import {
  RegionProviders,
  DEFAULT_HVN_PROVIDER_REGION,
  DEFAULT_SELECTED_PROVIDER_REGION,
} from 'core/utils/regions';
import { NETWORK_SUBMITTED } from 'core/utils/consts/analytics-events/platform';
import { variation } from 'ember-launch-darkly';

/**
 *
 * `PageHvnsCreate` renders the HashiCorp Virtual Networks Create page.
 *
 *
 * ```
 * <Page::Hvns::Create
 *   @model={{@model}}
 * />
 * ```
 *
 * @class PageHvnsCreate
 *
 */

/**
 *
 * `model` `project` and `networks` attributes
 * @argument model;
 * @type {Object}
 *
 */
export default class PageHvnsCreateComponent extends Component {
  @service analytics;
  @service api;
  @service abilities;
  @service flashMessages;
  @service intl;

  @service router;

  @tracked errors;
  @tracked id = 'hvn';
  @tracked cidrBlock = '172.25.16.0/20';
  @tracked provider = DEFAULT_HVN_PROVIDER_REGION.provider;
  @tracked region = DEFAULT_HVN_PROVIDER_REGION.region;

  get resourceType() {
    return TYPE_NETWORK;
  }

  get supportedRegions() {
    // HVN Create route model has awsRegions and azureRegions
    return this.args.model[`${RegionProviders[this.provider]}Regions`] || [];
  }
  @action
  onProviderChange({ target }) {
    let { value } = target;
    this.provider = value;
    this.region = DEFAULT_SELECTED_PROVIDER_REGION[value];
  }

  @task
  *save(evt) {
    // don't submit the form
    evt.preventDefault();

    let {
      parent: { id: orgId },
      id: projectId,
    } = this.args.model.project;
    let { id, cidrBlock, provider, region } = this;
    let resp;
    let payload = {
      network: {
        id,
        cidrBlock,
        location: {
          region: {
            provider,
            region,
          },
        },
      },
    };

    this.analytics.trackEvent(NETWORK_SUBMITTED, payload);

    // TODO: [TCE-2007] Remove HVN activation A/B test analytics instrumentation.
    const abTestVariation = variation('hcp-ui-ab-test-hvn-activation-create');

    // TODO: [TCE-2007] Remove HVN activation A/B test analytics instrumentation.
    this.analytics.trackEvent(
      'external_ui_hvn_activation_create_success_form_submitted',
      { ...payload, abTestVariation }
    );

    resp = yield this.api.network.create(orgId, projectId, payload);
    this.flashMessages.success(
      this.intl.t('components.page.hvns.create.success.title')
    );

    return this.router.transitionTo(
      'cloud.orgs.detail.projects.detail.hvns.detail',
      orgId,
      projectId,
      resp.network.id
    );
  }
}
