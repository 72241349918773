/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

/**
 *
 * `PageSignInMain` renders the main content panel for the sign-in page and
 * redirects users to the auth provider.
 *
 *
 * ```
 * <Page::SignInMain
 *  @connection={{@connection}}
 *  @error={{@error}}
 *  @isSignUpPage={{true}}
 * />
 * ```
 *
 * @class PageSignInMain
 *
 */
/**
 *
 * `error` will be a sign in error.
 * @argument error;
 * @type {String}
 *
 */

/**
 *
 * `connection` is a string specifying the type of auth connection
 * @argument connection;
 * @type {String}
 *
 */

/**
 *
 * `isSignUpPage` is a boolean and determines the headline and order of the
 * buttons.
 *
 * true: The sign-up headline renders, the create account button renders
 * first, the sign-in button renders second.
 *
 * false: The sign-in headline renders, the sign in button is first and the
 * create account button is second.
 *
 * @argument isSignUpPage;
 * @type {Boolean}
 *
 */

export const CONNECTION_TYPE_EMAIL = 'email';

export default class PageSignInMainComponent extends Component {
  @service config;

  CONNECTION_TYPE_EMAIL = CONNECTION_TYPE_EMAIL;

  get authenticatorType() {
    return this.config.authenticatorType;
  }
}
