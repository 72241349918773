/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { assert } from '@ember/debug';

export default class IamInvitationsModalsResendComponent extends Component {
  /**
   * Passed in callback function to cancel / close modal
   * @argument onClose
   * @type {Function}
   * @required
   */

  /**
   * Passed in callback function that cancels and then creates an invitation
   * @argument onSubmit
   * @type {Function}
   * @required
   */

  /**
   * Required argument whose value determines whether or not the modal is active
   * @argument isActive
   * @type {Boolean}
   * @required
   */

  /**
   * Required argument that is used to display the invitee's email address
   * @argument invite
   * @type {Object}
   * @required
   */

  constructor() {
    super(...arguments);

    assert(
      '<Iam::Invitations::Modals::Resend> required argument @onCancel is a defined function',
      typeof this.args.onClose === 'function'
    );

    assert(
      '<Iam::Invitations::Modals::Resend> required argument @onSubmit is a defined function',
      typeof this.args.onSubmit === 'function'
    );

    assert(
      '<Iam::Invitations::Modals::Resend> required argument @isActive is a defined boolean',
      typeof this.args.isActive === 'boolean'
    );

    assert(
      '<Iam::Invitations::Modals::Resend> required argument @invite is a defined object',
      typeof this.args.invite === 'object'
    );
  }
}
