import Route from '@ember/routing/route';

export default class CloudOrgsDetailSettingsIndexRenameRoute extends Route {
  queryParams = {
    next: {
      replace: true,
    },
  };

  async model() {
    let { organization } = this.modelFor('cloud.orgs.detail.settings.index');
    let { next } = this.paramsFor(this.routeName);
    return {
      next,
      organization,
    };
  }
}
