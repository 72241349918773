export async function errorsFromResponse(e) {
  let errors = {};
  if (e.status >= 500 && e.status < 600) {
    return (errors = { pageError: true });
  } else if (e.status >= 400 && e.status < 500) {
    let server_errors = e.json && (await e.json());
    server_errors.details.forEach((detail) => {
      Object.keys(detail).forEach((key) => {
        errors[key] = Object.values(detail[key]).reduce(function (
          errorMap,
          detailObject
        ) {
          errorMap[detailObject.field] = detailObject.description;
          return errorMap;
        }, {});
      });
    });
    return errors;
  }
}
export default errorsFromResponse;
