import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { variation } from 'ember-launch-darkly';

export default class ResourcesQuickStartRoute extends Route {
  @service api;
  @service userContext;
  @service router;

  async beforeModel() {
    let { project, organization } = this.userContext;
    let resp = await this.api.network.list(organization.id, project.id);

    if (!variation('hcp-ui-consul-quick-start')) {
      this.router.transitionTo('cloud.orgs');
    }

    // quickstart is only available when no HVNs exist
    if (resp.networks.length > 0) {
      this.router.transitionTo('cloud.orgs');
    }
  }

  async model() {
    return this.modelFor('cloud.services');
  }
}
