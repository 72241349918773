/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

/**
 *
 * `IamInvitationsBasicRoleChartProjectPermissionsTable` is a table to
 * display the permissions for project-level basic roles.
 *
 *
 * ```
 * <Iam::Invitations::BasicRoleChart::Project::PermissionsTable />
 * ```
 *
 * @class IamInvitationsBasicRoleChartProjectPermissionsTable
 *
 */

export default class IamInvitationsBasicRoleChartProjectPermissionsTableComponent extends Component {
  @service intl;

  allowedIcon = 'check';
  deniedIcon = 'skip';

  basicRoles = [
    {
      ability: this.intl.t(
        'iam.invitations.basic-role-chart.flyout.view-project'
      ),
      isAdminAllowed: true,
      isBrowserAllowed: true,
      isContributorAllowed: true,
      isViewerAllowed: true,
    },
    {
      ability: this.intl.t(
        'iam.invitations.basic-role-chart.flyout.edit-project-permissions'
      ),
      isAdminAllowed: true,
      isBrowserAllowed: false,
      isContributorAllowed: false,
      isViewerAllowed: false,
    },
    {
      ability: this.intl.t(
        'iam.invitations.basic-role-chart.flyout.delete-project'
      ),
      isAdminAllowed: true,
      isBrowserAllowed: false,
      isContributorAllowed: false,
      isViewerAllowed: false,
    },
    {
      ability: this.intl.t(
        'iam.invitations.basic-role-chart.flyout.create-and-delete-project-resources'
      ),
      isAdminAllowed: true,
      isBrowserAllowed: false,
      isContributorAllowed: true,
      isViewerAllowed: false,
    },
    {
      ability: this.intl.t(
        'iam.invitations.basic-role-chart.flyout.manage-project-service-principals'
      ),
      isAdminAllowed: true,
      isBrowserAllowed: false,
      isContributorAllowed: false,
      isViewerAllowed: false,
    },
    {
      ability: this.intl.t(
        'iam.invitations.basic-role-chart.flyout.manage-group-role-for-project'
      ),
      isAdminAllowed: true,
      isBrowserAllowed: false,
      isContributorAllowed: true,
      isViewerAllowed: false,
    },
  ];
}
