/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

/**
 *
 * `PageHvnPeeringsConfigurationAccordionWebConsole` description here.
 *
 *
 * ```
 * <Page::HvnPeerings::ConfigurationAccordion::WebConsole
 *   @model={{@model}}
 *   @connectionMethod={{@connectionMethod}}
 *   @connection={{hash region=this.region cidrBlock=this.cidrBlock}}
 *   @products={{this.products}}
 *   @product={{@product}}
 *   @awsDisclosureGuides={{this.awsDisclosureGuides}}
 * />
 * ```
 *
 * @class PageHvnPeeringsConfigurationAccordionWebConsole
 *
 */

export default class PageHvnPeeringsConfigurationAccordionWebConsoleComponent extends Component {
  @service router;

  @action
  updateQueryParams(queryParams = {}) {
    return this.router.transitionTo(this.router.currentRouteName, {
      queryParams,
    });
  }
}
