import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

/**
 *
 * In addition to the constraints provided in the `beforeModel` hook, this route is accessible by:
 *
 * 1. Visiting a HashiCorp app that uses Auth0
 * 2. After logging in with Auth0, an auth0 rule (written in terraform) runs a function that checks for service agreement metadata (see https://github.com/hashicorp/cloud-terraform/pull/1977)
 * 3. If the metadata isn't found, the rule redirects to the `ServiceAgreements` route (see https://github.com/hashicorp/cloud-ui/pull/1408)
 * 4. Once the `ServiceAgreements` form is submitted, it's POSTs to auth0 and the rule intercepts the call and writes the metadata to auth0
 * 5. The rule then continues the call to auth0 where the authentication process is completed
 * 6. The user is then redirected to the originating HashiCorp app
 *
 * @class ServiceAgreementsRoute
 *
 */
export default class ServiceAgreementsRoute extends Route {
  @service router;
  @service marketingEmail;

  queryParams = {
    state: {
      replace: true,
    },
  };

  async beforeModel() {
    let { state } = this.paramsFor(this.routeName);
    if (!state) this.router.transitionTo('cloud.orgs.list');
  }

  async model() {
    const marketingEmailStatus = await this.marketingEmail.status();

    return { marketingEmailStatus };
  }

  resetController(controller) {
    controller.state = undefined;
  }
}
