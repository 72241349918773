import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class CloudOrgsDetailProjectsDetailActiveResourcesController extends Controller {
  queryParams = [
    {
      activeResourcesListOrigin: 'content_origin',
    },
  ];

  @tracked activeResourcesListOrigin = null;
}
