import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class LicenseUtilizationRoute extends Route {
  @service router;
  @service currentUser;

  async beforeModel() {
    this.router.transitionTo('license-utilization.reports.create');
  }
}
