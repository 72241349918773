/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { variation } from 'ember-launch-darkly';

const placeholder = '*****************************************************';

/**
 *
 * `SsoConfigureOidc` is a generic encapsulation of the HTML form
 * and client side validation logic used to configure new OIDC connections, as well as
 * edit existing OIDC configurations.
 *
 * @class SsoConfigureOidc
 *
 */

export default class SsoConfigureOidc extends Component {
  @service config;

  FALLBACK_DEFAULT_ROLE = variation(
    'hcp-identity-sso-default-role-empty-string-no-role'
  )
    ? 'none'
    : 'roles/viewer';

  @tracked clientId = this.args.configuration?.clientId ?? '';
  @tracked clientSecret = this.args.configuration ? placeholder : '';
  @tracked clientSecretIsDisabled = this.args.configuration ? true : false;
  @tracked defaultRole =
    this.args.configuration?.defaultRole ?? this.FALLBACK_DEFAULT_ROLE;
  @tracked issuer = this.args.configuration?.issuer ?? '';

  get callbackUrl() {
    return this.config.app.baseIDPHost + '/sso/oidc/callback';
  }

  @action
  handleEditSecretClick() {
    if (this.args.configuration && this.clientSecret === placeholder) {
      this.clientSecretIsDisabled = false;
      this.clientSecret = '';

      setTimeout(() => {
        // pushes this to the bottom of the stack. Won't focus the input without timeout
        document.getElementById('client-secret-input').focus();
      }, 0);
    }
  }

  @action
  clientSecretBlurred() {
    if (this.args.configuration && !this.clientSecret) {
      this.clientSecretIsDisabled = true;
      this.clientSecret = placeholder;
    }
  }

  // the update request body must include an updateMask property
  @action
  setRequestBody(evt) {
    evt.preventDefault();
    let body;

    if (this.args.configuration) {
      body = {
        updateMask: {
          paths: [],
        },
      };
      if (this.clientId !== this.args.configuration.clientId) {
        body.clientId = this.clientId;
        body.updateMask.paths.push('client_id');
      }

      if (this.issuer !== this.args.configuration.issuer) {
        body.issuer = this.issuer;
        body.updateMask.paths.push('issuer');
      }

      if (this.clientSecret && this.clientSecret !== placeholder) {
        body.clientSecret = this.clientSecret;
        body.updateMask.paths.push('client_secret');
      }

      if (this.args.emailDomains[0] !== this.args.configuration.emailDomain) {
        body.emailDomain = this.args.emailDomains[0];
        body.updateMask.paths.push('email_domain');
      }

      if (variation('hcp-identity-sso-default-role')) {
        body.defaultRole = this.defaultRole;
        body.updateMask.paths.push('default_role');
      }
    } else {
      body = {
        organizationId: this.args.model.org.id,
        clientId: this.clientId,
        issuer: this.issuer,
        emailDomain: this.args.emailDomains[0],
        clientSecret: this.clientSecret,
      };

      if (variation('hcp-identity-sso-default-role')) {
        body.defaultRole = this.defaultRole;
      }
    }

    return this.args.save.perform(body);
  }
}
