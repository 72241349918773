/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { getOwner } from '@ember/owner';

/**
 *
 * `IamGroupsModalsDeleteGroup` renders the modal for deleting an HCP Group from their organization
 *
 *
 * ```
 * <Iam::Groups::Modals::DeleteGroup
 *  @group={{@group}}
 *  @groupName='Engineering'
 *  @isActive={{this.isActive}}
 *  @closeModal={{this.closeModal}}
 * />
 * ```
 *
 * @class IamGroupsModalsDeleteGroup
 *
 */

/**
 * `groupName` is the name of the Group
 * @argument groupName
 * @type {String}
 */

/**
 * This shows or hides the modal based on truthiness.
 * @argument isActive
 * @type {Boolean}
 */

/**
 * A callback function to be called on a successful removal of a Group.
 * @argument onSuccess
 * @type {String}
 */

export default class IamGroupsModalsDeleteGroupComponent extends Component {
  @service api;
  @service intl;
  @service flashMessages;
  @service router;
  @service userContext;

  // This task is required for Core::Modal::Confirm
  @task
  *deleteGroup() {
    try {
      yield this.api.groups.groupsServiceDeleteGroup(
        this.args.group.resourceName
      );
      const successTitle = this.intl.t(
        'components.iam.groups.form.delete-group.success.title'
      );
      this.flashMessages.success(successTitle, {
        content: this.intl.t(
          'components.iam.groups.form.delete-group.success.message',
          {
            htmlSafe: true,
            groupName: this.args.groupName,
          }
        ),
      });

      //refresh the Groups list route to update the model
      const listRoute = 'cloud.access-control.groups.list';
      let route = getOwner(this).lookup(`route:${listRoute}`);
      route.refresh();

      yield this.args.closeModal();

      // transition to the Groups list route
      return this.router.transitionTo('cloud.access-control.groups.list');
    } catch (err) {
      const errorTitle = this.intl.t(
        'components.iam.groups.form.delete-group.error.title'
      );
      this.flashMessages.error(errorTitle, {
        content: this.intl.t(
          'components.iam.groups.form.delete-group.error.message',
          {
            htmlSafe: true,
            groupName: this.args.groupName,
          }
        ),
      });
      throw err;
    }
  }
}
