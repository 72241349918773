/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { getOwner } from '@ember/owner';

/**
 *
 * `PageAccountSettingsSessionManager` description here.
 *
 *
 * ```
 * <Page::AccountSettings::SessionManager
 *   @testArg={{foo}}
 * />
 * ```
 *
 * @class PageAccountSettingsSessionManager
 *
 */

export default class PageAccountSettingsSessionManagerComponent extends Component {
  @service api;
  @service currentUser;
  @service router;

  @tracked showRevokedSessionsModal = false;

  @task *revokeAllSessions() {
    // prevents test failure in acceptance test
    if (this.isDestroyed || this.isDestroying) return;

    this.showRevokedSessionsModal = false;
    yield this.api.sessionManager.sessionServiceRevokeMySessions();
    this.router.transitionTo('sign-out');
  }

  // revokeSession revokes a specific session and then refreshes the list.
  @task *revokeSession(sid) {
    // call the endpoint
    yield this.api.sessionManager.sessionServiceRevokeSessionByID(sid);

    // refresh the model
    let route = getOwner(this).lookup(`route:${this.router.currentRouteName}`);
    route.refresh();
  }
}
