let TABLE_HEADERS = [
  'components.page.hvn-peerings.detail.instructions.azure.active.web-console.consul-rules.security-rules.th.priority',
  'components.page.hvn-peerings.detail.instructions.azure.active.web-console.consul-rules.security-rules.th.name',
  'components.page.hvn-peerings.detail.instructions.azure.active.web-console.consul-rules.security-rules.th.port',
  'components.page.hvn-peerings.detail.instructions.azure.active.web-console.consul-rules.security-rules.th.protocol',
  'components.page.hvn-peerings.detail.instructions.azure.active.web-console.consul-rules.security-rules.th.source',
  'components.page.hvn-peerings.detail.instructions.azure.active.web-console.consul-rules.security-rules.th.destination',
  'components.page.hvn-peerings.detail.instructions.azure.active.web-console.consul-rules.security-rules.th.action',
];

let InboundRules = (model) => {
  return [
    {
      priority: 400,
      name: 'ConsulServerInbound',
      port: 8301,
      protocol: 'Any',
      source: model.network.cidrBlock,
      destination: 'VirtualNetwork',
      action: 'Allow',
    },
    {
      priority: 401,
      name: 'ConsulClientInbound',
      port: 8301,
      protocol: 'Any',
      source: 'VirtualNetwork',
      destination: 'VirtualNetwork',
      action: 'Allow',
    },
  ];
};

let OutboundRules = (model) => {
  return [
    {
      priority: 400,
      name: 'ConsulServerOutbound',
      port: '8300-8301',
      protocol: 'Any',
      source: 'VirtualNetwork',
      destination: model.network.cidrBlock,
      action: 'Allow',
    },
    {
      priority: 401,
      name: 'ConsulClientOutbound',
      port: 8301,
      protocol: 'Any',
      source: 'VirtualNetwork',
      destination: 'VirtualNetwork',
      action: 'Allow',
    },
    {
      priority: 402,
      name: 'HTTPOutbound',
      port: 80,
      protocol: 'Any',
      source: 'VirtualNetwork',
      destination: model.network.cidrBlock,
      action: 'Allow',
    },
    {
      priority: 403,
      name: 'HTTPSOutbound',
      port: 443,
      protocol: 'Any',
      source: 'VirtualNetwork',
      destination: model.network.cidrBlock,
      action: 'Allow',
    },
  ];
};

let AgentlessRules = (model) => {
  return [
    {
      priority: 401,
      name: 'ConsulServerOutboundGRPC',
      port: 8502,
      protocol: 'TCP',
      source: 'VirtualNetwork',
      destination: model.network.cidrBlock,
      action: 'Allow',
    },
  ];
};

export { TABLE_HEADERS, InboundRules, OutboundRules, AgentlessRules };
