/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

/**
 *
 * `PageAccountSettingsBasicInfo` renders the user's profile.
 *
 *
 * ```
 * <Page::AccountSettings::BasicInfo />
 * ```
 *
 * @class PageAccountSettingsBasicInfo
 *
 */

export default class PageAccountSettingsBasicInfoComponent extends Component {
  @service currentUser;
}
