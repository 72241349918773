/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked, TrackedObject } from 'tracked-built-ins';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';

/**
 *
 * A component that encapsulates the Project resource's mutation logic
 * (API, CRUD) as exposes them as design spec UI elements (Actions and Modals).
 *
 *
 * ```
 * <Page::Projects::MutationContainer
 *   as |Container|
 * >
 *  <Container.Actions />
 *  <Container.Modals />
 * </Page::Projects::MutationContainer />
 * ```
 *
 * @class PageProjectsMutationContainer
 *
 */

export default class PageProjectsMutationContainerComponent extends Component {
  @service abilities;
  @service api;
  @service('resources.project') projects;
  @service userContext;

  @tracked showDeleteModal = false;
  @tracked resourceToMutate = null;

  actions = { delete: 'delete' };

  get unifiedExperienceEnabled() {
    // Boolean flag that signals if the current organization has
    // the unified experience enabled.
    return this.userContext.organizationMeta.unifiedExperienceEnabled;
  }

  @task
  *getDependencies(resource) {
    let resources;

    if (this.abilities.can('list resources')) {
      ({ resources } =
        yield this.api.resourceManager.resources.resourceServiceList(
          'PROJECT',
          resource.id
        ));
    }

    return { resources };
  }

  @tracked modals = new TrackedObject({
    delete: { isVisible: false },
  });

  @action
  showModalChanged(action) {
    if (action === this.actions.delete) {
      this.showDeleteModal = !this.showDeleteModal;
    }
  }

  @action
  async setResourceToMutate(resource) {
    if (this.resourceToMutate?.id === resource.id) {
      return;
    }

    const { resources } = await this.getDependencies.perform(resource);

    // Append the dependencies to the project model so that the delete modal
    // knows if there are any active resources.
    this.resourceToMutate = {
      ...resource,
      resources,
    };
  }

  @action
  showModalByType(actionType) {
    const action = this.actions[actionType];

    if (action) {
      this.modals[action] = {
        ...this.modals[action],
        isVisible: true,
      };
    }
  }

  @action
  hideModalByType(actionType) {
    const action = this.actions[actionType];

    if (action) {
      this.modals[action] = {
        ...this.modals[action],
        isVisible: false,
      };
    }
  }
}
