/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

/**
 *
 * `DomainVerification` is the first step of both the OIDC and SAML SSO configuration flows.
 * Users are prompted to copy a TXT record to verify within their DNS, then to add
 * their email address domain(s).
 *
 *
 * ```
 * <DomainVerification />
 * ```
 *
 * @class DomainVerification
 *
 */
export default class DomainVerification extends Component {
  @tracked steps = [
    'components.page.organizations.sso.configure.common.step-1.instructions.1',
    'components.page.organizations.sso.configure.common.step-1.instructions.2',
    'components.page.organizations.sso.configure.common.step-1.instructions.3',
    'components.page.organizations.sso.configure.common.step-1.instructions.4',
    'components.page.organizations.sso.configure.common.step-1.instructions.5',
  ];
}
