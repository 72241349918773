/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

/**
 *
 * `PageOrganizationsActions` is a dropdown menu used to manage an organization.
 *
 *
 * ```
 * <Page::Organizations::Actions
 *   @isToggleIcon={{true}}
 *   @next='cloud.orgs.detail'
 *   @organization={{organization}}
 *   @canRename={{true}}
 * />
 * ```
 *
 * @class PageOrganizationsActions
 *
 */

export default class PageOrganizationsActionsComponent extends Component {}
