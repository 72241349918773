import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import {
  PREFIX_IAM_GROUPS,
  ACTION_UPDATE,
} from 'authz/utils/permission-types/index';
import permissionGuard from 'core/decorators/permission-guard';

export default class CloudAccessControlGroupsDetailMembersEditRoute extends Route {
  @service api;
  @service router;
  @service userContext;
  @service groupMembers;

  serialize(_, params) {
    // NOTE: We're decoding the v2 `resource_name`, not serializing it ... that's OK.
    return {
      ...params,
      resource_name: decodeURIComponent(params.resource_name),
    };
  }

  async redirect() {
    // temporarily redirecting all traffic away from this route until if/when it's reimplemented. currently not in use.
    return this.router.transitionTo('cloud.access-control');
  }

  @permissionGuard({
    permission: `${PREFIX_IAM_GROUPS}.${ACTION_UPDATE}`,
  })
  async model() {
    this.groupMembers.reset();
    const { organization } = this.userContext;
    const { resource_name: resourceName } = this.paramsFor(
      'cloud.access-control.groups.detail'
    );

    const filter = {
      organizationId: organization.id,
      searchText: '',
      principalTypes: ['PRINCIPAL_TYPE_USER'],
      excludeMembersOfGroupNames: [resourceName],
    };

    const { members } =
      await this.api.groups.groupsServiceListGroupMembers(resourceName);

    const { principals } = await this.api.iam.iamServiceSearchPrincipals(
      organization.id,
      { filter }
    );

    let eligibleMembers = [];
    //put the principals in the list
    principals.forEach((principal) => {
      eligibleMembers.push(principal);
    });

    //put the existing members in the list
    members.forEach((member) => {
      member.checked = true;
      eligibleMembers.push(member);
      this.groupMembers.setCurrentMembersIds(member);
    });

    return {
      resourceName,
      eligibleMembers,
    };
  }
}
