/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

/**
 *
 * `ServicePrincipalKeysListTable` renders a generic list of service principal keys, organization/project scope agnostic.
 *
 *
 * ```
 * <ServicePrincipal::Keys::ListTable
 *  @keys={{...}}
 *  @handleConfirmDeleteKey={{...}}
 * />
 * ```
 *
 * @class ServicePrincipalKeysListTable
 *
 */

export default class ServicePrincipalKeysListTableComponent extends Component {
  /**
   * @argument keys
   * @type {Array.<Object>}
   * @required
   */
  /**
   * @argument handleConfirmDeleteKey
   * @type {Function}
   * @required
   */
}
