/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

/**
 *
 * `PageRolesList` lists the HCP Roles.
 *
 *
 * ```
 * <Page::Roles::List @model={{model}} />
 * ```
 *
 * @class PageRolesList
 *
 */

/**
 * `model` has the list of HCP roles.
 * @argument model
 * @type {Object}
 */
export default class PageRolesListComponent extends Component {}
