/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

/**
 *
 * `PageGroupsOrganizationList` is the parent wrapper component for the HCP Groups Organization list page
 *
 *
 * ```
 * <Page::Groups::Organization::List
 *  @groups={{this.model.groups}}
 * />
 * ```
 *
 * @class PageGroupsOrganizationList
 *
 */
/**
 *
 * @argument groups
 * @type {Object}
 *
 */

export default class PageGroupsOrganizationListComponent extends Component {}
