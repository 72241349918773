/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

/**
 *
 * `HcpMarketingMain` component is used to display HashiCorp Cloud Platform
 * branding and marketing information. It does not take any arguments.
 * All content is located within the component itself.
 *
 *
 * ```
 * <HcpMarketing::Main />
 * ```
 *
 * @class HcpMarketingMain
 *
 */

export default class HcpMarketingMainComponent extends Component {
  @service systemStatus;

  get statuspageUrl() {
    return this.systemStatus.statuspageUrl;
  }
}
