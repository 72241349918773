/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

/**
 *
 * `PageTerraform` description here.
 *
 *
 * ```
 * <Page::Terraform
 *   @testArg={{foo}}
 * />
 * ```
 *
 * @class PageTerraform
 *
 */

export default class PageTerraformComponent extends Component {
  @service userContext;
  /**
   * The description of `testArg` goes here. Any markdown you want!
   * @argument testArg
   * @type {string}
   */
  //testArg= null;
}
