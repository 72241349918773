/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/owner';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

/**
 *
 * `ScimTokensList` displays the token to be considered for removal in a modal
 *
 * ```
 * <Scim::Tokens::List
 *  @openTokenModal={{@model.openTokenModal}}
 *  @tokens={{@model.tokens}}
 *  @organization={{@model.organization}}
 * />
 * ```
 *
 * @class ScimTokensList
 *
 */

export default class ScimTokensList extends Component {
  /**
   * @argument openTokenModal
   * @type {Function}
   */
  /**
   * The tokens attached to a given organization
   * @argument tokens
   * @type {Object}
   * @required
   */
  /**
   * The organization
   * @argument organization
   * @type {Object}
   * @required
   */

  @service api;
  @service flashMessages;
  @service intl;
  @service router;

  @tracked tokenToDelete;
  @tracked showDeleteTokenModal = false;

  /**
   * Removes a scim token from an organization.
   */
  @task
  *deleteToken() {
    try {
      yield this.api.scim.scimServiceDeleteScimToken(
        this.args.organization.id,
        this.tokenToDelete.tokenId
      );
      this.flashMessages.success(
        this.intl.t(`components.scim.tokens.delete.success.title`, {
          tokenName: this.tokenToDelete.displayName,
          htmlSafe: true,
        })
      );
      this.closeDeleteTokenModal();
      this.refreshRoute();
      return;
    } catch (e) {
      this.closeDeleteTokenModal();
      this.flashMessages.error(
        this.intl.t(`components.scim.tokens.delete.error.title`, {
          tokenName: this.tokenToDelete.displayName,
          htmlSafe: true,
        }),
        {
          content: this.intl.t(`components.scim.tokens.delete.error.content`),
        }
      );
    }
  }

  @action
  openDeleteTokenModal(token) {
    this.showDeleteTokenModal = true;
    this.tokenToDelete = token;
  }

  @action
  closeDeleteTokenModal() {
    if (this.isDestroyed || this.isDestroying) return;
    this.showDeleteTokenModal = false;
    this.tokenToDelete = null;
  }

  @action
  refreshRoute() {
    let route = getOwner(this).lookup(
      `route:${this.router.currentRoute.parent.name}`
    );
    return route.refresh();
  }
}
