/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { DEBUG } from '@glimmer/env';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { TERRAFORM_IMPORTS } from 'core/utils/constants';

/**
 *
 * `PageHvnTransitGatewayAttachmentsDetail` renders the HVN TGW attachments Detail page.
 *
 *
 * ```
 * <Page::HvnTransitGatewayAttachments::Detail
 *   @model={{model}}
 *   @tab={{tab}}
 *   @product={{product}}
 * />
 * ```
 *
 * @class PageHvnTransitGatewayAttachmentsDetail
 *
 */
/**
 *
 * `tab` will be the selected tab;
 * @argument tab;
 * @type {String}
 *
 */
/**
 *
 * `product` such as consul or vault;
 * @argument product;
 * @type {String}
 *
 */
/**
 *
 * `model` will be a model containing the attachment detail;
 * @argument model;
 * @type {Object}
 *
 */

export default class PageHvnTransitGatewayAttachmentsDetailComponent extends Component {
  @service api;
  @service flashMessages;
  @service intl;
  @service operation;

  @tracked showDeleteModal = false;
  @tracked allowDelete = false;

  get instructionsId() {
    return 'instructions';
  }

  get terraformImport() {
    return TERRAFORM_IMPORTS.awsTransitGateway({
      hvnId: this.args.model.network.id,
      transitGatewayId: this.args.model.tgwAttachment.id,
    });
  }

  @task
  *deleteTransitGatewayAttachment(evt) {
    evt.preventDefault();
    let hvnId = this.args.model.network.id;
    let { organizationId, projectId } = this.args.model.network.location;
    let { id } = this.args.model.tgwAttachment;
    let operation;

    try {
      let resp = yield this.api.network.deleteTGWAttachment(
        organizationId,
        projectId,
        hvnId,
        id
      );
      this.flashMessages.success(
        this.intl.t(
          'components.page.hvn-transit-gateway-attachments.delete.success.title'
        )
      );
      operation = resp.operation;
    } catch (e) {
      if (DEBUG) {
        //eslint-disable-next-line no-console
        console.error(e);
      }
      throw e;
    }
    this.showDeleteModal = false;
    this.operation.addOperation(operation);
  }
}
