import Route from '@ember/routing/route';
import permissionGuard from 'core/decorators/permission-guard';
import {
  PREFIX_IAM_INVITATIONS,
  ACTION_CREATE,
} from 'authz/utils/permission-types/index';

export default class CloudAccessControlUsersInviteRoute extends Route {
  @permissionGuard({
    permission: `${PREFIX_IAM_INVITATIONS}.${ACTION_CREATE}`,
  })
  async afterModel() {}
}
