/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class HcpAlertTerraformPermissions extends Component {
  @service userContext;

  /** @type {boolean} */
  @tracked bannerDismissed = false;

  get showBanner() {
    return !this.bannerDismissed;
  }

  @action
  onDismiss() {
    this.bannerDismissed = true;
  }
}
