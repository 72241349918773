import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class TerraformRoute extends Route {
  @service userContext;

  model() {
    let {
      organizationMeta: { unifiedExperienceEnabled },
      organization,
      project,
    } = this.userContext;

    return { unifiedExperienceEnabled, organization, project };
  }
}
