/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

/**
 *
 * `PageHvnPeeringsConfigurationAccordionMarkCompleteAll` description here.
 *
 *
 * ```
 * <Page::HvnPeerings::ConfigurationAccordion::MarkCompleteAll
 *  @markCompleteAll={{this.markAllAsComplete}}
    @peeringMarkedComplete={{this.peeringMarkedComplete}}
 * />
 * ```
 *
 * @class PageHvnPeeringsConfigurationAccordionMarkCompleteAll
 *
 */
export default class PageHvnPeeringsConfigurationAccordionMarkCompleteAllComponent extends Component {}
