import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import {
  ACTION_SET_NAME,
  PREFIX_RESOURCE_MANAGER_ORGANIZATIONS,
} from 'authz/utils/permission-types/index';

export default class CloudOrgsListRoute extends Route {
  @service api;
  @service billing;
  @service currentUser;

  @service operation;
  @service userContext;
  @service router;

  deactivate() {
    // When we leave this route, we should start polling again.
    this.billing.start();
    this.operation.start();
  }

  async model() {
    let { organizations } =
      await this.api.resourceManager.org.organizationServiceList();

    // This nullifies the userContext which has side-effects such as hiding
    // the global app frame's sidebar. Most routes, especially at the 'cloud'
    // level, will set these if they aren't set. Unsetting the org also unsets
    // the project.
    await this.userContext.setOrganization(null);

    // When we enter this route, the operation and billing service won't have
    // an organization id to use for polling.
    this.billing.stop();
    this.operation.stop();

    for (let organization of organizations) {
      const { allowedPermissions } =
        await this.api.resourceManager.org.organizationServiceTestIamPermissions(
          organization.id,
          {
            permissions: [
              `${PREFIX_RESOURCE_MANAGER_ORGANIZATIONS}.${ACTION_SET_NAME}`,
            ],
          }
        );

      organization.canRename = allowedPermissions.includes(
        `${PREFIX_RESOURCE_MANAGER_ORGANIZATIONS}.${ACTION_SET_NAME}`
      );
    }

    this.currentUser.organizations = organizations;

    return {
      organizations,
      userId: this.userContext.currentUser?.user?.id,
      isSsoUser: this.userContext.currentUser.isSsoEnabled,
    };
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.contractActivation = null;
    }
  }
}
