import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class TfcMigrationNotificationRoute extends Route {
  @service userContext;

  async model() {
    const { user } = this.userContext.currentUser;

    return { user };
  }
}
