/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

/**
 * @typedef {Object} Service
 * Represents a single product with its associated data.
 * @property {string} logo - FlightIcon icon for the product logo.
 * @property {string} href - URL to the product's web page.
 * @property {TranslationKeys} translations - Translation keys for the product.
 */

/**
 * @typedef {Object} TranslationKeys
 * Holds the translation keys for various text elements of a product.
 * @property {string} product - Translation key for the product's name.
 * @property {string} description - Translation key for the product's description.
 * @property {string} learnLink - Translation key for the 'Learn More' link text.
 */

/**
 * @type {Object.<string, Service>}
 * A collection of services/products, each represented by a unique key.
 */
const SERVICES = {
  vault: {
    logo: 'vault',
    href: 'https://developer.hashicorp.com/hcp/docs/vault',
    translations: {
      product: 'service-card.vault',
      description: 'service-card.vault-no-role-text',
      learnLink: 'service-card.learn-more-link',
    },
  },

  secrets: {
    logo: 'vault-secrets',
    href: 'https://developer.hashicorp.com/hcp/docs/vault-secrets',
    translations: {
      product: 'service-card.secrets',
      description: 'service-card.secrets-get-started-text',
      learnLink: 'service-card.learn-more-link',
    },
  },

  consul: {
    logo: 'consul',
    href: 'https://developer.hashicorp.com/hcp/docs/consul',
    translations: {
      product: 'service-card.consul',
      description: 'service-card.consul-no-role-text',
      learnLink: 'service-card.learn-more-link',
    },
  },

  packer: {
    logo: 'packer',
    href: 'https://developer.hashicorp.com/hcp/docs/packer',
    translations: {
      product: 'service-card.packer',
      description: 'service-card.packer-no-role-text',
      learnLink: 'service-card.learn-more-link',
    },
  },

  waypoint: {
    logo: 'waypoint',
    href: 'https://developer.hashicorp.com/hcp/docs/waypoint',
    translations: {
      releaseBadge: 'nav.project.waypoint-badge',
      product: 'service-card.waypoint',
      description: 'service-card.waypoint-get-started-text',
      learnLink: 'service-card.learn-more-link',
    },
  },

  terraform: {
    logo: 'terraform',
    href: 'https://github.com/hashicorp/terraform-provider-hcp#readme',
    translations: {
      product: 'service-card.terraform.title',
      description: 'service-card.terraform-no-role-text',
      learnLink: 'service-card.learn-more-link',
    },
  },

  boundary: {
    logo: 'boundary',
    href: 'https://developer.hashicorp.com/hcp/docs/boundary',
    translations: {
      product: 'service-card.boundary',
      description: 'service-card.terraform-no-role-text',
      learnLink: 'service-card.learn-more-link',
    },
  },
};

/**
 *
 * `PageOrganizationsDetailNoRoleServiceCard` displays a dashboard for a Project.
 *
 *
 * ```
 * <Page::Organizations::Detail::NoRoleServiceCard
 *   @product='vault'
 * />
 * ```
 *
 * @class PageOrganizationsDetailNoRoleServiceCard
 *
 */

/**
 *
 * `product` is string of the product that should be respresented in the card.
 * A corresponding config key should be found in the SERVICES config abover for
 * the given string as well.
 * @argument product;
 * @type {String}
 *
 */

export default class PageOrganizationsDetailNoRoleServiceCardComponent extends Component {
  @service intl;

  get config() {
    const serviceConfigCopy = { ...SERVICES[this.args.product] };
    return serviceConfigCopy;
  }

  get translations() {
    let { translations } = this.config;
    let strings = {};
    let context = {
      product: this.intl.t(translations.product),
    };

    for (let [key, value] of Object.entries(translations)) {
      strings[key] = this.intl.t(value, context);
    }

    return strings;
  }

  get href() {
    return this.config?.href || null;
  }

  get linkText() {
    return this.translations.learnLink;
  }
}
