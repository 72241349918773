/**
 * Fetches data recursively until there are no more pages. This assumes two
 * things:
 *
 * 1. The response returns a pagination.nextPageToken.
 * 2. The next item in the initialArgs param after you pass the configuration
 *    values is the nextPageToken.
 *
 * @param {Function} apiFunc - The asynchronous API function to be called. This
 *     function must return a pagination object as one of its arguments, where
 *     `pagination.nextPageToken` is used for fetching subsequent pages.
 * @param {string} resultKey - The key in the response object from `apiFunc`
 *     that contains the data to be accumulated.
 * @param {...any} initialArgs - Initial arguments to be passed to `apiFunc`.
 *     If pagination is required, the function automatically handles it by using
 *     the nextPageToken from the HashiCorp pagination object, which must be the
 *     next in the list of arguments after `initialArgs`.
 * @returns {Promise<Object>} A promise that resolves with an object with the
 *     key of accumulated data from all pages.
 *
 * @example
 * const fetchAllGroups = fetchUntilEmpty(
 *   (...args) => this.api.groups.groupsServiceListGroups(...args),
 *   'groups',
 * );
 * const { groups } = await fetchAllGroups(
 *   `organization/${organization.id}`,
 *   undefined,
 *   groupIds,
 *   50
 * );
 */
export default function fetchUntilEmpty(apiFunc, resultKey) {
  async function fetchPage(
    accumulated,
    paginationNextPageToken,
    ...initialArgs
  ) {
    const args = paginationNextPageToken
      ? [...initialArgs, paginationNextPageToken]
      : initialArgs;

    const { pagination, ...rest } = await apiFunc(...args);
    const newAccumulated = accumulated.concat(rest[resultKey] || []);

    if (pagination?.nextPageToken && pagination.nextPageToken !== '') {
      return fetchPage(
        newAccumulated,
        pagination.nextPageToken,
        ...initialArgs
      );
    } else {
      return { [resultKey]: newAccumulated };
    }
  }

  return (...args) => fetchPage([], undefined, ...args);
}
