import type { OIDCToken } from './utils';

// The Issuer Identifier for the OpenID Provider (which is typically obtained during Discovery)
// MUST exactly match the value of the iss (issuer) Claim.
export function validateIssuerClaim(
	token: OIDCToken,
	providerIssuerID: string
) {
	if (token.claims['iss'] !== providerIssuerID) {
		throw new Error(
			'id_token issuer does not match the provider configuration'
		);
	}
}

// The Client MUST validate that the aud (audience) Claim contains its client_id value
// registered at the Issuer identified by the iss (issuer) Claim as an audience. The
// aud (audience) Claim MAY contain an array with more than one element. The ID Token
// MUST be rejected if the ID Token does not list the Client as a valid audience, or
// if it contains additional audiences not trusted by the Client.
export function validateAudienceClaim(token: OIDCToken, clientID: string) {
	if (!token.claims['aud'].includes(clientID)) {
		throw new Error(
			'id_token audience claim does not contain the expected client ID'
		);
	}
}

// If the ID Token contains multiple audiences, the Client SHOULD verify that an azp Claim is present.
//
// If an azp (authorized party) Claim is present, the Client SHOULD verify that its client_id is the Claim Value.
export function validateAzpClaim(token: OIDCToken, clientID: string) {
	if (token.claims['aud'] > 1) {
		if (!token.claims['azp']) {
			throw new Error(
				'id_token contains multiple audiences, but no authorized party claim is present'
			);
		}

		if (token.claims['azp'] !== clientID) {
			throw new Error(
				'id_token authorized party claim does not match the client_id'
			);
		}
	}
}

// The alg value SHOULD be the default of RS256 or the algorithm sent by the Client in the
// id_token_signed_response_alg parameter during Registration.
export function validateAlgValue(token: OIDCToken) {
	if (token.header.alg !== 'RS256') {
		throw new Error('id_token signed with unknown algorithm, not RS256');
	}
}

// The current time MUST be before the time represented by the exp Claim.
export function validateCurrentTime(token: OIDCToken) {
	const nowUNIX = Math.round(Date.now() / 1000);
	if (token.claims.exp <= nowUNIX) {
		throw new Error('id_token is expired');
	}
}
