/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { variation } from 'ember-launch-darkly';

/**
 *
 * `SsoConfigureSaml` is a generic encapsulation of the HTML form
 * and client side validation logic used to configure new SAML connections, as well as
 * edit existing SAML configurations.
 *
 * @class SsoConfigureSaml
 *
 */

export default class SsoConfigureSaml extends Component {
  FALLBACK_DEFAULT_ROLE = variation(
    'hcp-identity-sso-default-role-empty-string-no-role'
  )
    ? 'none'
    : 'roles/viewer';

  @tracked certificate = this.args.configuration?.certificate ?? '';
  @tracked signinUrl = this.args.configuration?.signinUrl ?? '';
  @tracked defaultRole = this.args.defaultRole ?? this.FALLBACK_DEFAULT_ROLE;

  @action
  setRequestBody(evt) {
    evt.preventDefault();

    let body = {
      organizationId: this.args.model.org.id,
      config: {
        type: 'SAML',
        saml: {
          ...this.configuration,
          certificate: this.certificate,
          signinUrl: this.signinUrl,
          emailDomains: this.args.emailDomains,
        },
      },
    };

    if (variation('hcp-identity-sso-default-role')) {
      body.config.defaultRole = this.defaultRole;
    }

    return this.args.save.perform(body);
  }
}
