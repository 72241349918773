/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

/**
 * ```
 * <Page::TfcMigrationNotification
 *   @user={{this.userContext.currentUser.user}}
 * />
 * ```
 *
 * @class PageTfcMigrationNotification
 *
 */

export default class PageTfcMigrationNotificationComponent extends Component {
  @service router;

  async ackMigrationNotification() {
    // TODO: Make a PUT request to acknowledge (ACK) the notification was dismissed instead.
    //  SEE: https://hashicorp.atlassian.net/browse/HCPIE-1339
    return Promise.resolve();
  }

  @action async handleGotoHcp() {
    await this.ackMigrationNotification();
    this.router.transitionTo('cloud');
  }

  @action async handleGotoTfcUs() {
    await this.ackMigrationNotification();
    window.location.href = 'https://app.terraform.io';
  }

  @action async handleGotoTfcEu() {
    await this.ackMigrationNotification();
    window.location.href = 'https://app.eu.terraform.io';
  }
}
