import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export const PEERING_CREATION_TYPE_AUTOMATE = 'automate';

export default class CloudOrgsDetailProjectsDetailHvnsDetailPeeringsCreateIndexController extends Controller {
  queryParams = ['peering_creation_type'];

  // When this query param is set to 'automate', the peering create page
  // component will instead render a different form along with some other
  // additional variations.
  @tracked peering_creation_type = null;
}
