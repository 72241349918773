/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { TYPE_NETWORK } from 'common/utils/cloud-resource-types';
import { variation } from 'ember-launch-darkly';

/**
 *
 * `PageHvnsList` renders the HashiCorp Virtual Networks List page.
 *
 *
 * ```
 * <Page::Hvns::List
 *   @model={{model}}
 * />
 * ```
 *
 * @class PageHvnsList
 *
 */

/**
 *
 * `model` is an array of HVNs.
 * @argument model;
 * @type {Object}
 *
 */
export default class PageHvnsListComponent extends Component {
  @service analytics;

  get resourceType() {
    return TYPE_NETWORK;
  }

  // TODO: [TCE-2007] Remove HVN activation A/B test analytics instrumentation.
  @action captureAnalyticsEventABTestHvnCreateIntentHeaderAction() {
    const abTestVariation = variation('hcp-ui-ab-test-hvn-activation-create');

    this.analytics.trackEvent(
      'external_ui_hvn_activation_create_intent_button_clicked',
      { abTestVariation, buttonLocation: 'list-page-header-action' }
    );
  }
}
