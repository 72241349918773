/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import {
  getTerraformQuickstartClickedPayload,
  TerraformQuickstartLaunchPoint,
} from 'core/utils/consul-analytics';
import { CONSUL_TERRAFORM_QUICKSTART_CLICKED } from 'core/utils/consts/analytics-events/consul';

export default class PageProjectsDashboardServiceCardConsulTfQuickstartComponent extends Component {
  @service analytics;

  @action
  sendTfQuickstartAnalyticsEvent() {
    this.analytics.trackEvent(
      CONSUL_TERRAFORM_QUICKSTART_CLICKED,
      getTerraformQuickstartClickedPayload(
        TerraformQuickstartLaunchPoint.Overview
      )
    );
  }
}
