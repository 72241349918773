import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class CloudOrgsDetailProjectsDetailHvnsDetailPeeringsConfigurationInstructionsRoute extends Route {
  @service api;

  async model({ peering_id }) {
    let { organization } = this.modelFor('cloud.orgs.detail');
    let { project } = this.modelFor('cloud.orgs.detail.projects.detail');
    let { network } = this.modelFor(
      'cloud.orgs.detail.projects.detail.hvns.detail'
    );
    let { peering } = await this.api.network.getPeering(
      organization.id,
      project.id,
      network.id,
      peering_id
    );
    return {
      organization,
      project,
      network,
      peering,
    };
  }
}
