import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class CloudOrgsDetailRedirectRoute extends Route {
  @service lastAccessedProject;
  @service router;

  // When an organization link is rendered outside of the organization context
  // we'd like to know where to send the user to but cannot because we won't
  // have granular LaunchDarkly context for each org. Instead, we send all
  // links here to the redirect and it will send them to the right place.
  async redirect() {
    const { organization, projects } = this.modelFor('cloud.orgs.detail');
    const metaLastOpenedProject = await this.lastAccessedProject.getProject(
      organization.id
    );
    let lastOpenedProject;

    // If they have projects then send them to either the last accessed
    // project or to the first project in the list.
    if (projects && projects.length > 0) {
      lastOpenedProject = projects.find((project) => {
        return project.id === metaLastOpenedProject?.id;
      });

      this.router.transitionTo(
        'cloud.orgs.detail.projects.detail.index',
        lastOpenedProject ? lastOpenedProject.id : projects[0].id
      );
    } else {
      // Otherwise, send them to the org dashboard.
      this.router.transitionTo('cloud.orgs.detail.index');
    }
  }
}
