/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/owner';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import IamPolicy from 'core/utils/iam-policy';

/**
 *
 * `PageGroupsProjectDetail` displays the details of a project level group. If a group contains members, they are listed in a table.
 *
 * ```
 * <Page::Groups::Project::Detail
 *  @group={{@model.group}}
 *  @isProjectContext={{@model.isProjectContext}}
 *  @members={{@model.members}}
 *  @project={{@model.project}}
 *  @role={{@model.role}}
 * />
 * ```
 *
 * @class PageGroupsProjectDetail
 *
 */

export default class PageGroupsProjectDetailComponent extends Component {
  /**
   * The group selected for the detail page to present.
   * @argument group
   * @type {Array.<Object>}
   */

  /**
   * True/false as to whether the current scope is at organization or project level
   * @argument isProjectContext
   * @type {Boolean}
   */

  /**
   * Members within a group
   * @argument members
   * @type {Array.<Object>}
   */

  /**
   * The project object
   * @argument project
   * @type {Array.<Object>}
   */

  /**
   * The group's role
   * @argument role
   * @type {String}
   */

  @service api;
  @service flashMessages;
  @service intl;
  @service router;

  @tracked showRemoveModal = false;
  @tracked groupToRemove = '';

  /**
   * Removes a group binding from a policy and attempts to update the policy.
   * If the policy has been updated concurrently, it will retry up to maxRetries.
   */
  @task
  *remove() {
    const projectId = this.args.project.id;
    const resourceId = this.groupToRemove.resourceId;
    const maxRetries = 3;

    const { updated } = yield* this.removeGroupFromPolicy(
      projectId,
      resourceId,
      maxRetries
    );

    if (updated) {
      this.flashMessages.success(
        this.intl.t(
          `components.page.access-control.groups.remove.modal.success.title`
        ),
        {
          content: this.intl.t(
            `components.page.access-control.groups.remove.modal.success.content`,
            {
              groupName: this.args.group.displayName,
              projectName: this.args.project.name,
              htmlSafe: true,
            }
          ),
        }
      );
      this.refreshRoute();
      return this.router.transitionTo('cloud.access-control.groups.list');
    } else {
      this.flashMessages.error(
        this.intl.t(
          `components.page.access-control.groups.remove.modal.error.title`
        ),
        {
          content: this.intl.t(
            `components.page.access-control.groups.remove.modal.error.content`,
            {
              groupName: this.args.group.displayName,
              projectName: this.args.project.name,
              htmlSafe: true,
            }
          ),
        }
      );
    }
  }

  *removeGroupFromPolicy(projectId, resourceId, maxRetries) {
    for (let i = 0; i < maxRetries; i++) {
      try {
        // Get the latest policy in order to get the latest etag to protect
        // from concurrent updates.
        const { policy } =
          yield this.api.resourceManager.project.projectServiceGetIamPolicy(
            projectId
          );

        if (!policy) {
          throw new Error();
        }

        // Given the latest policy, attempt to remove the group
        const policyToUpdate = new IamPolicy(policy);
        policyToUpdate.removeMemberById(resourceId);

        const updatedPolicy = policyToUpdate.get();
        const policyPayload = {
          policy: {
            ...updatedPolicy,
          },
        };

        yield this.api.resourceManager.project.projectServiceSetIamPolicy(
          projectId,
          policyPayload
        );

        return {
          updated: true,
        };
      } catch (e) {
        // A conflict indicates a concurrent update.
        // We may just need try again.
        if (e.status === 409) {
          continue;
        }

        return {
          updated: false,
        };
      }
    }

    return {
      updated: false,
    };
  }

  @task
  *confirmRemoveGroup(group) {
    this.groupToRemove = group;
    this.showRemoveModal = true;
    yield;
  }

  @action
  resetRemoveGroup() {
    this.showRemoveModal = false;
  }

  @action
  refreshRoute() {
    const listRoute = 'cloud.access-control.groups.list';
    let route = getOwner(this).lookup(`route:${listRoute}`);
    return route.refresh();
  }
}
