/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { variation } from 'ember-launch-darkly';
import { TYPE_CONSUL, TYPE_VAULT } from 'common/utils/cloud-resource-types';

/**
 *
 * `CreateClusterButton` renders a button and dropdown menu which allows you to Create a Vault or Consul cluster.
 *
 *
 * ```
 * <CreateClusterButton
 *   @model={{@model}}
 * />
 * ```
 *
 * @class CreateClusterButton
 *
 */
export default class CreateClusterButtonComponent extends Component {
  /**
   *
   * `model` is an HVN object.
   * @argument model;
   * @type {Object}
   *
   */
  @service abilities;
  @service analytics;

  get consulCreateTitle() {
    if (variation('hcp-rebrand-milestone-0')) {
      return 'components.page.hvns.detail.create-dropdown.consul.title-dedicated';
    }
    return 'components.page.hvns.detail.create-dropdown.consul.title';
  }

  get vaultCreateTitle() {
    if (variation('hcp-rebrand-milestone-0')) {
      return 'components.page.hvns.detail.create-dropdown.vault.title-dedicated';
    }
    return 'components.page.hvns.detail.create-dropdown.vault.title';
  }

  get showVaultCreate() {
    let clusters = this.args?.model?.dependencies || [];

    clusters = clusters.filter((c) => c.type === TYPE_VAULT);

    return this.abilities.can('create vault', clusters, {
      networks: [this.args.model.network],
    });
  }

  get isAzure() {
    return this.args.model.network.location.region.provider === 'azure';
  }

  get showConsulCreate() {
    let clusters = this.args?.model?.dependencies || [];

    clusters = clusters.filter((c) => c.type === TYPE_CONSUL);

    return this.abilities.can('create consul', clusters, {
      networks: [this.args.model.network],
    });
  }

  get consulCreateRoute() {
    return {
      routeName: 'consul.clusters.hcp.deploy.select-method.complete-deployment',
      models: [this.args.model.network.location.region.provider, 'form'],
    };
  }

  @action
  goToCreateConsulCluster() {
    this.analytics.trackEvent(
      'external_ui_hashicorp_network_hvn_create_cluster_dropdown_consul_clicked',
      this.args.model.network
    );
  }

  @action
  goToCreateVaultCluster() {
    this.analytics.trackEvent(
      'external_ui_hashicorp_network_hvn_create_cluster_dropdown_vault_clicked',
      this.args.model.network
    );
  }
}
