/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import {
  CONTENT_TABS_LABELS,
  PRODUCT_CONSUL,
} from 'networks-common/utils/content-tabs';
import {
  TABLE_HEADERS,
  OutboundRules,
  AgentlessRules,
} from '../consts/security-rules';

export default class PeeringsAzureConsulOutboundRulesComponent extends Component {
  /**
   *
   * `PeeringsAzureConsulOutboundRulesComponent` renders the Azure Consul Outbound Rules Component
   *
   *
   * ```
   * <Page::HvnPeerings::Detail::ContentTabs::Azure::Tables::Consul::OutboundRules
   *  @model={{@model}}
   * />
   * ```
   *
   * @class PeeringsAzureConsulOutboundRulesComponent
   *
   */

  tabs = CONTENT_TABS_LABELS;
  products = {
    consul: PRODUCT_CONSUL,
  };

  get outboundRules() {
    let consulRules = OutboundRules(this.args.model, this.azureSecurityGroupId);
    let agentlessRules = AgentlessRules(
      this.args.model,
      this.azureSecurityGroupId
    );
    return {
      tableHeaders: TABLE_HEADERS,
      tableData: consulRules.concat(agentlessRules),
    };
  }
}
