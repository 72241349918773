import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class CloudOrgsDetailProjectsDetailHvnsDetailTransitGatewayAttachmentsConfigurationInstructionsRoute extends Route {
  @service api;

  async model({ tgw_id }) {
    let { organization } = this.modelFor('cloud.orgs.detail');
    let { project } = this.modelFor('cloud.orgs.detail.projects.detail');
    let { network } = this.modelFor(
      'cloud.orgs.detail.projects.detail.hvns.detail'
    );
    let { tgwAttachment } = await this.api.network.getTGWAttachment(
      organization.id,
      project.id,
      network.id,
      tgw_id
    );
    return {
      organization,
      project,
      network,
      tgwAttachment,
    };
  }
}
