/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

/**
 *
 * `IamInvitationsBasicRoleChartOrganizationPermissionsTable` is a table to
 * display the permissions for organization-level basic roles.
 *
 *
 * ```
 * <Iam::Invitations::BasicRoleChart::Organization::PermissionsTable />
 * ```
 *
 * @class IamInvitationsBasicRoleChartOrganizationPermissionsTable
 *
 */

export default class IamInvitationsBasicRoleChartOrganizationPermissionsTableComponent extends Component {
  @service intl;

  allowedIcon = 'check';
  deniedIcon = 'skip';
  contexts = {
    hcp: {
      iconName: 'hcp',
      description: this.intl.t(
        'iam.invitations.basic-role-chart.flyout.hcp-context-description'
      ),
    },
    tfc: {
      iconName: 'terraform-color',
      description: this.intl.t(
        'iam.invitations.basic-role-chart.flyout.tfc-context-description'
      ),
    },
  };

  basicRoles = [
    {
      ability: this.intl.t(
        'iam.invitations.basic-role-chart.flyout.view-users'
      ),
      context: 'hcp',
      isAdminAllowed: true,
      isBrowserAllowed: true,
      isContributorAllowed: true,
      isViewerAllowed: true,
      isNoRoleAllowed: false,
    },
    {
      ability: this.intl.t(
        'iam.invitations.basic-role-chart.flyout.add-and-delete-users'
      ),
      context: 'hcp',
      isAdminAllowed: true,
      isBrowserAllowed: false,
      isContributorAllowed: false,
      isViewerAllowed: false,
      isNoRoleAllowed: false,
    },
    {
      ability: this.intl.t(
        'iam.invitations.basic-role-chart.flyout.manage-user-permissions'
      ),
      context: 'hcp',
      isAdminAllowed: true,
      isBrowserAllowed: false,
      isContributorAllowed: false,
      isViewerAllowed: false,
      isNoRoleAllowed: false,
    },
    {
      ability: this.intl.t(
        'iam.invitations.basic-role-chart.flyout.manage-service-principals'
      ),
      context: 'hcp',
      isAdminAllowed: true,
      isBrowserAllowed: false,
      isContributorAllowed: false,
      isViewerAllowed: false,
      isNoRoleAllowed: false,
    },
    {
      ability: this.intl.t(
        'iam.invitations.basic-role-chart.flyout.manage-groups'
      ),
      context: 'hcp',
      isAdminAllowed: true,
      isBrowserAllowed: false,
      isContributorAllowed: false,
      isViewerAllowed: false,
      isNoRoleAllowed: false,
    },
    {
      ability: this.intl.t(
        'iam.invitations.basic-role-chart.flyout.view-current-billing-status'
      ),
      context: 'hcp',
      isAdminAllowed: true,
      isBrowserAllowed: false,
      isContributorAllowed: true,
      isViewerAllowed: true,
      isNoRoleAllowed: false,
    },
    {
      ability: this.intl.t(
        'iam.invitations.basic-role-chart.flyout.manage-projects'
      ),
      context: 'hcp',
      isAdminAllowed: true,
      isBrowserAllowed: false,
      isContributorAllowed: true,
      isViewerAllowed: false,
      isNoRoleAllowed: false,
    },
    {
      ability: this.intl.t(
        'iam.invitations.basic-role-chart.flyout.view-hcp-resources'
      ),
      context: 'hcp',
      isAdminAllowed: true,
      isBrowserAllowed: false,
      isContributorAllowed: true,
      isViewerAllowed: true,
      isNoRoleAllowed: false,
    },
    {
      ability: this.intl.t(
        'iam.invitations.basic-role-chart.flyout.view-all-workspaces'
      ),
      context: 'tfc',
      isAdminAllowed: true,
      isBrowserAllowed: false,
      isContributorAllowed: true,
      isViewerAllowed: true,
      isNoRoleAllowed: false,
    },
    {
      ability: this.intl.t(
        'iam.invitations.basic-role-chart.flyout.manage-all-workspaces'
      ),
      context: 'tfc',
      isAdminAllowed: true,
      isBrowserAllowed: false,
      isContributorAllowed: true,
      isViewerAllowed: false,
      isNoRoleAllowed: false,
    },
    {
      ability: this.intl.t(
        'iam.invitations.basic-role-chart.flyout.manage-policies'
      ),
      context: 'tfc',
      isAdminAllowed: true,
      isBrowserAllowed: false,
      isContributorAllowed: false,
      isViewerAllowed: false,
      isNoRoleAllowed: false,
    },
    {
      ability: this.intl.t(
        'iam.invitations.basic-role-chart.flyout.manage-policy-overrides'
      ),
      context: 'tfc',
      isAdminAllowed: true,
      isBrowserAllowed: false,
      isContributorAllowed: false,
      isViewerAllowed: false,
      isNoRoleAllowed: false,
    },
    {
      ability: this.intl.t(
        'iam.invitations.basic-role-chart.flyout.manage-run-tasks'
      ),
      context: 'tfc',
      isAdminAllowed: true,
      isBrowserAllowed: false,
      isContributorAllowed: false,
      isViewerAllowed: false,
      isNoRoleAllowed: false,
    },
    {
      ability: this.intl.t(
        'iam.invitations.basic-role-chart.flyout.manage-version-control-settings'
      ),
      context: 'tfc',
      isAdminAllowed: true,
      isBrowserAllowed: false,
      isContributorAllowed: false,
      isViewerAllowed: false,
      isNoRoleAllowed: false,
    },
    {
      ability: this.intl.t(
        'iam.invitations.basic-role-chart.flyout.manage-private-registry-modules'
      ),
      context: 'tfc',
      isAdminAllowed: true,
      isBrowserAllowed: false,
      isContributorAllowed: false,
      isViewerAllowed: false,
      isNoRoleAllowed: false,
    },
    {
      ability: this.intl.t(
        'iam.invitations.basic-role-chart.flyout.manage-private-registry-providers'
      ),
      context: 'tfc',
      isAdminAllowed: true,
      isBrowserAllowed: false,
      isContributorAllowed: false,
      isViewerAllowed: false,
      isNoRoleAllowed: false,
    },
  ];
}
