import Service, { inject as service } from '@ember/service';
import { assert } from '@ember/debug';

export const ORG_PREFERENCES_STORAGE_KEY = 'org-preferences';

export default class OrgPreferencesService extends Service {
  @service('metadata.local-storage') localStorage;

  /** @member {MetadataAdapter|null} */
  adapter = null;

  /**
   * Configure the service at instantiation to the default adapter
   */
  constructor() {
    super(...arguments);
    this.configure('localStorage');
  }

  /**
   * Set the MetadataAdapter to be used.
   * @param {string} adapterName - The string that identifies the injected service property name
   */
  configure(adapterName) {
    let adapter = this[adapterName];
    assert('Incompatible metadata persistence adapter: not found', adapter);
    this.adapter = adapter;
  }

  /**
   * Retrieve a metadata entry for the last accessed project using the current configured adapter.
   * @return {Object} metadata - an entry to the metadata service modeled against the cloud-metadata-service
   */
  async getMetadata() {
    assert(
      'Incompatible metadata persistence adapter: no implemented getMetadata',
      this.adapter.getMetadata
    );
    let metadata = await this.adapter.getMetadata();
    return metadata;
  }

  /**
   * Persist a metadata entry for the last accessed project using the current configured adapter.
   * @param {Object} project - A project object with a required id member variable
   */
  async setMetadata(organization) {
    assert(
      'Incompatible metadata persistence adapter: no implemented setMetadata',
      this.adapter.setMetadata
    );
    let { metadata } = await this.getMetadata();
    let identifier = ORG_PREFERENCES_STORAGE_KEY;
    let newMetadata = {};
    if (metadata) {
      newMetadata = {
        ...metadata,
      };
    }
    newMetadata[identifier] = organization;

    let record = { scope: 'user', metadata: newMetadata };

    await this.adapter.setMetadata(record);
  }

  async getOrganization() {
    const { metadata = {} } = await this.getMetadata();
    const organization = metadata[ORG_PREFERENCES_STORAGE_KEY];
    if (organization) {
      return organization;
    }
  }

  async setOrganization(organization) {
    if (!organization || !organization.id) {
      return;
    }
    await this.setMetadata(organization);
  }
}
