import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class CloudAccountSettingsSecurityRoute extends Route {
  @service api;
  @service currentUser;
  @service router;

  boundRefresh = () => {
    this.refresh();
  };

  async beforeModel() {
    let currentUserIdentities = this.currentUser.user.identityTypes;
    let emailPassUser = currentUserIdentities.includes('EMAIL_PASSWORD');

    if (!emailPassUser) {
      this.router.transitionTo('cloud.account-settings.basic-info');
    }
  }

  async model() {
    const { status: mfaStatus } =
      await this.api.profile.profileServiceGetMFAStatus();

    return {
      refresh: this.boundRefresh,
      mfaStatus,
    };
  }
}
