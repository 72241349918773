/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import {
  HashicorpCloudNetwork20200907HVNRoute,
  HashicorpCloudNetwork20200907Network,
} from '@clients/cloud-network';
import { variation } from 'ember-launch-darkly';

/**
 *
 * `HvnRoutesTableTargetTypeLabel` renders the target type label and icon.
 *
 * If the route is making use of Azure hub/spoke topology, the label will show the
 * route's Azure next hop type and next hop IP address.
 *
 * Otherwise, the label will show the same display value as the resource-type-display-name helper.
 *
 *
 * ```
 * <Page::HvnRoutes::Table::TargetTypeLabel
 *   @route={{route}}
 *   @network={{network}}
 * />
 * ```
 *
 * @class HvnRoutesTableTargetTypeLabel
 *
 */

interface HvnRoutesTableTargetTypeLabelSignature {
  Args: {
    route: HashicorpCloudNetwork20200907HVNRoute;
    network: HashicorpCloudNetwork20200907Network;
  };
  Element: HTMLDivElement;
}

export default class HvnRoutesTableTargetTypeLabelComponent extends Component<HvnRoutesTableTargetTypeLabelSignature> {
  get nextHopIp() {
    const { route } = this.args;
    if (variation('hcp-ui-azure-hub-spoke')) {
      return route?.azureRoute?.nextHopIpAddress;
    }
  }
}
