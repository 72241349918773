/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { action } from '@ember/object';
// import { assert } from '@ember/debug';

class SelectGroupFormState {
  @tracked selectedGroupIds = [];
}

/**
 *
 * `IamGroupsProjectModalSelectGroup` allows the user to select group(s) from a fancy list.
 *
 * ```
 * <Iam::Groups::Project::Modal::SelectGroup
 *   @candidateGroups={{this.model.groups}}
 *   @initialSelectedGroupIds={{this.model.selectedGroupIds}}
 *   @handleSubmit={{this.submitHandler}}
 *   @handleCancel={{this.cancelHandler}}
 * />
 * ```
 *
 * @class IamGroupsProjectModalSelectGroup
 *
 */

export default class IamGroupsProjectModalSelectGroupComponent extends Component {
  formState = new SelectGroupFormState();

  constructor() {
    super(...arguments);

    this.formState.selectedGroupIds = this.args.initialSelectedGroupIds;
  }

  validate(/* formState */) {
    // TODO: Validate that one or more group IDs have been selected ...
  }

  @action isChecked(groupId) {
    return this.formState.selectedGroupIds.includes(groupId);
  }

  @action toggleGroupChecked(groupId) {
    if (this.isChecked(groupId)) {
      this.formState.selectedGroupIds = this.formState.selectedGroupIds.filter(
        (id) => id !== groupId
      );
    } else {
      this.formState.selectedGroupIds =
        this.formState.selectedGroupIds.concat(groupId);
    }
  }

  @task *submit(event) {
    event.preventDefault();

    this.validate(this.formState);
    yield this.args.handleSubmit.perform(this.formState);
  }

  @action cancel() {
    this.args.handleCancel();
  }
}
