import Service, { service } from '@ember/service';
import { getOwner } from '@ember/owner';
import config from 'hcp/config/environment';
import { PopupTimeoutError, PopupCancelledError } from '@auth0/auth0-spa-js';

export default class MFAService extends Service {
  @service currentUser;
  @service flashMessages;
  @service intl;

  get authenticator() {
    return getOwner(this).lookup('authenticator:auth0');
  }

  _auth0getToken = async (email) => {
    const client = await this.authenticator.client;
    // https://auth0.github.io/auth0-spa-js/classes/Auth0Client.html#getTokenWithPopup
    // we use popup because auth0 disallows getting an MFA token without an explicit challenge so getTokensSilently does not work for this flow
    return client.getTokenWithPopup({
      authorizationParams: {
        audience: `https://${config.APP.auth0.mfa_aud}/mfa/`,
        scope: 'enroll read:authenticators remove:authenticators',
        prompt: 'login',
        connection: 'Username-Password-Authentication',
        redirect_uri: `${window.location.origin}/login/callback`,
        login_hint: email,
      },
    });
  };

  getMFAToken = async (cancelFn = () => {}) => {
    const { email } = this.currentUser.user;
    return this._auth0getToken(email).catch((err) =>
      this.handlePopupErrors(err, cancelFn)
    );
  };

  handlePopupErrors(err, cancelFn) {
    let messageKey;
    if (err instanceof PopupTimeoutError) {
      messageKey =
        'components.page.account-settings.security.mfa.popupTimeoutError';
    }
    if (err instanceof PopupCancelledError) {
      messageKey =
        'components.page.account-settings.security.mfa.popupCancelledError';
    }
    if (messageKey) {
      this.flashMessages.error(this.intl.t(messageKey));
      if (cancelFn) {
        cancelFn();
      }
    } else {
      throw err;
    }
  }
}
