/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { TYPE_NETWORK_PEERING } from 'common/utils/cloud-resource-types';

/**
 *
 * `PageHvnPeeringsList` renders the AWS HVN Peerings List page.
 *
 *
 * ```
 * <Page::HvnPeerings::List::Aws
 *   @model={{model}}
 * />
 * ```
 *
 * @class PageHvnPeeringsListAws
 *
 */
/**
 *
 * `model` will be a model containing the peerings list, the current organization, and project.
 * @argument model;
 * @type {Object}
 *
 */
export default class PageHvnPeeringsListAwsComponent extends Component {
  @service api;
  @service operation;

  @tracked showDeleteModal = false;
  @tracked peeringToDelete;
  get peeringType() {
    return TYPE_NETWORK_PEERING;
  }
}
