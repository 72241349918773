import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { TYPE_TERRAFORM_WORKSPACE } from 'common/utils/cloud-resource-types';
import {
  ACTION_LIST,
  PREFIX_TERRAFORM_WORKSPACES,
} from 'authz/utils/permission-types/index';
import { variation } from 'ember-launch-darkly';
import RoleAssignmentsConfig from 'manage-access/utils/role-assignments-config';

export default class CloudServicesTerraformWorkspacesDetailRoute extends Route {
  @service abilities;
  @service api;
  @service permissions;
  @service userContext;
  @service roleAssignmentsConfig;

  async model({ resource_id }) {
    const { resources } =
      await this.api.resourceManager.resources.resourceServiceListAccessibleResources(
        null,
        resource_id,
        TYPE_TERRAFORM_WORKSPACE,
        this.permissions.generate(PREFIX_TERRAFORM_WORKSPACES, ACTION_LIST)
      );

    const workspace = resources[0];

    if (!workspace) {
      const error = new Error('NotFoundError');
      error.name = 'NotFoundError';
      error.status = 404;
      throw error;
    }

    this.userContext.resource = { type: TYPE_TERRAFORM_WORKSPACE, workspace };

    const TFWorkspaceDetailConfig = new RoleAssignmentsConfig({
      canEditPolicy: this.abilities.can(
        'set-iam-policy in terraform-workspace'
      ),
      filterRolesFunction: (roles) => {
        return roles;
      },
      isEnabled: variation(
        'hcp-ui-role-assignments-engine-terraform-workspaces'
      ),
      resourceContext: {
        id: workspace.resourceId,
        name: workspace.resourceName,
        resourceName: workspace.resourceName,
      },
    });

    this.roleAssignmentsConfig.set(
      TYPE_TERRAFORM_WORKSPACE,
      TFWorkspaceDetailConfig
    );

    return {
      workspace,
      organizationId: this.userContext.organization.id,
      projectId: this.userContext.project.id,
    };
  }
}
