/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import templateString from 'core/utils/template-string';
import { AZURE_PEERING_TERRAFORM_TAB_DOWNLOAD_TF_CLICKED } from 'core/utils/consts/analytics-events/platform';

/**
 *
 * `HvnPeeringsDetailAzureTerraformCodeSnippetComponent` renders the HVN Peerings Detail tabs content
 *
 *
 * ```
 * <Page::HvnPeeringsDetail::ContentTabs::Azure::CodeSnippets::Terraform
 *   @model={{model}}
 *   @tab={{tab}}
 *   @product={{product}}
 * />
 * ```
 *
 * @class HvnPeeringsDetailAzureTerraformCodeSnippetComponent
 *
 */
/**
 *
 * `tab` will be the selected tab;
 * @argument tab;
 * @type {String}
 *
 */
/**
 *
 * `product` such as consul or vault;
 * @argument product;
 * @type {String}
 *
 */
/**
 *
 * `model` will be a model containing the peering detail;
 * @argument model;
 * @type {Object}
 *
 */
let terraform_snippet = templateString`provider "azurerm" {
  features {}
}

locals {
  application_id = "${'application_id'}"
  role_def_name  = join("-", ["hcp-hvn-peering-access", local.application_id])
  vnet_id        = "/subscriptions/${'subscription_id'}/resourceGroups/${'resource_group'}/providers/Microsoft.Network/virtualNetworks/${'vnet_name'}"
}

resource "azuread_service_principal" "principal" {
  application_id = local.application_id
}

resource "azurerm_role_definition" "definition" {
  name  = local.role_def_name
  scope = local.vnet_id

  assignable_scopes = [
    local.vnet_id
  ]

  permissions {
    actions = [
      "Microsoft.Network/virtualNetworks/peer/action",
      "Microsoft.Network/virtualNetworks/virtualNetworkPeerings/read",
      "Microsoft.Network/virtualNetworks/virtualNetworkPeerings/write"
    ]
  }
}

resource "azurerm_role_assignment" "role_assignment" {
  principal_id       = azuread_service_principal.principal.id
  role_definition_id = azurerm_role_definition.definition.role_definition_resource_id
  scope              = local.vnet_id
}`;
export default class PageHvnPeeringsDetailTabsContentAzureTerraformComponent extends Component {
  @service intl;
  @service analytics;
  get applicationId() {
    return this.args.model.peering.target.azureTarget.applicationId;
  }

  get vnetName() {
    return this.args.model.peering.target.azureTarget.vnetName;
  }

  get subscriptionId() {
    return this.args.model.peering.target.azureTarget.subscriptionId;
  }

  get resourceGroupName() {
    return this.args.model.peering.target.azureTarget.resourceGroupName;
  }

  get terraformCodeSnippet() {
    let string = terraform_snippet({
      application_id: this.applicationId,
      vnet_name: this.vnetName,
      subscription_id: this.subscriptionId,
      resource_group: this.resourceGroupName,
    });
    return string;
  }
  get codeEditorOptions() {
    return {
      mode: 'shell',
      readOnly: true,
    };
  }
  @action
  sendDownloadTfClickedEvent() {
    this.analytics.trackEvent(AZURE_PEERING_TERRAFORM_TAB_DOWNLOAD_TF_CLICKED);
  }
}
