/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import {
  AWS_CONNECTIONS,
  AWS_CONNECTIONS_INFO,
} from 'networks-common/utils/aws-connections';

import { HVN_ROUTE_NAMES } from 'networks-common/utils/network-routes';
/**
 *
 * `PageHvnRoutesList` renders the HVN Routes either in a page view or in a table within a connection page view
 *
 *
 * ```
 * <Page::HvnRoutes::List
 *   @model={{@model}}
 *   @isHvnHvnPeering={{true|false}}
 * />
 * ```
 *
 * @class PageHvnRoutesList
 *
 */

/**
 *
 * `model` is the model sent from a connection type or from the routes tab within a network
 * @argument model;
 * @type {Object}
 *
 * `isHvnHvnPeering` signifies if the routes are foran hvn-hvn peering.
 * @argument isHvnHvnPeering;
 * @type {Boolean}
 *
 */

export default class PageHvnRoutesListComponent extends Component {
  @service intl;
  @service operation;
  @service router;

  @tracked routeName = this.router.currentRouteName;

  hvnRouteNames = HVN_ROUTE_NAMES;
  connectionsInfo = AWS_CONNECTIONS_INFO;

  get connection() {
    // Check to see if a connection is in the model.
    const { model } = this.args;
    let connection = null;

    AWS_CONNECTIONS.forEach((item) => {
      if (item in model) {
        connection = {
          id: model[item]?.id,
          type: AWS_CONNECTIONS_INFO[item]?.type,
          detail: AWS_CONNECTIONS_INFO[item]?.detail,
          list: AWS_CONNECTIONS_INFO[item]?.list,
          createRoute: AWS_CONNECTIONS_INFO[item]?.createRoute,
          state: model[item]?.state,
        };
      }
    });

    return connection;
  }
}
