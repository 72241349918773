import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import permissionGuard from 'core/decorators/permission-guard';
import {
  PREFIX_NETWORK_PEERINGS,
  ACTION_CREATE,
} from 'authz/utils/permission-types/index';
export default class CloudOrgsDetailProjectsDetailHvnsDetailPeeringsCreateRoute extends Route {
  @service api;
  @service userContext;

  @permissionGuard({
    permission: `${PREFIX_NETWORK_PEERINGS}.${ACTION_CREATE}`,
  })
  async model() {
    let { organization, project } = this.userContext;
    let { hvn_id } = this.paramsFor(
      'cloud.orgs.detail.projects.detail.hvns.detail'
    );

    let { network } = await this.api.network.get(
      organization.id,
      project.id,
      hvn_id
    );
    let { peerings } = await this.api.network.listPeerings(
      organization.id,
      project.id,
      network.id
    );
    return {
      network,
      peerings,
    };
  }
}
