/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { TYPE_CONSUL, TYPE_VAULT } from 'common/utils/cloud-resource-types';
import { TERRAFORM_IMPORTS } from 'core/utils/constants';
import { CONNECTION_METHODS } from 'networks-common/utils/connection-methods';
import {
  isPendingAcceptance,
  hidePeeringInstructions,
  getUpNextTranslations,
  getBusyStateTranslations,
  getTargetType,
  AWS_QUICK_CREATE_LINK,
  PEERING_CREATING_STATUS,
  PEERING_PENDING_ACCEPTANCE_STATUS,
  PEERING_ACCEPTED_STATUS,
  PEERING_ACTIVE_STATUS,
  PEERING_FAILED_STATUS,
  PEERING_EXPIRED_STATUS,
  PEERING_REJECTED_STATUS,
  PEERING_DELETING_STATUS,
  getPeeringExpiration,
  isPeeringConfigurationComplete,
} from 'networks-common/utils/peerings';
import { isAwsProviderNetwork } from 'networks-common/utils/networks';

/**
 *
 * `PageHvnPeeringsDetail` renders the HVN Peerings Detail page.
 *
 *
 * ```
 * <Page::HvnPeerings::Detail
 *   @model={{model}}
 *   @tab={{tab}}
 *   @product={{product}}
 * />
 * ```
 *
 * @class PageHvnPeeringsDetail
 *
 */
/**
 *
 * `tab` will be the selected tab;
 * @argument tab;
 * @type {String}
 *
 */
/**
 *
 * `product` such as consul or vault;
 * @argument product;
 * @type {String}
 *
 */
/**
 *
 * `model` will be a model containing the peering detail;
 * @argument model;
 * @type {Object}
 *
 */

export default class PageHvnPeeringsDetailComponent extends Component {
  @service api;
  @service flashMessages;
  @service intl;
  @service operation;

  @tracked showDeleteModal = false;
  @tracked allowDelete = false;
  @tracked isOpen;

  // Guided Flow state
  @tracked isAlertCardDismissed = false;
  @tracked peeringMetadata = null;

  constructor() {
    super(...arguments);

    if (this.args.model.metadata) {
      let {
        peering: { id: peeringId },
        metadata: {
          metadata: { peering: peeringsMetadata },
        },
      } = this.args.model;

      this.peeringMetadata = peeringsMetadata[peeringId];
    }
  }

  get busyStateTranslations() {
    let translations = getBusyStateTranslations();
    let busyStateTranslations = translations[this.args.model.peering.state];
    if (busyStateTranslations) {
      for (let [key, value] of Object.entries(busyStateTranslations)) {
        // When a Peering state is "CREATING", the message translation requires targetType
        if (this.targetType && key === 'message') {
          busyStateTranslations[key] = this.intl.t(value, {
            targetType: this.targetType,
          });
        } else {
          busyStateTranslations[key] = this.intl.t(value);
        }
      }
      return busyStateTranslations;
    } else {
      return {};
    }
  }

  get isAws() {
    return isAwsProviderNetwork(this.args.model.network);
  }

  get isAwsQuickCreateLink() {
    return this.args.model.peering.sourceChannel === AWS_QUICK_CREATE_LINK;
  }

  get upNextText() {
    return getUpNextTranslations(this.args.model.peering.state);
  }

  get hidePeeringInstructions() {
    return (
      hidePeeringInstructions(this.args.model.peering.state) ||
      this.isAwsQuickCreateLink
    );
  }

  get isPendingAcceptance() {
    return isPendingAcceptance(this.args.model.peering.state);
  }

  get instructionsId() {
    return 'instructions';
  }

  get isHvnHvnPeering() {
    return Boolean(this.args.model.peering.target.hvnTarget?.hvn);
  }

  get targetType() {
    return getTargetType(this.args.model.peering);
  }

  get dependencies() {
    let dependencies = [];
    let networkDependencies = this.args.model.networkDependencies.filter(
      (dependency) =>
        dependency.type === TYPE_CONSUL || dependency.type === TYPE_VAULT
    );
    let hvnTargetDependencies = this.args.model.hvnTargetDependencies.filter(
      (dependency) =>
        dependency.type === TYPE_CONSUL || dependency.type === TYPE_VAULT
    );
    if (networkDependencies.length && hvnTargetDependencies.length) {
      dependencies.push(...networkDependencies, ...hvnTargetDependencies);
    }
    return dependencies;
  }

  get isLocked() {
    return this.dependencies?.length > 0;
  }

  get terraformImport() {
    switch (this.targetType) {
      case 'aws':
        return TERRAFORM_IMPORTS.awsPeering({
          hvnId: this.args.model.network.id,
          peeringId: this.args.model.peering.id,
        });
      case 'azure':
        return TERRAFORM_IMPORTS.azurePeering({
          hvnId: this.args.model.network.id,
          peeringId: this.args.model.peering.id,
        });
      case 'hvn':
        return TERRAFORM_IMPORTS.hvnPeering({
          hvnId: this.args.model.network.id,
          peeringId: this.args.model.peering.id,
        });
      default:
        return '';
    }
  }

  get currentStep() {
    const {
      model: {
        peering: { state, sourceChannel },
      },
    } = this.args;

    switch (state.toUpperCase()) {
      case PEERING_PENDING_ACCEPTANCE_STATUS:
        return 2;
      case PEERING_ACCEPTED_STATUS:
        return 3;
      case PEERING_ACTIVE_STATUS:
        return sourceChannel === AWS_QUICK_CREATE_LINK ? 4 : 3; // Maintaining the Configure step as a mitigation design
      default:
        return 2;
    }
  }

  get shouldShowStepper() {
    const {
      model: {
        peering: { state, sourceChannel },
      },
    } = this.args;

    let stepperStates = [
      PEERING_FAILED_STATUS,
      PEERING_EXPIRED_STATUS,
      PEERING_REJECTED_STATUS,
      PEERING_DELETING_STATUS,
    ];

    if (sourceChannel === AWS_QUICK_CREATE_LINK) {
      stepperStates = [...stepperStates, PEERING_ACTIVE_STATUS];
    }

    return !stepperStates.includes(state);
  }

  get shouldShowAlertCard() {
    const {
      model: {
        peering: { state, sourceChannel },
      },
    } = this.args;
    let alertCardStates = [PEERING_FAILED_STATUS, PEERING_EXPIRED_STATUS];

    if (
      sourceChannel === AWS_QUICK_CREATE_LINK ||
      (this.peeringMetadata &&
        isPeeringConfigurationComplete(this.peeringMetadata))
    ) {
      alertCardStates = [...alertCardStates, PEERING_ACTIVE_STATUS];
    }

    return alertCardStates.includes(state);
  }

  get isAwsCloudShell() {
    return this.args.connectionMethod === CONNECTION_METHODS.awsCloudShell.key;
  }

  get isWebConsole() {
    return this.args.connectionMethod === CONNECTION_METHODS.webConsole.key;
  }

  get expirationDate() {
    let {
      model: { peering },
    } = this.args;
    return getPeeringExpiration(peering).toLocaleString();
  }

  get isManualConnectionActive() {
    let {
      model: { peering },
    } = this.args;

    return peering.sourceChannel !== AWS_QUICK_CREATE_LINK
      ? peering.state === PEERING_ACTIVE_STATUS
      : false;
  }

  get isPeeringBeingCreated() {
    let {
      model: {
        peering: { state },
      },
    } = this.args;

    return state === PEERING_CREATING_STATUS;
  }

  get connection() {
    let {
      model: { peering },
    } = this.args;

    if (!this.peeringMetadata || peering.state !== PEERING_ACTIVE_STATUS) {
      return this.args.model.peering;
    } else {
      let state = isPeeringConfigurationComplete(this.peeringMetadata)
        ? peering.state
        : PEERING_PENDING_ACCEPTANCE_STATUS;

      return { ...peering, ...{ state } };
    }
  }

  @action
  updatePeeringMetadata(metadata) {
    this.peeringMetadata = metadata;
  }
}
