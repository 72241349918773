/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

/**
 *
 * `PageProjectsUsersList` lists the HCP Project scope Users.
 *
 *
 * ```
 * <Page::Projects::Users::List @model={{model}} />
 * ```
 *
 * @class PageProjectsUsersList
 *
 */

/**
 * `model` has the organization, orgPolicy, project, projectPolicy,
 * userPrincipals, and pagination.
 * @argument model
 * @type {Object}
 */

export default class PageProjectsUsersListComponent extends Component {
  @service currentUser;
}
