/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

/**
 *
 * `Compliance::Footer` renders the footer links on the compliance pages.
 *
 *
 * ```
 * <Compliance::Footer />
 * ```
 *
 * @class ComplianceFooter
 *
 */

export default class ComplianceFooterComponent extends Component {
  @service systemStatus;

  get statuspageUrl() {
    return this.systemStatus.statuspageUrl;
  }
}
