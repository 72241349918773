/**
 * `decorateMembersWithIsManager` will add `isManager: true` or `isManager: false` to each group member inside of groupMembers to indicate if they are a group manager or not.
 *
 *
 * @method decorateMembersWithIsManager
 * @param {Array} groupMembers An array of objects.  Each object represents a group member with the properties principal_type, id, name and email
 * @param {Object} groupPolicy An object that contains the policy bindings and etag for a Group.  The policy bindings contains role and member id data.  For this function, we are interested in member id's who are assigned the role of roles/iam.group-manager in the group.
 * @returns {Array} This function returns an array of group members in which each member is decorated with the isManager property.
 */

export default function decorateMembersWithIsManager(
  groupMembers = [],
  groupPolicy = {}
) {
  const managers = groupPolicy?.bindings?.find(
    (item) => item.roleId === 'roles/iam.group-manager'
  );
  return groupMembers.map((member) => {
    return {
      ...member,
      isManager: managers?.members?.find(
        (manager) => manager.memberId === member.id
      )
        ? true
        : false,
    };
  });
}
