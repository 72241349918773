/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import {
  CREATE_CONNECTION_PEERING_LINK_CLICKED,
  CREATE_CONNECTION_TGW_LINK_CLICKED,
} from 'core/utils/consts/analytics-events/networks';

/**
 *
 * `PageHvnsDetailContainer` renders the HashiCorp Virtual Networks Detail page.
 *
 *
 * ```
 * <Page::Hvns::Detail::Container
 *   @model={{@model}}
 * />
 * ```
 *
 * @class PageHvnsDetailContainer
 *
 */

/**
 *
 * `model` is an object that will have an HVN object at model.network .
 * @argument model;
 * @type {Object}
 *
 */
export default class PageHvnsDetailContainerComponent extends Component {
  @service api;
  @service flashMessages;
  @service intl;
  @service operation;
  @service analytics;
  @service abilities;

  @tracked showModal = false;
  @tracked isDeleting = false;

  get canCreatePeerings() {
    const { peerings } = this.args.model;
    return this.abilities.can('create network-peerings', peerings);
  }

  get canCreateTgwAttachments() {
    const { tgwAttachments } = this.args.model;
    return this.abilities.can('create network-tgw-attachment', tgwAttachments);
  }

  get canCreateConnection() {
    return this.canCreatePeerings || this.canCreateTgwAttachments;
  }

  @task
  *deleteHVN(evt) {
    // don't submit the form
    evt.preventDefault();

    let {
      location: { organizationId, projectId, region },
      id,
    } = this.args.model.network;

    let { operation } = yield this.api.network._delete(
      organizationId,
      projectId,
      id,
      region.provider,
      region.region
    );
    this.flashMessages.success(
      this.intl.t('components.page.hvns.delete.success.title')
    );
    this.showModal = false;
    this.isDeleting = true;
    this.operation.addOperation(operation);
  }

  @action
  goToPeeringCreate() {
    this.analytics.trackEvent(
      CREATE_CONNECTION_PEERING_LINK_CLICKED,
      this.args.model.network
    );
  }

  @action
  goToTGWCreate() {
    this.analytics.trackEvent(
      CREATE_CONNECTION_TGW_LINK_CLICKED,
      this.args.model.network
    );
  }
}
