/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { getOwner } from '@ember/owner';

/**
 *
 * `PageOrganizationsDetailSso` displays configuration details (if enabled) for SSO settings.
 *
 *
 * ```
 * <Page::Organizations::Detail::Sso
 *   @model={{this.model}}
 * />
 * ```
 *
 * @class PageOrganizationsDetailSso
 *
 */

export default class PageOrganizationsDetailSsoComponent extends Component {
  @service api;
  @service flashMessages;
  @service intl;

  @service router;
  @service snapshots;

  @tracked isModalActive = false;
  @tracked modalList = [
    'components.page.organizations.sso.delete-modal.list.1',
    'components.page.organizations.sso.delete-modal.list.2',
    'components.page.organizations.sso.delete-modal.list.3',
  ];

  @action
  showDeleteModal() {
    this.isModalActive = true;
  }

  @action
  onCancel() {
    if (this.isDestroyed || this.isDestroying) return;
    this.isModalActive = false;
  }

  @task
  *save() {
    let organizationId = this.args.model.org.id;

    let { type } =
      yield this.api.sso.sSOManagementServiceGetSSOType(organizationId);

    try {
      if (type === 'SAML') {
        yield this.api.sso.sSOManagementServiceDeleteSSOConfiguration(
          organizationId,
          type
        );
      } else {
        yield this.api.authConfig.authConfigServiceDeleteAuthConnectionFromOrganization(
          organizationId
        );
      }
    } catch (err) {
      console.log(`Error while removing ${type} configuration\n`, err);
    }
    this.isModalActive = false;

    this.refreshRoute();

    this.flashMessages.success(
      this.intl.t(
        'components.page.organizations.sso.delete-modal.success-message',
        {
          orgName: this.args.model.org.name,
        }
      )
    );
  }

  @action
  refreshRoute() {
    let route = getOwner(this).lookup(`route:cloud.orgs.detail.settings.sso`);
    return route.refresh();
  }
}
