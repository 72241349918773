/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { getOwner } from '@ember/owner';
import { task } from 'ember-concurrency';
/**
 *
 * `PageServicePrincipalsProjectDetailWorkloadIdentityProviders` displays the workload identity providers of a project Service Principal and allows for them to be deleted.
 *  It also renders the <ServicePrincipals::WorkloadIdentityProviderCards /> which renders the provider card for each provider that is supported on HCP.
 *
 *
 * ```
 * <Page::ServicePrincipals::Project::Detail::WorkloadIdentityProviders
 *   @model={{model}}
 * />
 * ```
 *
 * @class PageServicePrincipalsProjectDetailWorkloadIdentityProviders
 *
 */
/**
 * Contains the `servicePrincipal` , 'project' and 'workloadIdentityProviders'.
 * @argument model
 * @type {Object}
 */
export default class PageServicePrincipalsProjectDetailWorkloadIdentityProvidersComponent extends Component {
  @service api;
  @service flashMessages;
  @service intl;
  @service router;

  @tracked showDeleteWipModal = false;
  @tracked servicePrincipalWipToDelete = null;

  @tracked returnFocusTo = null;

  @action
  confirmDeleteServicePrincipalWip(servicePrincipalWip) {
    this.showDeleteWipModal = true;
    this.servicePrincipalWipToDelete = servicePrincipalWip;
  }

  @task
  *deleteServicePrincipalWip() {
    this.servicePrincipalWipToDelete;
    const response =
      yield this.api.servicePrincipal.servicePrincipalsServiceDeleteWorkloadIdentityProvider(
        this.servicePrincipalWipToDelete.resourceName
      );

    this.onDeleteServicePrincipalWipSuccess({
      response,
      servicePrincipalWip: this.servicePrincipalWipToDelete,
    });
  }

  @action
  onDeleteServicePrincipalWipSuccess() {
    this.resetDeleteServicePrincipalWip();
    this.refreshRoute();
    this.flashMessages.success(
      this.intl.t(
        'components.page.access-control.service-principals.details.workload-identity-providers.delete-provider-modal.success'
      )
    );
  }

  @action
  resetDeleteServicePrincipalWip() {
    this.showDeleteWipModal = false;
    this.servicePrincipalWipToDelete = null;
  }

  refreshRoute() {
    let route = getOwner(this).lookup(
      `route:${this.router.currentRoute.parent.name}`
    );
    return route.refresh();
  }
}
