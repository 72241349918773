import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class CloudOrgsDetailProjectsDetailSettingsIndexRoute extends Route {
  @service router;

  redirect() {
    this.router.transitionTo(
      'cloud.orgs.detail.projects.detail.settings.general'
    );
  }
}
