/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

/**
 *
 * `ServicePrincipalKeysGenerateModal` is a shared component that generates a service principal key and provides the secret for organization and project level service principals.
 *
 *
 * ```
 * <ServicePrincipalKeysGenerateModal
 * />
 * ```
 *
 * @class ServicePrincipalKeysGenerateModal
 *
 */

const I18N_BASE = `components.page.access-control.service-principals.details.actions.generate-key`;

export default class ServicePrincipalKeysGenerateModalComponent extends Component {
  @service api;
  @service intl;

  @tracked clientId;
  @tracked clientSecret;

  get headerIcon() {
    return this.generateKey?.last?.error ? 'alert-diamond' : 'key';
  }

  get headerText() {
    if (this.generateKey?.last?.error) {
      return this.intl.t(`${I18N_BASE}.error-generating-key`);
    }

    if (this.generateKey.isRunning) {
      return this.intl.t(`${I18N_BASE}.generating-key`);
    }

    return this.intl.t(`${I18N_BASE}.title`);
  }

  @task
  *generateKey() {
    this.clientId = null;
    this.clientSecret = null;

    const {
      organizationId,
      projectId,
      id: principalId,
    } = this.args.model.servicePrincipal;
    let newKeyResponse;
    if (projectId) {
      newKeyResponse =
        yield this.api.servicePrincipal.servicePrincipalsServiceCreateProjectServicePrincipalKey(
          organizationId,
          projectId,
          { organizationId, projectId, principalId }
        );
    } else {
      newKeyResponse =
        yield this.api.servicePrincipal.servicePrincipalsServiceCreateOrganizationServicePrincipalKey(
          organizationId,
          {
            organizationId,
            principalId,
          }
        );
    }

    this.clientId = newKeyResponse.key.clientId;
    this.clientSecret = newKeyResponse.clientSecret;

    this.args.onSuccess({
      key: newKeyResponse.key,
    });
  }
}
