/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

import { TYPE_NETWORK_PEERING } from 'common/utils/cloud-resource-types';
import {
  isAwsProviderNetwork,
  isAzureProviderNetwork,
} from 'networks-common/utils/networks';
import { PEERING_ROUTE_NAMES } from 'networks-common/utils/network-routes';
import { PEERING_CREATION_TYPE_AUTOMATE } from 'hcp/controllers/cloud/orgs/detail/projects/detail/hvns/detail/peerings/create';

/**
 *
 * `PageHvnPeeringsList` renders the HVN Peerings List page.
 *
 *
 * ```
 * <Page::HvnPeerings::List
 *   @model={{model}}
 * />
 * ```
 *
 * @class PageHvnPeeringsList
 *
 */
/**
 *
 * `model` will be a model containing the peerings list, the current organization, and project.
 * @argument model;
 * @type {Object}
 *
 */
export default class PageHvnPeeringsListComponent extends Component {
  @service analytics;

  PEERING_CREATION_TYPE_AUTOMATE = PEERING_CREATION_TYPE_AUTOMATE;

  get peeringRoutes() {
    return PEERING_ROUTE_NAMES;
  }
  get peeringType() {
    return TYPE_NETWORK_PEERING;
  }
  get isAws() {
    return isAwsProviderNetwork(this.args.model.network);
  }
  get isAzure() {
    return isAzureProviderNetwork(this.args.model.network);
  }

  @action
  trackCreateConnectionButtonClick() {
    this.analytics.trackEvent(
      'external_ui_hashicorp_network_peering_list_create_connection_button_clicked',
      this.args.model.network
    );
  }
}
