import Cookies from 'js-cookie';
import config from 'hcp/config/environment';

const cookieName = 'hcp_access_token';

/**
 *`setCookie` will set a cookie containing the access token on the auth cookie
 * domain specified in the configuration.
 *
 * The cookie will e.g. be used to authenticate proxy-ed requests to the HTTP
 * proxy (which will be reachable under a subdomain of the auth cookie domain)
 *
 * @method setCookie
 * @param {String} accessToken
 * @param {String} domain
 *
 */
export default function setCookie(
  accessToken,
  domain = config.APP?.authCookieDomain
) {
  if (!domain) {
    console.error('Unable to find the config domain');
  }

  // Invalidate the cookie if the token is empty
  if (!accessToken) {
    Cookies.remove(cookieName, { domain });
    return;
  }

  // Get the token's expiry time
  let tokenData = parseJwt(accessToken);
  let expires = new Date(tokenData?.exp * 1000);

  // Set the cookie
  Cookies.set(cookieName, accessToken, {
    domain,
    expires,
    secure: true,
    sameSite: 'strict',
  });
}

// parseJwt will parse a JSON web token's payload and return it as an object.
function parseJwt(token) {
  let base64Url = token.split('.')[1];
  if (!base64Url) {
    return;
  }

  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  return JSON.parse(jsonPayload);
}
