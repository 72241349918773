/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
/**
 *
 * `IamGroupsListTable` shows summary details of a group, and it's members.
 *
 *
 * ```
 * <Iam::Groups::ListTable
 *  @groups={{foo}}
 *  @groupsPermissions={{foo}}
 *  @scimEnabled={{false}}
 * />
 * ```
 *
 * @class IamGroupsListTable
 *
 */

export default class IamGroupsListTableComponent extends Component {
  /**
   * @argument groups
   * @type {Object}
   * @required
   */
  /**
   * @argument groupsPermissions
   * @type {Object}
   * @required
   */
  /**
   * @argument scimEnabled
   * @type {Boolean}
   * @required
   */

  @tracked group;
  @tracked isActive = false;
  @tracked showActionsHeader;
  @action
  openDeleteModal(group) {
    this.isActive = true;
    this.group = group;
  }

  @action
  closeDeleteModal() {
    this.group = null;
    this.isActive = false;
  }

  @action
  toggleActionsHeader(canUpdateGroup) {
    if (canUpdateGroup) {
      this.showActionsHeader = true;
    } else {
      this.showActionsheader = false;
    }
    return this.showActionsHeader;
  }
}
