/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

/**
 *
 * Displays the list of workspaces for the current project.
 *
 *
 * ```
 * <Page::Terraform::Workspaces::List
 * />
 * ```
 *
 * @class PageTerraformWorkspacesList
 *
 */

/**
 * The current paginated list of workspaces.
 * @argument workspaces
 * @type {Array}
 */

/**
 * The current paginated list's page metadata.
 * @argument pagination
 * @type {Object}
 */

/**
 * A callback to set the page size
 * @argument onPageSizeChange
 * @type {Function}
 */

/**
 * The current in-scope organization's ID
 * @argument orgId | projectId
 * @type {string}
 */

/**
 * The current in-scope project's ID
 * @argument projectId
 * @type {string}
 */

export default class PageTerraformWorkspacesListComponent extends Component {
  @service router;

  query;

  get paginationQueryFunction() {
    const { nextPageToken, previousPageToken } = this.args?.pagination ?? {};
    return (page) => {
      if (page === 'next') {
        return {
          next: nextPageToken,
          prev: undefined,
        };
      } else {
        return {
          prev: previousPageToken,
          next: undefined,
        };
      }
    };
  }

  @action
  onSearchInput({ target: { value } }) {
    this.query = value;

    if (this.query === '') {
      //this.args.onSearch(this.query);
      this.router.transitionTo('terraform.workspaces.list', {
        queryParams: {
          filter: undefined,
        },
      });
    }
  }

  @action
  onSearchKeyup({ target: { value }, key }) {
    this.query = value;

    if (key === 'Enter') {
      //this.args.onSearch(this.query);
      this.router.transitionTo('terraform.workspaces.list', {
        queryParams: {
          filter: this.query,
        },
      });
    }
  }
}
