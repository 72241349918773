import Route from '@ember/routing/route';

export default class CloudOrgsDetailProjectsDetailHvnsDetailTransitGatewayAttachmentsConfigurationInstructionsIndexRoute extends Route {
  queryParams = {
    tab: {
      replace: true,
      refreshModel: true,
    },
    product: {
      replace: true,
      refreshModel: true,
    },
  };

  async model(params) {
    return {
      ...this.modelFor(
        'cloud.orgs.detail.projects.detail.hvns.detail.transit-gateway-attachments.detail'
      ),
      ...params,
    };
  }
}
