import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import {
  TYPE_CONSUL,
  TYPE_VAULT,
  TYPE_NETWORK_PEERING,
} from 'common/utils/cloud-resource-types';
import permissionGuard from 'core/decorators/permission-guard';
import {
  PREFIX_NETWORK_ROUTES,
  ACTION_CREATE,
} from 'authz/utils/permission-types/index';
export default class CloudOrgsDetailProjectsDetailHvnRoutesCreateRoute extends Route {
  @service api;

  @permissionGuard({
    permission: `${PREFIX_NETWORK_ROUTES}.${ACTION_CREATE}`,
  })
  async model() {
    let { hvn_id } = this.paramsFor(
      'cloud.orgs.detail.projects.detail.hvns.detail'
    );
    let { organization } = this.modelFor('cloud.orgs.detail');
    let { project } = this.modelFor('cloud.orgs.detail.projects.detail');
    let { network } = await this.api.network.get(
      organization.id,
      project.id,
      hvn_id
    );
    let { dependencies: networkDependencies } =
      await this.api.network.listDependencies(
        organization.id,
        project.id,
        hvn_id
      );

    let { peerings } = await this.api.network.listPeerings(
      organization.id,
      project.id,
      hvn_id
    );

    let { routes } = await this.api.network.listHVNRoutes(
      organization.id,
      project.id,
      network.id
    );

    //get the peering object if the route target is a peering
    let routeTargetPeerings = Promise.all(
      routes.map((route) => {
        if (route.target?.hvnConnection.type === TYPE_NETWORK_PEERING) {
          let peeringId = route.target?.hvnConnection.id;
          return this.api.network.getPeering(
            organization.id,
            project.id,
            network.id,
            peeringId
          );
        } else {
          return Promise.resolve();
        }
      })
    );
    routeTargetPeerings = await routeTargetPeerings;

    /**
     * TODO: Remove Promise.all call and filter against peerings
     * when API is updated to include peering dependencies
     * Then update the template logic to suss out federated Peerings
     */

    let peeringDependencies = Promise.all(
      peerings.map((peering) => {
        if (peering.target?.hvnTarget) {
          let networkId = peering.target?.hvnTarget.hvn.id;
          return this.api.network.listDependencies(
            organization.id,
            project.id,
            networkId
          );
        } else {
          return Promise.resolve();
        }
      })
    );

    //get the network dependencies related to a peerings hvnTarget
    let routeTargetPeeringsDep = Promise.all(
      routeTargetPeerings.map((peering) => {
        if (peering) {
          if (peering.peering.target?.hvnTarget)
            return this.api.network.listDependencies(
              organization.id,
              project.id,
              peering.peering.target.hvnTarget.hvn.id
            );
        } else {
          return Promise.resolve();
        }
      })
    );
    routeTargetPeeringsDep = await routeTargetPeeringsDep;

    peeringDependencies = await peeringDependencies;
    peerings = peerings.filter((peering, i) => {
      let peeringDependency = peeringDependencies[i]?.dependencies.filter(
        (dependency) =>
          dependency.type === TYPE_CONSUL || dependency.type === TYPE_VAULT
      );
      let networkConsulClusterDependencies = networkDependencies.filter(
        (dependency) =>
          dependency.type === TYPE_CONSUL || dependency.type === TYPE_VAULT
      );
      if (
        networkConsulClusterDependencies?.length > 0 &&
        peeringDependency?.length > 0
      ) {
        return false;
      } else {
        return true;
      }
    });
    let { tgwAttachments } = await this.api.network.listTGWAttachments(
      organization.id,
      project.id,
      hvn_id
    );

    // Compare the network dependencies to the route target dependencies
    // In this case we are only looking at the peering hvnTarget dependencies all the rest are undefined
    routes = routes.map((route, i) => {
      let routeTargetDependencies = routeTargetPeeringsDep[
        i
      ]?.dependencies.filter(
        (dependency) =>
          dependency.type === TYPE_CONSUL || dependency.type === TYPE_VAULT
      );
      let networkConsulClusterDependencies = networkDependencies.filter(
        (dependency) =>
          dependency.type === TYPE_CONSUL || dependency.type === TYPE_VAULT
      );
      if (
        networkConsulClusterDependencies?.length > 0 &&
        routeTargetDependencies?.length > 0
      ) {
        route.isLocked = true;
      } else {
        route.isLocked = false;
      }
      return route;
    });

    return {
      networkDependencies,
      network,
      peerings,
      tgwAttachments,
      routes,
    };
  }
}
