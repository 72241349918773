/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { getOwner } from '@ember/owner';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';

/**
 *
 * `PageGroupsOrganizationEditComponent` is the parent wrapper component for the HCP Groups edit page
 *  It contains the callback function that is passed down to <Iam::Groups::Forms::EditGroup @save={{this.save}} /> once the edit group form is validated.
 *
 *
 * ```
 * <Page::Groups::Organization::Edit />
 * ```
 *
 * @class PageGroupsOrganizationEit
 *
 */

export default class PageGroupsOrganizationEditComponent extends Component {
  /**
   * @argument organization
   * @type {Object}
   * @required
   */

  @service api;
  @service intl;
  @service flashMessages;
  @service router;
  @service userContext;

  get groupDisplayName() {
    return this.args.model.group.displayName;
  }

  get groupFormFields() {
    return {
      name: this.groupDisplayName,
      description: this.args.model.group.description,
    };
  }

  @task
  *save(form) {
    const payload = {
      resourceName: this.args.model.group.resourceName,
      group: {
        resourceId: this.args.model.group.resourceId,
        resourceName: this.args.model.group.resourceName,
        displayName: form.fields.name,
        description: form.fields.description,
      },
      updateMask: {
        paths: ['display_name', 'description'],
      },
    };

    try {
      yield this.api.groups.groupsServiceUpdateGroup(
        this.args.model.group.resourceName,
        payload
      );

      const successTitle = this.intl.t(
        'components.page.access-control.groups.detail.edit.success.title'
      );
      this.flashMessages.success(successTitle, {
        content: this.intl.t(
          'components.page.access-control.groups.detail.edit.success.message',
          {
            htmlSafe: true,
            groupName: form.fields.name,
          }
        ),
      });

      // refresh the Groups list and detail routes to update the model
      getOwner(this)
        .lookup('route:cloud.access-control.groups.list')
        ?.refresh();
      getOwner(this)
        .lookup('route:cloud.access-control.groups.detail')
        ?.refresh();

      // transition to the previous route
      return this.router.transitionTo(this.args.model.previousRouteName);
    } catch (error) {
      const errorTitle = this.intl.t(
        'components.page.access-control.groups.detail.edit.error.title'
      );
      this.flashMessages.error(errorTitle, {
        content: this.intl.t(
          'components.page.access-control.groups.detail.edit.error.message',
          {
            htmlSafe: true,
            groupName: this.groupDisplayName,
          }
        ),
      });
    }
  }
}
