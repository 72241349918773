import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { variation } from 'ember-launch-darkly';

export default class CloudOrgsDetailProjectsDetailHvnsListRoute extends Route {
  @service analytics;
  @service api;

  @service quota;
  @service abilities;

  async model() {
    let { organization } = this.modelFor('cloud.orgs.detail');
    let { project } = this.modelFor('cloud.orgs.detail.projects.detail');
    let { networks } = await this.api.network.list(organization.id, project.id);

    // TODO: [TCE-2007] Remove HVN activation A/B test analytics instrumentation.
    const abTestVariation = variation('hcp-ui-ab-test-hvn-activation-create');

    // TODO: [TCE-2007] Remove HVN activation A/B test analytics instrumentation.
    this.analytics.trackEvent('external_ui_hvn_activation_create_awareness', {
      abTestVariation,
    });

    // TODO: [TCE-2007] Remove HVN activation A/B test analytics instrumentation.
    const isHvnActivationEligible =
      networks?.length === 0 && this.abilities.can('create network', networks);
    if (isHvnActivationEligible)
      this.analytics.trackEvent(
        'external_ui_hvn_activation_create_eligibility',
        { abTestVariation }
      );

    return {
      project,
      networks,
    };
  }
}
