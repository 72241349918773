import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { DEBUG } from '@glimmer/env';

export default class VerifyRoute extends Route {
  @service session;
  @service flashMessages;
  @service api;
  @service intl;
  @service router;

  async beforeModel() {
    let { user_verification_token } = this.paramsFor('verify');
    let tokenIsValid;
    try {
      tokenIsValid = await this.api.email.verifyContact({
        verificationToken: user_verification_token,
      });
    } catch (e) {
      if (DEBUG) {
        //eslint-disable-next-line no-console
        console.error(e);
      }
    }
    this.redirectRoute('cloud', tokenIsValid);
  }

  redirectRoute(route, token) {
    this.router
      .transitionTo(route)
      .followRedirects()
      .finally(async () => {
        this.displayUserVerificationFlashMessage(token);
      });
  }

  async displayUserVerificationFlashMessage(token) {
    if (token) {
      let successTitle = this.intl.t(
        'notifications.user-verification.success.title'
      );
      this.flashMessages.success(successTitle, {
        content: this.intl.t('notifications.user-verification.success.content'),
        onAction: function (close) {
          return close();
        },
      });
    } else {
      let errorTitle = this.intl.t(
        'notifications.user-verification.error.title'
      );
      this.flashMessages.error(errorTitle, {
        content: this.intl.t('notifications.user-verification.error.content'),
        onAction: function (close) {
          return close();
        },
      });
    }
  }
}
