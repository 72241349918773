import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class CloudOrgsCreateController extends Controller {
  queryParams = [
    {
      orgCreateOrigin: 'content_origin',
      redirectAfterCreate: null,
    },
  ];

  @tracked orgCreateOrigin = null;
  @tracked redirectAfterCreate = null;
}
