import PolicyManager from 'manage-access/utils/policy-manager';

/**
 * Gets metadata for tracking iam assignment.
 *
 * @param {string} resourceMemberType - Resource member type (Users, Groups, or Service Principal)
 * @param {string} pageType - Type of page (Create or Edit)
 * @param {object} editedPolicy - New policy after permissions were updated.
 * @param {object} oldPolicy - Policy before permissions were changed.
 * @param {string} projectId - Project Id
 * @param {string} principalId - Principal Id
 * @returns {object} An object containing the metadata for tracking.
 */

const getProjectIamAssignmentTrackingMetadata = (
  resourceMemberType,
  pageType,
  editedPolicy,
  oldPolicy,
  projectId,
  principalId
) => {
  const updatedPolicyManager = new PolicyManager({
    policy: editedPolicy,
  });
  const oldPolicyManager = new PolicyManager({
    policy: oldPolicy,
  });

  const newRoles = updatedPolicyManager.getRolesByMemberId(principalId);
  const oldRoles = oldPolicyManager.getRolesByMemberId(principalId);

  const rolesAdded =
    newRoles?.roleIds?.filter(
      (newRole) => !oldRoles?.roleIds?.includes(newRole)
    ) || [];
  const rolesRemoved =
    oldRoles?.roleIds?.filter(
      (oldRole) => !newRoles?.roleIds?.includes(oldRole)
    ) || [];

  return {
    resource_member_type: resourceMemberType,
    page_type: pageType,
    roles_assigned: newRoles?.roleIds,
    roles_added: rolesAdded,
    roles_removed: rolesRemoved,
    project_id: projectId,
    principal_id: principalId,
  };
};

export default getProjectIamAssignmentTrackingMetadata;
