import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { RegionProviders } from 'core/utils/regions';
import {
  CONTENT_TABS_LABEL_TERMINAL,
  PRODUCT_CONSUL,
} from 'networks-common/utils/content-tabs';
export default class CloudOrgsDetailProjectsDetailHvnsDetailPeeringsConfigurationInstructionsRoute extends Route {
  @service router;

  queryParams = {
    tab: {
      replace: true,
      refreshModel: true,
    },
    product: {
      replace: true,
      refreshModel: true,
    },
  };

  async beforeModel() {
    let model = this.modelFor(
      'cloud.orgs.detail.projects.detail.hvns.detail.peerings.detail'
    );
    if (model.peering?.target?.hvnTarget) {
      this.router.transitionTo(
        'cloud.orgs.detail.projects.detail.hvns.detail.peerings.detail',
        model.peering.id
      );
    }
  }

  async model(params) {
    return {
      ...this.modelFor(
        'cloud.orgs.detail.projects.detail.hvns.detail.peerings.detail'
      ),
      ...params,
    };
  }

  resetController(controller) {
    // Reset the queryParams when a user navigates away from the page
    controller.tab = '';
    controller.product = '';
  }
  setupController(controller, model) {
    super.setupController(...arguments);
    let { network } = model;
    if (!controller.tab) {
      if (network.location.region.provider === RegionProviders.azure) {
        controller.tab = CONTENT_TABS_LABEL_TERMINAL.id;
        controller.product = PRODUCT_CONSUL.id;
      }
      if (network.location.region.provider === RegionProviders.aws) {
        controller.tab = CONTENT_TABS_LABEL_TERMINAL.id;
        controller.product = PRODUCT_CONSUL.id;
      }
    }
  }
}
