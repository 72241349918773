import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

export default class ResourcesProjectUserService extends Service {
  @service api;
  @service userContext;

  type = 'project-user';

  edit = {
    route: 'cloud.access-control.users.detail.edit-assignment',
    task: this.save,
  };

  delete = {
    task: this.removeUsers,
  };

  view = {
    route: 'cloud.access-control.users.detail',
  };

  get orgId() {
    return this.userContext.organization.id;
  }

  get projectId() {
    return this.userContext.project.id;
  }

  @task
  *save(projectPolicy, project) {
    let payload = {
      policy: {
        ...projectPolicy.get(),
      },
    };

    return yield this.api.resourceManager.project.projectServiceSetIamPolicy(
      project.id,
      payload
    );
  }

  @task
  *removeUsers(users, projectPolicy, project) {
    for (const user of users) {
      projectPolicy.removeMemberById(user.id);
    }

    const policy = projectPolicy.get();

    yield this.api.resourceManager.project.projectServiceSetIamPolicy(
      project.id,
      { policy }
    );
  }
}
