import Route from 'core/routes/route-with-user-context';
import { inject as service } from '@ember/service';
import { DEBUG } from '@glimmer/env';

export default class CloudAccessControlRoute extends Route {
  @service api;
  @service userContext;
  @service currentUser;

  async model({ org_id }) {
    let superCall;
    let ssoEnabled;

    superCall = await super.model(...arguments);
    if (!org_id) {
      org_id = superCall.organization?.id;
    }

    if (org_id) {
      // if sSOManagementServiceGetSSOType returns Not Found, then SSO is not enabled; otherwise, we can assume enabled
      try {
        let ssoType = await this.api.sso.sSOManagementServiceGetSSOType(org_id);
        if (ssoType !== null) {
          ssoEnabled = true;
        }
      } catch (err) {
        // 404 is returned if SSOType is of type 'None'
        if (err.status === 404) {
          ssoEnabled = false;
        }
      }
    }
    let isNoRoleGroupManagerInOrg = false;
    // allow group managers with no org role access to view groups
    if (!super.isProjectContext && this.currentUser.hasNoOrgRole) {
      const parentResourceName = `organization/${org_id}`;

      const { groups } =
        await this.api.groups.groupsServiceListGroups(parentResourceName);

      let groupLevelPermissionsAllGroups;

      // array of all permissions requested for all groups
      let requestedPermissions = [];

      // Iterate through each group to construct testIamPermissions payload
      for (const group of groups) {
        requestedPermissions.push({
          permissions: ['iam.groups.update'],
          resourceName: group.resourceName,
        });
      }
      try {
        ({ resourcePermissions: groupLevelPermissionsAllGroups } =
          await this.api.resourceManager.authorization.authorizationServiceBatchTestIamPermissions(
            {
              resources: requestedPermissions,
            }
          ));
      } catch (e) {
        if (DEBUG) {
          console.error(e);
        }
      }
      // Iterate through the keys of groupLevelPermissionsAllGroups
      Object.values(groupLevelPermissionsAllGroups).some((permissionsObj) => {
        const permissions = permissionsObj.allowedPermissions;
        // Check if the permissions array includes 'iam.groups.update'
        if (permissions.includes('iam.groups.update')) {
          // If 'iam.groups.update' permission is found, set isGroupManagerInOrg to true and exit the loop
          isNoRoleGroupManagerInOrg = true;
          return true;
        }
        return false;
      });
    }
    this.userContext.setSsoEnabled(ssoEnabled);
    let hasNoOrgRole = this.userContext.currentUser.hasNoOrgRole;
    return { ...superCall, isNoRoleGroupManagerInOrg, hasNoOrgRole };
  }
}
