/**
 * Produces a dot delimited string representing the previous route or if unavailable,
 * it returns the consumer supplied default route
 * @param {Ember::Transition} transition - @ember/routing/transition object
 * @param {string} defaultTo - consumer supplied default route
 * @returns {string}
 */

const routeIgnoreList = ['application_loading'];

export const capturePreviousRoute = (transition, defaultTo) => {
  if (!defaultTo || !transition)
    throw Error(
      'CapturePreviousRoute utility function requires all params to be provided'
    );

  const { from } = transition;
  if (from && !routeIgnoreList.includes(from.name)) {
    return from.name;
  } else {
    return defaultTo;
  }
};
