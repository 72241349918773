/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

/**
 *
 * `IamInvitationsBasicRoleChartServicesSecretsProjectPermissionsTable` is a table to
 * display the permissions for project-level basic roles.
 *
 *
 * ```
 * <Iam::Invitations::BasicRoleChart::Project::PermissionsTable />
 * ```
 *
 * @class IamInvitationsBasicRoleChartServicesSecretsProjectPermissionsTable
 *
 */

export default class IamInvitationsBasicRoleChartProjectPermissionsTableComponent extends Component {
  @service intl;

  allowedIcon = 'check';
  deniedIcon = 'skip';

  basicRoles = [
    {
      ability: this.intl.t(
        'iam.invitations.basic-role-chart.flyout.services.secrets.ability.read-secrets'
      ),
      isApplicationAdmin: true,
      isSecretReader: true,
    },
    {
      ability: this.intl.t(
        'iam.invitations.basic-role-chart.flyout.services.secrets.ability.create-edit-delete-secrets'
      ),
      isApplicationAdmin: true,
      isSecretReader: false,
    },
    {
      ability: this.intl.t(
        'iam.invitations.basic-role-chart.flyout.services.secrets.ability.create-edit-delete-applications'
      ),
      isApplicationAdmin: true,
      isSecretReader: false,
    },
    {
      ability: this.intl.t(
        'iam.invitations.basic-role-chart.flyout.services.secrets.ability.create-edit-delete-hvs-role'
      ),
      isApplicationAdmin: true,
      isSecretReader: false,
    },
    {
      ability: this.intl.t(
        'iam.invitations.basic-role-chart.flyout.services.secrets.ability.manage-project-service'
      ),
      isApplicationAdmin: false,
      isSecretReader: false,
    },
  ];
}
