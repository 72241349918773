/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import {
  FieldViolation,
  FormValidationError,
} from 'core/utils/form-validation';

/**
 *
 *
 * This component is primarily responsible for:
 *  1. Displaying the user's MFA recovery code
 *  2. Confirming that the user has recorded their MFA recovery code
 *  3. Closing the modal & displaying success flashMessage
 *
 * a success flashMessage indicating successful MFA enablement. If not passed in, the default value of `false` will be used instead.
 *
 */

export default class ManageMfaEnableRecoveryCodeComponent extends Component {
  @service intl;
  @tracked error = '';

  @action
  validateForm(evt) {
    evt.preventDefault();
    const validationError = new FormValidationError(
      this.intl.t(
        'components.page.account-settings.security.mfaModal.step3.error'
      )
    );
    if (this.args.downloadedRecoveryCode === false) {
      validationError.details.push(
        new FieldViolation(
          this.intl.t(
            'components.page.account-settings.security.mfaModal.step3.error'
          ),
          'recovery-code-checkbox'
        )
      );
      if (validationError.details.length) {
        this.error = validationError.details[0].field_violations[0].description;
        throw validationError;
      }
    }
    this.args.handleMFASuccess();
  }
}
