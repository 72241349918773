/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

/**
 *
 * `IamInvitationsBasicRoleChartFlyout` is a table contained in an Hds::Flyout
 * that describes roles and their permissiveness.
 *
 *
 * ```
 * <Iam::Invitations::BasicRoleChart::Flyout
 *   @permissionsTableComponent="some/component/path"
 *   @description={{t 'some.description'}}
 *   @isActive={{true}}
 *   @note={{t 'some.note'}}
 *   @onClose={{(set this.isActive false)}}
 *   @onOpen={{(set this.isActive true)}}
 * />
 * ```
 *
 * @class IamInvitationsBasicRoleChartFlyout
 *
 */

export default class IamInvitationsBasicRoleChartFlyoutComponent extends Component {
  /**
   * A string to place in the top and regular text of the alert.
   * @argument description
   * @type {string}
   */
  /**
   * A flag to control if the Hds::Flyout is open or closed. This is passed
   * directly to the component.
   * @argument isActive
   * @type {Boolean}
   */
  /**
   * A string to place in the bottom and semibold text of the alert.
   * @argument note
   * @type {string}
   */
  /**
   * A function that is passed along to the Hds::Flyout's @onClose argument.
   * @argument onClose
   * @type {Function}
   */
  /**
   * A function that is passed along to the Hds::Flyout's @onOpen argument.
   * @argument onOpen
   * @type {Function}
   */
}
