/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { setRedirectTarget } from 'hcp/utils/redirect';

/**
 *
 * `PageGlobalError` is used to display an invitation error.
 *
 *
 * ```
 * <Page::GlobalError @error={{error}} @variant={{errorType}} />
 * ```
 *
 * @class PageAcceptInvitationError
 *
 */
/**
 *
 * `error` containts the http error from a failed invitation acceptance call.
 * @argument error;
 * @type {Object}
 *
 */
/**
 *
 * `variant` changes the state of which error messaging is rendered.
 * @argument variant;
 * @type {String}
 *
 */
export default class PageGlobalErrorComponent extends Component {
  get errorText() {
    if (!this.args.error) {
      return '';
    }
    let { message, stack } = this.args.error;
    return `${message}

${stack && JSON.stringify(stack, null, 2).replace(/\\n/g, '\n')}
    `;
  }

  @action
  restartSession() {
    setRedirectTarget('/sign-up');
    this.session.invalidate();
  }
}
