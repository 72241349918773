/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

/**
 *
 * `PageOrganizationsDetail` shows details about and organization and notifies
 * a user if their SSO isn't set up.
 *
 *
 * ```
 * <Page::Organizations::Detail
 *   @model={{this.model}}
 * />
 * ```
 *
 * @class PageOrganizationsDetail
 *
 */

export default class PageOrganizationsDetailComponent extends Component {
  /**
   * The expected model is an object consisting of:
   * @param `model.owner`: the user principal who owns the org
   * @param `model.organization`: the organization record of the organization
   *     in question.
   * @param `model.hasSSO`: a boolean indicating if the org has SSO configured.
   *
   * @argument model
   * @type {object}
   */
}
