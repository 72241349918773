/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import {
  AWS_CONNECTIONS_INFO,
  AWS_DISCLOSURE_GUIDES_PEERING,
} from 'networks-common/utils/aws-connections';
import { TYPE_NETWORK } from 'common/utils/cloud-resource-types';
import {
  PRODUCT_CONSUL,
  PRODUCT_VAULT,
} from 'networks-common/utils/content-tabs';
import {
  AWS_SECURITY_GROUP,
  AWS_ROUTE_TABLE,
} from 'networks-common/utils/peerings';

/**
 *
 * `PageHvnPeeringsConfigurationAccordion` description here.
 *
 *
 * ```
 * <Page::HvnPeerings::ConfigurationAccordion
 *   @peering={{peering}}
 *   @connectionMethods={{connectionMethods}}
 *   @product={{product}}
 *   @peeringMetadata={{peeringMetadata}}
 * />
 * ```
 *
 * @class PageHvnPeeringsConfigurationAccordion
 *
 */

export default class PageHvnPeeringsConfigurationAccordionComponent extends Component {
  /**
   * The Model object
   * @argument model
   * @type {Object}
   */
  /**
   * A string defined in Peering util as constants, representing Web Console or AWS CLI
   * @argument connectionMethods
   * @type {Object}
   */
  /**
   *
   * `product` such as consul or vault;
   * @argument product;
   * @type {String}
   *
   */
  /**
   *
   * `peeringMetadata` is the peering JSON stored in the Metadata service for for this peering
   * @argument peeringMetadata;
   * @type {Object}
   *
   */

  @service api;
  @service('metadata.local-storage') metadata;

  @tracked awsSecurityGroupId = '';

  products = {
    consul: PRODUCT_CONSUL,
    vault: PRODUCT_VAULT,
  };

  configurationCompletionSteps = {
    securityGroup: AWS_SECURITY_GROUP,
    routeTable: AWS_ROUTE_TABLE,
  };

  get isAwsCloudShell() {
    return this.args.connectionMethods.isAwsCloudShell;
  }

  get isWebConsole() {
    return this.args.connectionMethods.isWebConsole;
  }

  awsDisclosureGuides = AWS_DISCLOSURE_GUIDES_PEERING;

  connectionsInfo = AWS_CONNECTIONS_INFO;

  get cidrBlock() {
    let { cidrBlock } = this.args.model.network;
    return cidrBlock;
  }

  get region() {
    let { region } = this.args.model.peering.target.awsTarget;
    return region;
  }

  get providerPeeringId() {
    let { providerPeeringId } = this.args.model.peering;
    return providerPeeringId;
  }

  get securityGroupMarkedComplete() {
    let { peeringMetadata } = this.args;
    return peeringMetadata.completion.includes(
      this.configurationCompletionSteps.securityGroup
    );
  }

  get routeTableMarkedComplete() {
    let { peeringMetadata } = this.args;
    return peeringMetadata.completion.includes(
      this.configurationCompletionSteps.routeTable
    );
  }

  get peeringMarkedComplete() {
    return this.securityGroupMarkedComplete && this.routeTableMarkedComplete;
  }

  @action
  markAsComplete(completedStep) {
    let {
      model: {
        project: { id: projectId },
        network: { id: networkId },
        peering: { id: peeringId },
      },
      peeringMetadata,
    } = this.args;
    this.saveCompletionMetadata.perform({
      projectId,
      networkId,
      peeringId,
      peeringMetadata,
      completedSteps: [completedStep],
    });
  }

  @action
  markAllAsComplete() {
    let {
      model: {
        project: { id: projectId },
        network: { id: networkId },
        peering: { id: peeringId },
      },
      peeringMetadata,
    } = this.args;
    let completedSteps = [AWS_SECURITY_GROUP, AWS_ROUTE_TABLE];
    this.saveCompletionMetadata.perform({
      projectId,
      networkId,
      peeringId,
      peeringMetadata,
      completedSteps,
    });
  }

  @task
  *saveCompletionMetadata({
    projectId,
    networkId,
    peeringId,
    peeringMetadata,
    completedSteps,
  }) {
    const scope = 'resource';
    const identifier = `project/${projectId}/${TYPE_NETWORK}/${networkId}`;

    let { completion } = peeringMetadata;

    if (completedSteps.length === 1) {
      let [completedStep] = completedSteps;
      if (!completion.includes(completedStep)) {
        completion = [...completion, completedStep];
      } else {
        completion = completion.filter((step) => step !== completedStep);
      }
    } else {
      let stepsToBeAddedAsComplete = completedSteps.filter(
        (completedStep) => !completion.includes(completedStep)
      );
      if (stepsToBeAddedAsComplete.length > 0) {
        completion = [...completion, ...stepsToBeAddedAsComplete];
      } else {
        completion = [];
      }
    }

    let newPeeringMetadata = {};
    newPeeringMetadata[peeringId] = { ...peeringMetadata, ...{ completion } };

    let d = {
      scope,
      identifier,
      metadata: { peering: newPeeringMetadata },
    };
    yield this.metadata.set(identifier, d);
    this.args.updatePeeringMetadata(newPeeringMetadata[peeringId]);
  }
}
