// this is a copy of
// https://github.com/mainmatter/ember-simple-auth/blob/0b4bebb95a74080cc20256ebd65cbb7e5890611f/packages/ember-simple-auth/addon/authenticators/test.js
//
//
const sessionData = {
  accessToken: 'fake-access-token',
  expiration: Date.now() + 60 * 60 * 1000,
  user: {
    email: 'test@hcp.com',
    email_verified: true,
    nickname: 'testuser',
    connection: 'auth0',
    auth_time: Math.floor(Date.now() / 1000),
  },
};
import BaseAuthenticator from 'ember-simple-auth/authenticators/base';

export default class LocalDev extends BaseAuthenticator {
  async restore(data = {}) {
    let mergedData = {
      ...data,
      ...sessionData,
    };
    return Promise.resolve(mergedData);
  }

  async authenticate(data = {}) {
    let mergedData = {
      ...data,
      ...sessionData,
    };
    return Promise.resolve(mergedData);
  }

  async invalidate() {
    return Promise.resolve();
  }
}
