import Service, { inject as service } from '@ember/service';
import { assert } from '@ember/debug';

/*
 *
 * The lastAccessedProject service stores and retrieves persisted metadata
 * modeled against the cloud-metadata-service structure.
 *
 * When a user accesses a project, this service can be used to capture the
 * project identifier.
 *
 * An adapter that honors the contract of both the localStorage and
 * cloudMetadata can also be implemented to progressively
 * support a hybrid approach for forwards compability.
 *
 * @typedef MetadataAdapter
 * @property {method} getMetadata - a method to retrieve an entry from a
 *  target persistence medium
 * @property {method} setMetadata - a method to persist an entry from a target
 *  persistence medium
 *
 * @typedef MetadataKeyValues
 * @property {string} scope - a string value representing the
 *  cloud-metadata-service scope (i.e. 'user', 'organization', 'resource')
 * @property {string} identifier - a string value representing the
 *  cloud-metadata-service scope's unique identifier (i.e. organization ID)
 * @property {Object.<string, Organization>} metadata - an object assigned to a key
 *  matching the cloud-metadata-service scope's unique identifier
 *
 * @typedef Organization
 * @property {string} id
 * @property {Project} project
 *
 * @typedef Project
 * @property {string} id
 * */

export default class LastAccessedProjectService extends Service {
  @service('metadata.local-storage') localStorage;

  /** @member {MetadataAdapter|null} */
  adapter = null;

  /**
   * Configure the service at instantiation to the default adapter
   */
  constructor() {
    super(...arguments);
    this.configure('localStorage');
  }

  /**
   * Set the MetadataAdapter to be used.
   * @param {string} adapterName - The string that identifies the injected service property name
   */
  configure(adapterName) {
    let adapter = this[adapterName];
    assert('Incompatible metadata persistence adapter: not found', adapter);
    this.adapter = adapter;
  }

  async getProject(identifier) {
    let { metadata = {} } = await this.getMetadata();
    if (metadata[identifier] && metadata[identifier].project) {
      return metadata[identifier].project;
    }
  }

  async setProject(project) {
    await this.setMetadata(project);
  }

  /**
   * Retrieve a metadata entry for the last accessed project using the current configured adapter.
   * @return {Object} metadata - an entry to the metadata service modeled against the cloud-metadata-service
   */
  async getMetadata() {
    assert(
      'Incompatible metadata persistence adapter: no implemented getMetadata',
      this.adapter.getMetadata
    );
    let metadata = await this.adapter.getMetadata();
    return metadata;
  }

  /**
   * Persist a metadata entry for the last accessed project using the current configured adapter.
   * @param {Object} project - A project object with a required id member variable
   */
  async setMetadata(project) {
    assert(
      'Incompatible metadata persistence adapter: no implemented setMetadata',
      this.adapter.setMetadata
    );
    let { metadata } = await this.getMetadata();
    let identifier = project.parent.id;
    let newMetadata = {};
    if (metadata) {
      newMetadata = {
        ...metadata,
      };
    }
    newMetadata[identifier] = {
      ...(newMetadata[identifier] ?? {}),
      project: { id: project.id },
    };

    let record = { scope: 'user', metadata: newMetadata };

    await this.adapter.setMetadata(record);
  }
}
