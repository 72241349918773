import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

// TODO: replace using this with the built-in helper `format-list`
// from ember-intl. Once we upgrade to version 6. `format-list` uses
// `Intl.ListFormat` under the covers.
export default class ListDependenciesHelper extends Helper {
  @service locale;
  @service intl;
  @service router;

  compute([dependencies = []]) {
    if (!dependencies || !dependencies.length) return '';

    let items = dependencies.map((dependency) => {
      let { id } = dependency;
      return `'<a href="${this.router.urlFor(
        'consul.clusters.hcp.detail.index',
        id,
        {
          queryParams: {
            project_id: dependency.location.projectId,
          },
        }
      )}">'${id}'</a>'`;
    });

    let lang = this.locale.locale;
    let formatter = new Intl.ListFormat(lang, {
      style: 'long',
      type: 'conjunction',
    });

    let delimitedList = formatter.format(items);

    return this.intl.formatMessage(delimitedList, { htmlSafe: true });
  }
}
