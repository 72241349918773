/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

/**
 *
 * `PageProjectsDashboard` displays a dashboard for a Project.
 *
 *
 * ```
 * <Page::Projects::Dashboard
 *   @organization={{this.model.organization}}
 *   @project={{this.model.project}}
 *   @resources={{this.model.resources}}
 * />
 * ```
 *
 * @class PageProjectsDashboard
 *
 */
/**
 *
 * `organization` is the organization context.
 * @argument organization;
 * @type {Object}
 *
 */
/**
 *
 * `project` is the project context.
 * @argument project;
 * @type {Object}
 *
 */
/**
 *
 * `resources` is a collection of active resources
 * @argument resources;
 * @type {Object}
 *
 */
export default class PageProjectsDashboardComponent extends Component {
  @service abilities;
  @service('resources.project') projects;
  @service userContext;
}
