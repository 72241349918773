/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

/**
 *
 * Edit form for project objects.
 *
 *
 * ```
 * <Page::Projects::Edit
 *   @model={{this.model}}
 * />
 * ```
 *
 * @class PageProjectsEdit
 *
 */

export default class PageProjectsEditComponent extends Component {
  /**
   * The current project object
   * @argument model
   * @type {Object}
   */

  @service api;
  @service router;
  @service flashMessages;
  @service intl;

  @tracked name = this.args?.model?.name;
  @tracked description = this.args?.model?.description;

  @task
  *save(evt) {
    // don't submit the form
    evt.preventDefault();

    let {
      model: { id, name, description },
      previousRouteName,
    } = this.args;

    if (this.name !== name) {
      yield this.api.resourceManager.project.projectServiceSetName(id, {
        name: this.name,
      });
    }

    if (this.description !== description) {
      yield this.api.resourceManager.project.projectServiceSetDescription(id, {
        description: this.description,
      });
    }

    this.flashMessages.success(
      this.intl.t('components.page.projects.create.project-updated')
    );

    this.router.transitionTo(previousRouteName);
  }
}
