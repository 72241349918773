/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

import IamPolicy from 'core/utils/iam-policy';
import { supportTicketPrefillHref } from 'core/utils/support-ticket';

/**
 *
 * `PageProjectsUsersDetailComponent` is renders a project-level user principal.
 *
 *
 * ```
 * <Page::Projects::Users::Detail
 *   @organization={{this.model.organization}}
 *   @organizationPolicy={{this.model.orgPolicy}}
 *   @project={{this.model.project}}
 *   @projectPolicy={{this.model.projectPolicy}}
 *   @roleLocations={{this.model.roleLocations}}
 *   @userPrincipal={{this.model.userPrincipal}}
 * />
 * ```
 *
 * @class PageProjectsUsersDetailComponent
 *
 */

/**
 * The organization.
 * @argument organization
 * @type {Object}
 */
/**
 * The organization policy.
 * @argument organizationPolicy
 * @type {Object}
 */
/**
 * The project.
 * @argument project
 * @type {Object}
 */
/**
 * The project policy.
 * @argument projectPolicy
 * @type {Object}
 */
/**
 * The user principal.
 * @argument userPrincipal
 * @type {Object}
 */
/**
 * This should be the organization role and a project role if applicable.
 * @argument roleLocations
 * @type {Array}
 */

export default class PageProjectsUsersDetailComponent extends Component {
  @service currentUser;
  @service flashMessages;
  @service intl;
  @service userContext;
  @service('resources.project-user') projectUsers;
  @service router;

  @tracked organizationPolicy = this.args.organizationPolicy
    ? new IamPolicy(this.args.organizationPolicy)
    : undefined;
  @tracked projectPolicy = this.args.projectPolicy
    ? new IamPolicy(this.args.projectPolicy)
    : undefined;

  get roleLocations() {
    const { roleLocations = [] } = this.args;
    return roleLocations;
  }

  get supportTicketHref() {
    return supportTicketPrefillHref(this.userContext);
  }
}
