import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { variation } from 'ember-launch-darkly';
import IamPolicy from 'core/utils/iam-policy';

import fetchUntilEmpty from 'hcp/utils/fetch-until-empty';
import filterBlacklistedRoles from 'manage-access/utils/filter-blacklisted-roles';
import { VAULT_RADAR_DEVELOPER_ROLE } from 'hcp/utils/constants';

export default class CloudAccessControlGroupsAddRoute extends Route {
  @service api;
  @service abilities;
  @service router;
  @service userContext;

  async redirect() {
    if (
      this.abilities.cannot('get-iam-policy in project') &&
      this.abilities.cannot('set-iam-policy in project')
    ) {
      return this.router.transitionTo('cloud.access-control');
    }
  }

  async model() {
    const { isProjectContext } = this.modelFor('cloud.access-control');
    const { organization, project } = this.userContext;
    const parentResourceName = `organization/${organization.id}`;
    const { groups } =
      await this.api.groups.groupsServiceListGroups(parentResourceName);

    const { policy: projectIamPolicyRaw } =
      await this.api.resourceManager.project.projectServiceGetIamPolicy(
        project.id
      );
    const projectIamPolicy = new IamPolicy(projectIamPolicyRaw);

    // Filter out the groups which already have a role binding to this project's IAM policy.
    const groupsWithoutProjectBindings = groups.filter(
      (g) => !projectIamPolicy.getMemberById(g.resourceId, 'GROUP')?.roleId
    );

    let projectRoles = [];

    if (this.abilities.can('list roles')) {
      const resourceName = `project/${project.id}`;
      const fetchAllRoles = fetchUntilEmpty(
        (...args) =>
          this.api.resourceManager.resources.resourceServiceListRoles(...args),
        'roles'
      );
      ({ roles: projectRoles } = await fetchAllRoles(
        resourceName,
        undefined,
        100
      ));

      const allowListedRoles = [];
      if (variation('hcp-vault-radar-abac-workflow')) {
        allowListedRoles.push(VAULT_RADAR_DEVELOPER_ROLE);
      }
      const denyListedRoles = (
        variation('hcp-ui-fine-grained-blacklisted-roles') ?? []
      ).filter((roleId) => {
        return !allowListedRoles.includes(roleId);
      });

      projectRoles = filterBlacklistedRoles(projectRoles, denyListedRoles);
    }

    return {
      isProjectContext,
      groups,
      organization,
      project,
      groupsWithoutProjectBindings,
      projectRoles,
      policy: projectIamPolicyRaw,
    };
  }
}
