/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

/**
 *
 * `PageHvnsCardsAssociatedClusters` this component renders the clusters associated with an HVN.
 *
 *
 * ```
 * <Page::Hvns::Cards::AssociatedClusters
 *   @model={{@model}}
 * />
 * ```
 *
 * @class PageHvnsCardsAssociatedClusters
 *
 */

export default class PageHvnsCardsAssociatedClusters extends Component {
  /**
   * `model` is an HVN Object
   * @argument model
   * @type {Object}
   */
}
