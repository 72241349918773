export const UTM_TO_CAMPAIGN_KEY_MAPPINGS = {
  product: 'product_intent', // TODO: [HCE-17] Remove `product` from campaign allow-list after *.io sites switch over.
  product_intent: 'product_intent',
  utm_campaign: 'name',
  utm_channel_bucket: 'channel_bucket',
  utm_content: 'content',
  utm_medium: 'medium',
  utm_offer: 'offer',
  utm_source: 'source',
};

export const UTM_KEYS_ALLOW_LIST = Object.keys(UTM_TO_CAMPAIGN_KEY_MAPPINGS);

export default function campaignAnalytics(searchParams) {
  searchParams = new URLSearchParams(searchParams);

  const campaign = UTM_KEYS_ALLOW_LIST.reduce((map, key) => {
    const value = searchParams.get(key);
    const newKey = 'insights_' + UTM_TO_CAMPAIGN_KEY_MAPPINGS[key];

    return value ? { ...map, [newKey]: value } : map;
  }, {});

  return campaign;
}
