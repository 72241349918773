import { PREFIX_VAULT_RADAR } from 'authz/utils/permission-types/index';

export const AUTH_CHANNEL_EVENTS = {
  ACCESS_TOKEN: 'ACCESS_TOKEN',
  INVALIDATE_SESSION: 'INVALIDATE_SESSION',
  RESTORE_SESSION: 'RESTORE_SESSION',
  SYNC_SESSION: 'SYNC_SESSION',
};

export const AUTH_CHANNEL_NAME = 'auth_channel';

// Org create route content_origin query params
export const CREATE_ORIGIN_TFC = 'tfc';
export const CREATE_ORIGIN_HCP_CLOUD = 'hcp_cloud_with_redirect';

// Active resources route content_origin query params
export const ACTIVE_RESOURCES_ORIGIN_PROJECT_DELETE = 'hcp_project_delete';

export const DENY_LIST = [
  '/login/callback',
  '/sign-up',
  '/terms-of-service',
  '/sla',
  '/shared-responsibility-model',
  '/service-agreements',
  '/sign-out',
  '/verify/',
];

export const ACTIVE_RESOURCES_PROJECT_DELETE_ERROR = {
  STATUS: 400,
  ERROR:
    'project deletion failed, make sure there are no active resources associated with it',
  CODE: '9',
  MESSAGE:
    'project deletion failed, make sure there are no active resources associated with it',
  DETAILS: [],
};

export const VAULT_RADAR_DEVELOPER_ROLE = `roles/${PREFIX_VAULT_RADAR}.developer`;
export const VAULT_RADAR_AGENT_ROLE = `roles/${PREFIX_VAULT_RADAR}.agent`;
export const VAULT_RADAR_CLI_USER_ROLE = `roles/${PREFIX_VAULT_RADAR}.cli-user`;
