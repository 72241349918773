/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import { AZURE_DISCLOSURE_GUIDES_PEERING } from 'networks-common/utils/azure-connections';

export default class AzureContentTabsCloudShellComponent extends Component {
  @service router;

  azureDisclosureGuides = AZURE_DISCLOSURE_GUIDES_PEERING;

  @action
  updateQueryParams(queryParams = {}) {
    return this.router.transitionTo(this.router.currentRouteName, {
      queryParams,
    });
  }
}
