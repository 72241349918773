/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

/**
 *
 * `PageAcceptInvitation` is the parent wrapper component for the user accept invite page.
 *
 * `invitationToken` is the invite token for the organization.
 * It is required for <Page::AcceptInvitation::Main @invitationToken={{@invitationToken}} />.
 *
 * ```
 * <Page::AcceptInvitation
 *  @invitationToken={{@invitationToken}}
 * />
 * ```
 *
 * @class PageAcceptInvitation
 *
 */
/**
 *
 * `invitationToken` is the invite token for the organization.
 * @argument invitationToken;
 * @type {String}
 *
 */

export default class PageAcceptInvitationComponent extends Component {}
