/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

/**
 *
 * `PageHvnsCardsLearnMore` renders static learn more content related to HashiCorp Virtual Networks (HVNS).
 *
 *
 * ```
 * <Page::Hvns::Cards::LearnMore />
 * ```
 *
 * @class PageHvnsCardsLearnMore
 *
 */

export default class PageHvnsCardsLearnMoreComponent extends Component {}
