import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { TYPE_NETWORK } from 'common/utils/cloud-resource-types';

export default class CloudOrgsDetailProjectsDetailHvnsDetailIndexRoute extends Route {
  @service('metadata.local-storage') metadata;

  async model() {
    let { organization } = this.modelFor('cloud.orgs.detail');
    let { project } = this.modelFor('cloud.orgs.detail.projects.detail');
    let model = this.modelFor('cloud.orgs.detail.projects.detail.hvns.detail');
    let {
      network: { id: networkId },
    } = model;

    const identifier = `project/${project.id}/${TYPE_NETWORK}/${networkId}`;
    let metadata = await this.metadata.get(identifier).catch(() => {});

    return {
      ...model,
      organization,
      project,
      metadata,
    };
  }
}
