import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import { ProjectServiceGetProjectsCountScopeTypeEnum } from '@clients/cloud-resource-manager';
import permissionGuard from 'core/decorators/permission-guard';
import {
  ACTION_ASSIGN_PROJECT,
  ACTION_CREATE,
  PREFIX_BILLING_ACCOUNTS,
  PREFIX_RESOURCE_MANAGER_PROJECTS,
} from 'authz/utils/permission-types/index';

export default class CloudOrgsDetailProjectsCreateRoute extends Route {
  @service api;
  @service currentUser;
  @service router;
  @service userContext;

  @permissionGuard({
    permission: `${PREFIX_RESOURCE_MANAGER_PROJECTS}.${ACTION_CREATE}`,
  })
  @permissionGuard({
    permission: `${PREFIX_BILLING_ACCOUNTS}.${ACTION_ASSIGN_PROJECT}`,
  })
  async model() {
    const { organization } = this.modelFor('cloud.orgs.detail');
    const { projects } =
      await this.api.resourceManager.project.projectServiceList(
        ProjectServiceGetProjectsCountScopeTypeEnum.ORGANIZATION,
        organization.id
      );

    await this.userContext.setProject(null);

    return { organization, projects };
  }
}
