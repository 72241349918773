import PaginatedRoute from 'core/routes/paginated-route';
import { inject as service } from '@ember/service';
import { assert } from '@ember/debug';

export default class CloudOrgsDetailProjectsListRoute extends PaginatedRoute {
  @service api;
  @service session;
  @service billing;
  @service abilities;
  @service currentUser;
  @service router;
  @service userContext;
  @service lastAccessedProject;

  async model({ pageSize = 10, nextPageToken, previousPageToken }) {
    const { organization } = this.userContext;
    const currentPageSize = pageSize ? Number(pageSize) : pageSize;

    await this.userContext.setProject(null);

    let { projects, pagination } =
      await this.api.resourceManager.project.projectServiceList(
        'ORGANIZATION',
        organization.id,
        currentPageSize,
        nextPageToken,
        previousPageToken
      );

    let metaLastOpenedProject = await this.lastAccessedProject.getProject(
      organization.id
    );

    let lastOpenedProject;

    if (metaLastOpenedProject?.id) {
      lastOpenedProject = projects.find((project) => {
        return project.id === metaLastOpenedProject.id;
      });

      if (!lastOpenedProject) {
        try {
          let { project } =
            await this.api.resourceManager.project.projectServiceGet(
              metaLastOpenedProject.id
            );
          lastOpenedProject = project;
        } catch (e) {
          assert(
            `Unable to fetch last opened project ${metaLastOpenedProject.id}. Error:`,
            e
          );
        }
      }
    }

    return {
      currentPageSize,
      lastOpenedProject,
      organization,
      pagination,
      projects,
    };
  }

  async redirect({ organization, projects = [] }) {
    let { id: orgId } = organization;
    if (
      projects.length === 0 &&
      this.abilities.can('create project', projects)
    ) {
      // and a default project
      let { project } =
        await this.api.resourceManager.project.projectServiceCreate({
          name: this.currentUser.defaultProjectName,
          parent: {
            id: orgId,
            type: 'ORGANIZATION',
          },
        });

      // if the billing account is not found, try to create it
      // there are guardrails in place to prevent duplicate billing
      // accounts on the server side in case of the billing service being down
      // (i.e.billing account not being found due to something other than a 404), see:
      // https://github.com/hashicorp/cloud-billing/blob/main/internal/billingaccount/privateapi/create.go#L184
      if (!this.userContext.billing?.billingAccount) {
        let defaultBilling = await this.billing.createDefaultBillingAccount(
          orgId,
          project.id,
          'US'
        );

        this.userContext.billing = defaultBilling;
      }

      this.router.transitionTo('cloud.orgs.detail.projects.detail', project.id);
    }
  }

  async afterModel({ projects = [] }) {
    if (!projects.length) {
      this.router.transitionTo('cloud.orgs.detail');
    }
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.resetPagination();
    }
  }
}
