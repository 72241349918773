/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { getOwner } from '@ember/owner';
import { inject as service } from '@ember/service';
import { DEBUG } from '@glimmer/env';

export default class PageOrganizationsDetailScimComponent extends Component {
  @service api;
  @service flashMessages;
  @service intl;
  @service router;

  @tracked showDisableModal = false;
  @tracked showTokenModal = false;

  @task
  *enableSCIM() {
    try {
      yield this.api.scim.scimServiceEnableScim(this.args.organization.id);
      this.flashMessages.success(
        this.intl.t(
          'components.page.organizations.detail.scim.enable.success.title'
        ),
        {
          content: this.intl.t(
            'components.page.organizations.detail.scim.enable.success.message'
          ),
        }
      );
      this.refreshRoute();
    } catch (e) {
      if (DEBUG) {
        //eslint-disable-next-line no-console
        console.error(e);
      }
      this.flashMessages.error(
        this.intl.t(
          'components.page.organizations.detail.scim.enable.error.title'
        ),
        {
          content: this.intl.t(
            'components.page.organizations.detail.scim.enable.error.message'
          ),
        }
      );
    }
  }

  @task
  *disableSCIM() {
    try {
      yield this.api.scim.scimServiceDisableScim(this.args.organization.id);
      this.flashMessages.success(
        this.intl.t(
          'components.page.organizations.detail.scim.disable.success.message'
        )
      );
      this.closeDisableModal();
      this.refreshRoute();
    } catch (e) {
      if (DEBUG) {
        //eslint-disable-next-line no-console
        console.error(e);
      }
      this.flashMessages.error(
        this.intl.t(
          'components.page.organizations.detail.scim.disable.error.title'
        ),
        {
          content: this.intl.t(
            'components.page.organizations.detail.scim.disable.error.message'
          ),
        }
      );
    }
  }

  @action
  openDisableModal() {
    this.showDisableModal = true;
  }

  @action
  closeDisableModal() {
    if (this.isDestroyed || this.isDestroying) return;
    this.showDisableModal = false;
  }

  @action
  openTokenModal() {
    this.showTokenModal = true;
  }

  @action
  closeTokenModal() {
    if (this.isDestroyed || this.isDestroying) return;
    this.showTokenModal = false;
    this.refreshRoute();
  }

  @action
  refreshRoute() {
    let route = getOwner(this).lookup(
      `route:${this.router.currentRoute.parent.name}`
    );
    return route.refresh();
  }
}
