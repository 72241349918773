/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import {
  CONTENT_TABS_LABELS,
  PRODUCT_POWERSHELL,
  PRODUCT_AZURE_CLI,
  PRODUCT_BASH,
} from 'networks-common/utils/content-tabs';
import {
  isActivePeering,
  isPendingAcceptance,
} from 'networks-common/utils/peerings';

export default class PageHvnPeeringsDetailContentTabsAzureComponent extends Component {
  @service router;

  tabs = CONTENT_TABS_LABELS;
  products = {
    powershell: PRODUCT_POWERSHELL,
    azurecli: PRODUCT_AZURE_CLI,
    bash: PRODUCT_BASH,
  };

  @action
  updateQueryParams(queryParams = {}) {
    return this.router.transitionTo(this.router.currentRouteName, {
      queryParams,
    });
  }

  get isActive() {
    return isActivePeering(this.args.peering);
  }

  get isPending() {
    return isPendingAcceptance(this.args.peering);
  }

  get product() {
    return this.args.product;
  }
}
