/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { assert } from '@ember/debug';
import { getOwner } from '@ember/owner';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import PolicyManager from 'manage-access/utils/policy-manager';
import { supportTicketPrefillHref } from 'core/utils/support-ticket';

import { HashicorpCloudResourcemanagerPolicyBindingMemberType } from '@clients/cloud-resource-manager';

/**
 *
 * `IamGroupsModalsEditMemberRole` renders the modal for removing a member from an HCP Group
 *
 * ```
 * <Iam::Groups::Modals::EditMemberRole
 *  @group={{@group}}
 *  @groupPolicy={{@groupPolicy}}
 *  @memberToEditRole={{@memberToEditRole}}
 *  @isActive={{@isActive}}
 *  @closeModal={{@closeRoleModal}}
 *  @onPolicyUpdateSuccess={{this.onPolicyUpdateSuccess}}
 * />
 * ```
 *
 * @class IamGroupsModalsEditMemberRole
 *
 */

/**
 * `group` is an object that contains the HCP Group properties
 * @argument group
 * @type {Object}
 */

/**
 * `groupLevelPermissions` is a an array of actions the `memberToEditRole` is authorized to take on the group
 * @argument groupLevelPermissions
 * @type {Object}
 */

/**
 * `rawGroupPolicy` is a raw json representation of an HCP Group's updated policy
 * @argument rawGroupPolicy
 * @type {Object}
 */

/**
 * `groupPolicy` is a Policy Manager instance that contains the HCP Group's updated policy
 * @argument groupPolicy
 * @type {Object}
 */

/**
 * `memberToEditRole is an object that contains properties of the member whose role to edit for the HCP Group
 * @argument memberToEditRole
 * @type {Object}
 */

/**
 * This shows or hides the modal based on truthiness.
 * @argument isActive
 * @type {Boolean}
 */

/**
 * A callback function to be called on a successful removal of a group member or
 * owhen a user cancels the modal.
 * @argument closeModal
 * @type {Function}
 */

export default class IamGroupsModalsEditMemberRole extends Component {
  @service api;
  @service intl;
  @service flashMessages;
  @service router;
  @service userContext;

  @tracked GroupPolicy = new PolicyManager({
    policy: this.args.groupPolicy,
  });

  GROUP_MANAGER_ROLE_ID = 'roles/iam.group-manager';

  get isGroupManager() {
    const { roleIds = [] } =
      this.GroupPolicy.getRolesByMember({
        memberId: this.args.memberToEditRole.id,
        memberType: HashicorpCloudResourcemanagerPolicyBindingMemberType.USER,
      }) ?? {};
    return roleIds.includes(this.GROUP_MANAGER_ROLE_ID);
  }

  get supportTicketHref() {
    return supportTicketPrefillHref(this.userContext);
  }

  @action
  didUpdateGroupPolicy() {
    this.GroupPolicy = new PolicyManager({
      policy: this.args.groupPolicy,
    });
  }

  @action
  removeGroupManagerRole() {
    this.GroupPolicy.removeMemberFromRole(
      {
        memberId: this.args.memberToEditRole.id,
        memberType: HashicorpCloudResourcemanagerPolicyBindingMemberType.USER,
      },
      [this.GROUP_MANAGER_ROLE_ID]
    );
  }

  @action
  addGroupManagerRole() {
    this.GroupPolicy.addMemberToRole(
      {
        memberId: this.args.memberToEditRole.id,
        memberType: HashicorpCloudResourcemanagerPolicyBindingMemberType.USER,
      },
      [this.GROUP_MANAGER_ROLE_ID]
    );
  }

  @task
  *updateGroupMemberRole(e) {
    e.preventDefault();
    assert(
      'memberToEditRole is required',
      this.args.memberToEditRole !== undefined
    );

    const { group, memberToEditRole, closeModal, onPolicyUpdateSuccess } =
      this.args;
    const { name, email } = memberToEditRole;
    const { resourceName } = group;

    try {
      const payload = this.GroupPolicy.getRawPolicy();
      const { policy } =
        yield this.api.resourceManager.resources.resourceServiceSetIamPolicy({
          policy: payload,
          resourceName,
        });

      if (policy) {
        if (
          onPolicyUpdateSuccess &&
          typeof onPolicyUpdateSuccess === 'function'
        ) {
          onPolicyUpdateSuccess(policy);
        }
        this.flashMessages.success(
          this.intl.t(
            'components.iam.groups.form.edit-member-role.success.title'
          ),
          {
            content: this.intl.t(
              'components.iam.groups.form.edit-member-role.success.message',
              {
                groupRole: this.isGroupManager
                  ? this.intl.t(
                      'components.iam.groups.form.edit-member-role.success.manager'
                    )
                  : this.intl.t(
                      'components.iam.groups.form.edit-member-role.success.member'
                    ),
                memberNameOrEmail: name || email,
                htmlSafe: true,
              }
            ),
          }
        );

        if (closeModal && typeof closeModal === 'function') {
          yield this.args.closeModal();
        }
      }
    } catch (e) {
      this.flashMessages.error(
        this.intl.t('components.iam.groups.form.edit-member-role.error.title'),
        {
          content: this.intl.t(
            'components.iam.groups.form.edit-member-role.error.message'
          ),
          linkIcon: 'help',
          linkIconPosition: 'leading',
          linkText: this.intl.t(
            'components.iam.groups.form.edit-member-role.error.message.support-button'
          ),
          linkUrl: this.supportTicketHref,
        }
      );
    }
    //refresh the Group detail route to update the model
    const refreshRoute = getOwner(this).lookup(
      'route:cloud.access-control.groups.detail'
    );
    yield refreshRoute.refresh();
    return this.router.transitionTo('cloud.access-control.groups.detail');
  }
}
