import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class CloudAccountSettingsSessionManagerRoute extends Route {
  @service config;
  @service api;
  @service router;

  async model() {
    let { sessions } =
      await this.api.sessionManager.sessionServiceListMySessions();

    return { sessions };
  }
}
