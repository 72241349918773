import Service from '@ember/service';
import { getOwner } from '@ember/owner';

export default class BillingConfigService extends Service {
  #appConfig = getOwner(this).lookup('service:config');

  get stripeConfig() {
    return this.#appConfig.app.stripe;
  }
}
