/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

/**
 *
 * `HcpAlertTerraformPermissionsGoToTerraform` is a control block at the end
 * of an alert for terraform permissions that points you to either a geo-specific
 * link of Terraform's app url along with a documentation link
 *
 *
 * ```
 * <Hcp::Alert::TerraformPermissions::GoToTerraform />
 * ```
 *
 * @class HcpAlertTerraformPermissionsGoToTerraform
 *
 */

export default class HcpAlertTerraformPermissionsGoToTerraformComponent extends Component {}
