/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import {
  isAwsProviderNetwork,
  isAzureProviderNetwork,
} from 'networks-common/utils/networks';

/**
 *
 * `PageHvnPeeringsDetailContentTabs` renders the HVN Peerings Detail tabs content based on provider
 *
 *
 * ```
 * <PageHvnPeeringsDetailTabsContent @model=={{@model}}/>
 * ```
 *
 * @class PageHvnPeeringsDetailTabsContent
 *
 */

export default class PageHvnPeeringsDetailTabsContentComponent extends Component {
  get isAws() {
    return isAwsProviderNetwork(this.args.model.network);
  }
  get isAzure() {
    return isAzureProviderNetwork(this.args.model.network);
  }
}
