/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class PageOrganizationsDetailSSOSelectMethodComponent extends Component {
  @service api;
  @service router;

  // set default
  @tracked selectedProtocol = this.protocols.OIDC.name;

  get protocols() {
    return {
      OIDC: {
        name: 'oidc',
        img: '/svgs/open-id.svg',
      },
      SAML: {
        name: 'saml',
        img: '/svgs/saml.svg',
      },
    };
  }

  @action
  onProtocolChange(e) {
    this.selectedProtocol = e.target.value;
  }

  @action
  onClick() {
    if (this.selectedProtocol === this.protocols.OIDC.name) {
      this.router.transitionTo('cloud.orgs.detail.settings.sso.oidc.configure');
    } else {
      // redirect to saml form
      this.router.transitionTo('cloud.orgs.detail.settings.sso.saml.configure');
    }
  }

  @action
  onCancel() {
    // redirect to SSO settings page
    this.router.transitionTo('cloud.orgs.detail.settings.sso');
  }
}
