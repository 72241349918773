import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class CloudOrgsDetailSettingsIndexRoute extends Route {
  @service api;
  @service abilities;
  @service router;

  async model() {
    const { org_id } = this.paramsFor('cloud.orgs.detail');
    const { organization } =
      await this.api.resourceManager.org.organizationServiceGet(org_id);

    let userPrincipal;

    if (this.abilities.can('get user')) {
      ({ userPrincipal } =
        await this.api.iam.iamServiceGetUserPrincipalByIdInOrganization(
          organization.id,
          organization.owner.user
        ));
    }

    let defaultRole = null;
    let hasSSO = false;
    let ssoType = 'UNSET';

    // NOTE: HTTP status code 404 signifies  SSO type is not found,
    //       if "OIDC"/"SAML" consider enabled, but not "UNSET".
    try {
      const { type } = await this.api.sso.sSOManagementServiceGetSSOType(
        organization.id
      );
      ssoType = type;

      hasSSO = !!ssoType;

      if (ssoType === 'OIDC') {
        const { authConnections } =
          await this.api.authConfig.authConfigServiceGetAuthConnections(
            organization?.id
          );
        defaultRole = authConnections[0]?.defaultRole;
      } else if (ssoType === 'SAML') {
        const { configurations } =
          await this.api.sso.sSOManagementServiceListSSOConfigurations(
            organization?.id
          );
        defaultRole = configurations[0]?.defaultRole;
      }
    } catch (err) {
      const isSsoTypeNotFound = err.status === 404;
      if (isSsoTypeNotFound) {
        hasSSO = false;
        ssoType = 'UNSET';
      }
    }

    return {
      owner: userPrincipal,
      organization,
      hasSSO,
      ssoType,
      defaultRole,
    };
  }
}
