/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
/**
 *
 * `PageGroupsOrganizationDetailEditMembersComponent` shows a list of organization principals
 * that can be added or removed from an HCP Group
 *
 *
 * ```
 * <Page::Groups::Organization::Detail::Members::Edit
 *   @group={{foo}}
 *   @principals={{@principals}}
 * />
 * ```
 *
 * @class PageGroupsOrganizationDetailEditMembersComponent
 *
 */

export default class PageGroupsOrganizationDetailEditMembersComponent extends Component {}
