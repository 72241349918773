import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import {
  CONTENT_TABS_LABEL_TERMINAL,
  PRODUCT_CONSUL,
} from 'networks-common/utils/content-tabs';

export default class CloudOrgsDetailProjectsDetailHvnsDetailTransitGatewayAttachmentsConfigurationInstructionsIndexController extends Controller {
  queryParams = ['tab', 'product'];

  @tracked tab = CONTENT_TABS_LABEL_TERMINAL.id;
  @tracked product = PRODUCT_CONSUL.id;
}
