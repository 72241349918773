import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import {
  PREFIX_IAM_GROUPS,
  ACTION_UPDATE,
} from 'authz/utils/permission-types/index';

export default class CloudAccessControlGroupsDetailMembersAddRoute extends Route {
  @service api;
  @service router;
  @service userContext;
  @service groupMembers;

  serialize(_, params) {
    // NOTE: We're decoding the v2 `resource_name`, not serializing it ... that's OK.
    return {
      ...params,
      resource_name: decodeURIComponent(params.resource_name),
    };
  }

  async beforeModel() {
    const { groupLevelPermissions } = this.modelFor(
      'cloud.access-control.groups.detail'
    );
    if (
      !groupLevelPermissions?.includes(`${PREFIX_IAM_GROUPS}.${ACTION_UPDATE}`)
    ) {
      const error = new Error('ForbiddenError');
      error.name = 'ForbiddenError';
      error.status = 403;
      throw error;
    }
  }

  async model() {
    this.groupMembers.reset();
    const { organization } = this.userContext;
    const { resource_name: resourceName } = this.paramsFor(
      'cloud.access-control.groups.detail'
    );

    const filter = {
      organizationId: organization.id,
      searchText: '',
      principalTypes: ['PRINCIPAL_TYPE_USER'],
      excludeMembersOfGroupNames: [resourceName],
    };

    const [{ group }, { principals: eligibleMembers }] = await Promise.all([
      this.api.groups.groupsServiceGetGroup(resourceName),
      this.api.iam.iamServiceSearchPrincipals(organization.id, { filter }),
    ]);

    return {
      group,
      eligibleMembers,
    };
  }
}
