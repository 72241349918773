import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import permissionGuard from 'core/decorators/permission-guard';
import {
  ACTION_LIST,
  PREFIX_IAM_INVITATIONS,
} from 'authz/utils/permission-types/index';

export default class CloudAccessControlInvitesListRoute extends Route {
  @service api;
  @service userContext;
  @service router;

  beforeModel() {
    let { organizationMeta } = this.userContext;
    if (organizationMeta.ssoEnabled) {
      this.router.transitionTo('cloud.access-control');
    }
  }

  @permissionGuard({
    permission: `${PREFIX_IAM_INVITATIONS}.${ACTION_LIST}`,
  })
  async model() {
    let { organization } = this.userContext;
    // Just a quick note about these undefineds. The cloud-iam client gets
    // generated with several pagination arguments that we aren't utilizing yet.
    // In order to pass in states, we have to skip the pagination args in this
    // function so that we can get to the states array.
    let invitations =
      await this.api.invitation.invitationsServiceListOrganizationInvitations(
        organization.id,
        undefined,
        undefined,
        undefined,
        ['PENDING']
      );
    let inviterUserPrincipalIds = invitations.invitations.reduce(
      (ids, invite) => {
        if (invite.inviterUserPrincipalId) {
          ids.push(invite.inviterUserPrincipalId);
        }
        return ids;
      },
      []
    );

    let userPrincipals = [];

    if (inviterUserPrincipalIds.length) {
      ({ userPrincipals } =
        await this.api.iam.iamServiceGetUserPrincipalsByIDsInOrganization(
          organization.id,
          {
            ids: inviterUserPrincipalIds,
          }
        ));
    }

    return {
      ...invitations,
      organization,
      userPrincipals,
    };
  }
}
