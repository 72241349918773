/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

/**
 *
 * `PageServicePrincipalsProjectDetail` renders the details of a project Service Principal
 *
 *
 * ```
 * <Page::ServicePrincipals::Project::Detail
 *   @model={{model}}
 * />
 * ```
 *
 * @class PageServicePrincipalsProjectDetail
 *
 */
/**
 * Contains the `servicePrincipal` and 'project'.
 * @argument model
 * @type {Object}
 */
export default class PageServicePrincipalsProjectDetailComponent extends Component {
  @service api;
  @service flashMessages;
  @service intl;
  @service router;
  @service quota;

  @tracked showDeleteModal = false;

  @task
  *deleteServicePrincipal() {
    const { id, organizationId, projectId } = this.args.model.servicePrincipal;
    const response =
      yield this.api.servicePrincipal.servicePrincipalsServiceDeleteProjectServicePrincipal(
        organizationId,
        projectId,
        id
      );
    this.onDeleteServicePrincipalSuccess({
      response,
      id,
    });
  }

  @action
  onDeleteServicePrincipal() {
    this.showDeleteModal = true;
  }

  @action
  resetDeleteServicePrincipal() {
    this.showDeleteModal = false;
  }

  @action
  onDeleteServicePrincipalSuccess() {
    this.resetDeleteServicePrincipal();
    this.flashMessages.success(
      this.intl.t(
        'components.page.access-control.service-principals.delete-modal.success'
      )
    );
    this.router.transitionTo('cloud.access-control.service-principals.list');
  }
}
