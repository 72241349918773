/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { variation } from 'ember-launch-darkly';

/**
 *
 * `PageHvnsCardsGetStarted` this renders static HVN Get started content. Eventually
 * this will be updated to contain dynamic content.
 *
 *
 * ```
 * <Page::Hvns::Cards::GetStarted @networks={{networks}}} @project={{project}} />
 * ```
 *
 * @class PageHvnsCardsGetStarted
 *
 */

export default class PageHvnsCardsGetStartedComponent extends Component {
  @service analytics;

  @action captureAnalyticsEventABTestHvnCreateIntentOnBoardingStepper() {
    const abTestVariation = variation('hcp-ui-ab-test-hvn-activation-create');

    this.analytics.trackEvent(
      'external_ui_hvn_activation_create_intent_button_clicked',
      { abTestVariation, buttonLocation: 'onboarding-stepper-step-1' }
    );
  }
}
