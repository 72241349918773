/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

/**
 *
 * ```
 * <Sso::Banner::DefaultRoleOrgLockoutConfirmation />
 * ```
 *
 * @class SsoBannerDefaultRoleOrgLockoutConfirmation
 *
 */

export default class SsoBannerDefaultRoleOrgLockoutConfirmationComponent extends Component {}
