/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/owner';

/**
 *
 * `PageUsersList` lists the HCP Users.
 *
 *
 * ```
 * <Page::Users::List @model={{model}} />
 * ```
 *
 * @class PageUsersList
 *
 */

/**
 * `model` has the list of HCP users, the current user, and the organization.
 * @argument model
 * @type {Object}
 */
export default class PageUsersListComponent extends Component {
  @service currentUser;
  @service userContext;
  @service flashMessages;
  @service intl;
  @service router;

  @tracked showRemoveModal = false;
  @tracked usersToRemove = null;
  @tracked returnFocusTo = null;

  // invitations are not enabled for organizations with sso enabled
  get invitationsEnabled() {
    return !this.userContext.organizationMeta.ssoEnabled;
  }

  @action
  confirmRemoveUsers(users, returnFocusTo) {
    this.showRemoveModal = true;
    this.usersToRemove = users;
    this.returnFocusTo = returnFocusTo;
  }

  @action
  onRemoveUsersError({ users }) {
    let action = 'remove';
    if (
      users[0].identityTypes.includes('SAMLP') ||
      users[0].identityTypes.includes('HASHICORP_SSO')
    ) {
      action = 'delete';
    }
    this.flashMessages.error(
      this.intl.t(
        `components.page.access-control.users.${action}.error.title`,
        {
          numUsers: users.length,
        }
      ),
      {
        actionText: this.intl.t(
          `components.page.access-control.users.${action}.error.action`
        ),
        content: this.intl.t(
          `components.page.access-control.users.${action}.error.content`,
          {
            numUsers: users.length,
          }
        ),
        onAction: (close) => {
          window.location.href =
            'https://support.hashicorp.com/hc/en-us/requests/new';
          return close();
        },
      }
    );

    return;
  }

  @action
  onRemoveUsersSuccess({ users }) {
    this.resetRemoveUsers();
    // We should use the router service for this once this PR is merged.
    // via: https://github.com/emberjs/rfcs/issues/592
    let route = getOwner(this).lookup(`route:${this.router.currentRouteName}`);
    let action = 'remove';
    if (
      users[0].identityTypes.includes('SAMLP') ||
      users[0].identityTypes.includes('HASHICORP_SSO')
    ) {
      action = 'delete';
    }
    this.flashMessages.success(
      this.intl.t(`components.page.access-control.users.${action}.success`, {
        numUsers: users.length,
      })
    );
    return route.refresh();
  }

  @action
  resetRemoveUsers() {
    this.showRemoveModal = false;
    this.usersToRemove = null;
    this.returnFocusTo = null;
  }
}
