import Route from '@ember/routing/route';

export default class CloudOrgsDetailProjectsDetailHvnsDetailPeeringsCreateRoute extends Route {
  async model(params) {
    return {
      ...this.modelFor(
        'cloud.orgs.detail.projects.detail.hvns.detail.peerings.detail'
      ),
      ...params,
    };
  }
}
