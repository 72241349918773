/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { supportTicketPrefillHref } from 'core/utils/support-ticket';
import IamPolicy from 'core/utils/iam-policy';

/**
 *
 * `PageUsersDetail` renders the details of a user and allows editing
 * some user traits. Important: This component is only good for editing basic
 * roles and should not be used for any fine-grained context.
 *
 *
 * ```
 * <Page::Users::PageUsersDetail
 *   @organization={{this.model.organization}}
 *   @organizationPolicy={{this.model.policy}}
 *   @roles={{this.model.roles}}
 *   @userPrincipal={{this.model.userPrincipal}}
 *   @scimEnabled={{this.model.scimEnabled}}
 * />
 * ```
 *
 * @class PageUsersDetail
 *
 */
/**
 * The organization.
 * @argument organization
 * @type {Object}
 */
/**
 * The organization policy.
 * @argument organizationPolicy
 * @type {Object}
 */
/**
 * This should be the organization role with any project roles.
 * @argument roles
 * @type {Array}
 */
/**
 * The user principal.
 * @argument userPrincipal
 * @type {Object}
 */
/**
 * A flag for if scim is enabled.
 * @argument scimEnabled
 * @type {Object}
 */
export default class PageUsersDetailComponent extends Component {
  @service flashMessages;
  @service intl;
  @service router;

  @tracked returnFocusTo = null;
  @tracked showRemoveModal = false;
  @tracked usersToRemove = null;

  get OrganizationPolicy() {
    const { organizationPolicy } = this.args;

    if (!organizationPolicy) {
      return undefined;
    }

    return new IamPolicy(organizationPolicy);
  }

  get organizationRoleId() {
    if (!this.OrganizationPolicy) {
      return undefined;
    }

    const { userPrincipal } = this.args;
    const { roleId } = this.OrganizationPolicy.getMemberById(userPrincipal.id);
    return roleId;
  }

  get roleLocations() {
    const { roleLocations = [] } = this.args;
    return roleLocations;
  }

  get supportTicketHref() {
    return supportTicketPrefillHref(this.userContext);
  }

  @action
  confirmRemoveUsers(users, returnFocusTo) {
    this.showRemoveModal = true;
    this.usersToRemove = users;
    this.returnFocusTo = returnFocusTo;
  }

  @action
  onRemoveUsersError({ users }) {
    this.flashMessages.error(
      this.intl.t('components.page.access-control.users.remove.error.title', {
        numUsers: users.length,
      }),
      {
        actionText: this.intl.t(
          'components.page.access-control.users.remove.error.action'
        ),
        content: this.intl.t(
          'components.page.access-control.users.remove.error.content',
          {
            numUsers: users.length,
          }
        ),
        onAction: (close) => {
          window.location.href =
            'https://support.hashicorp.com/hc/en-us/requests/new';
          return close();
        },
      }
    );

    return;
  }

  @action
  onRemoveUsersSuccess({ users }) {
    this.resetRemoveUsers();

    this.flashMessages.success(
      this.intl.t('components.page.access-control.users.remove.success', {
        numUsers: users.length,
      })
    );

    return this.router.transitionTo('cloud.access-control.users.list');
  }

  @action
  resetRemoveUsers() {
    this.showRemoveModal = false;
    this.usersToRemove = null;
    this.returnFocusTo = null;
  }
}
