import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { TYPE_NETWORK_TRANSIT_GATEWAY_ATTACHMENT } from 'common/utils/cloud-resource-types';

export default class CloudOrgsDetailProjectsDetailHvnsDetailTransitGatewayAttachmentsDetailIndexRoute extends Route {
  @service api;

  queryParams = {
    tab: {
      replace: true,
      refreshModel: true,
    },
    product: {
      replace: true,
      refreshModel: true,
    },
  };

  async model(params) {
    let { tgw_id } = this.paramsFor(
      'cloud.orgs.detail.projects.detail.hvns.detail.transit-gateway-attachments.detail'
    );
    let { organization } = this.modelFor('cloud.orgs.detail');
    let { project } = this.modelFor('cloud.orgs.detail.projects.detail');
    let { network } = this.modelFor(
      'cloud.orgs.detail.projects.detail.hvns.detail'
    );
    let { tgwAttachment } = await this.api.network.getTGWAttachment(
      organization.id,
      project.id,
      network.id,
      tgw_id
    );
    let { routes } = await this.api.network.listHVNRoutes(
      organization.id,
      project.id,
      network.id,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      tgw_id,
      TYPE_NETWORK_TRANSIT_GATEWAY_ATTACHMENT,
      undefined,
      undefined,
      undefined
    );
    return {
      organization,
      project,
      network,
      tgwAttachment,
      routes,
      ...params,
    };
  }
}
