/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

/**
 *
 * `IamInvitationsAlertNoRoleWithProjects` is an alert banner for a warning
 * before inviting a principal without a role.
 *
 *
 * ```
 * <Iam::Invitations::Alert::NoRoleWithProjects />
 * ```
 *
 * @class IamInvitationsAlertNoRoleWithProjects
 *
 */

export default class IamInvitationsAlertNoRoleWithProjectsComponent extends Component {}
