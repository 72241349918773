/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { assert } from '@ember/debug';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';
import { tracked } from 'tracked-built-ins';

/**
 *
 * `IamGroupsForm` is the parent wrapper component for the HCP Groups Organization list page
 *
 *
 * ```
 * <Iam::Groups::Form
 *   @saveButtonText='Create group'
 *   @save={{this.save}}
 *   @isSaveDisabled={{false}}
 * />
 * ```
 *
 * @class IamGroupsForm
 *
 */

const baseKey = 'components.iam.groups.form';

export default class IamGroupsFormComponent extends Component {
  /**
   * Button text to use for "save" action.
   * @argument saveButtonText
   * @type {String}
   * @required
   */

  /**
   * Passed in async/generator Ember concurrency task with a perform callback
   * function to invoke upon form submission
   * NOTE: This form component just collects and validates input, doesn't call API
   * @argument save
   * @type {Function}
   * @required
   */

  /**
   * Optionally disables the form submit button that would invoke the save action
   * @argument isSaveDisabled
   * @type {Boolean}
   */
  @service api;
  @service intl;
  @service flashMessages;
  @service router;
  @service userContext;

  constructor() {
    super(...arguments);

    assert(
      '<Iam::Groups::Form> argument @saveButtonText is a required non-empty string',
      typeof this.args.saveButtonText === 'string' &&
        this.args.saveButtonText.length >= 1
    );

    assert(
      '<Iam::Groups::Form> argument @save is a required Ember concurrency task with a .perform callback function',
      typeof this.args.save?.perform === 'function'
    );
  }

  @task
  *validate(evt) {
    evt.preventDefault();

    if (!this.validateForm()) return;
    // refer to callback save
    return yield this.args.save.perform(this.form);
  }

  // May only contain letters, numbers, underscores, periods, hypens.
  nameInvalidCharacters = /[^a-zA-Z0-9\s_\-.]/;

  @action setName(e) {
    this.form.fields.name = e.target.value;
    this.validateForm({ name: true });
  }
  @action setDescription(e) {
    this.form.fields.description = e.target.value;
    this.validateForm({ description: true });
  }

  @tracked form = {
    fields: this.args.groupFormFields || {
      name: '',
      description: '',
    },
    validationErrors: {
      name: [],
      description: [],
    },
    fieldValidators: {
      name: [
        {
          message: this.intl.t(`${baseKey}.errors.required`),
          isValid: (v) => v && v !== '',
          subValidators: [
            {
              message: this.intl.t(
                `${baseKey}.create-group.errors.name.must-only-contain-valid-characters`
              ),
              isValid: (v) => !this.nameInvalidCharacters.test(v),
            },
            {
              message: this.intl.t(
                `${baseKey}.create-group.errors.name.must-be-between-specific-length`
              ),
              isValid: (v) => v.length >= 1 && v.length <= 128,
            },
          ],
        },
      ],
      description: [
        {
          message: this.intl.t(
            `${baseKey}.create-group.errors.description.cannot-exceed-specific-length`
          ),
          isValid: (v) => v.length <= 300,
        },
      ],
    },
  };

  validateForm(
    { name = false, description = false } = { name: true, description: true }
  ) {
    if (name) {
      let nameErrors = this.runValidators(
        this.form.fieldValidators.name,
        this.form.fields.name
      );
      set(
        this.form,
        'validationErrors.name',
        nameErrors.map((v) => v.message)
      );
    }
    if (description) {
      let descriptionErrors = this.runValidators(
        this.form.fieldValidators.description,
        this.form.fields.description
      );
      set(
        this.form,
        'validationErrors.description',
        descriptionErrors.map((v) => v.message)
      );
    }

    return !Object.keys(this.form.validationErrors).find(
      (k) => this.form.validationErrors[k].length
    );
  }

  runValidators(validators, value) {
    let failedValidators = validators.flatMap((v) => {
      if (!v.isValid(value)) {
        return v;
      }

      if (v.subValidators) {
        return this.runValidators(v.subValidators, value);
      }

      return [];
    });

    return failedValidators;
  }
}
