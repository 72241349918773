export const REDIRECT_TARGET_KEY = 'hcp.redirectTarget';

export const getRedirectTarget = () => {
  return localStorage.getItem(REDIRECT_TARGET_KEY);
};

export const setRedirectTarget = (target) => {
  localStorage.setItem(REDIRECT_TARGET_KEY, target);
};

export const clearRedirectTarget = () => {
  localStorage.removeItem(REDIRECT_TARGET_KEY);
};
