/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

/**
 *
 * `LocationLink` renders a Hds::Link::Inline component that uses a helper to
 *     get route, model, and query param args based on a HashiCorp link model.
 *
 * ```
 * <LocationLink
 *   @color="secondary"
 *   @hideIcon={{true}}
 *   @link={{link}}
 * />
 * ```
 *
 * @class LocationLink
 *
 */

/**
 * Any @color value that Hds supports for Link components.
 * @argument color
 * @type {string}
 */

/**
 * Flag to override default behavior by hiding icon.
 * @argument hideIcon
 * @type {boolean|any}
 */

/**
 * HashiCorp API link model to use when attempting to map to a UI route.
 * @argument link
 * @type {Object}
 */

export default class LocationLinkComponent extends Component {
  @service intl;
}
