/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

/**
 *
 * `PageOrganizationsCreateSuccess` renders the organization create success messaging.
 *
 *
 * ```
 * <Page::Organizations::Create::Success @organization={{@organization}} />
 * ```
 *
 * @class PageOrganizationsCreateSuccess
 *
 */

/**
 *
 * `organization` is an object.
 * @argument organization;
 * @type {Object}
 * @required
 *
 */

export default class PageOrganizationsCreateSuccessComponent extends Component {}
