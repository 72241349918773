// OIDCClient implements an OpenID Connect (OIDC) "public client" using the OAuth2
// Proof Key for Code Exchange (PKCE) extension. OIDC allows applications to authenticate
// users from a trusted OAuth2 authorization server.
//
// The client assumes that window.crypto.getRandomValues is available.
//
// References
// * https://oauth.com/playground/
// * https://openid.net/developers/specs
// * https://datatracker.ietf.org/doc/html/rfc7636
// * https://datatracker.ietf.org/doc/html/rfc6749
// * https://datatracker.ietf.org/doc/html/rfc6819
// * https://datatracker.ietf.org/doc/html/rfc8414
// * https://datatracker.ietf.org/doc/html/draft-ietf-oauth-security-topics
// * https://www.ory.sh/oauth2-for-mobile-app-spa-browser/
// * https://www.ory.sh/hydra/docs/reference/api/#tag/public
// * https://www.ory.sh/hydra/docs/guides/cors/
// * https://auth0.com/docs/secure/tokens/json-web-tokens/validate-json-web-tokens

import Logger, { LoggerOptions } from './logger';
import type { TokenResponse } from './utils';
import {
	appendHiddenIFrame,
	base64urlencode,
	clearLocalStorage,
	OIDCToken,
	makeFetchRequest,
	sha256,
	verifyProviderKeySignature,
} from './utils';
import {
	validateIssuerClaim,
	validateAudienceClaim,
	validateAzpClaim,
	validateAlgValue,
	validateCurrentTime,
} from './validation';

class OIDCClient {
	public hiddenIframeRequestLocked = false;
	public ready: boolean;
	public clientID: string;
	public redirectURI: string;
	public providerConfigurationPromise: Promise<this>;
	public providerConfiguration: any;
	public tokenURL?: string;
	public authURL?: string;
	public logoutURL?: string;
	protected logger: Logger;

	constructor(
		clientID: string,
		redirectURI: string,
		issuerURI: string,
		loggerOptions?: LoggerOptions
	) {
		this.ready = false;
		this.clientID = clientID;
		this.redirectURI = redirectURI;
		this.logger = new Logger(loggerOptions ?? {});

		// Getting the provider configuration is an async operation, the client
		// isn't ready for use until we get this information from the provider.
		this.providerConfigurationPromise = Promise.resolve().then(async () => {
			// We use an arrow function to preserve the `this` context.
			const value = await this.getProviderConfiguration(issuerURI);
			this.providerConfiguration = value;
			this.tokenURL = value.token_endpoint;
			this.authURL = value.authorization_endpoint;
			this.logoutURL = value.end_session_endpoint;
			this.ready = true;
			return this;
		});

		// If we're in an iframe, message the parent window so that it can
		// continue with the auth flow.
		if (window.top && window.self !== window.top) {
			window.top.postMessage(
				{
					source: 'oidc-callback',
					href: window.location.href,
				},
				window.location.origin
			);
		}
	}

	async waitUntilReady() {
		if (this.ready) {
			return this;
		}
		return await this.providerConfigurationPromise;
	}

	getIdTokenClaims(id_token: string) {
		try {
			const { claims } = new OIDCToken(id_token);
			return claims;
		} catch (e) {
			this.logger.error('failed to parse id_token');
			this.logger.error(e);
		}
	}

	// https://datatracker.ietf.org/doc/html/rfc7636#section-4.1
	generateCodeVerifier() {
		const bytes = new Uint8Array(64);
		self.crypto.getRandomValues(bytes);
		return base64urlencode(bytes);
	}

	// https://datatracker.ietf.org/doc/html/rfc7636#section-4.2
	async generateCodeChallenge(codeVerifier: string) {
		const digest = await sha256(codeVerifier);
		return base64urlencode(digest);
	}

	// https://datatracker.ietf.org/doc/html/rfc6749#section-4.1.1
	// https://openid.net/specs/openid-connect-core-1_0.html#AuthRequest
	authCodeURL(state: string, codeChallenge: string, extraOptions?: object) {
		let url: URL;
		try {
			url = new URL(this.authURL!);
		} catch (e) {
			this.logger.error('failed to construct authCodeURL');
			this.logger.error(e);
			throw e;
		}
		url.searchParams.set('response_type', 'code');
		url.searchParams.set('client_id', this.clientID);
		url.searchParams.set('redirect_uri', this.redirectURI);
		url.searchParams.set('scope', 'openid');
		url.searchParams.set('state', state);
		url.searchParams.set('code_challenge', codeChallenge);
		url.searchParams.set('code_challenge_method', 'S256');

		// Add any extra options, such as provider-specific paramaters,
		// or other any optional OIDC paramaters such as login_hint,
		// prompt, max_age, ui_locales, display, and acr_values.
		if (extraOptions) {
			// We specially handle the scope and scopes in extraOptions
			// to ensure that "openid" is always included. The extra scope,
			// or extra scopes append to the default "openid" scope.
			//
			// Do not use both scope and scopes, only one or the other.
			for (const [key, value] of Object.entries(extraOptions)) {
				if (key === 'scope') {
					// single scope string
					let scopes = ['openid'];
					scopes.push(value);
					scopes = [...new Set(scopes)];
					url.searchParams.set('scope', scopes.join(' '));
				} else if (key === 'scopes') {
					// array of scope strings
					let scopes = ['openid'];
					scopes = scopes.concat(value);
					scopes = [...new Set(scopes)];
					url.searchParams.set('scope', scopes.join(' '));
				} else {
					url.searchParams.set(key, value);
				}
			}
		}
		return url;
	}

	// https://datatracker.ietf.org/doc/html/rfc6749#section-4.1.2
	verifyStateFromAuthorizationServer(
		knownState: string,
		redirectState: string
	) {
		return knownState === redirectState;
	}

	// https://datatracker.ietf.org/doc/html/rfc6749#section-4.1.2
	// https://datatracker.ietf.org/doc/html/rfc6749#section-4.1.2.1
	// https://openid.net/specs/openid-connect-core-1_0.html#AuthResponseValidation
	validateAuthorizationServerResponse(redirectURL: URL | string) {
		let url: URL;
		try {
			url = new URL(redirectURL);
		} catch (e) {
			this.logger.error('failed to construct redirectURL');
			this.logger.error(e);
			throw e;
		}

		if (url.searchParams.get('error')) {
			this.logger.error(
				`failed to login user due to ${url.searchParams.get(
					'error'
				)}: ${url.searchParams.get('error_description')}`
			);
			const err = new Error(url.searchParams.get('error_description')!);
			this.logger.error(err);
			throw err;
		}

		return true;
	}

	// https://datatracker.ietf.org/doc/html/rfc6749#section-4.1.3
	async exchangeAuthorizationCodeForTokens(
		authorizationCode: string,
		codeVerifier: string,
		extraOptions?: object
	) {
		let url: URL;
		try {
			url = new URL(this.tokenURL!);
		} catch (e) {
			this.logger.error('failed to construct tokenURL');
			this.logger.error(e);
			throw e;
		}

		url.searchParams.set('grant_type', 'authorization_code');
		url.searchParams.set('client_id', this.clientID);
		url.searchParams.set('redirect_uri', this.redirectURI);
		url.searchParams.set('code', authorizationCode);
		url.searchParams.set('code_verifier', codeVerifier);

		if (extraOptions) {
			for (const [key, value] of Object.entries(extraOptions)) {
				url.searchParams.set(key, value);
			}
		}

		let origin: string;
		try {
			origin = new URL(this.redirectURI).origin;
		} catch (e) {
			this.logger.error('failed to get origin from redirectURI');
			this.logger.error(e);
			throw e;
		}

		const response = await makeFetchRequest(
			this.tokenURL!,
			{
				method: 'POST',
				origin: origin,
				body: url.searchParams.toString(),
			},
			this.logger
		);

		if (response.ok) return await response.json();

		const text = await response.text();
		this.logger.error('failed to exchange authorization code for access token');
		this.logger.error(new Error(text));
	}

	// https://datatracker.ietf.org/doc/html/rfc6749#section-6
	async exchangeRefreshToken(refreshToken: string) {
		let url: URL;
		try {
			url = new URL(this.tokenURL!);
		} catch (e) {
			this.logger.error('failed to construct tokenURL');
			this.logger.error(e);
			throw e;
		}
		url.searchParams.set('grant_type', 'refresh_token');
		url.searchParams.set('client_id', this.clientID);
		url.searchParams.set('refresh_token', refreshToken);

		let origin: string;
		try {
			origin = new URL(this.redirectURI).origin;
		} catch (e) {
			this.logger.error('failed to get origin from redirectURI');
			this.logger.error(e);
			throw e;
		}

		const response = await makeFetchRequest(
			this.tokenURL!,
			{
				method: 'POST',
				origin: origin,
				body: url.searchParams.toString(),
			},
			this.logger
		);

		if (response.ok) return await response.json();

		const text = await response.text();
		this.logger.error('failed to exchange refresh token');
		this.logger.error(new Error(text));
	}

	// This is a hacky version of exchangeAuthorizationCodeForTokens. If login endpoint
	// credentials are still cached in the user's browser, we can possibly silently
	// authenticate them in a hidden iframe.
	//
	// A better solution might be to use OAuth 2.0 Web Message Response Mode, if the
	// authorization server actually supports it:
	// https://datatracker.ietf.org/doc/html/draft-sakimura-oauth-wmrm-00#section-5.1
	exchangeAuthorizationCodeForTokensAgainButHidden = async (
		extraOptions = {}
	) => {
		// We only want one hidden iframe to be used at a time, so we wait
		// for it to become available.
		while (this.hiddenIframeRequestLocked === true) {
			await this.delay(500);
			this.logger.debug('silent auth hidden iframe request is locked, waiting');
		}
		this.hiddenIframeRequestLocked = true;

		let hiddenIFrame: HTMLIFrameElement;
		try {
			hiddenIFrame = await appendHiddenIFrame();
		} catch (e) {
			this.logger.error('failed to create iframe element and set attributes');
			this.logger.error(e);
			throw e;
		}

		// Generate the nessecary parameters for the exchange.
		const codeVerifier = this.generateCodeVerifier();
		const codeChallenge = await this.generateCodeChallenge(codeVerifier);
		const state = this.generateState();
		const authCodeURL = this.authCodeURL(state, codeChallenge, extraOptions);

		localStorage.setItem('code', codeVerifier);
		localStorage.setItem('state', state);

		// Redirect the user to the authorization server's auth code URL, which should
		// redirect the user back automatically to the main window.location.origin
		// for us if they have cached credentials.
		hiddenIFrame.setAttribute('src', authCodeURL.toString());

		// Attempt to resolve the exchangeAuthorizationCodeForTokens response, if possible.
		// Declare messageListener variable so that we can remove this event listener when done with it.
		/* eslint prefer-const: ["error", { ignoreReadBeforeAssign: true }] */
		let messageListener: (event: MessageEvent) => Promise<void>;
		let hiddenIframeResponse: any;
		let redirectedURL: URL;

		const removeIFrameAndListener = () => {
			try {
				document.body.removeChild(hiddenIFrame);
				window.removeEventListener('message', messageListener);
			} catch (e) {
				this.logger.debug('failed to remove iframe and/or event listener', e);
			}
		};

		messageListener = async (event: MessageEvent) => {
			if (
				// if the event wasn't fired from the same origin or
				event.origin !== window.origin ||
				// if the source of the event isn't from this library
				event.data?.source !== 'oidc-callback'
			)
				// then return
				return;

			try {
				redirectedURL = new URL(event.data.href);
				this.validateAuthorizationServerResponse(redirectedURL);
				if (
					!this.verifyStateFromAuthorizationServer(
						state,
						redirectedURL.searchParams.get('state')!
					)
				) {
					throw new Error('failed to verify state from authorization server');
				}
				hiddenIframeResponse = await this.exchangeAuthorizationCodeForTokens(
					redirectedURL.searchParams.get('code')!,
					codeVerifier
				);
			} catch (error) {
				this.logger.error('message handler failed');
				this.logger.error(error);
				if (error instanceof Error)
					hiddenIframeResponse = { error: error.message };
			} finally {
				removeIFrameAndListener();
			}
		};

		// Add event listener to window
		window.addEventListener('message', messageListener);

		// Wait up to 60 seconds for this to happen, otherwise it likely will never happen.
		let halfSecondsWaited = 0;
		while (hiddenIframeResponse === undefined && halfSecondsWaited <= 120) {
			await this.delay(500); // 1/2 a second
			halfSecondsWaited++;
		}

		// Allow another hidden iframe request to happen.
		this.hiddenIframeRequestLocked = false;

		// If 60 seconds or more passed by, throw an error.
		if (hiddenIframeResponse === undefined) {
			removeIFrameAndListener();
			throw new Error('failed to silently authenticate user after 60 seconds');
		}
		if (hiddenIframeResponse.error) {
			throw new Error(hiddenIframeResponse.error);
		}

		// Return the response, with the tokens.
		return hiddenIframeResponse;
	};

	// https://openid.net/specs/openid-connect-core-1_0.html#IDTokenValidation
	async validateIDToken(id_token?: string) {
		let token: OIDCToken;
		try {
			token = new OIDCToken(id_token!);
		} catch (e) {
			this.logger.error('error parsing id_token');
			this.logger.error(e);
			throw e;
		}

		validateIssuerClaim(token, this.providerConfiguration.issuer);
		validateAudienceClaim(token, this.clientID);
		validateAzpClaim(token, this.clientID);
		validateAlgValue(token);
		validateCurrentTime(token);

		// If the ID Token is received via direct communication between the Client and the Token Endpoint
		// (which it is in this flow), the TLS server validation MAY be used to validate the issuer in place
		// of checking the token signature. The Client MUST validate the signature of all other ID Tokens
		// according to JWS [JWS] using the algorithm specified in the JWT alg Header Parameter. The Client
		// MUST use the keys provided by the Issuer.
		//
		// If we want to do this, we can do something like the following:
		//
		// if (new URL(this.tokenURL).protocol !== "https:") {
		// 	return token;
		// }
		//
		// But we can also choose to always verify the signature.
		let providerKeys = await this.getProviderKeys();

		// Get the corresponding provider key, using the key ID.
		let providerKey = providerKeys.keys.find(
			(key: any) => key.kid === token.header.kid
		);

		// If no corresponding provider key is found, we check if any new keys were added since
		// it might just be the cache not having any correct values.
		if (!providerKey) {
			providerKeys = await this.getProviderKeys(false);
			providerKey = providerKeys.keys.find(
				(key: any) => key.kid === token.header.kid
			);
		}

		// If we still do not have a corresponding provider key, then we cannot verify the signature.
		if (!providerKey) {
			throw new Error('id_token signed by unknown key');
		}

		// If a corresponding provider key is found, we can verify the signature.
		await verifyProviderKeySignature(providerKey, token);

		// Not currently implemented, or used:
		//
		// * If a nonce value was sent in the Authentication Request, a nonce Claim MUST be present and
		//   its value checked to verify that it is the same value as the one that was sent in the
		//   Authentication Request. The Client SHOULD check the nonce value for replay attacks. The precise
		//   method for detecting replay attacks is Client specific.
		//
		// * The iat Claim can be used to reject tokens that were issued too far away from the current time,
		//   limiting the amount of time that nonces need to be stored to prevent attacks. The acceptable
		//   range is Client specific.
		//
		// * If the acr Claim was requested, the Client SHOULD check that the asserted Claim Value is
		//   appropriate. The meaning and processing of acr Claim Values is out of scope for this specification.
		//
		// * If the auth_time Claim was requested, either through a specific request for this Claim or by using
		//   the max_age parameter, the Client SHOULD check the auth_time Claim value and request
		//   re-authentication if it determines too much time has elapsed since the last End-User authentication.

		// Validated token.
		return token;
	}

	// https://openid.net/specs/openid-connect-discovery-1_0.html#ProviderConfigurationRequest
	async getProviderConfiguration(issuer: string) {
		let url: URL;
		try {
			url = new URL(issuer);
		} catch (e) {
			this.logger.error(
				'failed to construct provider configuration URL from issuer'
			);
			this.logger.error(e);
			throw e;
		}

		url.pathname = '/.well-known/openid-configuration';

		const response = await makeFetchRequest(
			url.toString(),
			{
				method: 'GET',
				origin: window.location.origin, // CORS,
			},
			this.logger
		);

		if (response.ok) return await response.json();

		const text = await response.text();
		this.logger.error('failed to get provider configuration');
		this.logger.error(new Error(text));
	}

	// https://openid.net/specs/openid-connect-discovery-1_0.html#ProviderMetadata
	// https://datatracker.ietf.org/doc/html/draft-ietf-jose-json-web-key
	getProviderKeys = async (useCacheIfPossible = true) => {
		if (useCacheIfPossible && localStorage.getItem('provider_keys')) {
			return JSON.parse(localStorage.getItem('provider_keys')!);
		}

		const response = await makeFetchRequest(
			this.providerConfiguration.jwks_uri,
			{
				method: 'GET',
				origin: window.location.origin, // CORS,
			},
			this.logger
		);

		if (response.ok) {
			const providerKeys = await response.json();
			localStorage.setItem('provider_keys', JSON.stringify(providerKeys));
			return providerKeys;
		}

		const text = await response.text();
		this.logger.error('failed to get provider keys');
		this.logger.error(new Error(text));
	};

	// https://openid.net/specs/openid-connect-core-1_0.html#UserInfoRequest
	getUserInfo = async (accessToken: string) => {
		const response = await makeFetchRequest(
			this.providerConfiguration.userinfo_endpoint,
			{
				method: 'POST',
				origin: window.location.origin, // CORS,
				accessToken: accessToken,
			},
			this.logger
		);

		if (response.ok) return await response.json();

		const text = await response.text();
		this.logger.error('failed to get user info');
		this.logger.error(new Error(text));
	};

	// https://datatracker.ietf.org/doc/html/rfc6749#section-10.12
	generateState() {
		const bytes = new Uint8Array(64);
		self.crypto.getRandomValues(bytes);
		return base64urlencode(bytes);
	}

	redirect(url: string | URL) {
		window.location.assign(url);
	}

	loginRedirect = async (extraOptions?: object) => {
		const codeVerifier = this.generateCodeVerifier();
		const codeChallenge = await this.generateCodeChallenge(codeVerifier);
		const state = this.generateState();
		const authCodeURL = this.authCodeURL(state, codeChallenge, extraOptions);

		localStorage.setItem('code', codeVerifier);
		localStorage.setItem('state', state);

		this.redirect(authCodeURL.toString());
	};

	getTokens = async () => {
		let redirectedURL: URL;
		let resp: TokenResponse;
		try {
			redirectedURL = new URL(window.location.href);
		} catch (e) {
			this.logger.error('failed to construct URL from window.location.href');
			this.logger.error(e);
			throw e;
		}

		if (
			!this.verifyStateFromAuthorizationServer(
				localStorage.getItem('state')!,
				redirectedURL.searchParams.get('state')!
			)
		) {
			clearLocalStorage();
			throw new Error('failed to verify state from authorization server');
		}

		if (!this.validateAuthorizationServerResponse(redirectedURL)) {
			clearLocalStorage();
			throw new Error('authorization server response failed validation');
		}

		try {
			resp = await this.exchangeAuthorizationCodeForTokens(
				redirectedURL.searchParams.get('code')!,
				localStorage.getItem('code')!
			);
		} catch (error) {
			this.logger.error('failed to get tokens');
			this.logger.error(error);
			clearLocalStorage();
			throw error;
		}

		try {
			await this.validateIDToken(resp.id_token);
			clearLocalStorage();
		} catch (error) {
			this.logger.error('failed to validate id_token');
			this.logger.error(error);
			clearLocalStorage();
			throw error;
		}

		return resp;
	};

	getTokensSilently = async ({
		refreshToken,
		...extraOptions
	}: { refreshToken?: string; extraOptions?: object } = {}) => {
		let resp: TokenResponse;

		if (refreshToken) {
			this.logger.log('logging in with refresh token');
			try {
				resp = await this.exchangeRefreshToken(refreshToken);
			} catch (error) {
				this.logger.error('failed to get tokens silently');
				this.logger.error(error);
				clearLocalStorage();
				throw error;
			}
		} else {
			this.logger.log('logging in with hidden iframe');
			try {
				resp = await this.exchangeAuthorizationCodeForTokensAgainButHidden(
					extraOptions
				);
			} catch (error) {
				this.logger.error('failed to get tokens silently');
				this.logger.error(error);
				clearLocalStorage();
				throw error;
			}
		}

		try {
			await this.validateIDToken(resp.id_token);
			clearLocalStorage();
		} catch (error) {
			this.logger.error('failed to validate id_token');
			this.logger.error(error);
			clearLocalStorage();
			throw error;
		}

		return resp;
	};

	logout = async ({
		idTokenHint,
		postLogoutRedirectURI,
	}: { idTokenHint?: string; postLogoutRedirectURI?: string } = {}) => {
		clearLocalStorage();

		const url = new URL(this.logoutURL!);
		if (postLogoutRedirectURI) {
			url.searchParams.set('post_logout_redirect_uri', postLogoutRedirectURI);
		}
		if (idTokenHint) {
			url.searchParams.set('id_token_hint', idTokenHint);
		}
		window.location.assign(url.toString());
	};

	delay(time: number) {
		return new Promise((resolve) => setTimeout(resolve, time));
	}
}

export { OIDCClient };
