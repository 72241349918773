/* eslint-disable ember/no-mixins */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import config from '../config/environment';

export default class SignOutRoute extends Route {
  @service acceptInvitation;
  @service analytics;
  @service currentUser;
  @service session;

  async beforeModel(transition) {
    await this.session.requireAuthentication(transition, config.APP.homeRoute);
    this.currentUser.user = null;
    this.acceptInvitation.destroyInvitationToken();
    this.analytics.clearContext();
    this.session.set('data.accessToken', null);
    this.session.invalidate();
  }
}
