/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import config from 'hcp/config/environment';
import { MarketingEmailStatuses } from 'core/utils/marketing-email';

/**
 *
 * `ServiceAgreements` renders the HashiCorp cross-site service agreements form.
 * Once the form is completed, on submit, it redirects to Auth0.
 *
 *
 * ```
 * <Page::ServiceAgreements
 *   @marketingEmailStatus={{this.model.marketingEmailStatus}}
 * />
 * ```
 *
 * @class ServiceAgreementsComponent
 *
 */

export default class ServiceAgreementsComponent extends Component {
  @tracked termsOfService = false;
  @tracked privacyPolicy = false;
  @tracked _emailOptIn = false;
  @tracked showRequired = false;

  get hasCompletedRequiredChecks() {
    return this.termsOfService && this.privacyPolicy;
  }

  get formRedirectURL() {
    let params = new URL(window.location).searchParams;
    let state = params.get('state');

    return `https://${config.APP.auth0.domain}/continue?state=${state}&terms_of_service=${this.termsOfService}&privacy_policy=${this.privacyPolicy}&newsletter=${this.emailOptIn}`;
  }

  get emailOptIn() {
    if (this.args.marketingEmailStatus === MarketingEmailStatuses.MANDATORY) {
      return true;
    }

    return this._emailOptIn;
  }

  set emailOptIn(value) {
    if (this.args.marketingEmailStatus === MarketingEmailStatuses.MANDATORY) {
      this._emailOptIn = true;
    }

    this._emailOptIn = value;
  }

  @action
  onFormSubmission(event) {
    // we'll never submit the form since we're redirecting
    event.preventDefault();
    if (!this.hasCompletedRequiredChecks) {
      this.showRequired = true;
      return;
    }

    this.showRequired = false;

    return window.location.replace(this.formRedirectURL);
  }
}
