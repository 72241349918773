/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
/**
 *
 * `PageHvnPeeringsListZeroStateComponent` renders Zero State component.
 *
 *
 * ```
 * <Page::HvnPeerings::List::ZeroState />
 * ```
 *
 * @class PageHvnPeeringsListZeroStateComponent
 *
 */

export default class PageHvnPeeringsListZeroStateComponent extends Component {
  get peerings() {
    return [];
  }
  get provider() {
    return this.args.provider;
  }
}
