import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import permissionGuard from 'core/decorators/permission-guard';
import { capturePreviousRoute } from 'hcp/utils/capture-previous-route';
import {
  PREFIX_RESOURCE_MANAGER_PROJECTS,
  ACTION_UPDATE,
} from 'authz/utils/permission-types/index';

export default class CloudOrgsDetailProjectsDetailEditRoute extends Route {
  @service api;

  @permissionGuard({
    permission: `${PREFIX_RESOURCE_MANAGER_PROJECTS}.${ACTION_UPDATE}`,
  })
  async model(params, transition) {
    let { project_id } = this.paramsFor('cloud.orgs.detail.projects.detail');
    let { project } =
      await this.api.resourceManager.project.projectServiceGet(project_id);
    return {
      project,
      previousRouteName: capturePreviousRoute(
        transition,
        'cloud.orgs.detail.projects.list'
      ),
    };
  }
}
