import {
  TYPE_CONSUL,
  TYPE_PACKER,
  TYPE_VAULT,
} from 'common/utils/cloud-resource-types';

/**
 * @typedef {Object} ReferralState
 * @property {Object} queryParams - The query params key/value pair.
 */

/**
 * @typedef {Object} TriggerableParam
 * @property {string} key - The query param key to check.
 * @property {string} includes - The value that would trigger this product.
 */

/**
 * @typedef {Object} Referrer
 * @property {string} resourceType - The resource type of the product referrer.
 * @property {Array.<TriggerableParam>} triggerableParams - A list of query param triggers.
 */

/**
 * @typedef {Array.<Referrer>} Referrers
 */

/** @type {string} */
export const PRODUCT_CONSUL_SIGN_UP_TRIGGER = 'consul';
/** @type {string} */
export const PRODUCT_VAULT_SIGN_UP_TRIGGER = 'vault';
/** @type {string} */
export const PRODUCT_PACKER_SIGN_UP_TRIGGER = 'packer';

/** @type {Referrer} */
export const TYPE_CONSUL_REFERRER_CONFIG = {
  resourceType: TYPE_CONSUL,
  triggerableParams: [
    {
      key: 'product_intent',
      includes: PRODUCT_CONSUL_SIGN_UP_TRIGGER,
    },
    {
      key: 'utm_content',
      includes: PRODUCT_CONSUL_SIGN_UP_TRIGGER,
    },
    {
      key: 'utm_source',
      includes: PRODUCT_CONSUL_SIGN_UP_TRIGGER,
    },
  ],
};

/** @type {Referrer} */
export const TYPE_PACKER_REFERRER_CONFIG = {
  resourceType: TYPE_PACKER,
  triggerableParams: [
    {
      key: 'product_intent',
      includes: PRODUCT_PACKER_SIGN_UP_TRIGGER,
    },
    {
      key: 'utm_content',
      includes: PRODUCT_PACKER_SIGN_UP_TRIGGER,
    },
    {
      key: 'utm_source',
      includes: PRODUCT_PACKER_SIGN_UP_TRIGGER,
    },
  ],
};

/** @type {Referrer} */
export const TYPE_VAULT_REFERRER_CONFIG = {
  resourceType: TYPE_VAULT,
  triggerableParams: [
    {
      key: 'product_intent',
      includes: PRODUCT_VAULT_SIGN_UP_TRIGGER,
    },
    {
      key: 'utm_content',
      includes: PRODUCT_VAULT_SIGN_UP_TRIGGER,
    },
    {
      key: 'utm_source',
      includes: PRODUCT_VAULT_SIGN_UP_TRIGGER,
    },
  ],
};

/** @type {Referrers} */
export const REFERRERS_CONFIGS = [
  TYPE_CONSUL_REFERRER_CONFIG,
  TYPE_PACKER_REFERRER_CONFIG,
  TYPE_VAULT_REFERRER_CONFIG,
];

/**
 * Checks for if the referral state would trigger the resource type as a referrer.
 * @param {string} resourceType - The resource type of the product referrer.
 * @param {ReferralState} referralState - The state to check for product trigger inclusion.
 * @param {Referrers} referrers? - A list of referrer product configs.
 * @returns {Boolean}
 */
export function referralTypeIncludes(
  resourceType,
  referralState,
  referrers = REFERRERS_CONFIGS
) {
  let { queryParams = {} } = referralState;
  let { triggerableParams = [] } = referrers.find(
    (referrer) => referrer.resourceType === resourceType
  );
  return triggerableParams?.filter((param) => {
    return queryParams[param.key]?.includes(param?.includes);
  }).length
    ? true
    : false;
}
