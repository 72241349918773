import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class CallbackController extends Controller {
  queryParams = ['code', 'state', 'error', 'product'];

  @tracked code = '';

  @tracked state = '';

  @tracked error = '';

  @tracked product = '';
}
