import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class CloudOrgsDetailSettingsRoute extends Route {
  @service userContext;

  async model() {
    const { organization, ssoType, defaultRole } =
      this.modelFor('cloud.orgs.detail');

    await this.userContext.setProject(null);

    return {
      organization,
      ssoType,
      defaultRole,
    };
  }
}
