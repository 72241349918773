import Route from '@ember/routing/route';
import { variation } from 'ember-launch-darkly';

export default class CloudOrgsDetailHcpWithTerraformRoute extends Route {
  beforeModel({ from }) {
    if (!variation('hcp-ui-terraform-organization-create')) {
      throw new Error();
    }

    if (!from || from.name !== 'cloud.orgs.create.index') {
      throw new Error();
    }
  }
}
