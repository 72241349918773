/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import {
  TENANT_STATE,
  TENANT_TYPE,
  VAULT_RADAR_TENANT_STATES,
} from '../../../utils/vault-radar';
import { task, timeout } from 'ember-concurrency';
import { DEBUG } from '@glimmer/env';
import {
  PREFIX_VAULT_RADAR,
  VAULT_RADAR_ADMIN,
  VAULT_RADAR_USERS,
} from 'authz/utils/permission-types/index';

const VAULT_RADAR_ADMIN_PERMISSION = `${PREFIX_VAULT_RADAR}.${VAULT_RADAR_USERS}.${VAULT_RADAR_ADMIN}`;

const DELAY_FOR_VAULT_RADAR_TRANSITION = 2000;

export default class PageVaultRadarComponent extends Component {
  @service intl;
  @service analytics;
  @service userContext;
  @service config;
  @service api;
  @service permissions;
  @service('config') appConfig;
  @service flashMessages;

  @tracked tenantData;
  @tracked hasExistingTenant;

  @action
  trackWaitlistClick() {
    this.analytics.trackEvent('vault_radar_beta_waitlist_link_click');
  }

  @task
  *setProvisionedTenant() {
    const { project, organization } = this.userContext;

    try {
      yield this.api.vaultRadar.tenant.provisionTenant(project.id, {
        location: {
          organizationId: organization.id,
        },
      });

      this.flashMessages.success(
        this.intl.t('components.page.vault-radar.toast.setup.success')
      );

      // Buy some time to show the success message
      yield timeout(DELAY_FOR_VAULT_RADAR_TRANSITION);

      window.location.href = this.vaultRadarLink;
    } catch (e) {
      if (DEBUG) {
        // eslint-disable-next-line
        console.error(e);
      }
      this.flashMessages.error(
        this.intl.t('components.page.vault-radar.toast.setup.failed')
      );
    }
  }

  get vaultRadarLink() {
    const { project } = this.userContext;
    return `${this.appConfig.app.vaultRadarDomain}/projects/${project.id}`;
  }

  get tenantState() {
    const { tenantData, hasExistingTenant } = this.args;
    const { state, type } = tenantData || {};
    const canProvision = !hasExistingTenant || type === TENANT_TYPE.INTERNAL;

    const isTenantClaimed =
      !canProvision && state === TENANT_STATE.NEW && this.isAdmin;
    const isActiveCustomer =
      type !== TENANT_TYPE.BETA && state === TENANT_STATE.PROVISIONED;
    const isActiveTrial =
      type === TENANT_TYPE.BETA && state === TENANT_STATE.PROVISIONED;
    const isNewCustomer =
      state === TENANT_STATE.NEW && type !== TENANT_TYPE.BETA && this.isAdmin;
    const isNewTrial =
      state === TENANT_STATE.NEW && type === TENANT_TYPE.BETA && this.isAdmin;

    switch (true) {
      case isTenantClaimed:
        return VAULT_RADAR_TENANT_STATES.CLAIMED_TENANT;
      case isActiveCustomer:
        return VAULT_RADAR_TENANT_STATES.ACTIVE_CUSTOMER;
      case isActiveTrial:
        return VAULT_RADAR_TENANT_STATES.ACTIVE_BETA;
      case isNewCustomer:
        return VAULT_RADAR_TENANT_STATES.NEW_CUSTOMER;
      case isNewTrial:
        return VAULT_RADAR_TENANT_STATES.NEW_BETA;
      default:
        return VAULT_RADAR_TENANT_STATES.SETUP_REQUIRED;
    }
  }

  get isAdmin() {
    return this.permissions.has(VAULT_RADAR_ADMIN_PERMISSION);
  }

  get isActive() {
    const activeStates = [
      VAULT_RADAR_TENANT_STATES.ACTIVE_CUSTOMER,
      VAULT_RADAR_TENANT_STATES.ACTIVE_BETA,
    ];
    return activeStates.includes(this.tenantState);
  }

  get isNew() {
    const newStates = [
      VAULT_RADAR_TENANT_STATES.NEW_CUSTOMER,
      VAULT_RADAR_TENANT_STATES.NEW_BETA,
    ];
    return newStates.includes(this.tenantState);
  }

  get canProvisionTenant() {
    return this.isNew && this.isAdmin;
  }

  get alertSettings() {
    switch (this.tenantState) {
      case VAULT_RADAR_TENANT_STATES.CLAIMED_TENANT:
        return {
          title: this.intl.t('components.page.vault-radar.alert.claimed.title'),
          description: this.intl.t(
            'components.page.vault-radar.alert.claimed.description'
          ),
          color: 'warning',
          icon: 'alert-triangle',
        };
      case VAULT_RADAR_TENANT_STATES.ACTIVE_BETA:
        return {
          title: this.intl.t(
            'components.page.vault-radar.alert.beta-active.title'
          ),
          color: 'highlight',
          icon: 'info',
        };
      case VAULT_RADAR_TENANT_STATES.NEW_BETA:
        return {
          title: this.intl.t('components.page.vault-radar.alert.beta.title'),
          description: this.intl.t(
            'components.page.vault-radar.alert.beta.description'
          ),
          color: 'highlight',
          icon: 'info',
        };
      case VAULT_RADAR_TENANT_STATES.SETUP_REQUIRED:
        return {
          title: this.intl.t(
            'components.page.vault-radar.alert.setup-required.title'
          ),
          description: this.intl.t(
            'components.page.vault-radar.alert.setup-required.description'
          ),
          color: 'warning',
          icon: 'alert-triangle',
        };
      default:
        return null;
    }
  }

  /**
   * @description Returns the qualified qualtrics link to our waitlist form
   * @returns {string}
   */
  get waitlistLink() {
    return this.intl.t(
      'components.page.vault-radar.overview.card.waitlist.href'
    );
  }
}
