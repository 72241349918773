/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

/**
 *
 * `PageAcceptInvitationMain` renders an unauthenticated view to
 * display a message showing the user has been invited to HCP.
 * This component does not handle setting an invitation token to the session.
 *
 * `invitationToken` is the invite token for the organization.
 *
 * ```
 * <Page::AcceptInvitation::Main
 *   @invitationToken={{@invitationToken}}
 * />
 * ```
 *
 * @class PageAcceptInvitationMain
 *
 */

/**
 *
 * `invitationToken` is the invite token for the organization.
 * @argument invitationToken;
 * @type {String}
 *
 */

export default class PageAcceptInvitationMainComponent extends Component {
  @service acceptInvitation;
  @service config;
  @service router;
  @service session;

  @action
  async accept(invitationToken) {
    // This will set the `invitationToken` in localStorage to be consumed in the
    // cloud route either now or after redirecting through the auth flow.
    this.acceptInvitation.invitationToken = invitationToken;

    if (this.session.isAuthenticated) {
      this.router.transitionTo('cloud');
    } else {
      await this.session.authenticate(
        `authenticator:${this.config.authenticatorType}`
      );
    }
  }
}
