/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { registerDestructor } from '@ember/destroyable';

const STATES = {
  GET_TOKEN: 'get_token',
  LOADING: 'loading',
  MFA_CHALLENGE: 'mfa_challenge',
  RECOVERY_CODE: 'recovery_code',
};

export default class ManageMfaEnableComponent extends Component {
  @service api;
  @service intl;
  @service mfa;
  @service flashMessages;

  @tracked mfaToken;
  @tracked enableMFAResponse;

  // set from child components
  @tracked mfaIsConfirmed = false;
  @tracked downloadedRecoveryCode = false;

  STATES = STATES;

  constructor() {
    super(...arguments);
    registerDestructor(this, () => {
      this.mfaToken = '';
      this.enableMFAResponse = '';
      this.mfaIsConfirmed = false;
      this.downloadedRecoveryCode = false;
    });
  }

  get state() {
    if (this.popupForMFAToken.isRunning) {
      return STATES.LOADING;
    } else if (!this.mfaToken) {
      return STATES.GET_TOKEN;
    } else if (!this.mfaIsConfirmed) {
      return STATES.MFA_CHALLENGE;
    } else {
      return STATES.RECOVERY_CODE;
    }
  }

  isState = (state) => {
    return this.state === state;
  };

  get footerButtonText() {
    let i18nKeys = {
      [STATES.GET_TOKEN]:
        'components.page.account-settings.security.mfaModal.step1.continueBtn',
      [STATES.MFA_CHALLENGE]:
        'components.page.account-settings.security.mfaModal.step2.verifyBtn',
      [STATES.RECOVERY_CODE]:
        'components.page.account-settings.security.mfaModal.step3.finishBtn',
    };
    return this.intl.t(i18nKeys[this.state]);
  }

  popupForMFAToken = task(async (evt) => {
    evt.preventDefault();
    try {
      const mfaToken = await this.mfa.getMFAToken(this.args.onCancel);
      const enableMFAResponse = await this.api.profile.profileServiceEnableMFA({
        mfaToken,
      });
      this.enableMFAResponse = enableMFAResponse;
      this.mfaToken = mfaToken;
    } catch (err) {
      this.flashMessages.error(
        this.intl.t('components.page.account-settings.security.mfa.tokenError')
      );
      this.args.onCancel();
    }
  });

  handleMFASuccess = () => {
    this.flashMessages.success(
      this.intl.t(
        'components.page.account-settings.security.mfaEnable.flashMessage.success'
      )
    );
    this.args.onStatusChange();
    this.args.onCancel();
  };

  handleMFAError = (errorMsg) => {
    let error =
      errorMsg ??
      'components.page.account-settings.security.mfaEnable.flashMessage.error';
    this.flashMessages.error(this.intl.t(error));
    this.args.onCancel();
  };
}
