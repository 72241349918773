/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { DEBUG } from '@glimmer/env';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { getOwner } from '@ember/owner';
import * as Sentry from '@sentry/ember';
import { supportTicketPrefillHref } from 'core/utils/support-ticket';
import {
  ACTIVE_RESOURCES_PROJECT_DELETE_ERROR,
  ACTIVE_RESOURCES_ORIGIN_PROJECT_DELETE,
} from 'hcp/utils/constants';

/**
 *
 * A modal tied to the MutationContaier's context and yielded by its parent component.
 * Conditionally renders modals for every implemented mutation method (API, CRUD).
 * Currently only exposes the Delete Project modal and its enabled/disabled state.
 *
 *
 * ```
 * <Page::Projects::MutationContainer::Modals
 *  @modals={{this.modals}}
 *  @showDeleteModal={{this.showDeleteModal}}
 *  @resource={{this.resourceToMutate}}
 *  @onClose={{this.hideModalByType}}
 * />
 * ```
 *
 * @class PageProjectsMutationContainerModals
 *
 */

export default class PageProjectsMutationContainerModalsComponent extends Component {
  @service flashMessages;
  @service intl;
  @service router;
  @service userContext;

  /**
   * A map of modal state.
   * @argument modals
   * @type {boolean}
   */
  /**
   * The resource model object targeted for any mutation.
   * @argument resource
   * @type {Object}
   */
  /**
   * A callback to handle conditional render state
   * @argument onClose
   * @type {Function}
   */

  projectDeleteContentOrigin = ACTIVE_RESOURCES_ORIGIN_PROJECT_DELETE;
  ACTIVE_RESOURCES_PROJECT_DELETE_ERROR = ACTIVE_RESOURCES_PROJECT_DELETE_ERROR;

  get supportTicketHref() {
    return supportTicketPrefillHref(this.userContext);
  }

  @task
  *deleteProject(projectId) {
    const { service } = this.args;

    yield service.delete.task.perform(projectId);

    this.flashMessages.success(
      this.intl.t('components.page.projects.create.project-deleted')
    );

    const redirectRoute = 'cloud.orgs.detail.projects.list';
    const container = getOwner(this);
    const route = container.lookup(`route:${redirectRoute}`);
    route.refresh();

    // Unset this project if it's currently set and navigate back to the list.
    if (this.userContext?.project?.id === projectId) {
      this.userContext.setProject(null);
    }

    this.router.transitionTo(`${redirectRoute}`);
  }

  @action
  onClose(action) {
    const { onClose } = this.args;
    if (onClose && typeof onClose === 'function') {
      onClose(action);
    }
    // Clean up last successful/error task instances
    this.deleteProject.cancelAll({ resetState: true });
  }

  @action
  async onSubmit(projectId, e) {
    e.preventDefault();
    try {
      await this.deleteProject.perform(projectId);
      this.onClose('delete');
    } catch (error) {
      if (DEBUG) {
        console.error(error);
      } else {
        Sentry.captureException(error);
      }
    }
  }
}
