/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

/**
 *
 * `PageOrganizationsDetailOverview` displays general information about an
 * organization's billing, projects, and user principals.
 *
 *
 * ```
 * <Page::Organizations::Detail::Overview
 *   @billing={{this.model.billing}}
 *   @runningStatement={{this.model.runningStatement}}
 *   @organization={{this.model.organization}}
 *   @projects={{this.model.projects}}
 *   @userPrincipals={{this.model.userPrincipals}}
 *   @hasNoOrgRole={{this.model.hasNoOrgRole}}
 * @
 * />
 * ```
 *
 * @class OrganizationsDetailOverview
 *
 */

/**
 * The billing context for the current billing account.
 * @argument billing
 * @type {Object}
 */

/**
 * The running statement for the current billing account.
 * @argument runningStatement
 * @type {Object}
 */

/**
 * The organization to render details for.
 * @argument organization
 * @type {Object}
 */

/**
 * The projects associated with the organization.
 * @argument projects
 * @type {Array}
 */

/**
 * The user principals associated with the organization.
 * @argument userPrincipals
 * @type {Array}
 */

/**
 * A boolean flag that indicates that a user has no organization permissions
 * @argument hasNoOrgRole
 * @type {Boolean}
 */

export default class PageOrganizationsDetailOverviewComponent extends Component {
  @service('resources.organization') organizations;
  @service userContext;
}
