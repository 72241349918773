/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { TERRAFORM_IMPORTS } from 'core/utils/constants';
import {
  PEERING_ROUTE_NAMES,
  TGW_ROUTE_NAMES,
} from 'networks-common/utils/network-routes';

/**
 *
 * `PageHvnsDetailHeader` renders the HashiCorp Virtual Networks Detail page.
 *
 *
 * ```
 * <Page::Hvns::Detail::Header
 *   @model={{@model}}
 *   @canCreateConnection={{@canCreateConnection}}
 *   @canCreatePeerings={{@canCreatePeerings}}
 *   @canCreateTgwAttachments={{@canCreateTgwAttachments}}
 * />
 * ```
 *
 * @class PageHvnsDetailHeader
 *
 */
export default class PageHvnsDetailHeaderComponent extends Component {
  /**
   *
   * `showModal` - whether or not the modal should show.
   * @argument showModal;
   * @type {Boolean}
   *
   */
  /**
   *
   * `showModalChanged` a function that gets called with true if the modal is open and false if it is closed.
   * @argument showModalChanged;
   * @type {Function}
   *
   */
  /**
   *
   * `deleteHVN` is the function to delete the HVN.
   * @argument deleteHVN;
   * @type {Function}
   *
   */
  /**
   *
   * `model` is an HVN object.
   * @argument model;
   * @type {Object}
   *
   */

  get terraformImport() {
    return TERRAFORM_IMPORTS.hvn({ id: this.args.model.network.id });
  }

  connectionRouteNames = {
    peeringRouteNames: PEERING_ROUTE_NAMES,
    tgwRouteNames: TGW_ROUTE_NAMES,
  };
}
