import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import config from '../config/environment';

export default class SignUpRoute extends Route {
  @service session;

  async beforeModel() {
    this.session.prohibitAuthentication(config.APP.homeRoute);
  }
}
