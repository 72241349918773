/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

/**
 *
 * `HcpMarketingAside` component is used to display HashiCorp Cloud Platform branding
 * and marketing information. It does not take any arguments.
 * All content is located within the component itself.
 *
 *
 * ```
 * <HcpMarketing::Aside />
 * ```
 *
 * @class HcpMarketingAside
 *
 */

export default class HcpMarketingAsideComponent extends Component {}
