/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from 'tracked-built-ins';
import { ROLE_TYPE_CONTRIBUTOR } from 'core/helpers/rbac-roles';
import { assert } from '@ember/debug';

const baseKey = 'components.page.access-control.service-principals.form';

/**
 *
 * `ServicePrincipalFormComponent` is a generic encapsulation of the HTML form
 * and client side validation logic used to create new service principals, as well as
 * being used to edit existing service principals.
 *
 * ```
 * <ServicePrincipal::Form
 *   @saveButtonText={{"Create"}}
 *   @servicePrincipal={{}}
 *   @isNameInputDisabled={{true}}
 *   @isSaveDisabled={{false}}
 *   @save={{async () => {}}}
 *   @role={{}}
 * />
 * ```
 *
 * @class ServicePrincipalFormComponent
 *
 */

export default class ServicePrincipalFormComponent extends Component {
  /**
   * Button text to use for "save" action.
   * @argument saveButtonText
   * @type {String}
   * @required
   */

  /**
   * Passed in async/generator Ember concurrency task with a perform callback
   * function to invoke upon form submission
   * NOTE: The form component just collects and validates input, doesn't call API
   * @argument save
   * @type {Function}
   * @required
   */

  /**
   * Optionally disables the service principal name form input
   * @argument isNameInputDisabled
   * @type {Boolean}
   */

  /**
   * Optionally disables the form submit button that would invoke the save action
   * @argument isSaveDisabled
   * @type {Boolean}
   */

  /**
   * Model data from IAM service for the service principal, used when editing
   * @argument servicePrincipal
   * @type {Object}
   */

  /**
   * Existing role associated with the service principal, used to pre-populate the dropdown menu when editing
   * @argument role
   * @type {String}
   */

  @service intl;

  constructor() {
    super(...arguments);

    assert(
      '<ServicePrincipal::Form> argument @saveButtonText is a required non-empty string',
      typeof this.args.saveButtonText === 'string' &&
        this.args.saveButtonText.length >= 1
    );

    assert(
      '<ServicePrincipal::Form> argument @save is a required Ember concurrency task with a .perform callback function',
      typeof this.args.save?.perform === 'function'
    );
  }

  @tracked form = {
    fields: {
      name: this.args.servicePrincipal?.name ?? '',
      role: this.args.role ?? ROLE_TYPE_CONTRIBUTOR.value,
    },
    validationErrors: {
      name: [],
      role: [],
    },
    fieldValidators: {
      name: [
        {
          message: this.intl.t(`${baseKey}.errors.name.required`),
          isValid: (v) => v && v !== '',
          subValidators: [
            {
              message: this.intl.t(
                `${baseKey}.errors.name.must-start-with-ascii`
              ),
              isValid: (v) => this.nameStartsWithLetter.test(v),
            },
            {
              message: this.intl.t(
                `${baseKey}.errors.name.must-end-with-ascii-or-number`
              ),
              isValid: (v) => this.nameEndsWithAlphanumeric.test(v),
            },
            {
              message: this.intl.t(
                `${baseKey}.errors.name.must-only-contain-alphanumber-or-hyphen`
              ),
              isValid: (v) => !this.nameInvalidCharacters.test(v),
            },
            {
              message: this.intl.t(
                `${baseKey}.errors.name.must-be-between-specific-length`
              ),
              isValid: (v) => v.length >= 3 && v.length <= 36,
            },
          ],
        },
      ],
      role: [
        {
          message: this.intl.t(`${baseKey}.errors.role.required`),
          isValid: (v) => v !== '',
        },
      ],
    },
  };

  // Starts with a letter.
  nameStartsWithLetter = /^[a-z]+/i;

  // Ends with a letter or number.
  nameEndsWithAlphanumeric = /[a-z0-9]+$/i;

  // May only contain letters, numbers or hypens.
  nameInvalidCharacters = /[^a-z0-9-]/i;

  @action setName(e) {
    this.form.fields.name = e.target.value;
    this.validateForm({ name: true });
  }

  @task
  *save(evt) {
    evt.preventDefault();

    if (!this.validateForm()) return;

    return yield this.args.save.perform(this.form);
  }

  validateForm({ name = false, role = false } = { name: true, role: true }) {
    if (name) {
      let nameErrors = this.runValidators(
        this.form.fieldValidators.name,
        this.form.fields.name
      );
      set(
        this.form,
        'validationErrors.name',
        nameErrors.map((v) => v.message)
      );
    }

    if (role) {
      let roleErrors = this.runValidators(
        this.form.fieldValidators.role,
        this.form.fields.role
      );

      set(
        this.form,
        'validationErrors.role',
        roleErrors.map((v) => v.message)
      );
    }

    return !Object.keys(this.form.validationErrors).find(
      (k) => this.form.validationErrors[k].length
    );
  }

  runValidators(validators, value) {
    let failedValidators = validators.flatMap((v) => {
      if (!v.isValid(value)) {
        return v;
      }

      if (v.subValidators) {
        return this.runValidators(v.subValidators, value);
      }

      return [];
    });

    return failedValidators;
  }
}
