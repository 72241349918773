/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

/**
 *
 * `PageProjectsListLastOpenedCard` shows the name and description of a project
 * with a link to the project detail.
 *
 *
 * ```
 * <Page::Projects::List::LastOpenedCard
 *   @project={{this.project}}
 * />
 * ```
 *
 * @class PageProjectsListLastOpenedCard
 *
 */

/**
 * The project object.
 * @argument project
 * @type {Object}
 */

export default class PageProjectsListLastOpenedCardComponent extends Component {}
