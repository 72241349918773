/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { getOwner } from '@ember/owner';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { camelize } from '@ember/string';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

export default class PageOrganizationsDetailSsoOidcConfigureComponent extends Component {
  @service api;
  @service flashMessages;
  @service intl;
  @service router;

  @tracked domains = this.configuration?.emailDomain
    ? [this.configuration?.emailDomain]
    : [''];
  @tracked submittedDomains = [];

  get configuration() {
    return this.args.model?.configs?.[0];
  }

  get lastDomainIndex() {
    if (!this.domains.length) return 0;
    return this.domains.length - 1;
  }

  get emailDomains() {
    return this.domains.filter((domain) => domain !== '');
  }

  // if the email domain entered matches the domain already set, we can assume it's already been verified
  get unverifiedDomains() {
    return this.submittedDomains.filter((domain) => {
      return !domain.verified && domain !== this.configuration?.emailDomain;
    });
  }

  get verifiedDomains() {
    if (
      (this.configuration?.emailDomain && this.submittedDomains.length <= 0) ||
      (this.configuration?.emailDomains && this.submittedDomains.length <= 0)
    ) {
      return this.verifyExistingDomains();
    }
    return this.submittedDomains.filter((domain) => domain.verified);
  }

  // The domain must be converted from `example.com` to exampleCom because the
  // Ember template helper `get` confuses `example.com` for object dot notation.
  get unverifiedReasonsGroupedByDomain() {
    return this.unverifiedDomains.reduce((grouped, domain) => {
      if (domain.domain) {
        return {
          ...grouped,
          [camelize(domain.domain)]: domain.reason,
        };
      }
    }, {});
  }

  get verifiedReasonsGroupedByDomain() {
    return this.verifiedDomains.reduce((grouped, domain) => {
      return {
        ...grouped,
        [camelize(domain.domain)]: domain.reason,
      };
    }, {});
  }

  @action
  updateDomain(index, evt) {
    this.domains[index] = evt.target.value;
    this.domains = [...this.domains];
    this.submittedDomains = this.domains;
  }

  performVerificationRequest() {
    let organizationId = this.args.model.org.id;
    return Promise.all(
      this.emailDomains.map((domain) =>
        this.api.sso.sSOManagementServiceVerifyDomainOwnership(organizationId, {
          organizationId: this.args.model.org.id,
          domain,
        })
      )
    );
  }

  async verifyExistingDomains() {
    this.submittedDomains = await this.performVerificationRequest();
  }

  @task
  *verifyDomains(evt) {
    evt.preventDefault();
    this.submittedDomains = yield this.performVerificationRequest();
  }

  @task
  *save(body) {
    let messageType = this.configuration ? 'updated' : 'created';
    let organizationId = this.args.model.org.id;

    try {
      if (this.configuration) {
        yield this.api.authConfig.authConfigServiceEditAuthConnection(
          organizationId,
          body
        );
      } else {
        yield this.api.authConfig.authConfigServiceCreateAuthConnection(
          organizationId,
          body
        );
      }
    } catch (e) {
      // [HCPIE-1172]: scroll back to the top of the page, so the user can see the error displayed
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      throw e;
    }

    let parent = getOwner(this).lookup(`route:cloud.orgs.detail.settings.sso`);

    yield parent.refresh();
    this.flashMessages.success(
      this.intl.t(
        `components.page.organizations.sso.configure.success.${messageType}`,
        {
          orgName: this.args.model.org.name,
        }
      )
    );
    yield this.router.transitionTo(
      'cloud.orgs.detail.settings.sso.index',
      organizationId
    );
  }
}
