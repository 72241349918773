/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
// import templateString from 'core/utils/template-string';
export default class AzureAzureCliCodeSnippetComponent extends Component {
  get azureCliCodeSnippet() {
    return `azureCliCodeSnippet`;
  }
  get codeEditorOptions() {
    return {
      mode: 'shell',
      readOnly: true,
    };
  }
}
