import Route from '@ember/routing/route';
import { DEBUG } from '@glimmer/env';
import { inject as service } from '@ember/service';

export default class CloudOrgsDetailProjectsDetailIndexRoute extends Route {
  @service api;
  @service abilities;
  @service billing;
  @service userContext;

  async model() {
    const { organization, project } = this.userContext;

    let resources = [];

    if (this.abilities.can('list resources')) {
      try {
        ({ resources } =
          await this.api.resourceManager.resources.resourceServiceList(
            'PROJECT',
            project.id
          ));
      } catch (e) {
        if (DEBUG) {
          //eslint-disable-next-line no-console
          console.error(e);
        }
      }
    }

    return {
      organization,
      project,
      resources,
    };
  }
}
