/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { DEBUG } from '@glimmer/env';
import { TYPE_NETWORK_TRANSIT_GATEWAY_ATTACHMENT } from 'common/utils/cloud-resource-types';

/**
 *
 * `PageHvnTransitGatewayAttachmentsList` renders the HVN TransitGatewayAttachments List page.
 *
 *
 * ```
 * <Page::HvnTransitGatewayAttachments::List
 *   @model={{model}}
 * />
 * ```
 *
 * @class PageHvnTransitGatewayAttachmentsList
 *
 */
/**
 *
 * `model` will be a model containing the transitGatewayAttachments list, the current organization, and project.
 * @argument model;
 * @type {Object}
 *
 */
export default class PageHvnTransitGatewayAttachmentsListComponent extends Component {
  @service api;
  @service operation;

  @tracked showDeleteModal = false;
  @tracked transitGatewayAttachmentToDelete;
  @tracked allowDelete = false;

  get transitGatewayAttachmentType() {
    return TYPE_NETWORK_TRANSIT_GATEWAY_ATTACHMENT;
  }

  @task
  *deleteTransitGatewayAttachment(evt) {
    evt.preventDefault();
    let hvnId = this.args.model.network.id;
    let { organizationId, projectId } = this.args.model.network.location;
    let { id } = this.transitGatewayAttachmentToDelete;
    let operation;

    try {
      let resp = yield this.api.network.deleteTGWAttachment(
        organizationId,
        projectId,
        hvnId,
        id
      );
      operation = resp.operation;
    } catch (e) {
      if (DEBUG) {
        //eslint-disable-next-line no-console
        console.error(e);
      }
      // TODO - make this a flash message
      throw e;
    }
    this.showDeleteModal = false;
    this.operation.addOperation(operation);
  }
}
