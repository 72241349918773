import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class CloudAccessControlServicePrincipalsListErrorRoute extends Route {
  @service userContext;

  // list-error.js was added so that we can specify the back to link to take users to their current organization dashboard.
  // In order to do that, we need to pass in a users orgId into the HDS::Link component found in
  // hcp/app/templates/cloud/access-control/service-principals/list-error.hbs
  // Originally the back to link was taking users to @route='cloud' and that was causing undesired UX flow related to the org picker
  // For more context, see HCPIE-8

  setupController(controller, error) {
    controller.set('orgId', this.userContext.organization.id);
    super.setupController(controller, error);
  }
}
