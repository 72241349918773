/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { ParseResourceName } from 'core/utils/resource-name';

export default class IamServicePrincipalsSummaryCard extends Component {
  get truncatedResourceName() {
    const parsedResourceName = ParseResourceName(
      this.args.servicePrincipal.resourceName
    );

    if (!parsedResourceName) {
      return this.args.servicePrincipal.resourceName;
    }
    const { parts } = parsedResourceName;
    const name = parts[parts.length - 1][1];

    return `.../${name}`;
  }
}
