/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { getOwner } from '@ember/owner';

import IamPolicy from 'core/utils/iam-policy';
import { MEMBER_TYPE_SERVICE_PRINCIPAL } from 'core/helpers/rbac-member-types';
import getProjectIamAssignmentTrackingMetadata from 'hcp/utils/iam/get-project-iam-assignment-tracking-metadata';
import { PROJECT_IAM_ASSIGNMENT } from 'core/utils/consts/analytics-events/platform';
import { HashicorpCloudResourcemanagerPolicyBindingMemberType } from '@clients/cloud-resource-manager';

export const CREATE_TEMP_ID = 'xxx-xxx-xxx';
export const MAX_UPDATE_POLICY_RETRIES = 3;

/**
 *
 * `PageServicePrincipalsProjectCreate` form to create a service principal for a project and assign a role.
 *
 * ```
 * <Page::ServicePrincipals::Project::Create
 *   @organization={{...}}
 *   @project={{...}}
 * />
 * ```
 *
 * @class PageServicePrincipalsProjectCreate
 *
 */

export default class PageServicePrincipalsProjectCreateComponent extends Component {
  /**
   * @argument organization
   * @type {Object}
   * @required
   */

  /**
   * @argument project
   * @type {Object}
   * @required
   */

  @service api;
  @service intl;
  @service flashMessages;
  @service router;
  @service analytics;

  @task
  *saveV2(policy, servicePrincipalName) {
    const route = getOwner(this).lookup('route:cloud.access-control');
    const organizationId = this.args.organization.id;
    const projectId = this.args.project.id;

    try {
      const payload = {
        name: servicePrincipalName,
        organizationId,
        projectId,
      };

      const createResponse =
        yield this.api.servicePrincipal.servicePrincipalsServiceCreateProjectServicePrincipal(
          organizationId,
          projectId,
          payload
        );

      const updatedPolicyMembersBindings = policy.bindings.map((binding) => {
        binding.members = binding.members.map((member) => {
          const { memberId, memberType } = member;

          if (member.memberId === CREATE_TEMP_ID) {
            return {
              memberType,
              memberId: createResponse.servicePrincipal.id,
            };
          }
          return { memberType, memberId };
        });
        return binding;
      });

      policy.bindings = updatedPolicyMembersBindings;

      yield this.api.resourceManager.project.projectServiceSetIamPolicy(
        projectId,
        { policy }
      );

      const trackingMetadata = getProjectIamAssignmentTrackingMetadata(
        HashicorpCloudResourcemanagerPolicyBindingMemberType.SERVICEPRINCIPAL,
        'create',
        policy,
        this.args?.policy,
        projectId,
        createResponse.servicePrincipal.id
      );

      this.analytics.trackEvent(PROJECT_IAM_ASSIGNMENT, trackingMetadata);

      if (route) {
        // We need to refresh this before transitioning because if this is a
        // child route, the parent route will not call the model hook again when
        // transitioning upward even if data was changed in the child.
        route.refresh();
        this.router.transitionTo(
          'cloud.access-control.service-principals.detail',
          createResponse.servicePrincipal.id
        );
      }
    } catch (e) {
      console.error('error', e);
      const warningTitle = this.intl.t(
        'components.page.access-control.service-principals.create.warning.iam-policy-conflict-title'
      );
      this.flashMessages.warning(warningTitle, {
        content: this.intl.t(
          'components.page.access-control.service-principals.create.warning.iam-policy-conflict-content'
        ),
      });
      return this.router.transitionTo(
        'cloud.access-control.service-principals.list'
      );
    }
  }

  @task
  *save(form) {
    const organizationId = this.args.organization.id;
    const projectId = this.args.project.id;

    const payload = {
      name: form.fields.name,
      organizationId,
      projectId,
    };

    const createResponse =
      yield this.api.servicePrincipal.servicePrincipalsServiceCreateProjectServicePrincipal(
        organizationId,
        projectId,
        payload
      );

    // If an error is thrown it will be handled by the WithError component.
    // If we simply fail to update the IAM policy we will notify the user and retrieve an updated list.
    const { updated } = yield* this.addServicePrincipalToPolicy(
      projectId,
      createResponse.servicePrincipal.id,
      form.fields.role,
      MAX_UPDATE_POLICY_RETRIES
    );

    if (updated) {
      this.flashMessages.success(
        this.intl.t(
          'components.page.access-control.service-principals.create.success.title'
        )
      );

      return this.router.transitionTo(
        'cloud.access-control.service-principals.detail',
        createResponse.servicePrincipal.id
      );
    } else {
      const warningTitle = this.intl.t(
        'components.page.access-control.service-principals.create.warning.iam-policy-conflict-title'
      );
      this.flashMessages.warning(warningTitle, {
        content: this.intl.t(
          'components.page.access-control.service-principals.create.warning.iam-policy-conflict-content'
        ),
      });
    }

    return this.router.transitionTo(
      'cloud.access-control.service-principals.list'
    );
  }

  // addServicePrincipalToPolicy adds a new member binding to a policy and attempts
  // update the policy. If the policy has been updated concurrently it will retry up to maxRetries.
  *addServicePrincipalToPolicy(
    projectId,
    servicePrincipalId,
    roleId,
    maxRetries
  ) {
    for (let i = 0; i < maxRetries; i++) {
      try {
        // Get the latest policy in order to get the latest etag to protect
        // from concurrent updates.
        const { policy } =
          yield this.api.resourceManager.project.projectServiceGetIamPolicy(
            projectId
          );

        const policyToUpdate = new IamPolicy(policy);
        policyToUpdate.addMember(
          servicePrincipalId,
          MEMBER_TYPE_SERVICE_PRINCIPAL.value,
          roleId
        );

        const updatedPolicy = policyToUpdate.get();
        const policyPayload = {
          policy: {
            ...updatedPolicy,
          },
        };

        return {
          updated: true,
          response:
            yield this.api.resourceManager.project.projectServiceSetIamPolicy(
              projectId,
              policyPayload
            ),
        };
      } catch (e) {
        // A conflict indicates a concurrent update.
        // We may just need try again.
        if (e.status === 409) {
          continue;
        }

        // Any other error is unexpected and should be rethrown.
        throw e;
      }
    }

    return {
      updated: false,
    };
  }
}
