import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { TYPE_NETWORK } from 'common/utils/cloud-resource-types';
import { RegionProviders } from 'core/utils/regions';
import { variation } from 'ember-launch-darkly';
import permissionGuard from 'core/decorators/permission-guard';
import {
  PREFIX_NETWORK_HVNS,
  ACTION_CREATE,
} from 'authz/utils/permission-types/index';
export default class CloudOrgsDetailProjectsDetailHvnsCreateRoute extends Route {
  @service analytics;
  @service api;

  @service quota;
  @service regions;
  @service router;

  @permissionGuard({
    permission: `${PREFIX_NETWORK_HVNS}.${ACTION_CREATE}`,
  })
  async model() {
    let { organization } = this.modelFor('cloud.orgs.detail');
    let { networks } = await this.api.network.list(
      organization.id,
      this.project.id
    );
    let awsRegions = this.regions.networkProviderRegions(RegionProviders.aws);

    let azureRegions = this.regions.networkProviderRegions(
      RegionProviders.azure
    );

    // TODO: [TCE-2007] Remove HVN activation A/B test analytics instrumentation.
    const abTestVariation = variation('hcp-ui-ab-test-hvn-activation-create');

    // TODO: [TCE-2007] Remove HVN activation A/B test analytics instrumentation.
    this.analytics.trackEvent(
      'external_ui_hvn_activation_create_action_form_visited',
      { abTestVariation }
    );

    return {
      networks,
      project: this.project,
      awsRegions,
      azureRegions,
    };
  }

  async redirect() {
    let { organization } = this.modelFor('cloud.orgs.detail');
    let { networks } = await this.api.network.list(
      organization.id,
      this.project.id
    );

    if (this.quota.for(TYPE_NETWORK) === 1 && networks.length === 1) {
      this.router.transitionTo(
        'cloud.orgs.detail.projects.detail.hvns.detail',
        networks[0].id
      );
    }
  }

  get project() {
    let { project } = this.modelFor('cloud.orgs.detail.projects.detail');
    return project;
  }
}
