/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

/**
 *
 * `PageOrganizationsDetailRestrictedAccess` displays general information about HCP services
 *  and alerts user about their No Role status
 *
 *
 * ```
 * <Page::Organizations::Detail::RestrictedAccess
 *   @organization={{this.model.organization}}
 * />
 * ```
 *
 * @class PageOrganizationsDetailRestrictedAccess
 *
 */

/**
 * The organization to render details for.
 * @argument organization
 * @type {Object}
 */

export default class PageOrganizationsDetailRestrictedAccessComponent extends Component {}
