/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';

/**
 *
 * `PageGroupsOrganizationCreate` is the parent wrapper component for the HCP Groups Groups create page
 *  It contains the callback function that is passed down to <Iam::Groups::Forms::CreateGroup @save={{this.save}} /> once the create group form is validated.
 *
 *
 * ```
 * <Page::Groups::Organization::Create />
 * ```
 *
 * @class PageGroupsOrganizationCreate
 *
 */

export default class PageGroupsOrganizationCreateComponent extends Component {
  /**
   * @argument organization
   * @type {Object}
   * @required
   */

  @service api;
  @service intl;
  @service flashMessages;
  @service router;
  @service userContext;

  @task
  *save(form) {
    const { organization } = this.userContext;
    const parentResourceName = `organization/${organization.id}`;
    const payload = {
      name: form.fields.name,
      description: form.fields.description,
    };

    const createResponse = yield this.api.groups.groupsServiceCreateGroup(
      parentResourceName,
      payload
    );

    if (createResponse) {
      const successTitle = this.intl.t(
        'components.page.access-control.groups.create.success.title'
      );
      this.flashMessages.success(successTitle, {
        content: this.intl.t(
          'components.page.access-control.groups.create.success.message',
          {
            htmlSafe: true,
            groupName: payload.name,
          }
        ),
      });

      let { group } = createResponse;
      return this.router.transitionTo(
        'cloud.access-control.groups.detail',
        group.resourceName
      );
    } else {
      const errorTitle = this.intl.t(
        'components.page.access-control.groups.create.error.title'
      );
      this.flashMessages.error(errorTitle, {
        content: this.intl.t(
          'components.page.access-control.groups.create.error.message',
          {
            htmlSafe: true,
            groupName: payload.name,
          }
        ),
      });
      return this.router.transitionTo('cloud.access-control.groups.list');
    }
  }
}
