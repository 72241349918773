import Service, { inject as service } from '@ember/service';
import { registerDestructor } from '@ember/destroyable';
import { tracked } from '@glimmer/tracking';
import { restartableTask } from 'ember-concurrency';
import { DEBUG } from '@glimmer/env';
import { identify } from 'ember-launch-darkly';
import {
  TYPE_ORGANIZATION,
  TYPE_PROJECT,
} from 'common/utils/cloud-resource-types';

export const ORGANIZATION_META_DEFAULTS = {
  ssoEnabled: false,
  unifiedExperienceEnabled: false,
};

// This is the same as the default value that the api sets if no page_size
// is present. This is used so that we can adjust that number and deal with
// paginiation. It's also used in the project-picker to render an indicator that
// the user might have *more* than the list knows about because of pagination.
export const PROJECT_PAGINATION_PAGE_SIZE = 100;

const EXCLUDED_RESOURCES = ['packer', 'secrets'];

export default class UserContextService extends Service {
  @service api;
  @service('billing') billingService;
  @service abilities;
  @service currentUser;
  @service flashMessages;
  @service intl;

  @service permissions;
  @service router;
  @service lastAccessedProject;
  @service orgPreferences;

  @tracked _organization;
  @tracked _project;
  @tracked _projects = [];
  @tracked _projectsNextPageToken = null;
  @tracked _billing = null;

  constructor() {
    super(...arguments);
    this.boundHandleRouteChange = this.handleRouteChange.bind(this);
    this.router.on('routeDidChange', this.boundHandleRouteChange);
    registerDestructor(this, () => {
      this.router.off('routeDidChange', this.boundHandleRouteChange);
    });
  }

  // `organizationMeta` is set after invoking the `sSOManagementServiceGetSSOType` method from sso
  // api client every time an organization is changed. Currently the only use of
  // this is to hide invitations in an org that has SSO enabled and on the organization dashboard.
  @tracked organizationMeta = {
    ...ORGANIZATION_META_DEFAULTS,
  };

  // A `resource` will be any data from any resource that we want to
  // be able to share via the userContext service down into engines. This
  // is usually set on the detail route model hook. Services typically do not return
  // a type key so one will need to be set manually so that LocationLink can work.
  @tracked _resource;
  resourceRouteName;
  resourceChanged = false;

  set resource(resource) {
    this._resource = resource;
    if (resource) {
      // we mark this so that the route change handler knows that the route it
      // landed on set the resource. We can't check the current route name here
      // because it's not settled - you may end up with a "loading" route or
      // other substates.
      this.resourceChanged = true;
    } else {
      // if we're clearing the resource, we need to clear the route name as well
      this.resourceRouteName = undefined;
    }
  }

  get resource() {
    return this._resource;
  }

  handleRouteChange(transition) {
    const toRoute = transition.to.name;
    const toRouteNoIndex = toRoute.replace('.index', '');
    const isChildRoute = toRoute.startsWith(this.resourceRouteName);

    // opt packer, or secrets out of the resource reset because it _does not_ set the
    // resource in a parent route. this leads to premature un-setting of the
    // resource.
    if (EXCLUDED_RESOURCES.some((resource) => toRoute.startsWith(resource))) {
      return;
    }

    // if we changed the resource as a result of the transition, keep track of
    // the route that set the resource, and set resourceChanged to false
    if (this.resourceChanged) {
      this.resourceChanged = false;
      this.resourceRouteName = toRouteNoIndex;
      return;
    }
    // this branch is only run if we have resource and we're leaving the route
    // hierarchy that set the resource
    if (this.resource && this.resourceRouteName && !isChildRoute) {
      this.resource = undefined;
    }
  }

  get organization() {
    return this._organization;
  }

  async setOrganization(organization) {
    // Always refresh permissions when we set the organization even if we're
    // already in this org's context.
    if (organization) {
      this.updateOrganizationMeta(ORGANIZATION_META_DEFAULTS);
      if (organization.id !== this.organization?.id) {
        // When setting a different organization, we need to ensure some things
        // get reset. Some API calls may fail and we need a clean slate.
        this._project = undefined;
        this._projects = [];
        this._projectsNextPageToken = null;
      }

      // Remember the last organization selected.
      await this.orgPreferences.setOrganization({
        id: organization.id,
      });

      try {
        // Query the permissions IAM API to get RBAC context.
        await this.permissions.query(organization?.id, TYPE_ORGANIZATION);
      } catch (e) {
        if (DEBUG) {
          // eslint-disable-next-line
          console.error('Permission test failed to query in user-context', e);
        }
      }

      try {
        // Query the 'hcp-tfc-unified-experience' feature flag for the current organization
        const { flagEnabled: unifiedExperienceEnabled } =
          await this.api.flag.flagsServiceEvalUnificationFlag(
            'hcp-tfc-unified-experience',
            undefined,
            organization.id
          );
        this.updateOrganizationMeta({ unifiedExperienceEnabled });
      } catch (e) {
        const { unifiedExperienceEnabled } =
          ORGANIZATION_META_DEFAULTS.unifiedExperienceEnabled;
        this.updateOrganizationMeta({ unifiedExperienceEnabled });
        if (DEBUG) {
          // eslint-disable-next-line
          console.error('Flag evaluation failed to query in user-context', e);
        }
      }

      try {
        // Query the projects list.
        const { projects, nextPageToken } =
          await this.api.resourceManager.project.projectServiceList(
            'ORGANIZATION',
            organization.id,
            PROJECT_PAGINATION_PAGE_SIZE
          );
        this.projects = projects;
        this._projectsNextPageToken = nextPageToken;
      } catch (e) {
        if (DEBUG) {
          // eslint-disable-next-line
          console.error('Project list failed to query in user-context.', e);
        }
      }
    }

    // We want to call identify and remove the billing cache if we don't have
    // an org set yet or if we're switching orgs.
    if (!this._organization || this._organization.id !== organization?.id) {
      this.billing = null;
      // Anything called before this point will not have LaunchDarkly context
      // yet and variations will not apply until after identification.
      await this.setUserIdentity(organization);
    }

    this._organization = organization;
  }

  updateOrganizationMeta(diff = {}) {
    this.organizationMeta = { ...this.organizationMeta, ...diff };
  }

  async setProject(project) {
    const prevProject = this._project;
    this._project = project;

    // Re-identify with LaunchDarkly only when there is a change of project.
    if (prevProject?.id !== project?.id) {
      await this.setUserIdentity(null, project);
    }

    if (project) {
      // Anything called before this point will not have project_id in the LaunchDarkly context
      // yet and variations will not apply until after identification.
      await this.lastAccessedProject.setProject(project);
      try {
        // Query the permissions IAM API to get RBAC context at the project level.
        await this.permissions.query(project.id, TYPE_PROJECT);
      } catch (e) {
        if (DEBUG) {
          // eslint-disable-next-line
          console.error(e);
        }
      }
    }
  }

  async unsetResource() {
    this.resource = undefined;
  }

  setUserIdentity(newOrganization, newProject) {
    const organization = newOrganization || this._organization;
    const project = newProject || this._project;
    const email = this.currentUser?.user?.email || '';
    const employeeOwned = email.endsWith('@hashicorp.com');

    const user = {
      custom: {
        employee_owned: employeeOwned,
        user: this.currentUser?.user?.id,
        organization_id: organization?.id,
        project_id: project?.id,
      },
    };

    // Identify as the org if we have one, otherwise identify as the user.
    user.key = organization?.id ?? this.currentUser?.user?.id;
    return this.identifyUser.perform(user);
  }

  get identifyCall() {
    return this.identifyUser.last;
  }

  @restartableTask
  *identifyUser(user) {
    yield identify(user);
  }

  get project() {
    return this._project;
  }

  get projects() {
    return this._projects;
  }

  set projects(value) {
    this._projects = value;
  }

  get billing() {
    return this._billing;
  }

  set billing(value) {
    this.billingService.triggerNotifications(value);
    this._billing = value;
  }

  setSsoEnabled(ssoEnabled) {
    this.updateOrganizationMeta({ ssoEnabled });
  }
}
