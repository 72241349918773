/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
/**
 *
 * `PageGroupsOrganizationDetailAddMembersComponent` shows a list of organization principals that can be added to a group.
 *
 *
 * ```
 * <Page::Groups::Organization::Detail::Members::Add
 *   @group={{@model.group}}
 *   @eligibleMembers={{@model.eligibleMembers}}
 * />
 * ```
 *
 * @class PageGroupsOrganizationDetailAddMembersComponent
 *
 */

export default class PageGroupsOrganizationDetailAddMembersComponent extends Component {}
