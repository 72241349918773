/**
 * Type definition for a PolicyLocation object.
 * @typedef {Object} PolicyLocation
 * @property {LocationLink} link - The link to the location where the policy is defined.
 * @property {Policy} policy - The policy data.
 */

/**
 * Type definition for a RoleLocation object.
 * @typedef {Object} RoleLocation
 * @property {HashicorpCloudResourcemanagerPolicyBindingMember} member - The policy member.
 * @property {LocationLink} link - The link to the location where the role is defined.
 * @property {HashicorpCloudResourcemanagerRole.id} role
 * @property {HashicorpCloudIamGroup} [group]
 * @property {HashicorpCloudIamUserPrincipal} [userPrincipal]
 */

/**
 * Retrieves roles associated with a given principalId from a collection of
 * policies. Each policy contains bindings, which link members to roles.
 *
 * @param {string} [principalId=''] - The ID of the principal
 *     whose roles are to be retrieved. Defaults to an empty string.
 * @param {Array<PolicyLocation>} [policies=[]] - An array of policy location objects.
 *    Each policy object must have a `link` property (string) and a `policy`
 *     property, which contains a `bindings` array. Each binding in the
 *     `bindings` array is an object that contains a `members` array and a
 *     `roleId` property.
 * @returns {Array<RoleLocation>} An array of objects, where each object represents a
 *     role associated with the principalId. Each object contains a `member`
 *     (the member object found in the bindings that matches the principalId),
 *     a `link` (copied from the policy object), and a `role` (the roleId
 *     associated with the found member).
 */
const getRolesLocationsByPrincipalId = (
  principalId = '',
  policyLocations = []
) => {
  return policyLocations.reduce((accumulator, { link, policy }) => {
    if (!policy) {
      return accumulator;
    }

    const { bindings } = policy;

    bindings.forEach((binding) => {
      const member = binding.members.find(
        (member) => member.memberId === principalId
      );
      if (member) {
        accumulator.push({
          member,
          link,
          roleId: binding.roleId,
        });
      }
    });

    return accumulator;
  }, []);
};

/**
 * Finds and enriches roles based on principal IDs from given policies, groups,
 * roles, and user principal. This function maps each principal ID
 * to its roles defined in policies, then enriches these roles with additional
 * information from roles, groups, and the user principal based on
 * the member type of the role.
 *
 * @param {Object} params - The parameters for finding role locations.
 * @param {Array.<string>} [params.principalIds=[]] - An array of principal IDs
 *     to find roles for.
 * @param {Array.<HashicorpCloudIamGroup>} [params.groups=[]] - An array of
 *     group objects, where each group could be related to a role.
 * @param {Array.<HashicorpCloudResourcemanagerRole>} [params.roles=[]] - An array of
 *     role objects to enrich the roles found.
 * @param {Array.<PolicyLocation>} [params.policies=[]] - An array of policy
 *     location objects from which roles will be extracted.
 * @param {HashicorpCloudIamUserPrincipal} [params.userPrincipal={}] - An object representing the user
 *     principal to attach to user type roles.
 * @returns {Array.<RoleLocation>} An array of enriched role objects. Each object
 *     includes role data, possibly combined with organization role data, and
 *     conditionally includes group or userPrincipal information based on the
 *     role's member type.
 */
const findRoleLocationsFromPolicies = ({
  principalIds = [],
  groups = [],
  roles = [],
  policies = [],
  userPrincipal = {},
}) => {
  return principalIds.reduce((accumulator, principalId) => {
    const roleLocations = getRolesLocationsByPrincipalId(
      principalId,
      policies
    ).map((role) => {
      return {
        ...role,
        ...(roles.find((r) => r.id === role.roleId) ?? {}),
        group:
          role.member.memberType === 'GROUP'
            ? groups.find((g) => {
                return g.resourceId === role.member.memberId;
              })
            : undefined,
        userPrincipal:
          role.member.memberType === 'USER' ? userPrincipal : undefined,
      };
    });

    return [...accumulator, ...roleLocations];
  }, []);
};

export default findRoleLocationsFromPolicies;
