/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { getOwner } from '@ember/owner';
import { task } from 'ember-concurrency';

/**
 *
 * `PageServicePrincipalsOrganizationDetailKeys` renders the keys of a service principal in an organization and allows adding
 * and deleting keys
 *
 *
 * ```
 * <Page::ServicePrincipals::Organization::Detail::Keys
 *   @model={{model}}
 * />
 * ```
 *
 * @class PageServicePrincipalsOrganizationDetailKeys
 *
 */
/**
 * Contains the `servicePrincipal` and 'organization'.
 * @argument model
 * @type {Object}
 */
export default class PageServicePrincipalsOrganizationDetailKeysComponent extends Component {
  @service api;
  @service flashMessages;
  @service intl;
  @service router;
  @service quota;

  @tracked showDeleteKeyModal = false;
  @tracked servicePrincipalKeyToDelete = null;

  @tracked showGenerateKeyModal = false;

  @action
  confirmDeleteServicePrincipalKey(servicePrincipalKey) {
    this.showDeleteKeyModal = true;
    this.servicePrincipalKeyToDelete = servicePrincipalKey;
  }

  @task
  *deleteServicePrincipalKey() {
    const {
      servicePrincipal: { organizationId },
    } = this.args.model;
    const response =
      yield this.api.servicePrincipal.servicePrincipalsServiceDeleteOrganizationServicePrincipalKey(
        organizationId,
        this.servicePrincipalKeyToDelete.clientId
      );
    this.onDeleteServicePrincipalKeySuccess({
      response,
      servicePrincipalKey: this.servicePrincipalKeyToDelete,
    });
  }

  @action
  onDeleteServicePrincipalKeySuccess() {
    this.resetDeleteServicePrincipalKey();
    this.refreshRoute();
    this.flashMessages.success(
      this.intl.t(
        'components.page.access-control.service-principals.delete-key-modal.success'
      )
    );
  }

  @action
  resetDeleteServicePrincipalKey() {
    this.showDeleteKeyModal = false;
    this.servicePrincipalKeyToDelete = null;
  }

  @action
  showGenerateKeyModalChanged(value, evt) {
    evt.preventDefault();
    this.showGenerateKeyModal = value;
  }

  @action
  onGenerateKeySuccess() {
    this.refreshRoute();
    this.flashMessages.success(
      this.intl.t(
        'components.page.access-control.service-principals.details.actions.generate-key.success'
      )
    );
  }

  refreshRoute() {
    let route = getOwner(this).lookup(
      `route:${this.router.currentRoute.parent.name}`
    );
    return route.refresh();
  }
}
