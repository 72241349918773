/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';

/**
 *
 * ```
 * <Sso::FormInput::DefaultRoleToggleSelect
 *   @roleOptions={{array 'roles/viewer' 'roles/contributor' 'roles/admin'}}
 *   @selectedRole={{this.selectedRole}}
 *   @onRoleToggle={{this.handleRoleToggle}}
 *   @onRoleSelect={{this.handleRoleSelect}}
 * />
 * ```
 *
 * @class SsoFormInputDefaultRoleToggleSelect
 *
 */

export default class SsoFormInputDefaultRoleToggleSelectComponent extends Component {
  get id() {
    return guidFor(this);
  }

  @action handleToggle(event) {
    const checked = event.target.checked;
    this.args.onRoleToggle(checked ? 'roles/viewer' : 'none');
  }

  @action handleRoleSelect(event) {
    this.args.onRoleSelect(event.target.value);
  }
}
