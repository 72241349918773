/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import {
  CONTENT_TABS_LABELS,
  PRODUCT_CONSUL,
} from 'networks-common/utils/content-tabs';
import { TABLE_HEADERS, InboundRules } from '../consts/security-rules';
export default class PeeringsAzureConsulInboundRulesComponent extends Component {
  /**
   *
   * `PeeringsAzureConsulInboundRulesComponent` renders the Azure Consul Inbound Rules Component
   *
   *
   * ```
   * <Page::HvnPeerings::Detail::ContentTabs::Azure::Tables::Consul::InboundRules
   *  @model={{@model}}
   * />
   * ```
   *
   * @class PeeringsAzureConsulOutboundRulesComponent
   *
   */

  tabs = CONTENT_TABS_LABELS;
  products = {
    consul: PRODUCT_CONSUL,
  };

  get inboundRules() {
    return {
      tableHeaders: TABLE_HEADERS,
      tableData: InboundRules(this.args.model),
    };
  }
}
