import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import permissionGuard from 'core/decorators/permission-guard';
import {
  PREFIX_NETWORK_TRANSIT_GATEWAY_ATTACHMENTS,
  ACTION_CREATE,
} from 'authz/utils/permission-types/index';

export default class CloudOrgsDetailProjectsDetailHvnsDetailTransitGatewayAttachmentsCreateRoute extends Route {
  @service api;
  @service userContext;

  queryParams = {
    tab: {
      replace: true,
      refreshModel: true,
    },
  };

  @permissionGuard({
    permission: `${PREFIX_NETWORK_TRANSIT_GATEWAY_ATTACHMENTS}.${ACTION_CREATE}`,
  })
  async model(params) {
    let { organization, project } = this.userContext;
    let { hvn_id } = this.paramsFor(
      'cloud.orgs.detail.projects.detail.hvns.detail'
    );

    let { network } = await this.api.network.get(
      organization.id,
      project.id,
      hvn_id
    );
    let { tgwAttachments } = await this.api.network.listTGWAttachments(
      organization.id,
      project.id,
      network.id
    );
    return {
      network,
      tgwAttachments,
      ...params,
    };
  }
}
