/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

/**
 *
 * `PageHvnConnectedClusters` description here.
 *
 *
 * ```
 * <Page::HvnConnectedClusters
 *   @model={{@model}}
 * />
 * ```
 *
 * @class PageHvnConnectedClusters
 *
 */

export default class PageHvnConnectedClustersComponent extends Component {
  /**
   *
   * `model` will be a model containing the network dependencies (clusters);
   * @argument model;
   * @type {Object}
   *
   */
}
