/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

/**
 *
 * Displays the Project's fields using a definition list.
 *
 *
 * ```
 * <Page::Projects::Settings
 *   @model={{this.model}}
 * />
 * ```
 *
 * @class PageProjectsSettings
 *
 */

export default class PageProjectsSettingsComponent extends Component {
  /**
   * The project model.
   * @argument model
   * @type {Object}
   */
  get project() {
    const { project } = this.args?.model ?? {};
    return project;
  }
}
