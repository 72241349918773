/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

import {
  CONTENT_TABS_LABELS,
  PRODUCT_CONSUL,
} from 'networks-common/utils/content-tabs';
import { AZURE_DISCLOSURE_GUIDES_PEERING } from 'networks-common/utils/azure-connections';
import {
  isActivePeering,
  isPendingAcceptance,
} from 'networks-common/utils/peerings';

export default class PeeringsDetailAzureUpdatingSecurityGroupsComponent extends Component {
  @service router;

  @tracked securityGroupId = '';
  @tracked routeTableId;

  tabs = CONTENT_TABS_LABELS;
  products = {
    consul: PRODUCT_CONSUL,
  };
  azureDisclosureGuides = AZURE_DISCLOSURE_GUIDES_PEERING;

  @action
  updateQueryParams(queryParams = {}) {
    return this.router.transitionTo(this.router.currentRouteName, {
      queryParams,
    });
  }

  @action
  updateSecurityGroupId({ target }) {
    this.securityGroupId = target.value;
  }

  get isActive() {
    return isActivePeering(this.args.peering);
  }

  get isPending() {
    return isPendingAcceptance(this.args.peering);
  }
}
