import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import {
  TYPE_CONSUL,
  TYPE_NETWORK_PEERING,
  TYPE_VAULT,
} from 'common/utils/cloud-resource-types';

export default class CloudOrgsDetailProjectsDetailHvnRoutesIndexRoute extends Route {
  @service api;

  async model() {
    let { organization } = this.modelFor('cloud.orgs.detail');
    let { project } = this.modelFor('cloud.orgs.detail.projects.detail');
    let { network } = this.modelFor(
      'cloud.orgs.detail.projects.detail.hvns.detail'
    );
    let { routes } = await this.api.network.listHVNRoutes(
      organization.id,
      project.id,
      network.id
    );
    /**
     * TODO: Remove Promise.all call and filter against peerings
     * when API is updated to include hvnConnection dependencies
     * Then update the template logic to suss out federated Peerings
     */

    //get the peering object if the route target is a peering
    let routeTargetPeerings = Promise.all(
      routes.map((route) => {
        if (route.target?.hvnConnection.type === TYPE_NETWORK_PEERING) {
          let peeringId = route.target?.hvnConnection.id;
          return this.api.network.getPeering(
            organization.id,
            project.id,
            network.id,
            peeringId
          );
        } else {
          return Promise.resolve();
        }
      })
    );
    routeTargetPeerings = await routeTargetPeerings;

    //get the network dependencies related to a peerings hvnTarget
    let routeTargetPeeringsDep = Promise.all(
      routeTargetPeerings.map((peering) => {
        if (peering) {
          if (peering.peering.target?.hvnTarget)
            return this.api.network.listDependencies(
              organization.id,
              project.id,
              peering.peering.target.hvnTarget.hvn.id
            );
        } else {
          return Promise.resolve();
        }
      })
    );
    routeTargetPeeringsDep = await routeTargetPeeringsDep;
    let { dependencies: networkDependencies } = this.modelFor(
      'cloud.orgs.detail.projects.detail.hvns.detail'
    );

    // Compare the network dependencies to the route target dependencies
    // In this case we are only looking at the peering hvnTarget dependencies all the rest are undefined
    routes = routes.map((route, i) => {
      let routeTargetDependencies = routeTargetPeeringsDep[
        i
      ]?.dependencies.filter(
        (dependency) =>
          dependency.type === TYPE_CONSUL || dependency.type === TYPE_VAULT
      );
      let networkConsulClusterDependencies = networkDependencies.filter(
        (dependency) =>
          dependency.type === TYPE_CONSUL || dependency.type === TYPE_VAULT
      );
      if (
        networkConsulClusterDependencies?.length > 0 &&
        routeTargetDependencies?.length > 0
      ) {
        route.isLocked = true;
      } else {
        route.isLocked = false;
      }
      return route;
    });
    return {
      ...this.modelFor('cloud.orgs.detail.projects.detail.hvns.detail'),
      routes,
    };
  }
}
