import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import {
  TYPE_NETWORK_PEERING,
  TYPE_NETWORK,
} from 'common/utils/cloud-resource-types';
import {
  CONTENT_TABS_LABEL_TERRAFORM,
  CONTENT_TABS_LABEL_TERMINAL,
  PRODUCT_VAULT,
  PRODUCT_CONSUL,
  PRODUCT_POWERSHELL,
  CONTENT_TABS_LABEL_AUTOMATE,
  CONTENT_TABS_LABEL_CLOUD_SHELL,
} from 'networks-common/utils/content-tabs';
import { RegionProviders } from 'core/utils/regions';
import { HashicorpCloudNetwork20200907PeeringState } from '@clients/cloud-network';
import { variation } from 'ember-launch-darkly';

export default class CloudOrgsDetailProjectsDetailHvnsDetailPeeringsDetailIndexRoute extends Route {
  @service api;
  @service('metadata.local-storage') metadata;

  @service router;

  queryParams = {
    tab: {
      replace: true,
      refreshModel: true,
    },
    product: {
      replace: true,
      refreshModel: true,
    },
  };

  async model(params) {
    let { peering_id } = this.paramsFor(
      'cloud.orgs.detail.projects.detail.hvns.detail.peerings.detail'
    );
    let { organization } = this.modelFor('cloud.orgs.detail');
    let { project } = this.modelFor('cloud.orgs.detail.projects.detail');
    let { network } = this.modelFor(
      'cloud.orgs.detail.projects.detail.hvns.detail'
    );
    let { peering } = await this.api.network.getPeering(
      organization.id,
      project.id,
      network.id,
      peering_id
    );

    let { routes } = await this.api.network.listHVNRoutes(
      organization.id,
      project.id,
      network.id,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      peering_id,
      TYPE_NETWORK_PEERING,
      undefined,
      undefined,
      undefined
    );

    let networkDependencies = [];
    let resp = await this.api.network.listDependencies(
      organization.id,
      project.id,
      network.id
    );
    networkDependencies = resp.dependencies;

    let hvnTargetDependencies = [];
    if (peering.target.hvnTarget?.hvn) {
      let resp = await this.api.network.listDependencies(
        organization.id,
        project.id,
        peering.target.hvnTarget.hvn.id
      );
      hvnTargetDependencies = resp.dependencies;
    }

    const identifier = `project/${project.id}/${TYPE_NETWORK}/${network.id}`;
    let metadata = await this.metadata.get(identifier).catch(() => {});

    return {
      network,
      organization,
      peering,
      networkDependencies,
      hvnTargetDependencies,
      project,
      routes,
      metadata,
      ...params,
    };
  }

  resetController(controller) {
    // Reset the queryParams when a user navigates away from the page
    controller.tab = '';
    controller.product = '';
  }

  afterModel(model) {
    let { network, peering } = model;
    // If provider is AWS and the tab is automate but the peering state has changed from PENDING to ACTIVE or ACCEPTED
    if (network.location.region.provider === RegionProviders.aws) {
      if (
        this.router.currentRoute.queryParams.tab ===
        CONTENT_TABS_LABEL_AUTOMATE.id
      ) {
        if (
          peering.state !==
            HashicorpCloudNetwork20200907PeeringState.PENDINGACCEPTANCE &&
          variation('hcp-consul-activation')
        ) {
          this.router.transitionTo(
            'cloud.orgs.detail.projects.detail.hvns.detail.peerings.detail.index',
            peering.id,
            {
              queryParams: {
                tab: CONTENT_TABS_LABEL_TERMINAL.id,
                product: PRODUCT_CONSUL.id,
              },
            }
          );
        }
      }
    }
    // If provider is Azure and the tab is terraform or cloud-shell but the peering state has changed from PENDING to ACTIVE
    if (network.location.region.provider === RegionProviders.azure) {
      if (
        this.router.currentRoute.queryParams.tab ===
          CONTENT_TABS_LABEL_TERRAFORM.id ||
        this.router.currentRoute.queryParams.tab ===
          CONTENT_TABS_LABEL_CLOUD_SHELL.id
      ) {
        if (
          peering.state !==
          HashicorpCloudNetwork20200907PeeringState.PENDINGACCEPTANCE
        ) {
          this.router.transitionTo(
            'cloud.orgs.detail.projects.detail.hvns.detail.peerings.detail.index',
            peering.id,
            {
              queryParams: {
                tab: CONTENT_TABS_LABEL_TERMINAL.id,
                product: PRODUCT_CONSUL.id,
              },
            }
          );
        }
      }
    }
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    let { network } = model;

    // This sets the default queryParams if they are not provided
    if (!controller.tab) {
      if (network.location.region.provider === RegionProviders.azure) {
        if (
          model.peering.state ===
          HashicorpCloudNetwork20200907PeeringState.PENDINGACCEPTANCE
        ) {
          controller.tab = CONTENT_TABS_LABEL_TERRAFORM.id;
          controller.product = PRODUCT_POWERSHELL.id;
        }
        if (
          model.peering.state ===
          HashicorpCloudNetwork20200907PeeringState.ACTIVE
        ) {
          controller.tab = CONTENT_TABS_LABEL_TERMINAL.id;
          controller.product = PRODUCT_CONSUL.id;
        }
      }
      if (network.location.region.provider === RegionProviders.aws) {
        if (
          [
            HashicorpCloudNetwork20200907PeeringState.PENDINGACCEPTANCE,
            HashicorpCloudNetwork20200907PeeringState.CREATING,
          ].includes(model.peering.state) &&
          variation('hcp-consul-activation') &&
          !variation('hcp-consul-activation-phase-2')
        ) {
          controller.tab = CONTENT_TABS_LABEL_AUTOMATE.id;
        } else if (variation('hcp-consul-activation-phase-2')) {
          controller.tab = CONTENT_TABS_LABEL_TERMINAL.id;
        } else {
          controller.tab = CONTENT_TABS_LABEL_TERMINAL.id;
          controller.product = PRODUCT_CONSUL.id;
        }

        if (!controller.product) {
          controller.product = PRODUCT_VAULT.id;
        }
      }
    }

    if (!controller.connectionMethod && model.metadata) {
      let {
        metadata: { peering },
      } = model.metadata;
      controller.connectionMethod = peering[model.peering.id]?.connectionMethod;
    }
  }
}
