import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import {
  ACTION_LIST,
  PREFIX_RESOURCE_MANAGER_RESOURCES,
} from 'authz/utils/permission-types/index';
import permissionGuard from 'core/decorators/permission-guard';

export default class CloudOrgsDetailProjectsDetailSettingsActiveResourcesRoute extends Route {
  @service api;

  @permissionGuard({
    permission: `${PREFIX_RESOURCE_MANAGER_RESOURCES}.${ACTION_LIST}`,
  })
  async model({ activeResourcesListOrigin }) {
    let { project } = this.modelFor('cloud.orgs.detail.projects.detail');
    let { resources } =
      await this.api.resourceManager.resources.resourceServiceList(
        'PROJECT',
        project.id
      );

    return { project, resources, activeResourcesListOrigin };
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.activeResourcesListOrigin = null;
    }
  }
}
