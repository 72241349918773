/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { getOwner } from '@ember/owner';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { FormValidationError } from 'core/utils/form-validation';
import { supportTicketPrefillHref } from 'core/utils/support-ticket';
import getProjectIamAssignmentTrackingMetadata from 'hcp/utils/iam/get-project-iam-assignment-tracking-metadata';
import { PROJECT_IAM_ASSIGNMENT } from 'core/utils/consts/analytics-events/platform';
import { HashicorpCloudResourcemanagerPolicyBindingMemberType } from '@clients/cloud-resource-manager';

/**
 *
 * `PageGroupsProjectAdd`
 *
 *
 * ```
 * <Page::Groups::Project::Add />
 * ```
 *
 * @class PageGroupsProjectAdd
 *
 */

export default class PageGroupsProjectAddComponent extends Component {
  @service api;
  @service flashMessages;
  @service intl;
  @service router;
  @service analytics;

  @tracked groupIdToRoleIdMappings = {};
  @tracked showSelectModal = false;

  @task *handleSelectGroupSubmit(formState) {
    this.groupIdToRoleIdMappings = formState.selectedGroupIds.reduce(
      (mappings, resourceId) =>
        this.groupIdToRoleIdMappings[resourceId] === undefined
          ? { ...mappings, [resourceId]: 'roles/viewer' }
          : {
              ...mappings,
              [resourceId]: this.groupIdToRoleIdMappings[resourceId],
            },
      {}
    );

    this.showSelectModal = false;
    yield;
  }

  get selectedGroupIds() {
    return Object.keys(this.groupIdToRoleIdMappings);
  }

  get supportTicketHref() {
    return supportTicketPrefillHref(this.userContext);
  }

  @action handleSelectGroupCancel() {
    this.showSelectModal = false;
  }

  @action isSelected(group) {
    this.selectedGroupIds.includes(group.resourceId);
  }

  @action updateGroupRole(event) {
    event.preventDefault();
    const { name: resourceId, value: roleId } = event.target;
    this.groupIdToRoleIdMappings = {
      ...this.groupIdToRoleIdMappings,
      [resourceId]: roleId,
    };
  }

  @action removeGroup(resourceId) {
    delete this.groupIdToRoleIdMappings[resourceId];
    this.groupIdToRoleIdMappings = {
      ...this.groupIdToRoleIdMappings,
    };
  }

  @task *handleSelectGroupRoleSubmit(policy) {
    const hasGroupsToAdd = !!Object.keys(this.groupIdToRoleIdMappings).length;
    if (!hasGroupsToAdd) {
      throw new FormValidationError(
        this.intl.t('components.page.access-control.groups.add.error-empty')
      );
    }

    const { project } = this.args;

    const groupNames = this.args.groups
      .filter((group) =>
        Object.keys(this.groupIdToRoleIdMappings).includes(group.resourceId)
      )
      .map((group) => group.displayName);

    try {
      yield this.api.resourceManager.project.projectServiceSetIamPolicy(
        project.id,
        { policy }
      );

      this.args?.groups?.forEach((group) => {
        const trackingMetadata = getProjectIamAssignmentTrackingMetadata(
          HashicorpCloudResourcemanagerPolicyBindingMemberType.GROUP,
          'add',
          policy,
          this.args.policy,
          project?.id,
          group?.resourceId
        );

        this.analytics.trackEvent(PROJECT_IAM_ASSIGNMENT, trackingMetadata);
      });

      this.flashMessages.success(
        this.intl.t(`components.page.access-control.groups.add.success.title`),
        {
          content: this.intl.t(
            `components.page.access-control.groups.add.success.content`,
            {
              groupCount: groupNames.length,
              groupNames: groupNames.join(', '),
              projectName: project.name,
              htmlSafe: true,
            }
          ),
        }
      );

      getOwner(this)
        .lookup('route:cloud.access-control.groups.list')
        ?.refresh();
      this.router.transitionTo('cloud.access-control.groups.list');
    } catch (error) {
      this.flashMessages.error(
        this.intl.t('components.page.access-control.groups.add.error.title'),
        {
          content: this.intl.t(
            'components.page.groups.project.edit.flash-messages.something-went-wrong',
            { groupName: groupNames.join(', ') }
          ),
        }
      );
    }
  }
}
