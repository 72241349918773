export const DISCLOSURE_GUIDES = {
  cidrBlock: [
    {
      helpText:
        'components.page.hvn-transit-gateway-attachments.create.form.label.route-table-example-instructions',
      imgSrc: '/images/tgw-Instructions-route-table.png',
      imgAlt:
        'components.page.hvn-transit-gateway-attachments.create.form.label.route-table-example-alt',
    },
    {
      helpText:
        'components.page.hvn-transit-gateway-attachments.create.form.label.vpc-cidr-block-example-instructions',
      imgSrc: '/images/tgw-Instructions-cidr.png',
      imgAlt:
        'components.page.hvn-transit-gateway-attachments.create.form.label.vpc-cidr-block-example-alt',
    },
  ],
};
