import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class CloudAccountSettingsRoute extends Route {
  @service config;
  @service currentUser;
  @service userContext;
  @service billing;
  @service operation;

  /**
   * The operations start and stop below is necessary because we don't have an org id for polling.
   * This prevents tests from silently hanging as well.
   */
  activate() {
    this.billing.stop();
    this.operation.stop();
  }

  deactivate() {
    this.billing.start();
    this.operation.start();
  }

  async model() {
    await super.model(...arguments);

    await this.userContext.setOrganization(null);
    await this.userContext.setProject(null);

    let isEmailPasswordUser =
      this.currentUser.user.identityTypes.includes('EMAIL_PASSWORD');

    return { isEmailPasswordUser };
  }
}
