import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';

import {
  TYPE_BOUNDARY,
  TYPE_CONSUL,
  TYPE_CONSUL_GLOBAL_NETWORK_MANAGER,
  TYPE_VAULT,
  TYPE_PACKER,
  TYPE_PACKER_BUCKET,
  TYPE_TERRAFORM,
  TYPE_NETWORK,
  TYPE_WAYPOINT,
  TYPE_VAULT_SECRETS_APP,
  TYPE_WEBHOOK,
} from 'common/utils/cloud-resource-types';
/**
 * @typedef {Object} Location
 * @property {string} projectId the id of the associated project
 * @property {string} organizationId the id of the associated organization
 */

/**
 * @typedef {Object} HashiCorpLink
 * @property {string} name the unique name of the resource
 * @property {string} uuid the uuid of the resource
 * @property {Location} location the location association of the link
 */

/**
 * @param {HashiCorpLink}
 */
function getIdFromLink(link) {
  return link.id || link.uuid;
}

/**
 * @param {HashiCorpLink}
 */
function getLocationFromLink(link) {
  return link.location;
}

/**
 * A mapping of resource types to functions that return Link data to build a
 * UI link based on only hashicorp link data.
 *
 * We can't build a link to a few pages:
 * - A peering detail route requires the parent HVN id and a link does not
 * contain that data.
 * - A service principal route requires the v2 resource ID and the active
 * resources api does not return that data yet.
 */
export default class OptionForLocationLink extends Helper {
  @service intl;

  LINK_MAPPING = {
    [TYPE_BOUNDARY]: (link) => {
      let { projectId: project_id } = getLocationFromLink(link);
      let id = getIdFromLink(link);
      return {
        label: id,
        models: [id],
        route: 'boundary.clusters.cluster.detail',
        query: { project_id },
        icon: 'boundary-color',
      };
    },

    /**
     * @param {HashiCorpLink}
     */
    [TYPE_CONSUL]: (link) => {
      let { projectId: project_id } = getLocationFromLink(link);
      let id = getIdFromLink(link);
      return {
        label: id,
        models: [id],
        route: 'consul.clusters.hcp.detail',
        query: { project_id },
        icon: 'consul-color',
      };
    },

    [TYPE_CONSUL_GLOBAL_NETWORK_MANAGER]: (link) => {
      let { projectId: project_id } = getLocationFromLink(link);
      let id = getIdFromLink(link);
      return {
        label: id,
        models: [id],
        route: 'consul.clusters.self-managed.detail',
        query: { project_id },
        icon: 'consul-color',
      };
    },

    [TYPE_VAULT]: (link) => {
      let { projectId: project_id } = getLocationFromLink(link);
      let id = getIdFromLink(link);
      return {
        label: id,
        models: [id],
        route: 'vault.detail',
        query: { project_id },
        icon: 'vault-color',
      };
    },

    [TYPE_PACKER]: (link) => {
      let { projectId: project_id } = getLocationFromLink(link);
      return {
        label: this.intl.t('helpers.option-for-location-link.packer'),
        route: 'packer',
        query: { project_id },
        icon: 'packer-color',
      };
    },

    [TYPE_PACKER_BUCKET]: (link) => {
      let { projectId: project_id } = getLocationFromLink(link);
      let id = getIdFromLink(link);

      return {
        label: id,
        route: 'packer.registry.buckets.bucket',
        models: [id],
        query: { project_id },
        icon: 'packer-color',
      };
    },

    [TYPE_TERRAFORM]: () => {
      return {
        label: this.intl.t('helpers.option-for-location-link.terraform-cloud'),
        icon: 'terraform-color',
      };
    },

    [TYPE_NETWORK]: (link) => {
      let { projectId, organizationId } = getLocationFromLink(link);
      let id = getIdFromLink(link);
      return {
        label: id,
        models: [organizationId, projectId, id],
        route: 'cloud.orgs.detail.projects.detail.hvns.detail.index',
      };
    },

    [TYPE_WAYPOINT]: (link) => {
      let { projectId: project_id } = getLocationFromLink(link);
      return {
        label: this.intl.t('helpers.option-for-location-link.waypoint'),
        models: [],
        route: 'waypoint',
        query: { project_id },
        icon: 'waypoint-color',
      };
    },
    [TYPE_VAULT_SECRETS_APP]: (link) => {
      let { projectId: project_id } = getLocationFromLink(link);
      let id = getIdFromLink(link);

      return {
        label: id,
        models: [id],
        route: 'secrets.apps.app',
        query: { project_id },
        icon: 'vault-secrets-color',
      };
    },

    [TYPE_WEBHOOK]: (link) => {
      let { organizationId: org_id, projectId: project_id } =
        getLocationFromLink(link);
      let id = getIdFromLink(link);

      return {
        label: id,
        models: [org_id, project_id, id],
        route: 'cloud.orgs.detail.projects.detail.settings.webhooks.webhook',
        icon: 'webhook',
      };
    },
  };

  /**
   * @param {Array} args - The arguments array for an Ember helper. The first
   *     element in the array will need to be a HashiCorpLink.
   */
  compute([link]) {
    let { type } = link;

    if (this.LINK_MAPPING[type]) {
      return this.LINK_MAPPING[type](link);
    }

    return {};
  }
}
