/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import templateString from 'core/utils/template-string';
import {
  AWS_CONNECTIONS_INFO,
  AWS_DISCLOSURE_GUIDES_PEERING,
} from 'networks-common/utils/aws-connections';
import {
  CONTENT_TABS_LABELS,
  PRODUCT_CONSUL,
  PRODUCT_VAULT,
} from 'networks-common/utils/content-tabs';

/**
 *
 * `PageHvnPeeringsDetailContentTabsAwsCompnent` renders the HVN Peerings Detail tabs content
 *
 *
 * ```
 * <Page::HvnPeeringsDetail::ContentTabs::Aws
 *   @model={{model}}
 *   @tab={{tab}}
 *   @product={{product}}
 * />
 * ```
 *
 * @class PageHvnPeeringsDetailContentTabsAws
 *
 */
/**
 *
 * `tab` will be the selected tab;
 * @argument tab;
 * @type {String}
 *
 */
/**
 *
 * `product` such as consul or vault;
 * @argument product;
 * @type {String}
 *
 */
/**
 *
 * `model` will be a model containing the peering detail;
 * @argument model;
 * @type {Object}
 *
 */

let consulIngress = templateString`aws ec2 --region ${'region'} authorize-security-group-ingress --group-id ${'groupId'} --ip-permissions
IpProtocol=tcp,FromPort=8301,ToPort=8301,IpRanges='[{CidrIp=${'cidrBlock'}}]'
IpProtocol=udp,FromPort=8301,ToPort=8301,IpRanges='[{CidrIp=${'cidrBlock'}}]'
IpProtocol=tcp,FromPort=8301,ToPort=8301,UserIdGroupPairs='[{GroupId=${'groupId'}}]'
IpProtocol=udp,FromPort=8301,ToPort=8301,UserIdGroupPairs='[{GroupId=${'groupId'}}]'`;

let consulEgressAgentless = templateString`aws ec2 --region ${'region'} authorize-security-group-egress --group-id ${'groupId'} --ip-permissions
IpProtocol=tcp,FromPort=8300,ToPort=8300,IpRanges='[{CidrIp=${'cidrBlock'}}]'
IpProtocol=tcp,FromPort=8301,ToPort=8301,IpRanges='[{CidrIp=${'cidrBlock'}}]'
IpProtocol=udp,FromPort=8301,ToPort=8301,IpRanges='[{CidrIp=${'cidrBlock'}}]'
IpProtocol=tcp,FromPort=8301,ToPort=8301,UserIdGroupPairs='[{GroupId=${'groupId'}}]'
IpProtocol=udp,FromPort=8301,ToPort=8301,UserIdGroupPairs='[{GroupId=${'groupId'}}]'
IpProtocol=tcp,FromPort=80,ToPort=80,IpRanges='[{CidrIp=${'cidrBlock'}}]'
IpProtocol=tcp,FromPort=443,ToPort=443,IpRanges='[{CidrIp=${'cidrBlock'}}]'
IpProtocol=tcp,FromPort=8502,ToPort=8502,IpRanges='[{CidrIp=${'cidrBlock'}}]'`;

let vaultTemplate = templateString`aws ec2 --region ${'region'} authorize-security-group-egress --group-id ${'groupId_flag'} --ip-permissions
IpProtocol=tcp,FromPort=8200,ToPort=8200,IpRanges='[{CidrIp=${'cidrIp_tcp_port8200'}}]'`;

export default class PageHvnPeeringsDetailContentTabsAwsComponent extends Component {
  @service intl;
  @service router;

  @tracked awsSecurityGroupId = '';
  connectionsInfo = AWS_CONNECTIONS_INFO;
  tabs = CONTENT_TABS_LABELS;

  products = {
    consul: PRODUCT_CONSUL,
    vault: PRODUCT_VAULT,
  };

  awsDisclosureGuides = AWS_DISCLOSURE_GUIDES_PEERING;

  @action
  updateQueryParams(queryParams = {}) {
    return this.router.transitionTo(this.router.currentRouteName, {
      queryParams,
    });
  }

  get cidrBlock() {
    let { cidrBlock } = this.args.model.network;
    return cidrBlock;
  }

  get region() {
    let { region } = this.args.model.peering.target.awsTarget;
    return region;
  }

  get consulAuthorizeSecurityGroupIngress() {
    let string = consulIngress({
      region: this.region,
      groupId: this.awsSecurityGroupId,
      cidrBlock: this.cidrBlock,
    });

    return string;
  }

  get consulAuthorizeSecurityGroupEgress() {
    let string = consulEgressAgentless({
      region: this.region,
      groupId: this.awsSecurityGroupId,
      cidrBlock: this.cidrBlock,
    });

    return string;
  }

  get vaultAuthorizeSecurityGroupIngress() {
    let string = vaultTemplate({
      region: this.region,
      groupId_flag: this.awsSecurityGroupId,
      cidrIp_tcp_port8200: this.cidrBlock,
      groupId_tcp_port8200: this.awsSecurityGroupId,
    });
    return string;
  }
}
