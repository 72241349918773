const I18N_PREFIX = 'helpers.option-for-global-error';

export const DEFAULT_ERROR_CODE = 'default';
export const ERROR_CODE_MAPPING = {
  'authentication-callback-failure': {
    message: `${I18N_PREFIX}.authentication-callback-failure.message`,
    subtitle: `${I18N_PREFIX}.authentication-callback-failure.subtitle`,
    title: `${I18N_PREFIX}.authentication-callback-failure.title`,
  },
  'maintenance-mode': {
    message: `${I18N_PREFIX}.maintenance-mode.message`,
    title: `${I18N_PREFIX}.maintenance-mode.title`,
  },
  'invite-email-mismatch': {
    action: `${I18N_PREFIX}.invite-email-mismatch.action`,
    instructions: [
      {
        message: `${I18N_PREFIX}.invite-email-mismatch.list-option-invite.message`,
        help: `${I18N_PREFIX}.invite-email-mismatch.list-option-invite.help`,
      },
      {
        message: `${I18N_PREFIX}.invite-email-mismatch.list-option-create.message`,
        help: `${I18N_PREFIX}.invite-email-mismatch.list-option-create.help`,
      },
    ],
    message: `${I18N_PREFIX}.invite-email-mismatch.message`,
    subtitle: `${I18N_PREFIX}.invite-email-mismatch.subtitle`,
    title: `${I18N_PREFIX}.invite-email-mismatch.title`,
  },
  [DEFAULT_ERROR_CODE]: {
    message: `${I18N_PREFIX}.default.message`,
    subtitle: `${I18N_PREFIX}.default.subtitle`,
    title: `${I18N_PREFIX}.default.title`,
  },
};

export const ERROR_CODE_SCALE = Object.keys(ERROR_CODE_MAPPING);
