import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class CloudOrgsDetailSettingsSsoOidcConfigureRoute extends Route {
  @service api;

  async model() {
    let { org, configs } = this.modelFor('cloud.orgs.detail.settings.sso');
    let preconfig =
      await this.api.sso.sSOManagementServiceGetPreconfigurationData(org.id);

    return {
      org,
      configs,
      preconfig,
    };
  }
}
