/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

/**
 *
 * `PageOrganizationsList` renders a table of organizations.
 *
 *
 * ```
 * <Page::Organizations::List
 *   @contractActivation={{this.contractActivation}}
 *   @model={{this.model}}
 * />
 * ```
 *
 * @class PageOrganizationsList
 *
 */

/**
 *
 * `contractActivation` indicates whether or not we are
 * within the self serve contract activation workflow.
 * @argument contractActivation;
 * @type {Boolean}
 * @required
 *
 */

/**
 *
 * `model` is an object an `organizations` key.
 * @argument model;
 * @type {Object}
 * @required
 *
 */

export default class PageOrganizationsListComponent extends Component {
  @service currentUser;
  @service intl;

  // Returns both columnOne and columnTwo if outside of the contract
  // activation workflow. Returns only columnOne if inside the
  // activation workflow.
  get columns() {
    const columnOne = [
      {
        key: 'name',
        label: this.intl.t(
          'components.page.organizations.list.table.headers.name'
        ),
      },
    ];
    const columnTwo = {
      key: 'actions',
      label: this.intl.t(
        'components.page.organizations.list.table.headers.options'
      ),
      align: 'right',
    };

    return columnOne.concat(this.args?.contractActivation ? [] : [columnTwo]);
  }
}
