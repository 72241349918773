/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import IamPolicy from 'core/utils/iam-policy';
import { MEMBER_TYPE_SERVICE_PRINCIPAL } from 'core/helpers/rbac-member-types';
import { MAX_UPDATE_POLICY_RETRIES } from '../../create';

const baseKey = 'components.page.access-control.service-principals.edit';

/**
 *
 * `PageServicePrincipalsOrganizationDetailEdit` form to edit a service principal for an organization and assign a new role.
 *
 * ```
 * <Page::ServicePrincipals::Organization::Detail::Edit
 *   @organization={{...}}
 *   @role={{...}}
 *   @servicePrincipal={{...}}
 * />
 * ```
 *
 * @class PageServicePrincipalsOrganizationDetailEdit
 *
 */

export default class PageServicePrincipalsOrganizationDetailEditComponent extends Component {
  /**
   * @argument organization
   * @type {Object}
   * @required
   */

  /**
   * @argument role
   * @type {String}
   * @required
   */

  /**
   * @argument servicePrincipal
   * @type {Object}
   * @required
   */

  @service api;
  @service flashMessages;
  @service intl;
  @service router;

  @task
  *save(form) {
    let { updated } = yield* this.updateRole(form.fields.role);
    if (updated) {
      this.flashMessages.success(
        this.intl.t(`${baseKey}.success.title`, { name: form.fields.name })
      );
    } else {
      this.flashMessages.warning(
        this.intl.t(`${baseKey}.warning.title`, {
          content: `${baseKey}.warning.content`,
        })
      );
    }

    return this.router.transitionTo(
      'cloud.access-control.service-principals.list'
    );
  }

  *updateRole(role) {
    const orgId = this.args.organization.id;
    for (let i = 0; i < MAX_UPDATE_POLICY_RETRIES; i++) {
      try {
        let { policy } =
          yield this.api.resourceManager.org.organizationServiceGetIamPolicy(
            orgId
          );

        let policyToUpdate = new IamPolicy(policy);
        policyToUpdate.addMember(
          this.args.servicePrincipal.id,
          MEMBER_TYPE_SERVICE_PRINCIPAL.value,
          role
        );
        let updatedPolicy = policyToUpdate.get();
        let policyPayload = {
          policy: {
            ...updatedPolicy,
          },
        };
        return {
          updated: true,
          response:
            yield this.api.resourceManager.org.organizationServiceSetIamPolicy(
              orgId,
              policyPayload
            ),
        };
      } catch (e) {
        if (e.status === 409) {
          continue;
        }
        throw e;
      }
    }

    return {
      updated: false,
    };
  }
}
