/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { getOwner } from '@ember/owner';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { variation } from 'ember-launch-darkly';

import {
  TYPE_NETWORK_PEERING,
  TYPE_NETWORK,
} from 'common/utils/cloud-resource-types';
import {
  AWS_CONNECTIONS_INFO,
  AWS_DISCLOSURE_GUIDES_PEERING,
} from 'networks-common/utils/aws-connections';
import { AZURE_DISCLOSURE_GUIDES_PEERING } from 'networks-common/utils/azure-connections';
import { CONNECTION_METHODS } from 'networks-common/utils/connection-methods';
import {
  isAwsProviderNetwork,
  isAzureProviderNetwork,
  providerName,
} from 'networks-common/utils/networks';
import { PEERING_SUBMITTED } from 'core/utils/consts/analytics-events/platform';
import { PEERING_CREATION_TYPE_AUTOMATE } from 'hcp/controllers/cloud/orgs/detail/projects/detail/hvns/detail/peerings/create';
import awsRegions from 'core/utils/aws-region-list';

const {
  awsQuickLink: { key: quickCreate },
} = CONNECTION_METHODS;

/**
 *
 * `PageHvnPeeringsCreate` renders the HVN Peerings Create page.
 *
 *
 * ```
 * <Page::HvnPeerings::Create
 *   @model={{@model}}
 * />
 * ```
 *
 * @class PageHvnPeeringsCreate
 *
 */

/**
 *
 * `model` will be a model containing the network and peerings;
 * @argument model;
 * @type {Object}
 *
 */
export default class PageHvnPeeringsCreateComponent extends Component {
  @service analytics;
  @service api;
  @service abilities;
  @service flashMessages;
  @service intl;
  @service router;
  @service('metadata.local-storage') metadata;

  @tracked errors;
  @tracked id;

  // Guided Flow state
  @tracked connectionMethod = quickCreate;

  // AWS Form fields
  @tracked accountId;
  @tracked vpcId;
  @tracked vpcRegion = 'us-west-2';
  @tracked vpcCidr = '';

  // Azure form fields
  @tracked tenantId;
  @tracked subscriptionId;
  @tracked resourceGroupName;
  @tracked vnetName;
  @tracked allowForwardedTraffic = false;
  @tracked useRemoteGateways = false;

  awsRegions = awsRegions;

  PEERING_CREATION_TYPE_AUTOMATE = PEERING_CREATION_TYPE_AUTOMATE;

  constructor(owner, args) {
    super(owner, args);
    this.vpcRegion = this.args.model.network.location.region.region;
  }

  awsDisclosureGuides = AWS_DISCLOSURE_GUIDES_PEERING;
  azureDisclosureGuides = AZURE_DISCLOSURE_GUIDES_PEERING;

  get resourceType() {
    return TYPE_NETWORK_PEERING;
  }

  get isAws() {
    return isAwsProviderNetwork(this.args.model.network);
  }

  get isAwsQuickCreateLink() {
    return this.connectionMethod === quickCreate;
  }

  get isAzure() {
    return isAzureProviderNetwork(this.args.model.network);
  }

  get provider() {
    return providerName(this.args.model.network);
  }

  @task
  *save(evt) {
    // don't submit the form
    evt.preventDefault();

    let {
      location: { organizationId, projectId },
      id: hvnId,
    } = this.args.model.network;

    let { network } = this.args.model;
    let { id } = this;
    let payload;
    let target;
    // AWS PROVIDER
    if (this.isAws) {
      let { accountId, vpcId, vpcRegion, vpcCidr } = this;
      target = {
        awsTarget: {
          accountId,
          vpcId,
          region: vpcRegion,
          cidr: vpcCidr,
        },
      };
    }
    // AZURE PROVIDER
    if (this.isAzure) {
      const isHubAndSpokeEnabled = variation('hcp-ui-azure-hub-spoke');
      let {
        tenantId,
        subscriptionId,
        resourceGroupName,
        vnetName,
        allowForwardedTraffic,
        useRemoteGateways,
      } = this;
      target = {
        azureTarget: {
          tenantId,
          subscriptionId,
          resourceGroupName,
          vnetName,
          ...(isHubAndSpokeEnabled
            ? { allowForwardedTraffic, useRemoteGateways }
            : {}),
        },
      };
    }

    payload = {
      peering: {
        id,
        hvn: network,
        target: target,
      },
    };

    this.analytics.trackEvent(PEERING_SUBMITTED, payload);

    yield this.api.network.createPeering(
      organizationId,
      projectId,
      hvnId,
      payload
    );

    this.flashMessages.success(
      this.intl.t('components.page.hvn-peerings.create.success.title'),
      {
        content: this.intl.t(
          'components.page.hvn-peerings.create.success.message'
        ),
      }
    );

    if (this.isAws) {
      const scope = 'resource';
      const identifier = `project/${projectId}/${TYPE_NETWORK}/${hvnId}`;
      let d = {
        scope,
        identifier,
        metadata: {
          peering: {},
        },
      };

      d.metadata.peering[this.id] = {
        connectionMethod: this.connectionMethod,
        completion: [],
      };

      yield this.metadata.set(identifier, d);
    }
    let routeClass = getOwner(this).lookup(
      `route:${AWS_CONNECTIONS_INFO.peering.detail}`
    );
    routeClass?.refresh();

    return this.router.replaceWith(
      AWS_CONNECTIONS_INFO.peering.detail,
      this.id,
      { queryParams: { 'connection-method': this.connectionMethod } }
    );
  }

  @action
  changeConnectionMethod({ target: { value } }) {
    this.connectionMethod = value;
  }

  @action
  cancel() {
    this.analytics.trackEvent(
      'external_ui_hashicorp_network_peering_create_form_cancel_button_clicked',
      this.args.model.network
    );
  }
}
