/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { tracked } from 'tracked-built-ins';
import { inject as service } from '@ember/service';

import { CREATE_TEMP_ID } from '../../page/service-principals/project/create';
import { HashicorpCloudResourcemanagerPolicyBindingMemberType } from '@clients/cloud-resource-manager';
import PolicyManager from 'manage-access/utils/policy-manager';

const baseKey = 'components.page.access-control.service-principals.form';

/**
 *
 * `ServicePrincipalFormV2Component` is a generic encapsulation of the HTML form
 * and client side validation logic used to create new service principals, as well as
 * being used to edit existing service principals.
 *
 * ```
 * <ServicePrincipal::FormV2
 *   @saveButtonText={{"Create"}}
 *   @servicePrincipal={{}}
 *   @projectRoles={{@projectRoles}}
 *   @policy={{@policy}}
 *   @isNameInputDisabled={{true}}
 *   @isSaveDisabled={{false}}
 *   @save={{async () => {}}}
 * />
 * ```
 *
 * @class ServicePrincipalFormComponent
 *
 */

export default class ServicePrincipalFormComponent extends Component {
  /**
   * Button text to use for "save" action.
   * @argument saveButtonText
   * @type {String}
   * @required
   */

  /**
   * Passed in async/generator Ember concurrency task with a perform callback
   * function to invoke upon form submission
   * NOTE: The form component just collects and validates input, doesn't call API
   * @argument save
   * @type {Function}
   * @required
   */

  /**
   * Optionally disables the service principal name form input
   * @argument isNameInputDisabled
   * @type {Boolean}
   */

  /**
   * Optionally disables the form submit button that would invoke the save action
   * @argument isSaveDisabled
   * @type {Boolean}
   */

  /**
   * Model data from IAM service for the service principal, used when editing
   * @argument servicePrincipal
   * @type {Object}
   */

  /**
   * Existing role associated with the service principal, used to pre-populate the dropdown menu when editing
   * @argument role
   * @type {String}
   */

  @service intl;

  @tracked originalPolicy;
  @tracked editedPolicy;
  @tracked servicePrincipalId =
    this.args?.servicePrincipal?.id || CREATE_TEMP_ID;
  @tracked servicePrincipalName = this.args.servicePrincipal?.name ?? '';
  @tracked validationErrors = [];
  @tracked validationError;

  @action
  didInsert() {
    const { policy } = this.args;
    this.setEditedPolicy(policy);
  }

  setEditedPolicy(policy) {
    if (!policy) return;

    const DefaultPolicy = new PolicyManager({ policy });

    const member = {
      memberId: this.servicePrincipalId,
      memberType:
        HashicorpCloudResourcemanagerPolicyBindingMemberType.SERVICEPRINCIPAL,
    };
    const { roleIds = [] } = DefaultPolicy.getRolesByMember(member) ?? {};

    if (!roleIds.length) {
      DefaultPolicy.addMemberToRole(member, ['roles/viewer']);
    }

    this.originalPolicy = DefaultPolicy.getRawPolicy();
    this.editedPolicy = DefaultPolicy.getRawPolicy();
  }

  // Starts with a letter.
  nameStartsWithLetter = /^[a-z]+/i;

  // Ends with a letter or number.
  nameEndsWithAlphanumeric = /[a-z0-9]+$/i;

  // May only contain letters, numbers or hypens.
  nameInvalidCharacters = /[^a-z0-9-]/i;

  @action setName(e) {
    this.servicePrincipalName = e.target.value;
    this.validateForm();
  }

  @action
  onPolicyChange({ policy }) {
    this.editedPolicy = policy;
  }

  @task
  *save(evt) {
    evt.preventDefault();

    if (!this.validateForm()) return;

    return yield this.args.save.perform(
      this.editedPolicy,
      this.servicePrincipalName
    );
  }

  validateForm() {
    const fieldValidatorName = [
      {
        message: this.intl.t(`${baseKey}.errors.name.required`),
        isValid: (v) => v && v !== '',
        subValidators: [
          {
            message: this.intl.t(
              `${baseKey}.errors.name.must-start-with-ascii`
            ),
            isValid: (v) => this.nameStartsWithLetter.test(v),
          },
          {
            message: this.intl.t(
              `${baseKey}.errors.name.must-end-with-ascii-or-number`
            ),
            isValid: (v) => this.nameEndsWithAlphanumeric.test(v),
          },
          {
            message: this.intl.t(
              `${baseKey}.errors.name.must-only-contain-alphanumber-or-hyphen`
            ),
            isValid: (v) => !this.nameInvalidCharacters.test(v),
          },
          {
            message: this.intl.t(
              `${baseKey}.errors.name.must-be-between-specific-length`
            ),
            isValid: (v) => v.length >= 3 && v.length <= 36,
          },
        ],
      },
    ];

    let nameErrors = this.runValidators(
      fieldValidatorName,
      this.servicePrincipalName
    );
    this.validationErrors = nameErrors.map((v) => v.message);

    return !Object.keys(this.validationErrors).find(
      (k) => this.validationErrors[k].length
    );
  }

  runValidators(validators, value) {
    let failedValidators = validators.flatMap((v) => {
      if (!v.isValid(value)) {
        return v;
      }

      if (v.subValidators) {
        return this.runValidators(v.subValidators, value);
      }

      return [];
    });

    return failedValidators;
  }
}
