import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { TYPE_CONSUL, TYPE_VAULT } from 'common/utils/cloud-resource-types';

export default class CloudOrgsDetailProjectsDetailHvnsDetailPeeringsListRoute extends Route {
  @service api;

  async model() {
    let { organization } = this.modelFor('cloud.orgs.detail');
    let { project } = this.modelFor('cloud.orgs.detail.projects.detail');
    let { network } = this.modelFor(
      'cloud.orgs.detail.projects.detail.hvns.detail'
    );
    let networkDependencies = await this.api.network.listDependencies(
      organization.id,
      project.id,
      network.id
    );
    networkDependencies = networkDependencies.dependencies;
    let { peerings } = await this.api.network.listPeerings(
      organization.id,
      project.id,
      network.id
    );

    /**
     * TODO: Remove Promise.all call and filter against peerings
     * when API is updated to include peering dependencies
     */

    let peeringDep = Promise.all(
      peerings.map((peering) => {
        if (peering.target?.hvnTarget) {
          let networkId = peering.target?.hvnTarget.hvn.id;
          return this.api.network.listDependencies(
            organization.id,
            project.id,
            networkId
          );
        } else {
          return Promise.resolve();
        }
      })
    );
    peeringDep = await peeringDep;
    peerings = peerings.map((peering, i) => {
      let peeringHvnTargetConsulClusterDependencies = peeringDep[
        i
      ]?.dependencies.filter(
        (dependency) =>
          dependency.type === TYPE_CONSUL || dependency.type === TYPE_VAULT
      );
      let networkConsulClusterDependencies = networkDependencies.filter(
        (dependency) =>
          dependency.type === TYPE_CONSUL || dependency.type === TYPE_VAULT
      );
      if (
        networkConsulClusterDependencies?.length > 0 &&
        peeringHvnTargetConsulClusterDependencies?.length > 0
      ) {
        peering.isLocked = true;
      } else {
        peering.isLocked = false;
      }
      return peering;
    });

    let { tgwAttachments } = await this.api.network.listTGWAttachments(
      organization.id,
      project.id,
      network.id
    );

    return {
      network,
      networkDependencies,
      organization,
      peerings,
      project,
      tgwAttachments,
    };
  }
}
