/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from 'tracked-built-ins';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';
import { TYPE_NETWORK_TRANSIT_GATEWAY_ATTACHMENT } from 'common/utils/cloud-resource-types';
import {
  AWS_DISCLOSURE_GUIDES_TGW_ATTACHMENTS,
  AWS_CONNECTIONS_INFO,
} from 'networks-common/utils/aws-connections';
import { CONTENT_TABS_LABELS } from 'networks-common/utils/content-tabs';
import { getOwner } from '@ember/owner';
import { TRANSIT_GATEWAY_ATTACHMENT_SUBMITTED } from 'core/utils/consts/analytics-events/platform';

import templateString from 'core/utils/template-string';
import awsRegionList from 'core/utils/aws-region-list';

/**
 *
 * `PageHvnTransitGatewayAttachmentsCreate` renders the HVN Transit Gateway
 * Attachment Create page.
 *
 *
 * ```
 * <Page::HvnTransitGatewayAttachments::Create
 *   @model={{@model}}
 * />
 * ```
 *
 * @class PageHvnTransitGatewayAttachmentsCreate
 *
 */

/**
 *
 * `model` will be a model containing the network and transit gateway
 * attachments;
 * @argument model;
 * @type {Object}
 *
 */

let resourceShareTemplate = templateString`aws ram --region ${'region'} create-resource-share --name hcp-${'hvnId'}-resource-share --resource-arns ${'arn'} --principals ${'principal'}`;
let transitGatewayARNTemplate = templateString`arn:aws:ec2:${'region'}:${'accountId'}:transit-gateway/${'tgwId'}`;

const baseKey = 'components.page.hvn-transit-gateway-attachments.create.form';

export default class PageHvnTransitGatewayAttachmentsCreateComponent extends Component {
  @service analytics;
  @service api;
  @service abilities;
  @service flashMessages;
  @service intl;
  @service router;

  @tracked errors;
  @tracked cidr = '';
  @tracked cidrs = [];
  @tracked region = this.args.model.network.location.region.region;
  @tracked principalId =
    this.args.model.network.providerNetworkData.awsNetworkData.accountId;
  @tracked steps = [
    'components.page.hvn-transit-gateway-attachments.create.steps.create',
    'components.page.hvn-transit-gateway-attachments.create.steps.accept',
    'components.page.hvn-transit-gateway-attachments.create.steps.add-routes',
    'components.page.hvn-transit-gateway-attachments.create.steps.configure',
  ];

  awsRegions = awsRegionList;
  disclosureGuides = AWS_DISCLOSURE_GUIDES_TGW_ATTACHMENTS;
  tabs = CONTENT_TABS_LABELS;

  get resourceType() {
    return TYPE_NETWORK_TRANSIT_GATEWAY_ATTACHMENT;
  }

  get codeSnippetButtonDisabled() {
    return !this.renderValidCodeSnippet;
  }

  get renderValidCodeSnippet() {
    let requiredSnippetFields = ['accountId', 'transitGatewayId'];
    let emptyFields = [];

    let accountIdError = this.form.validationErrors.accountId;
    let tgwIdError = this.form.validationErrors.transitGatewayId;

    // Check if requiredSnippetFields are blank
    requiredSnippetFields.map((fieldName) => {
      for (const [key, value] of Object.entries(this.form.fields)) {
        if (key === fieldName && value === '') {
          emptyFields.push(fieldName);
        }
      }
    });

    // If requiredSnippetFields are blank or if they each have validation errors return false
    if (accountIdError.length || tgwIdError.length || emptyFields.length) {
      return false;
    } else {
      return true;
    }
  }
  get resourceShareCommand() {
    //only generate arn in cli command if AWS Account ID and Transit gateway ID fields are valid
    if (this.renderValidCodeSnippet) {
      let arn = transitGatewayARNTemplate({
        accountId: this.form.fields.accountId,
        tgwId: this.form.fields.transitGatewayId,
        region: this.region,
      });
      let string = resourceShareTemplate({
        principal: this.principalId,
        hvnId: this.args.model.network.id,
        arn: arn,
        region: this.region,
      });
      return string;
    } else {
      return this.intl.t(
        'components.page.hvn-transit-gateway-attachments.create.code-snippet.message'
      );
    }
  }

  checkElementValidity(element) {
    return element !== '';
  }

  @action
  updateQueryParams(queryParams = {}) {
    return this.router.transitionTo(this.router.currentRouteName, {
      queryParams,
    });
  }

  //TO DO - when HVN Routes API is ready, remove addCidr and removeCidr methods below
  @action
  addCidr(newCidr) {
    let exists = this.cidrs.find((cidr) => {
      return cidr === newCidr;
    });

    if (exists) {
      return;
    }

    if (!this.checkElementValidity(newCidr)) {
      return;
    }

    this.cidr = '';
    this.cidrs = [...this.cidrs, newCidr];
  }

  @task
  *save(evt) {
    // don't submit the form

    evt.preventDefault();
    this.validateForm();

    evt.preventDefault();
    this.addCidr(this.cidr);

    let {
      location: { organizationId, projectId },
      id: hvnId,
    } = this.args.model.network;
    let { network } = this.args.model;

    let id = this.form.fields.attachmentId;
    let transitGatewayId = this.form.fields.transitGatewayId;
    let resourceShareArn = this.form.fields.resourceShareArn;
    let cidrs = this.cidrs;
    let payload = {
      id,
      providerData: {
        awsData: {
          tgwId: transitGatewayId,
          resourceShareArn: resourceShareArn,
        },
      },
      cidrs: cidrs,
      hvn: network,
    };

    this.analytics.trackEvent(TRANSIT_GATEWAY_ATTACHMENT_SUBMITTED, payload);

    yield this.api.network.createTGWAttachment(
      organizationId,
      projectId,
      hvnId,
      payload
    );

    this.flashMessages.success(
      this.intl.t(
        'components.page.hvn-transit-gateway-attachments.create.success.title'
      ),
      {
        content: this.intl.t(
          'components.page.hvn-transit-gateway-attachments.create.success.message'
        ),
      }
    );
    let routeClass = getOwner(this).lookup(
      `route:${AWS_CONNECTIONS_INFO.tgwAttachment.list}`
    );
    routeClass?.refresh();
    return this.router.replaceWith(AWS_CONNECTIONS_INFO.tgwAttachment.list);
  }

  /** form declaration */

  form = {
    fields: tracked({
      attachmentId: '',
      accountId: '',
      transitGatewayId: '',
      resourceShareArn: '',
    }),
    validationErrors: tracked({
      attachmentId: [],
      accountId: [],
      transitGatewayId: [],
      resourceShareArn: [],
    }),
    fieldValidators: {
      attachmentId: [
        {
          message: this.intl.t(`${baseKey}.errors.attachment-id.required`),
          isValid: (v) => v !== '',
        },
      ],
      accountId: [
        {
          message: this.intl.t(`${baseKey}.errors.account-id.required`),
          isValid: (v) => v !== '',
          subValidators: [
            {
              message: this.intl.t(
                `${baseKey}.errors.account-id.must-match-account-id-pattern`
              ),
              isValid: (v) => this.accountIdMatchesPattern.test(v),
            },
          ],
        },
      ],
      transitGatewayId: [
        {
          message: this.intl.t(`${baseKey}.errors.transit-gateway-id.required`),
          isValid: (v) => v && v !== '',
          subValidators: [
            {
              message: this.intl.t(
                `${baseKey}.errors.transit-gateway-id.must-match-tgw-pattern`
              ),
              isValid: (v) => this.nameMatchesTgwPattern.test(v),
            },
          ],
        },
      ],
      resourceShareArn: [
        {
          message: this.intl.t(`${baseKey}.errors.resource-share-arn.required`),
          isValid: (v) => v !== '',
        },
      ],
    },
  };

  nameMatchesTgwPattern = /^(tgw-)([A-Za-z0-9]+)$/;
  accountIdMatchesPattern = /^\d{12}$/;

  @action setAttachmentId(e) {
    this.form.fields.attachmentId = e.target.value;
    this.validateForm('attachmentId');
  }
  @action setAccountId(e) {
    this.form.fields.accountId = e.target.value;
    this.validateForm('accountId');
  }
  @action setTransitGatewayId(e) {
    this.form.fields.transitGatewayId = e.target.value;
    this.validateForm('transitGatewayId');
  }

  @action setResourceShareArn(e) {
    this.form.fields.resourceShareArn = e.target.value;
    this.validateForm('resourceShareArn');
  }

  validateForm(...changedFormFields) {
    for (var i = 0; i < changedFormFields.length; i++) {
      let fieldName = changedFormFields[i];
      let fieldErrors = this.runValidators(
        this.form.fieldValidators[fieldName],
        this.form.fields[fieldName]
      );
      this.form.validationErrors[fieldName] =
        fieldErrors.map((v) => v.message) || [];
    }
    return !Object.keys(this.form.validationErrors).find(
      (k) => this.form.validationErrors[k].length
    );
  }

  runValidators(validators, value) {
    let failedValidators = validators.flatMap((v) => {
      if (!v.isValid(value)) {
        return v;
      }

      if (v.subValidators) {
        return this.runValidators(v.subValidators, value);
      }

      return [];
    });

    return failedValidators;
  }
}
