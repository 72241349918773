/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

/**
 * Component used to render a Project level service principal's workload identity providers
 *
 * ### Overview
 *
 * `ServicePrincipalWorkloadIdentityProvidersListTableComponent` renders an <Hds::Table /> of Workload identity providers for a project level service principal.
 *
 * Usage:
 * <ServicePrincipal::WorkloadIdentityProviders::ListTable
 *  @workloadIdentityProviders={{foo}}
 *  @workloadIdentityProvidersAllowedPermissions={{foo}}
 *  @organizationName={{foo}}
 *  @projectName={{foo}}
 *  @servicePrincipalName={{foo}}
 *  @handleConfirmDeleteWip={{fooFunction}}
 * />
 *
 *
 */
export default class ServicePrincipalWorkloadIdentityProvidersListTableComponent extends Component {
  /**
   * @argument workloadIdentityProviders
   * @type {Array.<Object>}
   * @required
   */
  /**
   * @argument workloadIdentityProvidersAllowedPermissions
   * @type {Array}
   * @required
   */
  /**
   * @argument organzationName
   * @type {String}
   * @required
   */
  /**
   * @argument projectName
   * @type {String}
   * @required
   */
  /**
   * @argument servicePrincipalName
   * @type {String}
   * @required
   */
  /**
   * @argument handleConfirmDeleteWip
   * @type {Function}
   * @required
   */
}
