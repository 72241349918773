/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked, TrackedObject } from 'tracked-built-ins';
import { action } from '@ember/object';

/**
 *
 * A component that encapsulates the Project User resource's mutation logic
 * (API, CRUD) as exposes them as design spec UI elements (Actions and Modals).
 *
 *
 * ```
 * <Page::Projects::Users::MutationContainer
 *   @orgPolicy={{this.orgPolicy}}
 *   @projectPolicy={{this.projectPolicy}}
 *   as |Container|
 * >
 *  <Container.Actions />
 *  <Container.Modals />
 * </Page::Projects::Users::MutationContainer />
 * ```
 *
 * @class PageProjectsUsersMutationContainer
 *
 */

/**
 * The IamPolicy class for the org policy.
 * @argument orgPolicy
 * @type {Object}
 */

/**
 * The IamPolicy class for the project policy.
 * @argument projectPolicy
 * @type {Object}
 */
export default class PageProjectsUsersMutationContainerComponent extends Component {
  @service('resources.project-user') projectUsers;

  @tracked resourcesToMutate = [];

  @tracked modals = new TrackedObject({
    delete: { isVisible: false },
  });

  @action
  setResourcesToMutate(resources) {
    this.resourcesToMutate = resources;
  }

  @action
  showModalByType(actionType) {
    const action = this.modals[actionType];

    if (action) {
      this.modals[actionType] = {
        ...action,
        isVisible: true,
      };
    }
  }

  @action
  hideModalByType(actionType) {
    const action = this.modals[actionType];

    if (action) {
      this.modals[actionType] = {
        ...action,
        isVisible: false,
      };
    }
  }
}
