/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import templateString from 'core/utils/template-string';
import { inject as service } from '@ember/service';

/**
 *
 * `AzureConsulInboundOutboundRulesSnippetComponent` renders the Azure Consul Inbound Outbound rules code snippet component
 *
 *
 * ```
 * <Page::HvnPeeringsDetail::ContentTabs::Azure::CodeSnippets::Consul::InboundOutboundRules
 *   @model={{model}}
 * />
 * ```
 *
 * @class AzureConsulInboundOutboundRulesSnippetComponent
 *
 */
/**
 *
 * `tab` will be the selected tab;
 * @argument tab;
 * @type {String}
 *
 */
/**
 *
 * `product` such as consul or vault;
 * @argument product;
 * @type {String}
 *
 */
/**
 *
 * `model` will be a model containing the peering detail;
 * @argument model;
 * @type {Object}
 *
 */

let consulRules = templateString`HVN_CIDR=\"${'cidrBlock'}\"
RESOURCE_GROUP_NAME=${'resourceGroupName'}
SECURITY_GROUP_ID=${'securityGroupId'}

# Create inbound rules Consul

az network nsg rule create \\\

    --resource-group "$RESOURCE_GROUP_NAME" \\\

    --nsg-name "$SECURITY_GROUP_ID" \\\

    --name ConsulServerInbound \\\

    --priority 400 \\\

    --source-address-prefixes "$HVN_CIDR" \\\

    --destination-address-prefixes VirtualNetwork \\\

    --destination-port-ranges 8301 \\\

    --direction Inbound \\\

    --access Allow

    az network nsg rule create \\\

    --resource-group "$RESOURCE_GROUP_NAME" \\\

    --nsg-name "$SECURITY_GROUP_ID" \\\

    --name ConsulClientInbound \\\

    --priority 401 \\\

    --source-address-prefixes VirtualNetwork \\\

    --destination-address-prefixes VirtualNetwork \\\

    --destination-port-ranges 8301 \\\

    --direction Inbound \\\

    --access Allow

# Create outbound rules Consul

az network nsg rule create \\\

    --resource-group "$RESOURCE_GROUP_NAME" \\\

    --nsg-name "$SECURITY_GROUP_ID" \\\

    --name ConsulServerOutbound \\\

    --priority 400 \\\

    --source-address-prefixes VirtualNetwork \\\

    --destination-address-prefixes "$HVN_CIDR" \\\

    --destination-port-ranges "8300-8301" \\\

    --direction Outbound \\\

    --access Allow

    az network nsg rule create \\\

    --resource-group "$RESOURCE_GROUP_NAME" \\\

    --nsg-name "$SECURITY_GROUP_ID" \\\

    --name ConsulClientOutbound \\\

    --priority 401 \\\

    --source-address-prefixes VirtualNetwork \\\

    --destination-address-prefixes VirtualNetwork \\\

    --destination-port-ranges 8301 \\\

    --direction Outbound \\\

    --access Allow

    az network nsg rule create \\\

    --resource-group "$RESOURCE_GROUP_NAME" \\\

    --nsg-name "$SECURITY_GROUP_ID" \\\

    --name HTTPOutbound \\\

    --priority 402 \\\

    --source-address-prefixes VirtualNetwork \\\

    --destination-address-prefixes "$HVN_CIDR" \\\

    --destination-port-ranges 80 \\\

    --direction Outbound \\\

    --access Allow

    az network nsg rule create \\\

    --resource-group "$RESOURCE_GROUP_NAME" \\\

    --nsg-name "$SECURITY_GROUP_ID" \\\

    --name HTTPSOutbound \\\

    --priority 403 \\\

    --source-address-prefixes VirtualNetwork \\\

    --destination-address-prefixes "$HVN_CIDR" \\\

    --destination-port-ranges 443 \\\

    --direction Outbound \\\

    --access Allow

    az network nsg rule create \\\

    --resource-group "$RESOURCE_GROUP_NAME" \\\

    --nsg-name "$SECURITY_GROUP_ID" \\\

    --name ConsulServerOutboundGRPC \\\

    --priority 404 \\\

    --source-address-prefixes VirtualNetwork \\\

    --destination-address-prefixes "$HVN_CIDR" \\\

    --destination-port-ranges 8502 \\\

    --direction Outbound \\\

    --access Allow`;

let agentlessRules = templateString`

# Create outbound rules Vault
az network nsg rule create \\\

    --resource-group "$RESOURCE_GROUP_NAME" \\\

    --nsg-name "$SECURITY_GROUP_ID" \\\

    --name VaultServerOutbound \\\

    --priority 405 \\\

    --source-address-prefixes VirtualNetwork \\\

    --destination-address-prefixes "$HVN_CIDR" \\\

    --destination-port-ranges 8200 \\\

    --direction Outbound \\\

    --access Allow`;

export default class AzureConsulInboundOutboundRulesSnippetComponent extends Component {
  @service intl;

  get cidrBlock() {
    let { cidrBlock } = this.args.model.network;
    return cidrBlock;
  }

  get region() {
    let { region } = this.args.model.peering.target.azureTarget;
    return region;
  }

  get resourceGroupName() {
    let { resourceGroupName } = this.args.model.peering.target.azureTarget;
    return resourceGroupName;
  }

  get securityGroupId() {
    return this.args.securityGroupId;
  }

  get consulSecurityGroupSnippet() {
    let securityGroupId = !this.securityGroupId
      ? this.intl.t(
          'components.page.hvn-peerings.detail.tables.rules.labels.security-group-id'
        )
      : this.securityGroupId;

    let string = consulRules({
      securityGroupId,
      resourceGroupName: this.resourceGroupName,
      cidrBlock: this.cidrBlock,
    });

    return string + agentlessRules();
  }

  get codeEditorOptions() {
    return {
      mode: 'shell',
      readOnly: true,
    };
  }
}
