/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { getOwner } from '@ember/owner';

/**
 *
 * A modal tied to the MutationContaier's context and yielded by its parent
 * component. Conditionally renders modals for every implemented mutation method
 * (API, CRUD). Currently only exposes the Remove user from project modal and
 * its enabled/disabled state.
 *
 *
 * ```
 * <Page::Projects::Users::MutationContainer::Modals
 *  @modals={{this.modals}}
 *  @onClose={{this.hideModalByType}}
 *  @projectPolicy={{this.projectPolicy}}
 *  @refreshRoute={{this.refreshRoute}}
 *  @resources={{this.resourcesToMutate}}
 *  @service={{this.projectUsers}}
 *  @setResourcesToMutate={{this.setResourcesToMutate}}
 * />
 * ```
 *
 * @class PageProjectsUsersMutationContainerModals
 *
 */

/**
 * A map of modal state.
 * @argument modals
 * @type {Object}
 */

/**
 * A callback to handle conditional render state
 * @argument onClose
 * @type {Function}
 */

/**
 * The IamPolicy for the project policy.
 * @argument projectPolicy
 * @type {Object}
 */

/**
 * The resource model(s) targeted for any mutation.
 * @argument resources
 * @type {Array}
 */

/**
 * The pluggable service to manage data flow.
 * @argument service
 * @type {Object}
 */

/**
 * The parent setResourcesToMutate fn to set current context.
 * @argument setResourcesToMutate
 * @type {Function}
 */

export default class PageProjectsUsersMutationContainerModalsComponent extends Component {
  @service flashMessages;
  @service intl;
  @service router;
  @service userContext;

  get users() {
    const { resources = [] } = this.args;
    return resources;
  }

  get userEmails() {
    let { users } = this;

    if (users.length > 3) {
      return null;
    }

    return users
      .map(({ email }, index, self) => {
        return `${
          self.length > 1 && index === self.length - 1 ? 'and ' : ''
        }${email}`;
      })
      .join(', ');
  }

  get projectName() {
    return this.userContext.project.name;
  }

  @task
  *removeUsers(users, e) {
    e.preventDefault();

    const { onClose, service, projectPolicy, refreshRoute } = this.args;

    try {
      yield service.delete.task.perform(
        users,
        projectPolicy,
        this.userContext.project
      );

      // We should use the router service for this once this PR is merged.
      // via: https://github.com/emberjs/rfcs/issues/592
      let route = getOwner(this).lookup(
        `route:${refreshRoute || this.router.currentRouteName}`
      );

      if (onClose && typeof onClose === 'function') {
        onClose('delete');
      }

      this.onRemoveUsersSuccess(users);

      return route.refresh();
    } catch (err) {
      this.onRemoveUsersError(users);
    }
  }

  onRemoveUsersSuccess(users) {
    this.flashMessages.success(
      this.intl.t('components.page.access-control.users.remove.success', {
        numUsers: users.length,
      })
    );
  }

  onRemoveUsersError(users) {
    this.flashMessages.error(
      this.intl.t('components.page.access-control.users.remove.error.title', {
        numUsers: users.length,
      }),
      {
        actionText: this.intl.t(
          'components.page.access-control.users.remove.error.action'
        ),
        content: this.intl.t(
          'components.page.access-control.users.remove.error.content',
          {
            numUsers: users.length,
          }
        ),
        onAction: (close) => {
          window.location.href =
            'https://support.hashicorp.com/hc/en-us/requests/new';
          return close();
        },
      }
    );

    return;
  }
}
