import type Logger from './logger';

// https://datatracker.ietf.org/doc/html/rfc6749#section-5.1
export interface TokenResponse {
	access_token: string;
	token_type: string;
	id_token: string; // not included in the spec, but required for our flows
	expires_in: number;
	refresh_token?: string;
	scope?: string;
}

export class OIDCToken {
	public raw: string;
	public header: any;
	public claims: any;
	public signature: string;

	constructor(id_token: string) {
		const parts = id_token.split(/\./);

		this.raw = id_token;
		this.header = base64URLDecodeToObject(parts[0]);
		this.claims = base64URLDecodeToObject(parts[1]);
		this.signature = parts[2];
	}
}

// https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
export function sha256(plain?: string) {
	const encoder = new TextEncoder();
	const data = encoder.encode(plain);
	return window.crypto.subtle.digest('SHA-256', data);
}

// https://datatracker.ietf.org/doc/html/rfc4648#section-5
export function base64urlencode(input: ArrayBuffer) {
	let str = '';
	const bytes = new Uint8Array(input);
	const len = bytes.byteLength;
	for (let i = 0; i < len; i++) {
		str += String.fromCharCode(bytes[i]);
	}
	return window
		.btoa(str)
		.replace(/\+/g, '-')
		.replace(/\//g, '_')
		.replace(/=+$/, '');
}

function base64urldecode(input: string) {
	const padding = '='.repeat((4 - (input.length % 4)) % 4);
	const base64 = (input + padding).replace(/-/g, '+').replace(/_/g, '/');

	const rawData = window.atob(base64);
	const outputBytes = new Uint8Array(rawData.length);

	for (let i = 0; i < rawData.length; ++i) {
		outputBytes[i] = rawData.charCodeAt(i);
	}

	return outputBytes;
}

function decodeUint8Array(array: Uint8Array) {
	return new TextDecoder().decode(array);
}

function base64URLDecodeToObject(input: string) {
	const decoded = base64urldecode(input);
	return JSON.parse(decodeUint8Array(decoded));
}

export async function appendHiddenIFrame() {
	const hiddenIFrame = window.document.createElement('iframe');
	hiddenIFrame.setAttribute('id', 'auth_iframe');
	hiddenIFrame.setAttribute('width', '0');
	hiddenIFrame.setAttribute('height', '0');
	hiddenIFrame.style.display = 'none';
	window.document.body.appendChild(hiddenIFrame);
	return hiddenIFrame;
}

interface MakeFetchRequestOptions {
	accessToken?: string;
	body?: RequestInit['body'];
	method: Uppercase<'get' | 'post' | 'put' | 'patch' | 'delete'>;
	origin: string;
}

// takes a url, a MakeFetchRequestOptions object and a logger
export async function makeFetchRequest(
	requestUrl: string,
	options: MakeFetchRequestOptions,
	logger: Logger
) {
	if (!requestUrl) {
		logger.error('a url must be provided for the fetch request');
	}

	const { method, origin } = options;

	const fetchData: any = await {
		method: method,
		headers: new Headers({
			Origin: origin,
		}),
		mode: 'cors',
	};

	if (options.accessToken) {
		await fetchData.headers.append(
			'Authorization',
			`Bearer ${options.accessToken}`
		);
	}

	if (options.body) {
		await fetchData.headers.append(
			'Content-Type',
			'application/x-www-form-urlencoded'
		);
		fetchData.body = options.body;
	}

	return await fetch(requestUrl!, fetchData);
}

export async function verifyProviderKeySignature(
	providerKey: any,
	token: OIDCToken
) {
	const keyData = {
		kty: 'RSA',
		e: providerKey.e,
		n: providerKey.n,
		alg: 'RS256',
		ext: true,
	};

	const algo = {
		name: 'RSASSA-PKCS1-v1_5',
		hash: { name: 'SHA-256' },
	};

	const providerKeyImported = await crypto.subtle.importKey(
		'jwk',
		keyData,
		algo,
		false,
		['verify']
	);

	const tokenSignatureIsValid = await window.crypto.subtle.verify(
		{ name: 'RSASSA-PKCS1-v1_5' },
		providerKeyImported,
		base64urldecode(token.signature),
		new TextEncoder().encode(token.raw.split(/\./).slice(0, 2).join('.'))
	);

	// Check if the token signature was verified.
	if (!tokenSignatureIsValid) {
		throw new Error('id_token signature is not valid with any provider keys');
	}
}

// This is not a pure function, but do we still want to include this refactor?
export function clearLocalStorage() {
	try {
		localStorage.removeItem('code');
		localStorage.removeItem('state');
		localStorage.removeItem('provider_keys');
	} catch {
		throw new Error('problem clearing local storage');
	}
}
