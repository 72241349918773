/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { variation } from 'ember-launch-darkly';

/**
 *
 * `PageHvnsListZeroState` renders a group of helper content when a user does not have any HVNs.
 *  This includes a Get started stepper, learn more and a video.
 *
 *
 * ```
 * <Page::Hvns::List::ZeroState
 *   @model={{model}}
 * />
 * ```
 *
 * @class PageHvnsListZeroState
 *
 */
/**
 *
 * `model` is an object that contains the project and networks
 * @argument model;
 * @type {Object}
 *
 */
export default class PageHvnsListZeroStateComponent extends Component {
  @service analytics;

  // TODO: [TCE-2007] Remove HVN activation A/B test analytics instrumentation.
  @action captureAnalyticsEventABTestHvnCreateIntentZeroState() {
    const abTestVariation = variation('hcp-ui-ab-test-hvn-activation-create');

    this.analytics.trackEvent(
      'external_ui_hvn_activation_create_intent_button_clicked',
      { abTestVariation, buttonLocation: 'zero-state-content' }
    );
  }
}
