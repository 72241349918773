/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { assert } from '@ember/debug';

import cloudIamRbacRoles, {
  ROLE_KEY_VIEWER,
} from 'core/utils/cloud-iam-rbac-roles';
import { TYPE_ORGANIZATION } from 'common/utils/cloud-resource-types';
/**
 *
 * `IamInvitationsFormBasicRoleSelect` renders a toggle and a select and exposes
 * a callback function to keep track of a value for selecting a basic role for
 * a user. This only renders basic roles and is not intended for use with
 * fine-grained access control.
 *
 *
 * ```
 * <Iam::Invitations::Form::BasicRoleSelect
 *   @onChange={{this.onChange}}
 * />
 * ```
 *
 * @class IamInvitationsFormBasicRoleSelect
 *
 */

const cloudIamRbacRoleValues = cloudIamRbacRoles
  .filter((role) => {
    // Find all organization role values.
    return role.type === TYPE_ORGANIZATION;
  })
  .map((role) => {
    // Get only the values.
    return role.value;
  });

export default class IamInvitationsFormBasicRoleSelectComponent extends Component {
  get id() {
    return guidFor(this);
  }

  get roleId() {
    const { roleId } = this.args;

    if (roleId) {
      assert(
        `@roleId for "Iam::Invitations::Form::BasicRoleSelect" must one of the following: ${cloudIamRbacRoleValues.join(
          ', '
        )}, received ${roleId}`,
        cloudIamRbacRoleValues.includes(roleId)
      );
    }

    return roleId;
  }

  get defaultRoleValue() {
    const { defaultRoleValue = ROLE_KEY_VIEWER } = this.args;
    return defaultRoleValue;
  }

  @action
  onToggleChange(e) {
    const checked = e.target.checked;
    if (checked) {
      this.onChange(this.defaultRoleValue);
    } else {
      this.onChange(null);
    }
  }

  @action
  onSelectChange(e) {
    this.onChange(e.target.value);
  }

  /**
   * The callback function when either the toggle or the select changes.
   * @argument onChange
   * @type {Function}
   */
  @action
  onChange(roleId) {
    let { onChange } = this.args;

    const role =
      roleId &&
      cloudIamRbacRoles.find((role) => {
        return role.value === roleId;
      });

    if (typeof onChange === 'function') {
      if (!role) {
        return onChange({ value: undefined });
      }

      return onChange({ value: roleId });
    }
  }
}
