/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';

/**
 *
 * Displays the list of projects for an organization.
 *
 *
 * ```
 * <Page::Projects::List
 *   @organization={{this.model.organization}}
 *   @projects={{array project1 project2}}
 * />
 * ```
 *
 * @class PageProjectsList
 *
 */

/**
 * The current organization.
 * @argument organization
 * @type {Object}
 */

/**
 * The current organization's projects list.
 * @argument projects
 * @type {Array}
 */

export default class PageProjectsListComponent extends Component {
  @service router;

  @action
  async handleOnPageSizeChanged(pageSize) {
    const routeQueryParams = this?.router?.currentRoute?.queryParams ?? {};
    const queryParams = {
      ...routeQueryParams,
      size: pageSize,
    };
    await this.router.transitionTo(this.router.currentRouteName, {
      queryParams,
    });
  }

  get paginationQueryFunction() {
    const { nextPageToken, previousPageToken } = this.args?.pagination ?? {};

    return (page) => {
      if (page === 'next') {
        return {
          next: nextPageToken,
          prev: undefined,
        };
      } else {
        return {
          prev: previousPageToken,
          next: undefined,
        };
      }
    };
  }
}
