/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import IamPolicy from 'core/utils/iam-policy';
import { MEMBER_TYPE_SERVICE_PRINCIPAL } from 'core/helpers/rbac-member-types';

export const MAX_UPDATE_POLICY_RETRIES = 3;

/**
 *
 * `PageServicePrincipalsOrganizationCreate` form to create a service principal and assign a role.
 *
 *
 * ```
 * <Page::ServicePrincipals::Create
 *   @organization={{...}}
 * />
 * ```
 *
 * @class PageServicePrincipalsOrganizationCreate
 *
 */

export default class PageServicePrincipalsOrganizationCreateComponent extends Component {
  /**
   * @argument organization
   * @type {Object}
   * @required
   */

  @service api;
  @service intl;
  @service flashMessages;
  @service router;

  @task
  *save(form) {
    let orgId = this.args.organization.id;
    let payload = {
      name: form.fields.name,
    };

    let createResponse =
      yield this.api.servicePrincipal.servicePrincipalsServiceCreateOrganizationServicePrincipal(
        orgId,
        payload
      );

    // If an error is thrown it will be handled by the WithError component.
    // If we simply fail to update the IAM policy we will notify the user and retrieve an updated list.
    let { updated } = yield* this.addServicePrincipalToPolicy(
      orgId,
      createResponse.servicePrincipal.id,
      form.fields.role,
      MAX_UPDATE_POLICY_RETRIES
    );

    if (updated) {
      this.flashMessages.success(
        this.intl.t(
          'components.page.access-control.service-principals.create.success.title'
        )
      );
      return this.router.transitionTo(
        'cloud.access-control.service-principals.detail',
        createResponse.servicePrincipal.id
      );
    } else {
      let warningTitle = this.intl.t(
        'components.page.access-control.service-principals.create.warning.iam-policy-conflict-title'
      );
      this.flashMessages.warning(warningTitle, {
        content: this.intl.t(
          'components.page.access-control.service-principals.create.warning.iam-policy-conflict-content'
        ),
      });
    }

    return this.router.transitionTo(
      'cloud.access-control.service-principals.list'
    );
  }

  // addServicePrincipalToPolicy adds a new member binding to a policy and attempts
  // update the policy. If the policy has been updated concurrently it will retry up to maxRetries.
  *addServicePrincipalToPolicy(orgId, servicePrincipalId, roleId, maxRetries) {
    for (let i = 0; i < maxRetries; i++) {
      try {
        // Get the latest policy in order to get the latest etag to protect
        // from concurrent updates.
        let { policy } =
          yield this.api.resourceManager.org.organizationServiceGetIamPolicy(
            orgId
          );

        // Given the latest policy, attempt to add the service principal with the specified role.
        let policyToUpdate = new IamPolicy(policy);
        policyToUpdate.addMember(
          servicePrincipalId,
          MEMBER_TYPE_SERVICE_PRINCIPAL.value,
          roleId
        );

        let updatedPolicy = policyToUpdate.get();
        let policyPayload = {
          policy: {
            ...updatedPolicy,
          },
        };

        return {
          updated: true,
          response:
            yield this.api.resourceManager.org.organizationServiceSetIamPolicy(
              orgId,
              policyPayload
            ),
        };
      } catch (e) {
        // A conflict indicates a concurrent update.
        // We may just need try again.
        if (e.status === 409) {
          continue;
        }

        // Any other error is unexpected and should be rethrown.
        throw e;
      }
    }

    return {
      updated: false,
    };
  }
}
