/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

/**
 *
 * An Actions dropdown tied to the MutationContainer's context and is yielded to its parent.
 * Conditionally renders Interactive components for every implemented mutation method (API, CRUD).
 *
 * ```
 * <Page::Projects::Users::MutationContainer::Actions
 *   @model={{this.resource}}
 *   @listItem={{this.isList}}
 *   @showModalByType={{this.showModalByType}}
 *   @setResourceToMutate={{this.setResourceToMutate}}
 *   @mutations={{this.resourceScopedService}}
 * />
 * ```
 *
 * @class PageProjectsUsersMutationContainerActions
 *
 */

export default class PageProjectsUsersMutationContainerActionsComponent extends Component {
  /**
   * Resource object
   * @argument model
   * @type {Object}
   */
  /**
   * Modal visibility state map setter function
   * @argument showModalByType
   * @type {Function}
   */
  /**
   * Closure action used to set the current contextual resource targeted for mutation
   * @argument setResourceToMutate
   * @type {Function}
   */
  /**
   * A resource scoped service that defines and handles all resource type mutation actions
   * @argument mutations
   * @type {Object}
   */
}
