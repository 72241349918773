/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { action } from '@ember/object';
import {
  AWS_DISCLOSURE_GUIDES_PEERING,
  AWS_DISCLOSURE_GUIDES_TGW_ATTACHMENTS,
  AWS_CONNECTIONS,
  AWS_CONNECTIONS_INFO,
} from 'networks-common/utils/aws-connections';
import { HVN_ROUTE_NAMES } from 'networks-common/utils/network-routes';
import { TYPE_NETWORK_ROUTE } from 'common/utils/cloud-resource-types';
import { ROUTE_SUBMITTED } from 'core/utils/consts/analytics-events/platform';
import { variation } from 'ember-launch-darkly';
import { isAzureProviderNetwork } from 'networks-common/utils/networks';
import { HashicorpCloudNetwork20200907AzureHopType } from '@clients/cloud-network';
/**
 *
 * `PageHvnRoutesCreate` renders the HVN Routes Create page.
 *
 *
 * ```
 * <Page::HvnRoutes::Create
 *   @model={{@model}}
 * />
 * ```
 *
 * @class PageHvnRoutesCreate
 *
 */

/**
 *
 * `model` will be a model containing the network and network connections (Peering or TGW);
 * @argument model;
 * @type {Object}
 *
 */

export default class PageHvnRoutesCreateComponent extends Component {
  azureHopType = HashicorpCloudNetwork20200907AzureHopType;

  @service analytics;
  @service api;
  @service flashMessages;
  @service intl;
  @service router;

  @tracked errors;
  @tracked id;
  @tracked cidr;
  @tracked routeName = this.router.currentRouteName;
  @tracked connectionTarget = null;
  @tracked useHubSpoke = false;
  @tracked nextHopType = this.azureHopType.APPLIANCE;
  @tracked nextHopIP;

  hvnRouteNames = HVN_ROUTE_NAMES;
  connectionsInfo = AWS_CONNECTIONS_INFO;

  checkElementValidity(element) {
    return element !== '';
  }

  get resourceType() {
    return TYPE_NETWORK_ROUTE;
  }

  get connection() {
    //Check the model to see if a connection is in the model
    let model = this.args.model;
    let connection = null;
    AWS_CONNECTIONS.filter((item) => {
      if (item in model) {
        return (connection = {
          id: model[item]?.id,
          ...AWS_CONNECTIONS_INFO[item],
        });
      }
    });
    return connection;
  }

  get disclosureGuideCidr() {
    if (this.connectionTarget) {
      if (this.connectionTarget.type === AWS_CONNECTIONS_INFO.peering.type) {
        return AWS_DISCLOSURE_GUIDES_PEERING.cidrBlock;
      }
      if (
        this.connectionTarget.type === AWS_CONNECTIONS_INFO.tgwAttachment.type
      ) {
        return AWS_DISCLOSURE_GUIDES_TGW_ATTACHMENTS.cidrBlock;
      }
    }
    if (this.connection.type) {
      if (this.connection.type === AWS_CONNECTIONS_INFO.peering.type) {
        return AWS_DISCLOSURE_GUIDES_PEERING.cidrBlock;
      }
      if (this.connection.type === AWS_CONNECTIONS_INFO.tgwAttachment.type) {
        return AWS_DISCLOSURE_GUIDES_TGW_ATTACHMENTS.cidrBlock;
      }
    }
    return null;
  }

  get isAzure() {
    return isAzureProviderNetwork(this.args.model.network);
  }

  get showHubSpokeConfig() {
    return (
      variation('hcp-ui-azure-hub-spoke') &&
      this.isAzure &&
      this.isHubSpokePeering
    );
  }

  get isAzureRouteConfigured() {
    return this.args.model.routes?.some((a) => a.azureRoute);
  }

  get isHubSpokePeering() {
    //model differs between network details -create route and peering connections-create hvn route
    const peering = this.args.model.peerings?.length
      ? this.args.model.peerings[0]
      : this.args.model.peering;
    const { useRemoteGateways = false, allowForwardedTraffic = false } =
      peering?.target?.azureTarget ?? {};
    return useRemoteGateways || allowForwardedTraffic;
  }
  get showHubSpokeWarning() {
    return !this.isAzureRouteConfigured && this.isHubSpokePeering;
  }

  get isDisabled() {
    if (
      this.connectionTargets?.peeringTargets.length === 0 &&
      this.connectionTargets?.tgwAttachmentTargets.length === 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  get connectionTargets() {
    //This is needed for the select options if creating a Route from the Routes tab
    let { tgwAttachments, peerings } = this.args.model;
    if (tgwAttachments || peerings) {
      let peeringTargets = peerings.filter((peering) => {
        if (!peering.target?.hvnTarget) {
          return (
            peering.state === 'ACTIVE' || peering.state === 'PENDING_ACCEPTANCE'
          );
        }
      });

      let tgwAttachmentTargets = tgwAttachments.filter(
        (tgw) => tgw.state === 'ACTIVE' || tgw.state === 'PENDING_ACCEPTANCE'
      );
      peeringTargets;
      tgwAttachmentTargets;
      return { peeringTargets, tgwAttachmentTargets };
    }
    return null;
  }

  @action toggleUseHubSpoke() {
    this.useHubSpoke = !this.useHubSpoke;
  }

  @action updateConnectionTarget(event) {
    let selectedIndex = event.target.selectedIndex;
    let optGroup = event.target[selectedIndex].parentNode;
    if (optGroup.id === AWS_CONNECTIONS_INFO.tgwAttachment.type) {
      this.connectionTarget = {
        id: event.target.value,
        type: optGroup.id,
        ...AWS_CONNECTIONS_INFO.tgwAttachment,
      };
    }
    if (optGroup.id === AWS_CONNECTIONS_INFO.peering.type) {
      this.connectionTarget = {
        id: event.target.value,
        type: optGroup.id,
        ...AWS_CONNECTIONS_INFO.peering,
      };
    }
  }

  @task
  *save(evt) {
    // don't submit the form
    evt.preventDefault();
    let {
      location: { organizationId, projectId },
      id: hvnId,
    } = this.args.model.network;
    let { location } = this.args.model.network;
    let { cidr, id } = this;
    let payload;
    let target;
    let configPath;
    if (this.connection) {
      //target needed to create HVN Route
      target = {
        hvnConnection: {
          type: this.connection.type,
          id: this.connection.id,
          location: location,
        },
      };
      //part of ember route path needed for View Configuration instructions link in success flash message
      configPath = this.connection.configPath;
    }
    if (this.connectionTarget) {
      //target needed to create HVN Route
      target = {
        hvnConnection: {
          type: this.connectionTarget.type,
          id: this.connectionTarget.id,
          location: location,
        },
      };
      //part of ember route path needed for View Configuration instructions link in success flash message
      configPath = this.connectionTarget.configPath;
    }

    payload = {
      id: id,
      destination: cidr,
      target: target,
      azureRoute: this.useHubSpoke
        ? {
            nextHopType: this.nextHopType,
            nextHopIpAddress: this.nextHopIP,
          }
        : undefined,
    };

    this.analytics.trackEvent(ROUTE_SUBMITTED, payload);

    yield this.api.network.createHVNRoute(
      organizationId,
      projectId,
      hvnId,
      payload
    );
    let successTitle = this.intl.t(
      'components.page.hvn-routes.create.success.title'
    );
    this.flashMessages.success(successTitle, {
      content: this.intl.t(
        'components.page.hvn-routes.create.success.message',
        {
          htmlSafe: true,
          configurationLink: `/orgs/${organizationId}/projects/${projectId}/hvns/${hvnId}/${configPath}/${target.hvnConnection.id}/configuration-instructions`,
        }
      ),
    });
    //if creating an HVN Route from a connections (TGW or Peering) Route table, take the user back to that connection detail page
    if (this.connection) {
      yield this.router
        .transitionTo(
          this.connection.detail,
          organizationId,
          projectId,
          hvnId,
          target.hvnConnection.id
        )
        .followRedirects();
    } else {
      yield this.router
        .transitionTo(
          'cloud.orgs.detail.projects.detail.hvns.detail.hvn-routes.index',
          organizationId,
          projectId,
          hvnId
        )
        .followRedirects();
    }
  }
}
