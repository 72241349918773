/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

/**
 *
 * `AuthButton` component is used to start the authentication cycle.
 * Triggering the button will call the session service's `authenticate` method.
 *
 *
 * ```
 * <AuthButton @variant='primary' @text="Sign up now!" />
 * ```
 *
 * @class AuthButton
 *
 *
 */

export default class AuthButtonComponent extends Component {
  @service session;
  @service config;

  /**
   *
   * `variant` is an optional argument that will be passed along to the `<Hds::Button>` component.
   * @argument variant;
   * @type {String}
   *
   */

  /**
   *
   * `text` is an optional argument that is used as `@text` for the `<Hds::Button>` component
   * (if no argument is passed, a default string is used).
   * @argument text;
   * @type {String}
   *
   */

  /**
   *
   * `extraAuthArgs` is an optional argument that will be passed along as arguments to the authenticated method.
   * @argument extraAuthArgs;
   * @type {Object}
   *
   */

  /**
   *
   * `authenticator` optionally allows the user to override the default authenticator of 'cloud-idp'. Doing so will require a corresponding imlementation in `app/authenticators/<authenticator>.js`.
   * @argument authenticator;
   * @type {String}
   *
   */
  authenticator = 'cloud-idp';

  @action
  async authenticate() {
    let { config } = this;

    if (config.environment === 'development' && window.mirage) {
      await this.session.authenticate('authenticator:local-dev');
      return;
    }

    let provider = this.args.authenticator || this.authenticator;

    await this.session.authenticate(
      `authenticator:${provider}`,
      this.args.extraAuthArgs
    );
  }
}
