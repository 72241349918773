import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

export default class CloudAccessControlIndexRoute extends Route {
  @service abilities;
  @service router;

  async redirect() {
    const { isProjectContext } = this.modelFor('cloud.access-control');
    const isOrganizationContext = !isProjectContext;
    const hasIamProjectPolicyPermission = this.abilities.can(
      'get-iam-policy in project'
    );
    const hasIamOrganizationPermission = this.abilities.can(
      'get-iam-policy in organization'
    );

    if (
      isProjectContext &&
      variation('hcp-ui-role-assignments-engine-projects') &&
      hasIamProjectPolicyPermission
    ) {
      this.router.transitionTo('cloud.access-control.role-assignments');
      return;
    }

    if (
      isOrganizationContext &&
      variation('hcp-ui-role-assignments-engine-organizations') &&
      hasIamOrganizationPermission
    ) {
      this.router.transitionTo('cloud.access-control.role-assignments');
      return;
    }

    this.router.transitionTo('cloud.access-control.users.list');
  }
}
