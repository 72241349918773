/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { service } from '@ember/service';
import { FormValidationError } from 'core/utils/form-validation';

export default class ManageMfaDisableComponent extends Component {
  @service api;
  @service flashMessages;
  @service intl;
  @service mfa;

  @tracked allowDisable = false;

  redirectForDisableMFAToken = task(
    {
      drop: true,
    },
    async (evt) => {
      evt.preventDefault();
      if (this.allowDisable === false) {
        //TODO fix this and show it in ModalConfirm
        throw new FormValidationError(`Please enter DISABLE to disable MFA`);
      }
      let mfaToken;
      try {
        mfaToken = await this.mfa.getMFAToken(this.args.onCancel);
        await this.api.profile.profileServiceDisableMFA({
          mfaToken,
        });
        this.flashMessages.success(
          this.intl.t(
            'components.page.account-settings.security.mfaDisable.flashMessage.success'
          )
        );
        this.args.onStatusChange();
      } catch (err) {
        // if there's a token it was disable that errored
        // otherwise show an error for token generation
        let messageKey = mfaToken
          ? 'components.page.account-settings.security.mfaDisable.flashMessage.error'
          : 'components.page.account-settings.security.mfa.tokenError';

        this.flashMessages.error(this.intl.t(messageKey));
        this.args.onCancel();
      }
    }
  );
}
