import { helper } from '@ember/component/helper';
import { TYPE_CONSUL, TYPE_VAULT } from 'common/utils/cloud-resource-types';

/** Derives a Peering federation locked status by checking if the Peering has an hvnTarget
 * and if its parent network has consul cluster dependencies
 *
 * Example usage as an Ember template helper:
 * ```handlebars
 * {{hvn-hvn-peering-federation-locked-status @model.dependencies peering}}
 * ```
 *
 * @param {Array.<Object>} params - 0th param should be a Network Dependencies
 * @returns {(true| null)} - value `null` means the peering is not federated and it is not locked
 */
export default helper(function hvnHvnPeeringFederationLockedStatus([
  networkDependencies = [],
  peering,
  hvnTargetDependencies = [],
] = []) {
  networkDependencies = networkDependencies.filter((dependency) => {
    dependency.type === TYPE_CONSUL || dependency.type === TYPE_VAULT;
  });
  let peeringTarget = peering?.target?.hvnTarget?.hvn;
  if (peeringTarget) {
    hvnTargetDependencies = hvnTargetDependencies.filter((dependency) => {
      dependency.type === TYPE_CONSUL || dependency.type === TYPE_VAULT;
    });
  }

  return peeringTarget &&
    networkDependencies.length &&
    hvnTargetDependencies.length
    ? true
    : false;
});
