/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

/**
 *
 * `ServicePrincipalWorkloadIdentityProviders::Cards` renders the
 *  Project Level Service Principal Workload Identity Provider cards.
 *  It renders provider cards for a given provider specified in providerCardsTranslations and will include a providers HDS flight icon, title,
 *  description, and button that takes users to the workload identity provider documentation.
 *  The last card rendered will provide users with a link to request a provider not supported.
 *
 *
 * ```
 * <ServicePrincipal::WorkloadIdentityProviders::Cards />
 * ```
 *
 * @class ServicePrincipalWorkloadIdentityProviders::Cards
 *
 */

export default class ServicePrincipalWorkloadIdentityProvidersCardsComponent extends Component {
  @service intl;

  get providerCardsTranslations() {
    // Order of cards matter - gcp represents google cloud provider
    let providers = [
      'aws',
      'azure',
      'github',
      'gitlab',
      'gcp',
      'openid',
      'request',
    ];
    return providers.map((provider) => {
      let container = {};
      if (provider === 'request') {
        // 'request' represents a request for a provider not listed - it does not have a logo or title
        container['description'] = this.intl.t(
          `components.page.access-control.service-principals.details.workload-identity-providers.cards.${provider}.description`
        );
        container['requestProvider'] = this.intl.t(
          `components.page.access-control.service-principals.details.workload-identity-providers.cards.button.label.request-provider`
        );
        container['href'] = this.intl.t(
          `components.page.access-control.service-principals.details.workload-identity-providers.cards.${provider}.href`
        );
      } else {
        container['logo'] = `${provider}-color`;
        container['title'] = this.intl.t(
          `components.page.access-control.service-principals.details.workload-identity-providers.cards.${provider}.title`
        );
        container['description'] = this.intl.t(
          `components.page.access-control.service-principals.details.workload-identity-providers.cards.${provider}.description`
        );
        container['viewDocs'] = this.intl.t(
          `components.page.access-control.service-principals.details.workload-identity-providers.cards.button.label.view-docs`
        );
        container['href'] = this.intl.t(
          `components.page.access-control.service-principals.details.workload-identity-providers.cards.${provider}.href`
        ); // TODO update translations href's https://hashicorp.atlassian.net/browse/HCPIE-1456
      }
      return container;
    });
  }
}
