/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import {
  isAwsProviderNetwork,
  isAzureProviderNetwork,
} from 'networks-common/utils/networks';
/**
 *
 * `PeeringConfigurationInstructions` renders the HVN Peerings Configuration instructions page.
 *
 *
 * ```
 * <Page::HvnPeerings::ConfigurationInstructions
 *   @model={{model}}
 *   @tab={{tab}}
 *   @product={{product}}
 * />
 * ```
 *
 * @class PeeringConfigurationInstructions
 *
 */
/**
 *
 * `tab` will be the selected tab;
 * @argument tab;
 * @type {String}
 *
 */
/**
 *
 * `product` such as consul or vault;
 * @argument product;
 * @type {String}
 *
 */
/**
 *
 * `model` will be a model containing the peering detail;
 * @argument model;
 * @type {Object}
 *
 */
export default class PagePeeringConfigurationInstructions extends Component {
  get isAws() {
    return isAwsProviderNetwork(this.args.model.network);
  }
  get isAzure() {
    return isAzureProviderNetwork(this.args.model.network);
  }
}
