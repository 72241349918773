import { inject as service } from '@ember/service';
import Route from '@ember/routing/route';
import * as Sentry from '@sentry/ember';

export default class AcceptInvitationRoute extends Route {
  @service router;
  @service api;

  org_name = '';

  async beforeModel() {
    let { invitation_token } = this.paramsFor(this.routeName);

    await this.api.invitation
      .invitationsServiceGetOrganizationNameByInvitationToken(invitation_token)
      .then(({ organizationName }) => {
        this.org_name = organizationName;
      })
      .catch((e) => {
        Sentry.captureException(e);
        this.router.transitionTo('sign-in');
      });

    if (!invitation_token || !this.org_name) {
      this.router.transitionTo('sign-in');
    }
  }

  async model() {
    let { invitation_token: invitationToken } = this.paramsFor(this.routeName);
    return {
      invitationToken,
      organizationName: this.org_name,
    };
  }
}
