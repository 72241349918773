/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { isPresent } from '@ember/utils';

import { AWS_DISCLOSURE_GUIDES_TGW_ATTACHMENTS } from 'networks-common/utils/aws-connections';
import {
  CONTENT_TABS_LABEL_TERMINAL,
  CONTENT_TABS_LABEL_WEB_CONSOLE,
  PRODUCT_CONSUL,
  PRODUCT_VAULT,
} from 'networks-common/utils/content-tabs';
import templateString from 'core/utils/template-string';

/**
 *
 * `PageHvnTransitGatewayAttachmentsConfigurationInstructions` renders the HVN TGW attachments Configuration Instructions page.
 *
 *
 * ```
 * <Page::HvnTransitGatewayAttachments::ConfigurationInstructions
 *   @model={{model}}
 *   @tab={{tab}}
 *   @product={{product}}
 * />
 * ```
 *
 * @class PageHvnTransitGatewayAttachmentsConfigurationInstructions
 *
 */
/**
 *
 * `tab` will be the selected tab;
 * @argument tab;
 * @type {String}
 *
 */
/**
 *
 * `product` such as consul or vault;
 * @argument product;
 * @type {String}
 *
 */
/**
 *
 * `model` will be a model containing the attachment detail;
 * @argument model;
 * @type {Object}
 *
 */

let consulIngress = templateString`aws ec2 --region ${'region'} authorize-security-group-ingress --group-id ${'groupId'} --ip-permissions
IpProtocol=tcp,FromPort=8301,ToPort=8301,IpRanges='[{CidrIp=${'cidrBlock'}}]'
IpProtocol=udp,FromPort=8301,ToPort=8301,IpRanges='[{CidrIp=${'cidrBlock'}}]'
IpProtocol=tcp,FromPort=8301,ToPort=8301,UserIdGroupPairs='[{GroupId=${'groupId'}}]'
IpProtocol=udp,FromPort=8301,ToPort=8301,UserIdGroupPairs='[{GroupId=${'groupId'}}]'`;

let consulEgressAgentless = templateString`aws ec2 --region ${'region'} authorize-security-group-egress --group-id ${'groupId'} --ip-permissions
IpProtocol=tcp,FromPort=8300,ToPort=8300,IpRanges='[{CidrIp=${'cidrBlock'}}]'
IpProtocol=tcp,FromPort=8301,ToPort=8301,IpRanges='[{CidrIp=${'cidrBlock'}}]'
IpProtocol=udp,FromPort=8301,ToPort=8301,IpRanges='[{CidrIp=${'cidrBlock'}}]'
IpProtocol=tcp,FromPort=8301,ToPort=8301,UserIdGroupPairs='[{GroupId=${'groupId'}}]'
IpProtocol=udp,FromPort=8301,ToPort=8301,UserIdGroupPairs='[{GroupId=${'groupId'}}]'
IpProtocol=tcp,FromPort=80,ToPort=80,IpRanges='[{CidrIp=${'cidrBlock'}}]'
IpProtocol=tcp,FromPort=443,ToPort=443,IpRanges='[{CidrIp=${'cidrBlock'}}]'
IpProtocol=tcp,FromPort=8502,ToPort=8502,IpRanges='[{CidrIp=${'cidrBlock'}}]'`;

let vaultTemplate = templateString`aws ec2 --region ${'region'} authorize-security-group-egress --group-id ${'groupId_flag'} --ip-permissions
IpProtocol=tcp,FromPort=8200,ToPort=8200,IpRanges='[{CidrIp=${'cidrIp_tcp_port8200'}}]'`;

let createRouteTemplate = templateString`aws ec2 --region ${'region'} create-route --route-table-id ${'routeTableId'} --destination-cidr-block ${'cidrBlock'} --transit-gateway-id ${'tgwId'}`;

export default class PageHvnTransitGatewayAttachmentsConfigurationInstructionsComponent extends Component {
  @service router;

  tabs = {
    terminal: CONTENT_TABS_LABEL_TERMINAL,
    webConsole: CONTENT_TABS_LABEL_WEB_CONSOLE,
  };

  products = {
    consul: PRODUCT_CONSUL,
    vault: PRODUCT_VAULT,
  };

  disclosureGuides = AWS_DISCLOSURE_GUIDES_TGW_ATTACHMENTS;

  @tracked awsSecurityGroupId = '';
  @tracked awsRouteTableId = '';

  @action
  updateQueryParams(queryParams = {}) {
    return this.router.transitionTo(this.router.currentRouteName, {
      queryParams,
    });
  }

  get cidrBlock() {
    let { cidrBlock } = this.args.model.network;
    return cidrBlock;
  }

  get region() {
    let { region } = this.args.model.tgwAttachment.location.region;
    return region;
  }

  get createRouteCommand() {
    let routeTableId = '<TARGET_VPC_ROUTE_TABLE_ID>';
    if (isPresent(this.awsRouteTableId)) {
      routeTableId = this.awsRouteTableId;
    }
    let string = createRouteTemplate({
      region: this.region,
      cidrBlock: this.cidrBlock,
      routeTableId: routeTableId,
      tgwId: this.args.model.tgwAttachment.providerData.awsData.tgwId,
    });

    return string;
  }

  get consulAuthorizeSecurityGroupIngress() {
    let string = consulIngress({
      region: this.region,
      groupId: this.awsSecurityGroupId,
      cidrBlock: this.cidrBlock,
    });

    return string;
  }

  get consulAuthorizeSecurityGroupEgress() {
    let string = consulEgressAgentless({
      region: this.region,
      groupId: this.awsSecurityGroupId,
      cidrBlock: this.cidrBlock,
    });

    return string;
  }

  get vaultAuthorizeSecurityGroupIngress() {
    let awsSecurityGroupId = '<USERS_SG_ID>';
    if (isPresent(this.awsSecurityGroupId)) {
      awsSecurityGroupId = this.awsSecurityGroupId;
    }
    let string = vaultTemplate({
      region: this.region,
      groupId_flag: this.awsSecurityGroupId,
      cidrIp_tcp_port8200: this.cidrBlock,
      groupId_tcp_port8200: awsSecurityGroupId,
    });
    return string;
  }
}
