import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';

export default class VaultRadarTenantService extends Service {
  @service api;
  @service userContext;
  @service abilities;

  @tracked _tenantData = {};
  @tracked _error = null;
  @tracked _hasFetchedData = false;

  #lastFetchedProjectId = null;

  get tenantData() {
    return this._tenantData;
  }

  get error() {
    return this._error;
  }

  get loading() {
    return this.fetchTask.isRunning;
  }

  @dropTask
  *fetchTask() {
    const { organization, project } = this.userContext;

    // Return data if already fetched or cannot view vault radar
    if (
      project?.id === this.#lastFetchedProjectId ||
      this.abilities.cannot('view vault-radar')
    ) {
      return this._tenantData;
    }

    try {
      const tenantResponse = yield this.api.vaultRadar.tenant.getTenant(
        project.id,
        {
          location: {
            organizationId: organization.id,
          },
        }
      );

      this.#lastFetchedProjectId = project.id;
      this._hasFetchedData = true;
      this._tenantData = tenantResponse;
    } catch (error) {
      this._error = error;
    }

    return this._tenantData;
  }

  fetch() {
    return this.fetchTask.perform();
  }
}
