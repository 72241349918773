/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

/**
 *
 * `PageHvnsCardsHvnDetails` this renders the details of an HVN.
 *
 *
 * ```
 * <Page::Hvns::Cards::HvnDetails
 *   @network={{network}}
 * />
 * ```
 *
 * @class PageHvnsCardsHvnDetails
 *
 */

export default class PageHvnsCardsHvnDetailsComponent extends Component {
  /**
   * `network` is an HVN object
   * @argument network
   * @type {object}
   */
}
