/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { getOwner } from '@ember/owner';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import PolicyManager from 'manage-access/utils/policy-manager';

/**
 *
 * `PageGroupsProjectList` lists organization groups with a project level IAM policy role binding and their members
 *
 * ```
 * <Page::Groups::Project::List
 *   @groups={{@model.groups}}
 *   @project={{@model.project}}
 *   @projectGroupsToMembersListBindings={{@model.projectGroupsToMembersListBindings}}
 * />
 * ```
 *
 * @class PageGroupsProjectList
 *
 */

export default class PageGroupsProjectListComponent extends Component {
  /**
   * All of the groups at an organization level.
   * @argument groups
   * @type {Array.<Object>}
   */

  /**
   * The project object
   * @argument project
   * @type {Array.<Object>}
   */

  /**
   * Bindings of groups with a project level IAM policy binding to their group members list.
   * @argument projectGroupsToMembersListBindings
   * @type {Array.<Object>}
   */

  @service api;
  @service flashMessages;
  @service intl;
  @service router;

  @tracked showRemoveModal = false;
  @tracked groupToRemove = ''; // TODO: Don't initialize this as a String, it will become a Group object later, null might be better.

  /**
   * Removes a group binding from a policy and attempts to update the policy.
   * If the policy has been updated concurrently, it will retry up to maxRetries.
   */
  @task
  *remove() {
    const projectId = this.args.project.id;
    const resourceId = this.groupToRemove.resourceId;
    const maxRetries = 3;

    const { updated } = yield* this.removeGroupFromPolicy(
      projectId,
      resourceId,
      maxRetries
    );

    if (updated) {
      this.flashMessages.success(
        this.intl.t(
          `components.page.access-control.groups.remove.modal.success.title`
        ),
        {
          content: this.intl.t(
            `components.page.access-control.groups.remove.modal.success.content`,
            {
              groupName: this.groupToRemove.displayName,
              projectName: this.args.project.name,
              htmlSafe: true,
            }
          ),
        }
      );
      this.refreshRoute();
    } else {
      this.flashMessages.error(
        this.intl.t(
          `components.page.access-control.groups.remove.modal.error.title`
        ),
        {
          content: this.intl.t(
            `components.page.access-control.groups.remove.modal.error.content`,
            {
              groupName: this.groupToRemove.displayName,
              projectName: this.args.project.name,
              htmlSafe: true,
            }
          ),
        }
      );
    }
    this.resetRemoveGroup();
  }

  *removeGroupFromPolicy(projectId, resourceId, maxRetries) {
    for (let i = 0; i < maxRetries; i++) {
      try {
        // Get the latest policy in order to get the latest etag to protect
        // from concurrent updates.
        const { policy } =
          yield this.api.resourceManager.project.projectServiceGetIamPolicy(
            projectId
          );

        if (!policy) {
          throw new Error();
        }

        let policyToUpdate;
        let updatedPolicy;

        // Given the latest policy, attempt to remove the group
        policyToUpdate = new PolicyManager({ policy });
        const { memberType, roleIds = [] } =
          policyToUpdate.getRolesByMemberId(resourceId) ?? {};
        roleIds.forEach((roleId) => {
          policyToUpdate.removeMemberFromRole(
            { memberType, memberId: resourceId },
            [roleId]
          );
        });
        updatedPolicy = policyToUpdate.getRawPolicy();

        const policyPayload = {
          policy: {
            ...updatedPolicy,
          },
        };

        yield this.api.resourceManager.project.projectServiceSetIamPolicy(
          projectId,
          policyPayload
        );

        return {
          updated: true,
        };
      } catch (e) {
        // A conflict indicates a concurrent update.
        // We may just need try again.
        if (e.status === 409) {
          continue;
        }

        return {
          updated: false,
        };
      }
    }

    return {
      updated: false,
    };
  }

  @task
  *confirmRemoveGroup(group) {
    this.groupToRemove = group;
    this.showRemoveModal = true;
    yield;
  }

  @action
  resetRemoveGroup() {
    this.showRemoveModal = false;
  }

  @action
  refreshRoute() {
    let route = getOwner(this).lookup(
      `route:${this.router.currentRoute.parent.name}`
    );
    return route.refresh();
  }

  get hasGroupsInOrg() {
    return this.args.groups?.length;
  }
  get hasprojectGroupsToMembersListBindings() {
    return this.args.projectGroupsToMembersListBindings?.length;
  }
}
