import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { CREATE_ORIGIN_HCP_CLOUD } from 'hcp/utils/constants';

export default class CloudResourcesRoute extends Route {
  @service api;
  @service abilities;
  @service userContext;
  @service lastAccessedProject;
  @service currentUser;
  @service router;

  queryParams = {
    project_id: {
      refreshModel: true,
    },
  };

  async findLastAccessedProject(orgId) {
    const lastAccessedProject =
      await this.lastAccessedProject.getProject(orgId);
    if (!lastAccessedProject?.id) {
      return;
    }
    const { project } =
      await this.api.resourceManager.project.projectServiceGet(
        lastAccessedProject.id
      );
    return project;
  }

  async findFirstProject(orgId) {
    const { projects } =
      await this.api.resourceManager.project.projectServiceList(
        'ORGANIZATION',
        orgId
      );
    return projects[0];
  }

  /**
   * This method will attempt to pick the first org and the last used project from localstorage.
   * If the last used project is not available it will load the projects for the org and pick the first one.
   */

  async findDefaultProjectAndOrg() {
    const organization = this.currentUser.organizations[0];
    let project = await this.findLastAccessedProject(organization.id);
    if (!project) {
      project = await this.findFirstProject(organization.id);
    }
    return {
      organization,
      project,
    };
  }

  async createOrganization(transition) {
    const targetRoute = transition?.intent?.url;
    const queryParams = {
      queryParams: {
        redirectAfterCreate: targetRoute,
        orgCreateOrigin: CREATE_ORIGIN_HCP_CLOUD,
      },
    };
    if (targetRoute) {
      return this.router.transitionTo('cloud.orgs.create', queryParams);
    }

    return this.router.transitionTo('cloud.orgs.create');
  }

  async beforeModel(transition) {
    // If we have organizations, we don't need to create one
    const organizations = this.currentUser.organizations;
    if (organizations.length) {
      return;
    }

    // If the user has the feature flag enabled, we will redirect them to the create org page
    // Otherwise do nothing
    if (this.isOrgCreateEnabled) {
      return this.createOrganization(transition);
    }
  }

  async model({ project_id }) {
    if (!project_id) {
      // If project id is not present, find the default org and project
      const projectAndOrg = await this.findDefaultProjectAndOrg();
      await this.userContext.setOrganization(projectAndOrg.organization);
      await this.userContext.setProject(projectAndOrg.project);
      return projectAndOrg;
    }
    let { project } =
      await this.api.resourceManager.project.projectServiceGet(project_id);
    let { organization } =
      await this.api.resourceManager.org.organizationServiceGet(
        project.parent.id
      );
    await this.userContext.setOrganization(organization);
    await this.userContext.setProject(project);
    return {
      project,
      organization,
    };
  }

  get isOrgCreateEnabled() {
    let { organizations, user } = this.currentUser;
    return this.abilities.can('create organizations', organizations, {
      userId: user.id,
    });
  }
}
