export interface LoggerOptions {
	captureError?: (error: Error) => void;
	level?: LoggerLevels;
}

export type LoggerLevels = 'debug' | 'info' | 'default' | 'warn' | 'error';

export enum LoggerLevelOrder {
	debug,
	info,
	default,
	warn,
	error,
}

export function isError(err: unknown): err is Error {
	return err instanceof Error;
}

export default class Logger {
	public level;
	protected captureError;

	constructor({ level, captureError }: LoggerOptions = {}) {
		this.level = level ?? 'default';
		this.captureError = captureError;
	}

	public debug(message: any, ...optionalParams: any[]) {
		if (this.canLogAt('debug')) {
			console.debug(message, ...optionalParams);
		}
	}

	public info(message: any, ...optionalParams: any[]) {
		if (this.canLogAt('info')) {
			console.info(message, ...optionalParams);
		}
	}

	public log(message: any, ...optionalParams: any[]) {
		if (this.canLogAt('default')) {
			console.log(message, ...optionalParams);
		}
	}

	public warn(message: any, ...optionalParams: any[]) {
		if (this.canLogAt('warn')) {
			console.warn(message, ...optionalParams);
		}
	}

	public error(error: unknown) {
		if (!this.canLogAt('error')) return;

		if (isError(error)) {
			if (this.captureError) {
				this.captureError(error);
			}
			console.error(error, error.stack);
		} else {
			console.error(error);
		}
	}

	public canLogAt(level: LoggerLevels) {
		const loggerLevelThreshold = LoggerLevelOrder[this.level];
		const logAtLevel = LoggerLevelOrder[level];

		return logAtLevel >= loggerLevelThreshold;
	}
}
