/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import templateString from 'core/utils/template-string';
import { inject as service } from '@ember/service';

/**
 *
 * `PageHvnPeeringsConfigurationAccordionAwsCli` description here.
 *
 *
 * ```
 * <Page::HvnPeerings::ConfigurationAccordion::AwsCli
 *   @model={{@model}}
 *   @connectionMethod={{@connectionMethod}}
 *   @connection={{hash region=this.region cidrBlock=this.cidrBlock}}
 *   @products={{this.products}}
 *   @product={{@product}}
 * />
 * ```
 *
 * @class PageHvnPeeringsConfigurationAccordionAwsCli
 *
 */

let consulIngress = templateString`aws ec2 --region ${'region'} authorize-security-group-ingress --group-id ${'groupId'} --ip-permissions
 IpProtocol=tcp,FromPort=8301,ToPort=8301,IpRanges='[{CidrIp=${'cidrBlock'}}]'
 IpProtocol=udp,FromPort=8301,ToPort=8301,IpRanges='[{CidrIp=${'cidrBlock'}}]'
 IpProtocol=tcp,FromPort=8301,ToPort=8301,UserIdGroupPairs='[{GroupId=${'groupId'}}]'
 IpProtocol=udp,FromPort=8301,ToPort=8301,UserIdGroupPairs='[{GroupId=${'groupId'}}]'`;

let consulEgressAgentless = templateString`aws ec2 --region ${'region'} authorize-security-group-egress --group-id ${'groupId'} --ip-permissions
 IpProtocol=tcp,FromPort=8300,ToPort=8300,IpRanges='[{CidrIp=${'cidrBlock'}}]'
 IpProtocol=tcp,FromPort=8301,ToPort=8301,IpRanges='[{CidrIp=${'cidrBlock'}}]'
 IpProtocol=udp,FromPort=8301,ToPort=8301,IpRanges='[{CidrIp=${'cidrBlock'}}]'
 IpProtocol=tcp,FromPort=8301,ToPort=8301,UserIdGroupPairs='[{GroupId=${'groupId'}}]'
 IpProtocol=udp,FromPort=8301,ToPort=8301,UserIdGroupPairs='[{GroupId=${'groupId'}}]'
 IpProtocol=tcp,FromPort=80,ToPort=80,IpRanges='[{CidrIp=${'cidrBlock'}}]'
 IpProtocol=tcp,FromPort=443,ToPort=443,IpRanges='[{CidrIp=${'cidrBlock'}}]'
 IpProtocol=tcp,FromPort=8502,ToPort=8502,IpRanges='[{CidrIp=${'cidrBlock'}}]'`;

let vaultTemplate = templateString`aws ec2 --region ${'region'} authorize-security-group-egress --group-id ${'groupId_flag'} --ip-permissions IpProtocol=tcp,FromPort=8200,ToPort=8200,IpRanges='[{CidrIp=${'cidrIp_tcp_port8200'}}]'`;

export default class PageHvnPeeringsConfigurationAccordionAwsCliComponent extends Component {
  @service router;

  @tracked awsSecurityGroupId;
  @tracked awsRouteTableId;

  scriptRegex = /\n/g;

  constructor(owner, args) {
    super(owner, args);
    this.awsSecurityGroupId = '';
    this.awsRouteTableId = '';
  }

  @action
  updateQueryParams(queryParams = {}) {
    return this.router.transitionTo(this.router.currentRouteName, {
      queryParams,
    });
  }

  get routeTableCommand() {
    let { cidrBlock, providerPeeringId, region } = this.args.connection;
    let { awsRouteTableId } = this;
    return `aws ec2 --region ${region}
    create-route --route-table-id ${awsRouteTableId}
    --destination-cidr-block ${cidrBlock}
    --vpc-peering-connection-id ${providerPeeringId}`.replace(
      this.scriptRegex,
      ' '
    );
  }

  get consulAuthorizeSecurityGroupIngress() {
    let { region, cidrBlock } = this.args.connection;
    let { awsSecurityGroupId } = this;
    let string = consulIngress({
      region: region,
      groupId: awsSecurityGroupId,
      cidrBlock: cidrBlock,
    });

    return string.replace(this.scriptRegex, ' ');
  }

  get consulAuthorizeSecurityGroupEgress() {
    let { region, cidrBlock } = this.args.connection;
    let { awsSecurityGroupId } = this;

    let string = consulEgressAgentless({
      region: region,
      groupId: awsSecurityGroupId,
      cidrBlock: cidrBlock,
    });

    return string.replace(this.scriptRegex, ' ');
  }

  get vaultAuthorizeSecurityGroupIngress() {
    let { region, cidrBlock } = this.args.connection;
    let { awsSecurityGroupId } = this;
    let string = vaultTemplate({
      region: region,
      groupId_flag: awsSecurityGroupId,
      cidrIp_tcp_port8200: cidrBlock,
      groupId_tcp_port8200: this.awsSecurityGroupId,
    });
    return string.replace(this.scriptRegex, ' ');
  }
}
