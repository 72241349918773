/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

/**
 *
 * `PageOrganizationsDetailUserPrincipalsCard` is a card for the organization
 * dashboard that renders service principal data and a link to the service
 * principal list page.
 *
 *
 * ```
 * <Page::Organizations::Detail::UserPrincipalsCard
 *   @organization={{this.organization}}
 * />
 * ```
 *
 * @class PageOrganizationsDetailUserPrincipalsCard
 *
 */

/**
 * The organization in context.
 * @argument organization
 * @type {Object}
 */

export default class PageOrganizationsDetailUserPrincipalsCardComponent extends Component {}
