import Application from '@ember/application';
import Resolver from 'ember-resolver';
import { importSync, isDevelopingApp, macroCondition } from '@embroider/macros';
import loadInitializers from 'ember-load-initializers';
import engineInterface from 'common/utils/engines/interface';
import config from 'hcp/config/environment';
import { appVersion } from 'hcp/helpers/app-version';
import * as Sentry from '@sentry/ember';
import { readPageConfig } from './instance-initializers/page-config';

if (macroCondition(isDevelopingApp())) {
  importSync(`common/utils/deprecation-workflow`);
}

let dependencies = engineInterface({ isApp: true });

let billingDeps = engineInterface({ isApp: true });
billingDeps.services = dependencies.services.slice();
billingDeps.services.push('billing-config');

let roleAssignmentsDeps = engineInterface({ isApp: true });
roleAssignmentsDeps.services = dependencies.services.slice();
roleAssignmentsDeps.services.push('principals');
roleAssignmentsDeps.services.push('role-assignments-config');

let secretsDeps = engineInterface({ isApp: true });
secretsDeps.services = dependencies.services.slice();
secretsDeps.services.push('principals');
secretsDeps.services.push('role-assignments-config');

let pageConfig = readPageConfig();

Sentry.init({
  dsn: pageConfig.sentry?.dsn,
  enabled: pageConfig.sentry?.enabled,
  environment: pageConfig.deployTarget,
  ignoreErrors: [/TransitionAborted/],
  release: appVersion(null, { shaOnly: true }),
  denyUrls: [/.+\/login\/callback/],

  // Set tracesSampleRate to 10% in prod, otherwise 30%
  tracesSampleRate:
    pageConfig.deployTarget === config.DEPLOY_TARGETS.PROD ? 0.1 : 0.3,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  //replaysSessionSampleRate: 0.1,
  //replaysOnErrorSampleRate: 1.0,
});

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;

  constructor() {
    super(...arguments);

    this.engines = {
      billing: {
        dependencies: billingDeps,
      },
      boundary: {
        dependencies,
      },
      consul: {
        dependencies,
      },
      monitoring: {
        dependencies,
      },
      packer: {
        dependencies,
      },
      ['role-assignments']: {
        dependencies: roleAssignmentsDeps,
      },
      secrets: {
        dependencies: secretsDeps,
      },
      snapshots: {
        dependencies,
      },
      vagrant: {
        dependencies,
      },
      vault: {
        dependencies,
      },
      waypoint: {
        dependencies,
      },
      webhooks: {
        dependencies,
      },
    };
  }
}

loadInitializers(App, config.modulePrefix);
