/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';

/**
 *
 * `IamInvitationsBasicRoleChartToggle` renders a button with an icon and text
 * for the button that will toggle the BasicRoleChartFlyout.
 *
 *
 * ```
 * <Iam::Invitations::BasicRoleChart::ToggleButton
 *   @onClick={{this.onClick}}
 * />
 * ```
 *
 * @class IamInvitationsBasicRoleChartToggleButton
 *
 */

export default class IamInvitationsBasicRoleChartToggleButtonComponent extends Component {
  /**
   * The function to pass onto the button for click events.
   * @argument onClick
   * @type {Function}
   */

  /**
   * Retrieves the onClick handler from component arguments.
   *
   * @returns {Function} The onClick handler function from the arguments.
   */
  @action
  onClick() {
    const { onClick } = this.args;

    if (onClick && typeof onClick === 'function') {
      return onClick();
    }
  }
}
