/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { assert } from '@ember/debug';

export default class IamGroupsSearchPrincipals extends Component {
  /**
 *
 * `IamGroupsSearchPrincipals` returns the response from iamServiceSearchPrincipals to a @searchCallBack
 *  The response is a list of eligible principal members that can be added to an HCP Group.
 *  iamServiceSearchPrincipals is triggered when there is text in the input field and the users presses down on the return key.
 *  If there is no value in the input and a user hits return, the API is not called and @startingList is sent to @searchCallBack.
 *  The same thing happens when a user clicks the escape key, the API is not called and @startingList is sent to @searchCallBack.
 *
 *
 * ```
    <Iam::Groups::Search::Principals
      @mode={{@mode}}
      @resourceName={{@resourceName}}
      @searchCallBack={{this.setPrincipalsList}}
      @startingList={{@eligibleMembers}}
    />
 * ```
 *
 * @class IamGroupsMembersUpdateListTable
 *
 */

  /**
   * mode is a string that represents whether a user is adding members or editing a member list.
   * It takes two values `add` or `edit`.
   * @argument mode
   * @type {String}
   * @required
   */

  /**
   * `resourceName is the V2 resource name, in this case the resource is an HCP Group
   * @argument resourceName
   * @type {String}
   * @required
   */

  /**
   * `searchCallBack` is a callback function that takes the iamServiceSearchPrincipals response as a parameter
   * @argument searchCallBack
   * @type {Function}
   * @required
   */

  /**
   * `startingList is an array of eligible principal member objects.
   * @argument startingList
   * @type {Array}
   * @required
   */

  constructor() {
    super(...arguments);

    assert(
      '<Iam::Groups::Search::Principals> argument @mode is required and must be a string',
      typeof this.args?.mode === 'string' && !!this.args?.mode
    );

    assert(
      '<Iam::Groups::Search::Principals> argument @resourceName is required and must be a string',
      typeof this.args?.resourceName === 'string' && !!this.args?.resourceName
    );

    assert(
      '<Iam::Groups::Search::Principals> argument @eligibleMembers is required and must be an array',
      typeof this.args?.startingList === 'object' && !!this.args?.startingList
    );
  }

  @service api;
  @service userContext;
  @service groupMembers;

  @task
  *searchPrincipals(evt) {
    const { organization } = this.userContext;
    let filter;
    let resourceName = this.args.resourceName;
    if (evt.keyCode === 13 && evt.target.value !== '') {
      // Edit searches for eligible principal group members in the organization
      if (this.args.mode === 'edit') {
        filter = {
          organizationId: organization.id,
          searchText: evt.target.value,
          principalTypes: ['PRINCIPAL_TYPE_USER'],
        };
      }

      // Add searches for eligible principal group members that are not already in the group
      if (this.args.mode === 'add') {
        filter = {
          organizationId: organization.id,
          searchText: evt.target.value,
          principalTypes: ['PRINCIPAL_TYPE_USER'],
          excludeMembersOfGroupNames: [resourceName],
        };
      }

      let { principals } = yield this.api.iam.iamServiceSearchPrincipals(
        organization.id,
        { filter }
      );
      this.args.searchCallBack(principals);
    } else {
      this.args.searchCallBack(this.args.startingList);
    }

    if (evt.keyCode === 27) {
      this.args.searchCallBack(this.args.startingList);
    }
  }
}
