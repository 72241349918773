import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import config from '../config/environment';
import { clearRedirectTarget, getRedirectTarget } from 'hcp/utils/redirect';

export default class SignInRoute extends Route {
  @service config;
  @service session;
  @service router;

  queryParams = {
    connection: {
      replace: true,
      refreshModel: true,
      // Map "with" to "connection" to avoid reserved word conflicts.
      as: 'with',
    },
    conn_id: {
      replace: true,
      as: 'conn-id',
    },
    error: {
      replace: true,
    },
    from: {
      replace: true,
    },
  };

  async beforeModel() {
    if (getRedirectTarget() === '/sign-up') {
      clearRedirectTarget();
      return this.router.transitionTo('sign-up');
    }
    this.session.prohibitAuthentication(config.APP.homeRoute);
  }

  async model({ connection, conn_id, error, from }) {
    if (conn_id !== undefined) {
      await this.session.authenticate('authenticator:cloud-idp', { conn_id });
    }

    if (from !== undefined) {
      await this.session.authenticate('authenticator:cloud-idp', {});
    }

    return {
      connection,
      error,
    };
  }

  resetController(controller) {
    controller.error = '';
  }
}
