/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

/**
 *
 * `PageHvnsDetail` renders the HashiCorp Virtual Networks Detail page.
 *
 *
 * ```
 * <Page::Hvns::Detail
 *   @model={{@model}}
 * />
 * ```
 *
 * @class PageHvnsDetail
 *
 */

/**
 *
 * `model` is an object with an HVN `network` key and a `dependencies` key.
 * @argument model;
 * @type {Object}
 *
 */
export default class PageHvnsDetailComponent extends Component {}
