/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

/**
 *
 * `PageSignUp` renders the page for the sign-up route. There are two states for this page - the normal page that a user would seee before authenticating, and a page will different messaging that a user would encounter if they initially did the "sign-in" flow but didn't yet have an account on HCP.
 *
 *
 * ```
 * <Page::SignUp />
 * ```
 *
 * @class PageSignUp
 *
 */

export default class PageSignUpComponent extends Component {
  @service router;

  @action
  signUp() {
    this.router.transitionTo('cloud');
  }
}
