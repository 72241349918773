import Route from '@ember/routing/route';

import permissionGuard from 'core/decorators/permission-guard';
import {
  PREFIX_NETWORK_ROUTES,
  ACTION_CREATE,
} from 'authz/utils/permission-types/index';

export default class CloudOrgsDetailProjectsDetailHvnsDetailTransitGatewayAttachmentsCreateRoute extends Route {
  @permissionGuard({
    permission: `${PREFIX_NETWORK_ROUTES}.${ACTION_CREATE}`,
  })
  async model(params) {
    return {
      ...this.modelFor(
        'cloud.orgs.detail.projects.detail.hvns.detail.transit-gateway-attachments.detail'
      ),
      ...params,
    };
  }
}
