/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { getOwner } from '@ember/owner';
import { assert } from '@ember/debug';

export default class IamGroupsMembersUpdateListTable extends Component {
  /**
 *
 * `IamGroupsMembersUpdateListTable` renders a list of
 *  eligible principal members for an HCP Group with a base checkbox
 *  and individual checkboxes on each member row.
 *
 *
 * ```
    <Iam::Groups::Members::UpdateListTable
      @submitBtnText={{t "components.iam.groups.form.add-group-members.cta"}}
      @group={{@model.group}}
      @eligibleMembers={{@model.eligibleMembers}}
      @mode="add"
    />
 * ```
 *
 * @class IamGroupsMembersUpdateListTable
 *
 */

  /**
   * `submitBtnText` is a string that renders on the submit button
   * @argument submitBtnText
   * @type {String}
   * @required
   */

  /**
   * `resourceName is the V2 resource name
   * @argument resourceName
   * @type {String}
   * @required
   */

  /**
   * eligibleMembers is an array of eligible principal member objects.
   * @argument eligibleMembers
   * @type {Array}
   * @required
   */

  /**
   * mode is a string that represents whether a user is adding members or editing a member list.
   * It takes two values `add` or `edit`.
   * @argument mode
   * @type {String}
   * @required
   */

  constructor() {
    super(...arguments);

    assert(
      '<Iam::Groups::Members::UpdateListTable> argument @submitBtnText is required and must be a string',
      typeof this.args?.submitBtnText === 'string' && !!this.args?.submitBtnText
    );

    assert(
      '<Iam::Groups::Members::UpdateListTable> argument @group.resourceName must be a non-empty string',
      typeof this.args?.group?.resourceName === 'string' &&
        !!this.args?.group?.resourceName
    );

    assert(
      '<Iam::Groups::Members::UpdateListTable> argument @group.displayName must be a non-empty string',
      typeof this.args?.group?.displayName === 'string' &&
        !!this.args?.group?.displayName
    );

    assert(
      '<Iam::Groups::Members::UpdateListTable> argument @eligibleMembers is required and must be an array',
      Array.isArray(this.args?.eligibleMembers)
    );

    assert(
      '<Iam::Groups::Members::UpdateListTable> argument @mode is required and must be a string',
      typeof this.args?.mode === 'string' && !!this.args?.mode
    );
  }

  @service api;
  @service intl;
  @service flashMessages;
  @service router;
  @service userContext;
  @service groupMembers;
  @tracked basePrincipalCheckbox = document.getElementsByClassName(
    'principal-members-base-checkbox'
  );
  @tracked eligibleMembers = this.args.eligibleMembers; //The initial list of unchecked/unselected principals
  @tracked error = false;
  @tracked errorText = '';
  @tracked isCheckedCount;
  @tracked currentListCheckCount;
  @tracked theInputs = document.getElementsByClassName(
    'group-principal-member-checkbox'
  );
  @task
  *setBaseCheckboxState() {
    // known bug  - https://hashicorp.atlassian.net/browse/HCPIE-633
    yield this.principalInputs;

    let isCheckedCount = 0;
    this.principalInputs.map((input) =>
      input.checked ? isCheckedCount++ : ''
    );
    // If isCheckedCount is at least 1 or more
    // set the base checkbox state to indeterminate
    this.basePrincipalCheckbox[0].indeterminate = isCheckedCount ? true : false;

    // If a user individually checks all the principals in the list
    // set the base checkbox state to checked
    if (isCheckedCount === this.principalInputs.length) {
      this.basePrincipalCheckbox[0].indeterminate = false;
      this.basePrincipalCheckbox[0].checked = true;
    }
    // If a user individually unchecks all the principals in the list
    // set the base checkbox state to unchecked
    if (isCheckedCount === 0) {
      this.basePrincipalCheckbox[0].indeterminate = false;
      this.basePrincipalCheckbox[0].checked = false;
    }
  }

  get principalInputs() {
    return Array.from(
      document.getElementsByClassName('group-principal-member-checkbox')
    );
  }

  @action
  setPrincipalsList(principals) {
    let principalsList = principals;
    principalsList.map((principal) => {
      let indexToAdd = this.groupMembers.idsToAdd.indexOf(principal.id);
      let indexToRemove = this.groupMembers.idsToRemove.indexOf(principal.id);
      let indexCurrentMember = this.groupMembers.idsOfCurrentMembers.indexOf(
        principal.id
      );

      if (indexCurrentMember === -1 && indexToAdd > -1) {
        principal.checked = true;
      } else if (indexCurrentMember > -1 && indexToRemove > -1) {
        principal.checked = false;
      } else if (indexCurrentMember > -1 && indexToRemove === -1) {
        principal.checked = true;
      }
    });

    this.eligibleMembers = principalsList;
  }

  @action
  addOrRemoveEligibleMember(principal, evt) {
    this.error = false;
    evt.target.checked
      ? this.groupMembers.addMemberById(principal.id)
      : this.groupMembers.removeMemberById(principal.id);

    this.setBaseCheckboxState.perform();
  }
  @action
  selectAllMembers() {
    this.principalInputs.forEach((input) => {
      input.checked = true;
      let index = this.groupMembers.idsToAdd.indexOf(input.id);
      if (index === -1) {
        this.groupMembers.addMemberById(input.id);
      }
    });
    this.basePrincipalCheckbox[0].checked = true;
  }

  @action
  deselectAllMembers() {
    this.principalInputs.forEach((input) => {
      input.checked = false;
      this.groupMembers.removeMemberById(input.id);
    });
    this.basePrincipalCheckbox[0].checked = false;
  }

  @action
  checkOrUncheckAll() {
    if (this.basePrincipalCheckbox[0].checked) {
      this.selectAllMembers();
      this.isCheckedCount = this.principalInputs.length;
    }

    if (!this.basePrincipalCheckbox[0].checked) {
      this.deselectAllMembers();
      this.isCheckedCount = this.principalInputs.length;
    }
  }

  @task
  *updateGroupMembers() {
    // show error message when attempting to save without changes
    if (!this.groupMembers.hasChanged) {
      this.error = true;
      this.args.mode === 'edit'
        ? (this.errorText = this.intl.t(
            'components.page.access-control.groups.detail.members.edit-members.error'
          ))
        : (this.errorText = this.intl.t(
            'components.page.access-control.groups.detail.members.add-members.error'
          ));
      return;
    }

    let { resourceName } = this.args.group;

    let payload = {
      resourceName,
      memberPrincipalIdsToAdd: this.groupMembers.idsToAdd,
      memberPrincipalIdsToRemove: this.groupMembers.idsToRemove,
    };

    this.groupMembers.reset();

    let successTitle;
    let errorTitle;
    if (this.args.mode === 'add') {
      successTitle = this.intl.t(
        'components.iam.groups.form.add-group-members.success.title'
      );
      errorTitle = this.intl.t(
        'components.iam.groups.form.add-group-members.error.title'
      );
    }
    if (this.args.mode === 'edit') {
      successTitle = this.intl.t(
        'components.iam.groups.form.edit-group-members.success.title'
      );
      errorTitle = this.intl.t(
        'components.iam.groups.form.edit-group-members.error.title'
      );
    }

    // this.groupMembers.hasChanged - we can use this to show an alert or validation error somewhere on the page

    try {
      yield this.api.groups.groupsServiceUpdateGroupMembers(
        resourceName,
        payload
      );

      //refresh the Group detail and list routes to update the model
      const detailRoute = getOwner(this).lookup(
        'route:cloud.access-control.groups.detail'
      );
      const listRoute = getOwner(this).lookup(
        'route:cloud.access-control.groups.list'
      );
      detailRoute.refresh();
      listRoute.refresh();

      this.flashMessages.success(successTitle);
      return this.router.transitionTo('cloud.access-control.groups.detail');
    } catch (err) {
      this.flashMessages.error(errorTitle);
      return this.router.transitionTo('cloud.access-control.groups.detail');
    }
  }
}
