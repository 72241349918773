import * as cloudUiIndex from 'core/utils/cloud-ui-index';

export function initialize(appInstance) {
  const config = appInstance.resolveRegistration('config:environment');

  const marketingEmailService = appInstance.lookup('service:marketing-email');

  switch (config?.APP?.deployTarget) {
    case config?.DEPLOY_TARGETS.PROD:
      return (marketingEmailService.config = cloudUiIndex.PRODUCTION);

    case config?.DEPLOY_TARGETS.INT:
      return (marketingEmailService.config = cloudUiIndex.INTEGRATION);

    case config?.DEPLOY_TARGETS.DEV:
      return (marketingEmailService.config = cloudUiIndex.DEVELOPMENT);

    case config?.DEPLOY_TARGETS.CI:
      // NOTE: The development URL for Cloud UI is mocked + intercepted in Mirage
      return (marketingEmailService.config = cloudUiIndex.DEVELOPMENT);
  }
}

export default {
  name: 'marketing-email',
  after: ['page-config'],
  initialize,
};
