/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

/**
 *
 * `PageRolesDetailComponent` renders the details of a role and its nested permissions.
 *
 *
 * ```
 * <Page::Roles::Detail
 *   @model={{model}}
 * />
 * ```
 *
 * @class PageRolesDetailComponent
 *
 */
/**
 * Contains the `role` and 'organization'.
 * @argument model
 * @type {Object}
 */
export default class PageRolesDetailComponent extends Component {}
