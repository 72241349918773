import { helper as buildHelper } from '@ember/component/helper';
import {
  DEFAULT_ERROR_CODE,
  ERROR_CODE_MAPPING,
} from 'hcp/utils/error-code-scale';

export function optionForGlobalError([code = 'default']) {
  let option =
    ERROR_CODE_MAPPING[code] || ERROR_CODE_MAPPING[DEFAULT_ERROR_CODE];

  return {
    ...option,
  };
}

export default buildHelper(optionForGlobalError);
