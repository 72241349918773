/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

/**
 *
 * ## Overview
 * `ServicePrincipalDeleteModalComponent` renders Core::Modal::Confirm with or without a block of body.
 *  The body block renders only if a Service principal has one or more resources, in this case keys or
 *  workload identity providers.  This component requires a callback method so that users
 *  can delete a Service principal.
 *
 * @param {Function} onCancel - Callback method to reset servicePrincipal
 * @param {Function} onSubmit - Callback method to delete servicePrincipal
 * @param {Object} servicePrincipalToDelete - HCP service principal to delete
 * @param {Array} keys - an array of key resources that are attached to a service principal
 * @param {Array} wips - an array of workload identity provider resources that are attached to a service principal
 *
 * @example
 * <ServicePrincipal::DeleteModal
 *  @onCancel={{this.onCancel}}
 *  @onSubmit={{this.onSubmit}}
 *  @servicePrincipalToDelete={{this.servicePrincipal}}
 *  @keys={{this.keys}}
 *  @wips={{this.wips}}
 * />
 *
 * @class ServicePrincipalDeleteModalComponent
 *
 */

export default class ServicePrincipalDeleteModalComponent extends Component {}
