/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';

/**
 *
 * `PageHvnsCardsFlowDiagram` this renders the HVN Flow Diagram image.
 *
 *
 * ```
 * <Page::Hvns::Cards::FlowDiagram/>
 * ```
 *
 * @class PageHvnsCardsFlowDiagram
 *
 */

export default class PageHvnsCardsFlowDiagramComponent extends Component {}
