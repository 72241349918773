import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { TYPE_NETWORK_PEERING } from 'common/utils/cloud-resource-types';

export default class CloudOrgsDetailProjectsDetailHvnsDetailPeeringsDetailRoute extends Route {
  @service api;

  async model({ peering_id }) {
    let { organization } = this.modelFor('cloud.orgs.detail');
    let { project } = this.modelFor('cloud.orgs.detail.projects.detail');
    let { network } = this.modelFor(
      'cloud.orgs.detail.projects.detail.hvns.detail'
    );
    let { peering } = await this.api.network.getPeering(
      organization.id,
      project.id,
      network.id,
      peering_id
    );

    let { routes } = await this.api.network.listHVNRoutes(
      organization.id,
      project.id,
      network.id,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      peering_id,
      TYPE_NETWORK_PEERING,
      undefined,
      undefined,
      undefined
    );

    return {
      network,
      organization,
      peering,
      project,
      routes,
    };
  }
}
