/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import IamPolicy from 'core/utils/iam-policy';
import { MEMBER_TYPE_SERVICE_PRINCIPAL } from 'core/helpers/rbac-member-types';
import { MAX_UPDATE_POLICY_RETRIES } from '../../create';
import { getOwner } from '@ember/owner';
import getProjectIamAssignmentTrackingMetadata from 'hcp/utils/iam/get-project-iam-assignment-tracking-metadata';
import { PROJECT_IAM_ASSIGNMENT } from 'core/utils/consts/analytics-events/platform';
import { HashicorpCloudResourcemanagerPolicyBindingMemberType } from '@clients/cloud-resource-manager';

const baseKey = 'components.page.access-control.service-principals.edit';

/**
 *
 * `PageServicePrincipalsProjectDetailEdit` form to edit a service principal for a project and assign a new role.
 *
 * ```
 * <Page::ServicePrincipals::Project::Detail::Edit
 *   @project={{...}}
 *   @role={{...}}
 *   @servicePrincipal={{...}}
 * />
 * ```
 *
 * @class PageServicePrincipalsProjectDetailEdit
 *
 */

export default class PageServicePrincipalsProjectDetailEditComponent extends Component {
  /**
   * @argument project
   * @type {Object}
   * @required
   */

  /**
   * @argument role
   * @type {String}
   * @required
   */

  /**
   * @argument servicePrincipal
   * @type {Object}
   * @required
   */

  @service api;
  @service flashMessages;
  @service intl;
  @service router;
  @service analytics;

  @task
  *saveV2(policy) {
    const route = getOwner(this).lookup('route:cloud.access-control');
    const { project, servicePrincipal } = this.args;

    try {
      yield this.api.resourceManager.project.projectServiceSetIamPolicy(
        project.id,
        { policy }
      );

      const trackingMetadata = getProjectIamAssignmentTrackingMetadata(
        HashicorpCloudResourcemanagerPolicyBindingMemberType.SERVICEPRINCIPAL,
        'edit',
        policy,
        this.args.rawPolicy,
        project?.id,
        this.args?.servicePrincipal?.id
      );

      this.analytics.trackEvent(PROJECT_IAM_ASSIGNMENT, trackingMetadata);

      this.flashMessages.success(
        this.intl.t(`${baseKey}.success.title`, { name: servicePrincipal.name })
      );

      if (route) {
        // We need to refresh this before transitioning because if this is a
        // child route, the parent route will not call the model hook again when
        // transitioning upward even if data was changed in the child.
        route.refresh();
        this.router.transitionTo(
          'cloud.access-control.service-principals.list'
        );
      }
    } catch (e) {
      this.flashMessages.warning(
        this.intl.t(`${baseKey}.warning.title`, {
          content: `${baseKey}.warning.content`,
        })
      );
    }
  }

  @task
  *save(form) {
    let { updated } = yield* this.updateRole(form.fields.role);
    if (updated) {
      this.flashMessages.success(
        this.intl.t(`${baseKey}.success.title`, { name: form.fields.name })
      );
    } else {
      this.flashMessages.warning(
        this.intl.t(`${baseKey}.warning.title`, {
          content: `${baseKey}.warning.content`,
        })
      );
    }

    return this.router.transitionTo(
      'cloud.access-control.service-principals.list'
    );
  }

  *updateRole(role) {
    const projectId = this.args.project.id;

    for (let i = 0; i < MAX_UPDATE_POLICY_RETRIES; i++) {
      try {
        let { policy } =
          yield this.api.resourceManager.project.projectServiceGetIamPolicy(
            projectId
          );
        let policyToUpdate = new IamPolicy(policy);
        policyToUpdate.addMember(
          this.args.servicePrincipal.id,
          MEMBER_TYPE_SERVICE_PRINCIPAL.value,
          role
        );
        let updatedPolicy = policyToUpdate.get();
        let policyPayload = {
          policy: {
            ...updatedPolicy,
          },
        };
        return {
          updated: true,
          response:
            yield this.api.resourceManager.project.projectServiceSetIamPolicy(
              projectId,
              policyPayload
            ),
        };
      } catch (e) {
        if (e.status === 409) {
          continue;
        }
        throw e;
      }
    }

    return {
      updated: false,
    };
  }
}
